
































import {Vue } from 'vue-property-decorator';
import GroupListModalTemplate from '@/components/templates/GroupListModalTemplate/GroupListModalTemplate.vue';
import * as Config from '@/config';
import store from '@/store';
export interface DataType {
  perPage: number;
  currPage: number;
  keyword: string;
  scorp: any;
  loading: boolean;
  errorMessageLogs: string;
  successMessageLogs: string;
  dataGroupsModal: any;
  rowinErrDialog: number;
  successItemProcess: number;
  errorMessageProcess: any;
  progress: number;
  isFinishProcess: boolean;
  totalDataRowProcess: number;
  isGroupEditModalShow: boolean;
  authority: any;
}
export default Vue.extend({
  name: 'GateListModalPage',
  components: {
    GroupListModalTemplate,
  },
  data: (): DataType => ({
    perPage: Config.PAGE_DEFAULT,
    currPage: 1,
    scorp: ['groupName'],
    keyword: '',
    loading: false,
    errorMessageLogs: '',
    successMessageLogs: '',
    dataGroupsModal: {
      dataOri: [],
      data: [],
      total: 0,
    },
    rowinErrDialog: 0,
    successItemProcess: 0,
    errorMessageProcess: [],
    progress: 0,
    isFinishProcess: false,
    totalDataRowProcess: 0,
    isGroupEditModalShow: false,
    authority: store.state.auth,
  }),
  created() {
    //
    if (typeof localStorage.isVaildDataGroupModal === 'undefined') {
      localStorage.setItem('isVaildDataGroupModal', 'true');
    }
    if (typeof localStorage.groupModalSortKey === 'undefined') {
      localStorage.setItem('groupModalSortKey', 'registered');
    }
    if (typeof localStorage.groupModalSortName === 'undefined') {
      localStorage.setItem('groupModalSortName', 'desc');
    }
    if (typeof localStorage.pageSizeGroupModal === 'undefined') {
      localStorage.setItem('pageSizeGroupModal', Config.PAGE_DEFAULT.toString());
      this.perPage = Config.PAGE_DEFAULT;
    } else {
      this.perPage = parseInt(localStorage.pageSizeGroupModal);
    }
    this.getListGroupsModal();
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  mounted() {
    //
  },
  methods: {
    //
    changePerPage(value: any): any {
      this.perPage = value;
      localStorage.setItem('pageSizeGroupModal', value);
      if (this.authority.isAdmin) {
        this.getListGroupsModal();
      } else {
        this.getListGroupUserLoginChange();
      }
    },
    changePage(value: any): any {
      this.currPage = value;
      if (this.authority.isAdmin) {
        this.getListGroupsModal();
      } else {
        this.getListGroupUserLoginChange();
      }
    },
    searchGroup(value: any): any {
      this.keyword = value.text;
      this.scorp = value.targets;
      if (this.authority.isAdmin) {
        this.getListGroupsModal();
      } else {
        this.getListGroupUserLoginChange();
      }
    },
    getListGroupsModal(): any {
      this.resetData();
      if (this.authority.isAdmin) {
        this.getListGroups();
      } else {
        this.loading = true;
        this.getListGroupUserLogin(1);
      }
    },
    getListGroupUserLoginChange() {
        this.loading = true;
        let dataOri = this.dataGroupsModal.dataOri;
        if (this.keyword !== '') {
          dataOri = this.dataGroupsModal.dataOri.filter((element: any) =>
          element.name.toLowerCase().includes(this.keyword.toLowerCase()) === true);
        }
        const data  = Array.of(...dataOri);
        this.dataGroupsModal.data =
        data.slice((this.currPage - 1) * this.perPage, this.currPage * this.perPage);
        setTimeout(() => {
          this.loading = false;
        }, 100);
      },
    getListGroupUserLogin(page: any) {
      const apiLink = Config.GROUP_ADMINISTRATOR_API +
        '?userId=' + encodeURIComponent(localStorage.userProfileId ) + '&limit=1000' + '&page=' + page;
      this.$http.get(apiLink)
        .then(async (response: any) => {
          if (response.body.maxRecords > 0) {
            const data = response.body.groupAdministrators;
            const item: any = [];
            for (let i = 0; i < data.length; i += 10) {
              const chunk = data.slice(i, i + 10);
              const arrayPromise: any = [];
              for (const [index, element] of  chunk.entries()) {
                element.isData = false;
                this.dataGroupsModal.dataOri.push(element);
                const result: any = this.getGroupDetail(element);
                arrayPromise.push(result);
              }
              await Promise.all(arrayPromise);
            }
            const dataResult = this.dataGroupsModal.dataOri.filter((element: any) =>  element.isData === true);
            this.dataGroupsModal.data  = dataResult;
            this.dataGroupsModal.total = dataResult.length;
            this.currPage = 1;
            if (response.body.maxRecords > response.body.endRecord) {
              page++;
              this.getListGroupUserLogin(page);
            } else {
              //
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.loading = false;
        });
    },
    getGroupDetail(item: any) {
        return new Promise((resolve, reject) => {
          let apiLink = Config.GROUP_LIST_API + '?groupId=' + encodeURIComponent(item.groupId) + '&isExceptOptionalGate=true';
          if (localStorage.isVaildDataGroupModal === 'true') {
            apiLink += '&isValid=true';
          }
          this.$http.get(apiLink)
            .then((response: any) => {
              const index = this.dataGroupsModal.dataOri.findIndex((x: any) =>
              x.groupId === item.groupId);
              if (index >= 0) {
                this.dataGroupsModal.dataOri[index] = response.body.groups[0];
                this.dataGroupsModal.dataOri[index].isData = true;
              }
              resolve(true);
            })
            .then( null, (err: any) => {
              resolve(false);
            }); // catch
        });
    },
    getListGroups() {
      this.loading = true;
      let apiLink = Config.GROUP_LIST_API + '?limit=' + localStorage.pageSizeGroupModal +
      '&page=' + this.currPage + '&sortKey=' +
      localStorage.groupModalSortName + ':' + localStorage.groupModalSortKey;
      if (this.keyword !== null && this.keyword !== undefined && this.keyword !== '') {
        apiLink += '&keyword=' + this.keyword + '&scorp=' + this.scorp;
      }
      if (localStorage.isVaildDataGroupModal === 'true') {
        apiLink += '&isValid=true';
      }
      apiLink += '&embed=passableTimeSetting&isExceptOptionalGate=true';
      this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.groups)) {
            this.dataGroupsModal.data = response.body.groups;
            this.dataGroupsModal.total = response.body.maxRecords;
            this.currPage = Math.ceil(response.body.startRecord / this.perPage);
          } else {
            this.dataGroupsModal.total = 0;
            this.currPage = 1;
          }
          this.loading = false;
        })
        .then( null, (err: any) => {
          this.loading = false;
          switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
        }); // catch
    },
    async clickGroupsAction(eventArgs: any) {
      this.rowinErrDialog = 0;
      this.errorMessageProcess = [];
      this.successItemProcess = 0;
      this.progress = 0;
      this.isFinishProcess = false;

      this.totalDataRowProcess = eventArgs.data.length;
      const percent = (100 / this.totalDataRowProcess);

      for (const item of  eventArgs.data) {
        let result;
        if (eventArgs.typeAction === 0) {
          //  enable
          result = await this.enableOrDisableGroup(item, true);
        } else if (eventArgs.typeAction === 1) {
          //  disable
          result = await this.enableOrDisableGroup(item, false);
        }
        if (result) {
          this.successItemProcess++;
        }
        this.progress += percent;
      }
      this.isFinishProcess = true;
    },
    enableOrDisableGroup(groupId: string, isEnable: boolean) {
      return new Promise((resolve) => {
        const data = {
          isValid: isEnable,
        };
        this.$http.put(Config.GROUP_LIST_API + '/' + encodeURIComponent(groupId), data)
        .then((response: any) => {
          console.log(response);
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = 'groupIdが存在しない。';
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = groupId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        });
      });
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.errorMessageLogs = '';
      this.successMessageLogs = '';
      this.dataGroupsModal = {
        dataOri: [],
        data: [],
        total: 0,
      };
    },
    clickAddGroup(eventArgs: any) {
      this.isGroupEditModalShow = true;
      localStorage.setItem('isEdit', 'false');
    },
    ChangeProperty(eventArgs: any) {
      this.isGroupEditModalShow = false;
      this.getListGroupsModal();
    },
    clickEditGroup(eventArgs: any) {
      this.isGroupEditModalShow = true;
      localStorage.setItem('isEdit', 'true');
    },
    getGroupIsValid(val: any) {
      localStorage.setItem('isVaildDataGroupModal', val.toString());
      this.getListGroupsModal();
    },
  },
});
