


















































































































































































































































































import Vue, { PropType } from 'vue';
import AffiliationAdd from '@/components/organisms/AffiliationListModalTable/AffiliationListModalAdd.vue';
import AffiliationTable from '@/components/organisms/AffiliationListModalTable/AffiliationListModalTable.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Searchbox, { SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import UsersAllowedTable from '@/components/organisms/UsersAllowedTable/UsersAllowedTable.vue';
import AccessPermissionGroupTable from '@/components/organisms/AccessPermissionGroupTable/AccessPermissionGroupTable.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import ModalTitle from '@/components/molecules/ModalTitle/ModalTitle.vue';
import * as Config from '@/config';

export interface DataType {
  searchTargetItems: SearchTargetItem[];
  selectedGroupIds: string[];
  actionItems: ActionItem[];
  showConfirm: boolean;
  showProgress: boolean;
  typeAction: number;
  isShow: boolean;
  departmentId: string;
  selectedUserIds: any;
  selectedGIds: any;
  listAff: any;
  departmentInfo: any;
  rulesValidate: any;
  dataAllow: any;
}
interface Type {
  groupId: string;
  groupName: string;
  userId: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
  userCount: number;
}
interface ArrayTime {
  passableTimeSettingId: string;
  name: string;
  dayTypeName: string;
  startTime: string;
  endTime: string;
}
export default Vue.extend({
  name: 'AffiliationListModalTemplate',
  components: {
    Searchbox,
    AffiliationTable,
    Breadcrumbs,
    ActionButton,
    UsersAllowedTable,
    AccessPermissionGroupTable,
    InputForm,
    ModalTitle,
    AffiliationAdd,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    dataGroupsModal: {
      type: Object,
      default: {
        data: [],
        total: 0,
      },
    },
    errorMessageProcess: {
      default: [],
    },
    userItems: {
      default: [],
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    isGetUser: {
      type: Boolean,
      default: false,
    },
      successItemProcess: {
      type: Number,
      default: 0,
    },
    expandData: {
      type: Object,
      default: ({}),
    },
    dataRelationUser: {
      type: Object,
      default: ({}),
    },
    dataRelationGroup: {
      type: Object,
      default: ({}),
    },
    groups: {
      type: Object,
      default: () => ({}),
    },
    authority: {
      type: Object,
      default: ({}),
    },
    isPage: {
      type: String,
      default: '',
    },
    department: {
      type: Object,
      default: ({}),
    },
  },
  data: (): DataType => ({
    rulesValidate: {
      requiredName: (name: string) => !!name || '所属名は必須です。',
    },
    listAff: [{
      userId: '',
      checkOne: false,
      checkTwo: false,
    }],
    departmentInfo: {},
    selectedUserIds: [],
    selectedGIds: [],
    departmentId: '',
    isShow: false,
    typeAction: 0,
    showConfirm: false,
    showProgress: false,
    searchTargetItems: [
      { label: '所属名', value: 'name', isDisabled: true },
    ],
    selectedGroupIds: [],
    actionItems: [
      { title: '有効化する', emitName: 'click-enable' },
      { title: '無効化する', emitName: 'click-disable' },
    ],
    dataAllow: [],
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: '所属一覧', disabled: true },
        ];
      },
    },
  },
  watch: {
    userItems(data: any) {
      if (data.length > 0) {
        const dataAllow  = Array.of(...data);
        this.dataAllow = dataAllow;
      }
    },
  },
  methods: {
    isAlert() {
      let userCount = 0;
      const i = this.dataGroupsModal.data.findIndex((x: any) =>  x.departmentId === this.departmentId);
      if (i >= 0) {
        userCount = this.dataGroupsModal.data[i].userCount;
      }
      return userCount > 0 ? true : false;
    },
    clickDeleteItem(departmentId: any) {
      this.departmentId = departmentId;
      this.showConfirm = true;
      this.typeAction = 3;
    },
    clickDeleteDepartment() {
      this.showProgress = true;
      this.showConfirm = false;
      this.typeAction = 3;
      this.$emit('delete-department', this.departmentId);
    },
    showDialogConfirm(type: any) {
      this.showConfirm = true;
      this.typeAction = type;
    },
    closeDialogConfirm(selectedGroupIds: any, type: any) {
      this.showConfirm = false;
    },
    clickGroupsAction() {
      this.showProgress = true;
      this.showConfirm = false;
      this.$emit('click-groups-action', {data: this.selectedGroupIds, typeAction: this.typeAction});
    },
    closeModalProgress() {
      this.showProgress = false;
      this.showConfirm = false;
      this.$emit('get-list-group-modal');
    },
    closeModalProgressDelete() {
      this.showProgress = false;
      this.showConfirm = false;
      this.$emit('get-list-group-modal');
    },
    clickAddGroupModal() {
      this.$router.push({ path: Config.GROUP_MODAL_ADD});
    },
    addAff() {
      this.isShow = true;
      this.$emit('load-group-and-user-for-add', {page: 1, perPage: 25});
    },
    addAffiliation() {
      this.listAff.push({
        userId: '',
        checkOne: false,
        checkTwo: false,
      });
    },
    clickBack() {
      this.isShow = false;
      this.departmentInfo = {};
      this.listAff = [{
        userId: '',
        checkOne: false,
        checkTwo: false,
      }];
      if (this.successMessageLogs !== '') {
        this.$emit('get-list-group-modal');
        this.$emit('get-list-user');
      }
    },
    changeAllow() {
    this.dataAllow.forEach((element: any, key: any) => {
      const check = this.listAff.some((item: any) => item.userId.value === element.value);
      if (check) {
        this.dataAllow[key].disabled = true;
      }
    });
  },
  },
});
