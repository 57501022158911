





































































































































































































































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import { Group } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import ModalTitle from '@/components/molecules/ModalTitle/ModalTitle.vue';
import * as Config from '@/config';
import store from '@/store';
import moment from 'moment';
export interface TypeItem {
  title: string;
}
export interface TypeDessert {
  name: string;
  isVaild: boolean;
}
export interface DataType {
  listIconType: any;
  listIconTypeForVirtualGate: any;
  listControlTime: any;
  listIsVisitor: any;
  listBuzzerPattern: any;
  listPassLenght: any;
  listBleIntensity: any;
  listBleIntensityForWafer: any;
  listAntiMode: any;
  inputIndex: any;
  filterSectionHeight: any;
  authority: any;
}

export interface TimeSettings {
  dayTypeId: number;
  dayTypeName: string;
  isAllDay: number;
  startTime: string;
  endTime: string;
}
export default Vue.extend({
  name: 'GateDetailTable',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    TextLink,
    ModalTitle,
  },
  props: {
    btnUnlock: {
      type: Boolean,
      default: false,
    },
    isRemoteUnlockValid: {
      type: Boolean,
      default: false,
    },
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    unlockTimes: {
      type: Array as PropType<TypeDessert[]>,
      default: () => [],
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    successMessageModal : {
      type: String,
      default: '',
    },
    errorMessageModal : {
      type: String,
      default: '',
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
    gateDetail: {
      default: {},
    },
    groupList: {
      default: [],
    },
    dayTypes: {
      type: Array,
      default: [],
    },
    dataUnlockingTime: {
      type: Array as PropType<TimeSettings[]>,
      default: [],
    },
    isFinishEditGate: {
      default: false,
    },
    loading: {
      default: false,
    },
    showModal: {
      default: false,
    },
    showConfirm: {
      default: false,
    },
  },
  data: (): DataType => ({
    filterSectionHeight: 0,
    listIconType: [
      { text: 'ドア', value: 1 },
      { text: 'ロッカー', value: 2 },
      { text: 'その他', value: 0 },
    ],
    listIconTypeForVirtualGate: [
      { text: 'その他', value: 0 },
    ],
    listControlTime: [
      { text: '0000－9999 S', value: 0 },
      { text: '000.0-999.9 S', value: 1 },
      { text: '00.00-99.99 S', value: 2 },
      { text: '0.000-9.999 S', value: 3 },
    ],
    listIsVisitor: [
      { text: 'ビジター', value: true },
      { text: 'メンバー', value: false },
    ],
    listBuzzerPattern: [
      { text: 'OFF', value: 0 },
      { text: '鳴動パターン１', value: 1 },
      { text: '鳴動パターン２', value: 2 },
    ],
    listPassLenght: [
      { text: '5桁', value: 5 },
      { text: '6桁', value: 6 },
      { text: '7桁', value: 7 },
      { text: '8桁', value: 8 },
    ],
    listBleIntensity: [
      { text: '非常に強い', value: 0 },
      { text: 'とても強い', value: 1 },
      { text: '強い', value: 2 },
      { text: 'やや強い', value: 3 },
      { text: '普通', value: 4 },
      { text: 'やや弱い', value: 5 },
      { text: '弱い', value: 6 },
      { text: 'とても弱い', value: 7 },
    ],
    listBleIntensityForWafer: [
      { text: 'とても強い', value: 1 },
      { text: '強い', value: 2 },
      { text: 'やや強い', value: 3 },
      { text: '普通', value: 4 },
      { text: 'やや弱い', value: 5 },
      { text: '弱い', value: 6 },
    ],
    listAntiMode: [
      { text: 'ログモード', value: 0 },
      { text: '通行禁止モード', value: 1 },
    ],
    inputIndex: {
        gateName: 1,
        isVisitor: 2,
        isNFCValid: 3,
        isTenKeyValid: 4,
        isGatePasswordValid: 5,
        gatePasswordLength: 6,
        gatePasswordSeed: 7,
        isTwoStepAuthValid: 8,
        bleIntensity: 9,
        buzzerPattern: 10,
        option1: 11,
        option2: 12,
        gateSNMain: 13,
        gateSNBLE: 14,
        gateFWMain: 15,
        gateFWBLE: 16,
        registered: 17,
        updated: 18,
        reported: 19,
        revisionDesired: 20,
        revisionReported: 21,
        voltage: 22,
        scanDate: 23,
        isValid: 24,
        needLocation: 25,
      },
      authority:  store.state.auth,
  }),
  watch: {
    loading(val) {
      if (val === false) {
        this.matchHeight();
      }
    },
  },
  mounted() {
    //
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '曜日',
            align: 'start',
            sortable: false,
            value: 'date',
          },
          {
            text: '期間指定',
            align: 'start',
            sortable: false,
            value: 'time',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
  },
  methods: {
    checkShowHide(makerCode: any, inputIndex: any) {
      inputIndex = inputIndex || -1;
      switch (inputIndex) {
        case this.inputIndex.isTwoStepAuthValid:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.isVisitor:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.isNFCValid:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.isTenKeyValid:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.isGatePasswordValid:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.gatePasswordLength:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.gatePasswordSeed:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.bleIntensity:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.buzzerPattern:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.gateSNMain:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.gateSNBLE:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.gateFWMain:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.gateFWBLE:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.registered:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.updated:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.reported:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.revisionDesired:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.revisionReported:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.voltage:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.scanDate:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.iconType:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.needLocation:
          if (makerCode !== 91) {
            return false;
          } else {
            return true;
          }
        default:
          if (makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
      }
    },
    checkShowHideAdvance(makerCode: any, customizable: any) {
      if (makerCode === 2 || makerCode === 91 || makerCode === 4) {
        return false;
      } else {
        if (customizable) {
          return true;
        } else {
          return false;
        }
      }
    },
    displayAntiSetting(isVisitor: any, makerCode: any) {
      if (!isVisitor && makerCode === 1) {
        return true;
      }
      return false;
    },
    matchHeight() {
      const colLeft = document.getElementById('col-left');
      const colRight = document.getElementById('col-right');
      setTimeout(() => {
        if (colRight && colLeft) {
          const heightRight = colRight.offsetHeight;
          const heightLeft = colLeft.offsetHeight;
          this.filterSectionHeight = heightRight > heightLeft ? heightRight : heightLeft;
        }
      }, 200);
    },
    formatTime(hours: string, minute: number | string) {
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}`;
    },
    clickGroup(ID: any, gateId: any) {
      this.$router.push({ path: Config.GROUP_DETAIL, query: {groupId: ID, goBack: 'gate/detail?gateId=' + gateId}});
    },
    clickEditGate(ID: any) {
      this.$router.push({ path: Config.GATE_LOCK_EDIT, query: {gateId: ID, goBack: 'gate-lock/detail?gateId=' + ID}});
    },
    converDate(t: any) {
      return (t !== '' && t !== 0) ? moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm:ss') : 'ー';
    },
    getTextArray(arr: any, val: any) {
      const result = arr.find((item: { value: any; }) => item.value === val);
      return result !== undefined ? result.text : '';
    },
  },
});
