import Vue from 'vue';
import * as Config from '@/config';
import { User } from '@/types/alligate';

/**
 * 全ユーザを取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<User[]>} 取得した全ユーザ
 */
export async function getAllUsers(vue: Vue): Promise<User[]> {
  try {
    const users = await getAllUsersIter(vue, 1);
    return users;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('ユーザー情報の取得に失敗しました。ユーザー情報を取得する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 全ユーザを取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<User[]>} 取得した全ユーザ
 */
async function getAllUsersIter(vue: Vue, page: number): Promise<User[]> {
  const query = `?limit=1000&exclude=thumbnail&page=${page}&sortKey=${Config.DESC}:${Config.REGISTERED}`;
  try {
    const response = await vue.$axios.get(Config.USERS_LIST_API + query);
    if (response.data.endRecord === response.data.maxRecords) {
      return response.data.users;
    } else {
      const nextUsers = await getAllUsersIter(vue, page + 1);
      return response.data.users.concat(nextUsers);
    }
  } catch (err) {
    throw err;
  }
}

/**
 * ユーザIDを指定して取得する
 * @param {Vue} vue Vueインスタンス
 * @param {string} userId ユーザID
 * @return {Promise<User>} 取得したユーザ
 */
export async function getUser(vue: Vue, userId: string): Promise<User> {
  const query = `?userId=${encodeURIComponent(userId)}`;
  try {
    const response = await vue.$axios.get(Config.USERS_LIST_API + query);
    return response.data.users[0];
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('ユーザー情報の取得に失敗しました。ユーザー情報を取得する権限がありません。'));
      case 404:
        throw(new Error('指定されたユーザーは存在しません。'));
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}
/**
 * 全ユーザを取得する
 * @param {Vue} vue Vueインスタンス
 * @return {Promise<User[]>} 取得した全ユーザ
 */
export async function getAllUsersIsValid(vue: Vue): Promise<User[]> {
  try {
    const users = await getAllUsersIterIsValid(vue, 1);
    return users;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw(new Error('ユーザー情報の取得に失敗しました。ユーザー情報を取得する権限がありません。'));
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 全ユーザを取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<User[]>} 取得した全ユーザ
 */
async function getAllUsersIterIsValid(vue: Vue, page: number): Promise<User[]> {
  const query = `?limit=1000&exclude=thumbnail&isValid=true&page=${page}&sortKey=${Config.DESC}:${Config.REGISTERED}`;
  try {
    const response = await vue.$axios.get(Config.USERS_LIST_API + query);
    if (response.data.endRecord === response.data.maxRecords) {
      return response.data.users;
    } else {
      return response.data.users;
      const nextUsers = await getAllUsersIter(vue, page + 1);
      return response.data.users.concat(nextUsers);
    }
  } catch (err) {
    throw err;
  }
}
// Administrators
export async function getGroupAuth(vue: Vue, userId: string): Promise<any> {
  try {
    const response = await vue.$axios.get(Config.GROUP_ADMINISTRATOR_API + '?userId=' + userId);
    return response.data.groupAdministrators;
  } catch (err) {
    return false;
  }
}
export async function getDepartmentAuth(vue: Vue, userId: string): Promise<any> {
  try {
    const response = await vue.$axios.get(Config.DEPARTMENT_ADMINISTRATOR_API + '?userId=' + userId);
    return response.data.departmentAdministrators;
  } catch (err) {
    return false;
  }
}
export async function setAdministrator(vue: Vue, data: any, ID: any): Promise<any> {
  const promise1: any = await getDepartmentAuth(vue, ID);
  if (promise1 !== null && promise1.length > 0) {
    const dataUpdate = {
      isAdminToEditUser: data.department.isAdminToEditUser,
      isAdminToAddUser: data.department.isAdminToAddUser,
      isAdminToSetPcodesToUser: data.department.isAdminToSetPcodesToUser,
    };
    await updateDepartmentItem(vue, dataUpdate, promise1.relationId);
  } else {
    const dataPost = {
      departmentId: data.department.departmentId,
      userId: ID,
      isAdminToEditUser: data.department.isAdminToEditUser,
      isAdminToAddUser: data.department.isAdminToAddUser,
      isAdminToSetPcodesToUser: data.department.isAdminToSetPcodesToUser,
    };
    await postDepartmentItem(vue, dataPost);
  }
  const promise2: any = await getGroupAuth(vue, ID);
  if (promise2 !== null && promise2.length > 0) {
    const dataUpdate = {
      isAdminOfGroupPassages: data.group.isAdminOfGroupPassages,
      isAdminOfGatePassages: data.group.isAdminOfGatePassages,
      isAdminOfRemoteUnlock: data.group.isAdminOfRemoteUnlock,
    };
    await updateGroupAdministratorsItem(vue, dataUpdate, promise2.groupAdministratorId);
  } else {
    const dataPost = {
      groupId: data.group.groupId,
      userId: ID,
      isAdminToEditUser: data.group.isAdminToEditUser,
      isAdminToAddUser: data.group.isAdminToAddUser,
      isAdminToSetPcodesToUser: data.group.isAdminToSetPcodesToUser,
    };
    await postGroupAdministratorsItem(vue, dataPost);
  }
  return true;
}

async function postDepartmentItem(vue: Vue, data: any) {
  return new Promise((resolve, reject) => {
    vue.$http.post(Config.DEPARTMENT_ADMINISTRATOR_API , data)
    .then((response: any) => {
      resolve(true);
    })
    .then(null, (error: any) => {
      resolve(false);
    });
  });
}

async function updateDepartmentItem(vue: Vue, data: any, ID: any) {
  return new Promise((resolve, reject) => {
    vue.$http.put(Config.DEPARTMENT_ADMINISTRATOR_API + '/' + ID, data)
    .then((response: any) => {
      resolve(true);
    })
    .then(null, (error: any) => {
      resolve(false);
    });
  });
}

async function postGroupAdministratorsItem(vue: Vue, data: any) {
  return new Promise((resolve, reject) => {
    vue.$http.put(Config.GROUP_ADMINISTRATOR_API, data)
    .then((response: any) => {
      resolve(true);
    })
    .then(null, (error: any) => {
      resolve(false);
    });
  });
}

async function updateGroupAdministratorsItem(vue: Vue, data: any, ID: any) {
  return new Promise((resolve, reject) => {
    vue.$http.put(Config.GROUP_ADMINISTRATOR_API + '/' + ID, data)
    .then((response: any) => {
      resolve(true);
    })
    .then(null, (error: any) => {
      resolve(false);
    });
  });
}
export async function getDepartmentUser(vue: Vue, userId: string): Promise<any> {
  try {
    const response = await vue.$axios.get(Config.RELATION_DEPARTMENT_USER_API + `?userId=${encodeURIComponent(userId)}&embed=department`);
    return response.data.relationsDepartmentUser;
  } catch (err) {
    return [];
  }
}
