var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-reflection-gate-table no-box-shadow" },
    [
      _c(
        "v-container",
        { staticClass: "tab-gate-log-list" },
        [
          _c(
            "div",
            { staticClass: "round-action pb-0 pt-0" },
            [
              _vm.inPage === "modal-group"
                ? _c(
                    "v-row",
                    { attrs: { justify: "end" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "btn-div",
                              attrs: { justify: "end" }
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mr-2", attrs: { cols: "3" } },
                                [
                                  _vm.authority.isAdmin === true
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn-primary",
                                          attrs: { outlined: "" },
                                          on: { click: _vm.goControlAdd }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  新規制御盤を追加する\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  { staticClass: "text-center mr-1" },
                                  [
                                    _vm.authority.isAdmin === true
                                      ? _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "open-on-hover": "",
                                              "offset-y": ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "btn-primary"
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                        新規ゲートを追加する\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              3671385614
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _vm.authority.isAdmin === true
                                              ? _c(
                                                  "v-list",
                                                  [
                                                    _c(
                                                      "v-list-item",
                                                      {
                                                        attrs: { link: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            ;(_vm.isGateLockModalShow = true),
                                                              (_vm.isGateLockAdd = true)
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [_vm._v("ゲート登録")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-list-item",
                                                      {
                                                        attrs: { link: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.$emit(
                                                              "showDialogImport",
                                                              true
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [_vm._v("一斉登録")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _vm.authority.isAdmin === true
                                  ? _c(
                                      "div",
                                      { staticClass: "text-center mt-0" },
                                      [
                                        _c("action-button", {
                                          attrs: {
                                            text: "アクション ▼",
                                            "action-items": _vm.actionItems,
                                            disabled:
                                              _vm.selectedItem.length === 0
                                          },
                                          on: {
                                            "click-enable": function($event) {
                                              _vm.isConfirmAction = true
                                              _vm.type = "enable"
                                            },
                                            "click-disable": function($event) {
                                              _vm.isConfirmAction = true
                                              _vm.type = "disable"
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "div",
                          { staticClass: "area-search-box" },
                          [
                            _c(
                              "v-row",
                              {
                                attrs: {
                                  "no-gutters": "",
                                  justify: "space-between"
                                }
                              },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("searchbox", {
                                      staticClass:
                                        "input-search btn-user-search ",
                                      attrs: {
                                        searchTargetItems:
                                          _vm.searchTargetItems,
                                        title: "選択項目にチェックして検索",
                                        disabled: _vm.isGetDataList
                                      },
                                      on: {
                                        search: function($event) {
                                          return _vm.$emit(
                                            "search-gate",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    { staticClass: "btn-div", attrs: { justify: "end" } },
                    [
                      _c("v-col", { attrs: { md: "4", xl: "3" } }, [
                        _c(
                          "div",
                          { staticClass: "area-search-box" },
                          [
                            _c(
                              "v-row",
                              {
                                attrs: {
                                  "no-gutters": "",
                                  justify: "space-between"
                                }
                              },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("searchbox", {
                                      staticClass:
                                        "input-search btn-user-search ml-2",
                                      attrs: {
                                        searchTargetItems:
                                          _vm.searchTargetItems,
                                        title: "選択項目にチェックして検索",
                                        disabled: _vm.isGetDataList
                                      },
                                      on: {
                                        search: function($event) {
                                          return _vm.$emit(
                                            "search-gate",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "btn-div mr-2",
                              attrs: { justify: "end" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-center mr-10" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "btn-add pt-0",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _vm.authority.isAdmin === true
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "btn-second",
                                              attrs: {
                                                outlined: "",
                                                disabled:
                                                  _vm.selectedItem.length === 0
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.isConfirm = true
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  チェックしたゲートを通行権限グループから削除する\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "btn-add pt-0",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _vm.authority.isAdmin === true
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "btn-primary",
                                              attrs: { outlined: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.loadGate()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  他のゲートを通行権限グループに追加する\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.successMessageLogs !== ""
                ? _c(
                    "v-col",
                    {
                      staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-success"
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.successMessageLogs) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.errorMessageLogs !== ""
                ? _c(
                    "v-col",
                    { staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-error" },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errorMessageLogs) + "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "mt-3 border-bottom-bold",
                  attrs: {
                    "no-gutters": "",
                    justify: "space-between",
                    "align-content": "center"
                  }
                },
                [
                  _c("show-per-page", {
                    staticClass: "ml-8 mb-3",
                    attrs: {
                      "per-page": _vm.perPage,
                      page: _vm.page,
                      "items-length": _vm.gateTotalCount
                    }
                  }),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-checkbox", {
                    staticClass: "text-caption-checkbox mr-5",
                    attrs: {
                      label: "有効なゲートのみ表示",
                      color: "grey",
                      dense: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.showValid,
                      callback: function($$v) {
                        _vm.showValid = $$v
                      },
                      expression: "showValid"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-caption display-option included-option text-sm-caption text-md-caption text-lg-caption text-xl-caption mr-8",
                      on: {
                        click: function() {
                          _vm.showOption = !_vm.showOption
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "display-option-title" }, [
                        _vm._v("\n        表示オプション\n      ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "mb-3" },
                        [
                          _c("v-icon", { attrs: { dense: "" } }, [
                            _vm._v("mdi-chevron-down")
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "pt-0 pb-0 mt-0" },
                [
                  _c("display-option", {
                    attrs: {
                      isShowOption: _vm.showOption,
                      "display-option-items": _vm.displayOptionItems,
                      disabled: _vm.isGetDataList,
                      isVaildOnly: _vm.isVaildOnly,
                      isVaildOnlyShow: false
                    },
                    on: {
                      "show-only-isVaild": function($event) {
                        return _vm.$emit("show-only-isVaild", $event)
                      },
                      input: function($event) {
                        return _vm.CheckColumn($event)
                      }
                    },
                    model: {
                      value: _vm.selectedDisplayOption,
                      callback: function($$v) {
                        _vm.selectedDisplayOption = $$v
                      },
                      expression: "selectedDisplayOption"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "round-data" },
            [
              _c("v-data-table", {
                staticClass: "border-table scroll-table",
                class: { "modal-table": _vm.inPage === "modal-group" },
                attrs: {
                  "must-sort": "",
                  "custom-sort": _vm.customSort,
                  headers: _vm.headers,
                  loading: _vm.isGetDataList,
                  items: _vm.dataGatesLoad,
                  "items-per-page": _vm.perPage,
                  "item-key": "gate.gateId",
                  "no-data-text": "データーはありません",
                  "loading-text": "データを読み込中です",
                  "hide-default-footer": "",
                  expanded: _vm.expanded
                },
                on: {
                  "update:options": _vm.customSort,
                  "update:expanded": function($event) {
                    _vm.expanded = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.controller.name",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.controller !== undefined &&
                          item.gate.gateModelCode === "ALIG-030"
                            ? _c(
                                "div",
                                { class: item.class },
                                [
                                  _c("text-link", {
                                    class: [
                                      _vm.inPage === "modal-group"
                                        ? "item-user-name wrap-name"
                                        : "item-user-name"
                                    ],
                                    attrs: { text: item.controller.name },
                                    on: {
                                      click: function($event) {
                                        return _vm.goDetailControl(
                                          item.controller.controllerId
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  class: [
                                    item.index !== 1 ? "no-controller" : ""
                                  ]
                                },
                                [_vm._v("\n      制御盤なし\n    ")]
                              )
                        ]
                      }
                    },
                    {
                      key: "item.gate.isValid",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.gateId",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.name",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.gateModelId",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.gateModelCode",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.gateModelName",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.iconType",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.makerCode",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.customizable",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.target",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.controlTime",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.timeUnit",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.isNFCValid",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.isVisitor",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.isTenKeyValid",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.isGatePasswordValid",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.gatePasswordLength",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.gatePasswordSeed",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.isTwoStepAuthValid",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.bleIntensity",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.buzzerPattern",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.needLocation",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.option1",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.option2",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.gateSNMain",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.gateFWMain",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.gateFWBLE",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.registered",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.updated",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.reported",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.revisionDesired",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.gateSNBLE",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.revisionReported",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.voltage",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.scanDate",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.gate.controllerGateNo",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span")]
                      }
                    },
                    {
                      key: "item.action-expand",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.controller !== undefined &&
                          item.gate.gateModelCode === "ALIG-030"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.$emit(
                                                              "goReflectionPage",
                                                              item.controller
                                                                .controllerId
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-format-list-checks"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [_vm._v("反映状況一覧")])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _vm.authority.isAdmin === true
                                                  ? _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: { icon: "" },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.goControlEdit(
                                                                  item
                                                                    .controller
                                                                    .controllerId
                                                                )
                                                              }
                                                            }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-circle-edit-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [_vm._v("制御盤編集")])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.safieRelationsGateDevice[item.gateId]
                                    ? _vm._l(
                                        _vm.safieRelationsGateDevice[
                                          item.gateId
                                        ],
                                        function(gateDevice) {
                                          return _c(
                                            "v-tooltip",
                                            {
                                              key: gateDevice.deviceId,
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.safieDeviceLinkTo(
                                                                      gateDevice.deviceId
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-video-outline"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    gateDevice.notes
                                                      ? gateDevice.notes
                                                      : "<<名称未設定>>"
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        }
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            : _c("div")
                        ]
                      }
                    },
                    {
                      key: "expanded-item",
                      fn: function(ref) {
                        var headers = ref.headers
                        var item = ref.item
                        return _vm._l(headers, function(field) {
                          return _c("td", { key: field.value }, [
                            field.value === "controller.name"
                              ? _c(
                                  "div",
                                  [
                                    _vm.inPage !== "modal-group" ||
                                    (_vm.inPage === "modal-group" &&
                                      item.gate.gateModelCode !== "ALIG-030")
                                      ? _c("v-checkbox", {
                                          staticClass: "check-box-expanded",
                                          staticStyle: {
                                            margin: "0px",
                                            padding: "0px"
                                          },
                                          attrs: {
                                            value: item,
                                            "hide-details": ""
                                          },
                                          model: {
                                            value: _vm.selectedItem,
                                            callback: function($$v) {
                                              _vm.selectedItem = $$v
                                            },
                                            expression: "selectedItem"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.gateId"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.gateId) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.name"
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "allig-text-link item-user-name gate-name"
                                    },
                                    [
                                      item.gate.gateModelCode !== "ALIG-030"
                                        ? _c("text-link", {
                                            class: [
                                              _vm.inPage === "modal-group"
                                                ? "item-user-id wrap-name"
                                                : "item-user-id"
                                            ],
                                            attrs: { text: item.gate.name },
                                            on: {
                                              click: function($event) {
                                                return _vm.goDetailGateLock(
                                                  item.gate.gateId
                                                )
                                              }
                                            }
                                          })
                                        : _c("text-link", {
                                            class: [
                                              _vm.inPage === "modal-group"
                                                ? "item-user-id wrap-name"
                                                : "item-user-id"
                                            ],
                                            attrs: { text: item.gate.name },
                                            on: {
                                              click: function($event) {
                                                return _vm.goDetailGate(
                                                  item.gate.gateId
                                                )
                                              }
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.gateModelId"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.gateModelId) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.gateModelName"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.gateModelName) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.gateModelCode"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.gateModelCode) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.iconType"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.iconType) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.makerCode"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.makerCode) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.customizable"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.customizable) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.target"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.target) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.controlTime"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.controlTime) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.timeUnit"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.timeUnit) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.isVisitor"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.convertStatus(item.gate.isVisitor)
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.isNFCValid"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.convertStatus(item.gate.isNFCValid)
                                      ) +
                                      " " +
                                      _vm._s(item.gate.isNFCValid) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.isTenKeyValid"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.convertStatus(
                                          item.gate.isTenKeyValid
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.isGatePasswordValid"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.convertStatus(
                                          item.gate.isGatePasswordValid
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.gatePasswordLength"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.gatePasswordLength) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.gatePasswordSeed"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.gatePasswordSeed) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.isTwoStepAuthValid"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.convertStatus(
                                          item.gate.isTwoStepAuthValid
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.bleIntensity"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.bleIntensity) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.buzzerPattern"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.buzzerPattern) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.needLocation"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.convertStatus(
                                          item.gate.needLocation
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.option1"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.option1) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.option2"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.option2) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.gateSNMain"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.gateSNMain) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.gateSNBLE"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.gateSNBLE) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.gateFWMain"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.gateFWMain) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.gateFWBLE"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.gateFWBLE) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.registered"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.converDateTime(item.gate.registered)
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.updated"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.converDateTime(item.gate.updated)
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.reported"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.converDateTime(item.gate.reported)
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.revisionDesired"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.revisionDesired) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.revisionReported"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.revisionReported) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.voltage"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.voltage) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.scanDate"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.converDateTime(item.gate.scanDate)
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.controllerGateNo"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.gate.controllerGateNo) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "gate.isValid"
                              ? _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        item.gate.isValid ? "有効" : "無効"
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            field.value === "action-expand"
                              ? _c("div", [
                                  item.gate.gateModelCode !== "ALIG-030"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$emit(
                                                                      "clickUpdateValid",
                                                                      {
                                                                        gateId:
                                                                          item
                                                                            .gate
                                                                            .gateId,
                                                                        isValid: item
                                                                          .gate
                                                                          .isValid
                                                                          ? false
                                                                          : true
                                                                      }
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            item.gate.isValid
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "green--text"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-lock-check"
                                                                    )
                                                                  ]
                                                                )
                                                              : _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-lock-alert"
                                                                  )
                                                                ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    item.gate.isValid
                                                      ? "有効"
                                                      : "無効"
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _vm.authority
                                                          .isAdmin === true
                                                          ? _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      icon: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.clickEditGateLock(
                                                                          item
                                                                            .gate
                                                                            .gateId
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-square-edit-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [_vm._v("ゲート編集")])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.authority.isAdmin === true ||
                                          _vm.authority
                                            .isAdminOfRemoteUnlock === true
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "button-gate-lock",
                                                                    attrs: {
                                                                      icon: "",
                                                                      disabled:
                                                                        _vm.btnUnlock.includes(
                                                                          item
                                                                            .gate
                                                                            .gateId
                                                                        ) ===
                                                                          false ||
                                                                        item
                                                                          .gate
                                                                          .isTwoStepAuthValid ===
                                                                          true ||
                                                                        item
                                                                          .gate
                                                                          .isVisitor ===
                                                                          true ||
                                                                        item
                                                                          .gate
                                                                          .makerCode ===
                                                                          91
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.openHandleCommand(
                                                                          item
                                                                            .gate
                                                                            .gateId,
                                                                          item
                                                                            .gate
                                                                            .name
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-lock-open-variant-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v("リモート解錠")
                                                  ])
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "button-gate-lock",
                                                                attrs: {
                                                                  icon: "",
                                                                  disabled:
                                                                    !item.gate
                                                                      .isGatePasswordValid ||
                                                                    !item.gate
                                                                      .isValid
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.getGatePassword(
                                                                      item.gate
                                                                        .gateId
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-arrow-horizontal-lock"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v("ゲートパスワード取得")
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _vm.authority
                                                          .isAdmin === true
                                                          ? _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      icon: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.$emit(
                                                                          "clickEditGate",
                                                                          item.gate
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-square-edit-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [_vm._v("ゲート編集")])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.authority.isAdmin === true ||
                                          _vm.authority
                                            .isAdminOfRemoteUnlock === true
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "button-gate-lock",
                                                                    attrs: {
                                                                      icon: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.$emit(
                                                                          "clickUnClock",
                                                                          {
                                                                            gateId:
                                                                              item
                                                                                .gate
                                                                                .gateId,
                                                                            gateName:
                                                                              item
                                                                                .gate
                                                                                .name
                                                                          }
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-lock-open-variant-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v("リモート解錠")
                                                  ])
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$emit(
                                                                      "goLogPage",
                                                                      item.gate
                                                                        .name
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-file-move-outline"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(" ゲートログ")
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                ])
                              : _vm._e()
                          ])
                        })
                      }
                    },
                    {
                      key: "item.status",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.controller.connectionStatus === false
                            ? _c("span", [_vm._v("通信エラー発生")])
                            : item.controller.connectionStatus === true &&
                              item.controller.commandStatus === 1
                            ? _c("span", [_vm._v("コマンド送信待ち")])
                            : item.controller.connectionStatus === true &&
                              item.controller.commandStatus === 2
                            ? _c("span", [_vm._v("設定反映中")])
                            : item.controller.connectionStatus === true &&
                              item.controller.commandStatus === 3
                            ? _c("span", [_vm._v("設定エラー発生")])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.isValid",
                      fn: function(ref) {
                        var item = ref.item
                        return undefined
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-row", {
            staticStyle: { height: "20px" },
            attrs: { "no-gutters": "" }
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "mt-3",
              attrs: { "no-gutters": "", justify: "space-between" }
            },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "mr-5 mb-2" },
                [
                  _c("select-per-page", {
                    attrs: {
                      page: _vm.page,
                      "per-page-items": [25, 50, 100, 1000],
                      "items-length": _vm.gateTotalCount,
                      disabled: _vm.isGetDataList
                    },
                    model: {
                      value: _vm.innerPerPage,
                      callback: function($$v) {
                        _vm.innerPerPage = $$v
                      },
                      expression: "innerPerPage"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                staticClass: "mb-1",
                attrs: {
                  "page-length": Math.ceil(_vm.gateTotalCount / _vm.perPage),
                  disabled: _vm.isGetDataList
                },
                model: {
                  value: _vm.innerPage,
                  callback: function($$v) {
                    _vm.innerPage = $$v
                  },
                  expression: "innerPage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { width: "600" },
              model: {
                value: _vm.showModal,
                callback: function($$v) {
                  _vm.showModal = $$v
                },
                expression: "showModal"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "background-color-green" },
                    [_vm._v(" リモート解錠 ")]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { staticClass: "pa-4 mb-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _vm.successMessageModal === "" &&
                          _vm.errorMessageModal === ""
                            ? _c("v-col", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.gateName) +
                                    "ゲートをリモート解錠します。\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.successMessageModal !== ""
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "font-weight-bold bg-success no-bg"
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.successMessageModal) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errorMessageModal !== ""
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "font-weight-bold bg-error no-bg"
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.errorMessageModal)
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _vm.errorMessageModal === "" && _vm.successMessageModal === ""
                    ? _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-row",
                            { staticClass: "ma-2", attrs: { justify: "end" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("closeModalUnClock")
                                    }
                                  }
                                },
                                [_vm._v("\n          キャンセル\n          ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-primary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("clickRemoteUnlock")
                                    }
                                  }
                                },
                                [_vm._v("\n          OK\n          ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-row",
                            { staticClass: "ma-2", attrs: { justify: "end" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("closeModalUnClock")
                                    }
                                  }
                                },
                                [_vm._v("\n          OK\n          ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.isShowControlPanelDetail
        ? _c(
            "v-dialog",
            {
              attrs: { width: "800", persistent: "" },
              model: {
                value: _vm.isShowControlPanelDetail,
                callback: function($$v) {
                  _vm.isShowControlPanelDetail = $$v
                },
                expression: "isShowControlPanelDetail"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("ControlPanelDetail", {
                    attrs: { authority: _vm.authority },
                    on: {
                      deleteBridgeProperty: _vm.deleteBridgeProperty,
                      loading: function($event) {
                        return _vm.$emit("loading-from-child", $event)
                      },
                      clickGroupDetail: _vm.clickGroupDetail
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-row", {
                        staticClass: "ma-2",
                        attrs: { justify: "end" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowDetailGate
        ? _c(
            "v-dialog",
            {
              attrs: { width: "800", persistent: "" },
              model: {
                value: _vm.isShowDetailGate,
                callback: function($$v) {
                  _vm.isShowDetailGate = $$v
                },
                expression: "isShowDetailGate"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("GateDetailPage", {
                    on: {
                      deleteBridgeProperty: _vm.deleteBridgeProperty,
                      loading: function($event) {
                        return _vm.$emit("loading-from-child", $event)
                      },
                      clickGroupDetail: _vm.clickGroupDetail
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-row", {
                        staticClass: "ma-2",
                        attrs: { justify: "end" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowDetailGateLock
        ? _c(
            "v-dialog",
            {
              attrs: { width: "1200", persistent: "" },
              model: {
                value: _vm.isShowDetailGateLock,
                callback: function($$v) {
                  _vm.isShowDetailGateLock = $$v
                },
                expression: "isShowDetailGateLock"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("GateLockDetailPage", {
                    attrs: { gateId: _vm.gateid },
                    on: {
                      "gate-edit": function($event) {
                        return _vm.clickEditGateLock($event)
                      },
                      loading: function($event) {
                        return _vm.$emit("loading-from-child", $event)
                      },
                      clickBackGate: function($event) {
                        _vm.isShowDetailGateLock = false
                        _vm.$emit("close-modal-control")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-row", {
                        staticClass: "ma-2",
                        attrs: { justify: "end" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "800", persistent: "" },
          model: {
            value: _vm.isAddControlShow,
            callback: function($$v) {
              _vm.isAddControlShow = $$v
            },
            expression: "isAddControlShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _vm.isAddControlShow
                ? _c("ControlPanelAddPage", {
                    on: {
                      deleteBridgeProperty: _vm.deleteBridgeProperty,
                      loading: function($event) {
                        return _vm.$emit("loading-from-child", $event)
                      },
                      "close-modal-control": function($event) {
                        _vm.isAddControlShow = false
                        _vm.$emit("close-modal-control")
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.isGateLockModalShow
        ? _c(
            "v-dialog",
            {
              attrs: { width: "1200", persistent: "" },
              model: {
                value: _vm.isGateLockModalShow,
                callback: function($$v) {
                  _vm.isGateLockModalShow = $$v
                },
                expression: "isGateLockModalShow"
              }
            },
            [
              _c(
                "v-card",
                [
                  _vm.isGateLockModalShow
                    ? _c("GateLockAddPage", {
                        attrs: { isGateLockAdd: _vm.isGateLockAdd },
                        on: {
                          deleteBridgeProperty: _vm.deleteGateLockAddPage,
                          loading: function($event) {
                            return _vm.$emit("loading-from-child", $event)
                          },
                          "close-modal-control": function($event) {
                            _vm.isGateLockModalShow = false
                            _vm.$emit("close-modal-control")
                          },
                          "load-gates": function($event) {
                            return _vm.$emit("load-gates", 1)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showProgress,
            callback: function($$v) {
              _vm.showProgress = $$v
            },
            expression: "showProgress"
          }
        },
        [
          _c(
            "v-card",
            [
              _vm.type === "del"
                ? _c(
                    "v-card-title",
                    { staticClass: "background-color-green" },
                    [_vm._v("チェックしたゲートを通行権限グループから削除")]
                  )
                : _vm.type === "enable"
                ? _c(
                    "v-card-title",
                    { staticClass: "background-color-green" },
                    [_vm._v("関連付け有効")]
                  )
                : _vm.type === "disable"
                ? _c(
                    "v-card-title",
                    { staticClass: "background-color-green" },
                    [_vm._v("関連付け無効")]
                  )
                : _c(
                    "v-card-title",
                    { staticClass: "background-color-green" },
                    [_vm._v("関連付け追加")]
                  ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _vm.dataRelationGate.total >= 0
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-progress-linear",
                        {
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.dataRelationGate.progressLoad,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.dataRelationGate,
                                "progressLoad",
                                $$v
                              )
                            },
                            expression: "dataRelationGate.progressLoad"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                Math.round(_vm.dataRelationGate.progressLoad)
                              ) + "%"
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.dataRelationGate.successItem > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.dataRelationGate.total) +
                                "件中" +
                                _vm._s(_vm.dataRelationGate.successItem) +
                                " \n            "
                            ),
                            _vm.type === "add"
                              ? _c("span", [_vm._v("件を追加しました。")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.type === "del"
                              ? _c("span", [_vm._v("件を削除しました。")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.type === "disable"
                              ? _c("span", [_vm._v("件を無効化しました。")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.type === "enable"
                              ? _c("span", [_vm._v("件を有効化しました。")])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dataRelationGate.errorMessage.length > 0
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "error-box" },
                              [
                                _vm._l(
                                  _vm.dataRelationGate.errorMessage,
                                  function(item) {
                                    return [
                                      _c("p", {
                                        key: item,
                                        domProps: { innerHTML: _vm._s(item) }
                                      })
                                    ]
                                  }
                                )
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dataRelationGate.isFinish
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeModalGate()
                                }
                              }
                            },
                            [_vm._v("OK")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "800", persistent: "" },
          model: {
            value: _vm.showGate,
            callback: function($$v) {
              _vm.showGate = $$v
            },
            expression: "showGate"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v("他のゲートを通行権限グループに追加")
              ]),
              _vm._v(" "),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "ml-5", attrs: { justify: "start" } },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-5 mb-3 mr-9" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary ",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showGate = false
                                      _vm.$emit("close-modal-control")
                                    }
                                  }
                                },
                                [_vm._v("\n              戻る\n              ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.showGate
                ? _c("GateAllListTable", {
                    attrs: {
                      dataList: _vm.expandData.gateAll.data,
                      perPage: _vm.expandData.gateAll.perPage,
                      page: _vm.expandData.gateAll.page,
                      totalCount: _vm.expandData.gateAll.total,
                      loading: _vm.expandData.gateAll.loading,
                      relationsGateGroup: _vm.dataGates
                    },
                    on: {
                      "change-page": function($event) {
                        return _vm.$emit("change-page-gate-all", $event)
                      },
                      "change-per-page": function($event) {
                        return _vm.$emit("change-per-page-gate-all", $event)
                      },
                      "add-gate": function($event) {
                        return _vm.addGate($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.isConfirm,
            callback: function($$v) {
              _vm.isConfirm = $$v
            },
            expression: "isConfirm"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v("チェックしたゲートを通行権限グループから削除")
              ]),
              _vm._v(" "),
              _c("v-card-text", { staticClass: "pt-5 pb-5" }, [
                _vm._v("チェックしたゲートを通行権限グループから削除しまか？")
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              _vm.isConfirm = false
                            }
                          }
                        },
                        [_vm._v("\n            キャンセル\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.removeGate(_vm.selectedItem)
                            }
                          }
                        },
                        [_vm._v("\n            削除\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.isGatePassword,
            callback: function($$v) {
              _vm.isGatePassword = $$v
            },
            expression: "isGatePassword"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v(" ゲートパスワードの取得 ")
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-4 mb-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "col-5" }, [
                        _c("div", { staticClass: "v-subheader theme--light" }, [
                          _vm._v(" 対象のゲート（ゲートID）")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("v-col", { staticClass: "mt-3" }, [
                        _vm._v(_vm._s(_vm.gateid))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "col-5" }, [
                        _c("div", { staticClass: "v-subheader theme--light" }, [
                          _vm._v(" ゲートを利用する日付")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c("DatePicker", {
                            on: {
                              input: function($event) {
                                return _vm.getTime($event)
                              }
                            },
                            model: {
                              value: _vm.dateGate,
                              callback: function($$v) {
                                _vm.dateGate = $$v
                              },
                              expression: "dateGate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "col-5" }, [
                        _c("div", { staticClass: "v-subheader theme--light" }, [
                          _vm._v(" ゲートを利用する時間")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.listTime,
                              "item-text": "text",
                              "item-value": "value",
                              "single-line": "",
                              color: "green",
                              "hide-details": "auto",
                              "no-data-text": "",
                              outlined: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.timeGate,
                              callback: function($$v) {
                                _vm.timeGate = $$v
                              },
                              expression: "timeGate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _vm.gatePassword !== ""
                    ? _c("v-row", { staticClass: "background-color-gray" }, [
                        _vm._v(" " + _vm._s(_vm.gatePassword) + " ")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.closePasswordGenerate()
                            }
                          }
                        },
                        [_vm._v("\n            キャンセル\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("passwordGenerate", {
                                scorpId: _vm.timeGate,
                                date: _vm.dateGate,
                                gateId: _vm.gateid
                              })
                            }
                          }
                        },
                        [_vm._v("\n            パスワードを発行\n            ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.isHandleCommand,
            callback: function($$v) {
              _vm.isHandleCommand = $$v
            },
            expression: "isHandleCommand"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v(
                  _vm._s(_vm.currentGateName) +
                    " ゲートの遠隔解錠を実施しますか？ "
                )
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _vm.errorMessageLogsPhone !== ""
                ? _c("div", {
                    staticClass: "bg-error m5 col",
                    domProps: { innerHTML: _vm._s(_vm.errorMessageLogsPhone) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.successMessageLogsPhone !== ""
                ? _c("div", {
                    staticClass: "bg-success m5 col",
                    domProps: { innerHTML: _vm._s(_vm.successMessageLogsPhone) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.lastLog.length > 0
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-4 mb-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { staticClass: "col-5" }, [
                            _c(
                              "div",
                              { staticClass: "v-subheader theme--light" },
                              [_vm._v("遠隔解錠登録日時：")]
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-col", { staticClass: "mt-3 col-5" }, [
                            _vm._v(
                              _vm._s(
                                _vm.converDateFull(_vm.lastLog[0].registered)
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { staticClass: "col-5" }, [
                            _c(
                              "div",
                              { staticClass: "v-subheader theme--light" },
                              [_vm._v("遠隔解錠状況：")]
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-col", { staticClass: "col-5 mt-3" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.converStatus(_vm.lastLog[0].status)
                                ) +
                                " "
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "ml-4 icon-refresh",
                                on: {
                                  click: function($event) {
                                    return _vm.getLastLog()
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-replay")])],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _vm.isFinishHandleCommand
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeHandleCommand()
                                }
                              }
                            },
                            [_vm._v("\n            OK\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeHandleCommand()
                                }
                              }
                            },
                            [_vm._v("\n            キャンセル\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.handleCommand()
                                }
                              }
                            },
                            [_vm._v("\n            はい\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("gate-import", {
        attrs: {
          showModalImport: _vm.showModalImport,
          showProgressExportCSV: _vm.showProgressExportCSV,
          progressCsvXlsx: _vm.progressCsvXlsx,
          errorMessageCsvXlsx: _vm.errorMessageCsvXlsx,
          showProgressImport: _vm.showProgressImport,
          successItemImport: _vm.successItemImport,
          totalDataRowImport: _vm.totalDataRowImport,
          errorMessageImport: _vm.errorMessageImport,
          isFinishProcess: _vm.isFinishProcess,
          sheetUserName: _vm.sheetUserName,
          isShowViewCSV: _vm.isShowViewCSV
        },
        on: {
          clickExportCSV: function($event) {
            return _vm.$emit("clickExportCSV", $event)
          },
          closeModalProcessCSV: function($event) {
            return _vm.$emit("closeModalProcessCSV", $event)
          },
          closeImportCSV: function($event) {
            return _vm.$emit("closeImportCSV", $event)
          },
          clickImportCSV: function($event) {
            return _vm.$emit("clickImportCSV", $event)
          },
          closeModalProgressImport: function($event) {
            return _vm.$emit("closeModalProgressImport", $event)
          },
          clickCheckBoxExportGateInfoCSV: function($event) {
            return _vm.$emit("clickCheckBoxExportGateInfoCSV", $event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.isConfirmAction,
            callback: function($$v) {
              _vm.isConfirmAction = $$v
            },
            expression: "isConfirmAction"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm.type === "enable"
                  ? _c("span", [_vm._v("ゲートを有効化")])
                  : _c("span", [_vm._v("ゲートを無効化")])
              ]),
              _vm._v(" "),
              _c("v-card-text", { staticClass: "pt-5 pb-5" }, [
                _vm.type === "enable"
                  ? _c("span", [_vm._v("チェックしたゲートを有効化します。")])
                  : _c("span", [_vm._v("チェックしたゲートを無効化します。")])
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              _vm.isConfirmAction = false
                            }
                          }
                        },
                        [_vm._v("\n            キャンセル\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.confirmAction()
                            }
                          }
                        },
                        [_vm._v("\n            OK\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }