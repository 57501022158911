var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-table mt-2" },
    [
      _c(
        "v-row",
        {
          attrs: {
            "no-gutters": "",
            justify: "space-between",
            "align-content": "center"
          }
        },
        [
          _c("show-per-page", {
            staticClass: "ml-8",
            attrs: {
              "per-page": _vm.perPage,
              page: _vm.page,
              "items-length": _vm.dataGroupsModal.total
            }
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-checkbox", {
            staticClass: "text-caption-checkbox mr-10",
            attrs: {
              label: "有効なグループのみ表示",
              color: "green",
              dense: "",
              "hide-details": ""
            },
            model: {
              value: _vm.showValid,
              callback: function($$v) {
                _vm.showValid = $$v
              },
              expression: "showValid"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "text-caption display-option included-option text-sm-caption text-md-caption text-lg-caption text-xl-caption mr-4",
              on: {
                click: function() {
                  _vm.showOption = !_vm.showOption
                }
              }
            },
            [
              _c("span", { staticClass: "display-option-title" }, [
                _vm._v("\n      表示オプション\n    ")
              ]),
              _vm._v(" "),
              _c(
                "span",
                [
                  _c("v-icon", { attrs: { dense: "" } }, [
                    _vm._v("mdi-chevron-down")
                  ])
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showOption
        ? _c(
            "v-row",
            { staticClass: "pt-0 pb-0 mt-0" },
            [
              _c(
                "v-container",
                { staticClass: "pt-0" },
                [
                  _c("display-option", {
                    attrs: {
                      isShowOption: _vm.showOption,
                      "display-option-items": _vm.displayOptionItems,
                      disabled: _vm.loading,
                      isVaildOnly: _vm.isVaildOnly,
                      isVaildOnlyShow: false
                    },
                    on: {
                      "show-only-isVaild": function($event) {
                        return _vm.$emit("show-only-isVaild", $event)
                      },
                      input: function($event) {
                        return _vm.CheckColumn($event)
                      }
                    },
                    model: {
                      value: _vm.selectedDisplayOption,
                      callback: function($$v) {
                        _vm.selectedDisplayOption = $$v
                      },
                      expression: "selectedDisplayOption"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "pa-3 pt-0 mt-0" },
        [
          _c("v-col", { staticClass: "pt-0", attrs: { sm: "12" } }, [
            _c(
              "div",
              { staticClass: "table-affiliation" },
              [
                _c("v-data-table", {
                  staticClass:
                    "border-table scroll-table modal-table table-no-bg",
                  attrs: {
                    "must-sort": "",
                    headers: _vm.headers,
                    items: _vm.dataGroupsModal.data,
                    "items-per-page": _vm.perPage,
                    "item-key": "groupId",
                    "no-data-text": "データーはありません",
                    loading: _vm.loading,
                    "loading-text": "データを読み込中です",
                    "hide-default-footer": "",
                    "show-select":
                      _vm.authority.isAdmin === true ? true : false,
                    "custom-sort": _vm.customSort,
                    isGroupEditModalShow: _vm.isGroupEditModalShow
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.name",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("text-link", {
                              staticClass: "item-user-name",
                              attrs: { text: item.name },
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "click-group-detail",
                                    item.groupId
                                  )
                                }
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "item.gateCount",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "span",
                              { staticClass: "item-user-id" },
                              [
                                _c("v-icon", [_vm._v("mdi-door")]),
                                _vm._v(_vm._s(item.gateCount))
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "item.userCount",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "span",
                              { staticClass: "item-user-id" },
                              [
                                _c("v-icon", [_vm._v("mdi-account")]),
                                _vm._v(_vm._s(item.userCount))
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "item.registered",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.convertDate(item.registered)))
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.isValid",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.isValid ? "有効" : "無効") +
                                "\n          "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.icons",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$emit(
                                                        "clickEditGroup",
                                                        item.groupId
                                                      )
                                                    }
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  "mdi-square-edit-outline"
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [_vm._v("グループ編集")])
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.groupSelected,
                    callback: function($$v) {
                      _vm.groupSelected = $$v
                    },
                    expression: "groupSelected"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-3",
          attrs: { "no-gutters": "", justify: "space-between" }
        },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "mr-5 mb-2" },
            [
              _c("select-per-page", {
                attrs: {
                  page: _vm.page,
                  "per-page-items": [25, 50, 100, 1000],
                  "items-length": _vm.dataGroupsModal.total,
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.innerPerPage,
                  callback: function($$v) {
                    _vm.innerPerPage = $$v
                  },
                  expression: "innerPerPage"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            staticClass: "mb-1",
            attrs: {
              "page-length": Math.ceil(_vm.dataGroupsModal.total / _vm.perPage),
              disabled: _vm.loading
            },
            model: {
              value: _vm.innerPage,
              callback: function($$v) {
                _vm.innerPage = $$v
              },
              expression: "innerPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }