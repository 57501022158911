var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-table aff-table" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "modal-title", attrs: { color: "green" } },
            [
              _vm._t("default", [
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "", justify: "space-between" } },
                  [
                    _vm._v("所属追加\n              "),
                    _c(
                      "v-icon",
                      {
                        attrs: { color: "white" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("click")
                          }
                        }
                      },
                      [_vm._v("mdi-close")]
                    )
                  ],
                  1
                )
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { staticStyle: { "padding-bottom": "0" } },
            [
              _vm.successMessageLogs !== "" &&
              _vm.loading === false &&
              _vm.expandData.errorData.groups.length === 0 &&
              _vm.expandData.errorData.users.length === 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "font-weight-bold bg-success bg-no align-self-center mr-2"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.successMessageLogs) +
                          "\n          "
                      )
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "font-weight-bold bg-error bg-no align-self-center mr-2"
                    },
                    [
                      _vm.successMessageLogs !== "" && _vm.loading === false
                        ? _c("p", [_vm._v(_vm._s(_vm.successMessageLogs))])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageLogs !== "" && _vm.loading === false
                        ? _c("p", [_vm._v(_vm._s(_vm.errorMessageLogs))])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.expandData.errorData.users.length > 0 &&
                      _vm.loading === false
                        ? _c("p", [
                            _vm._v(
                              "登録失敗所属ユーザー: " +
                                _vm._s(
                                  _vm.expandData.errorData.users.toString()
                                )
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.expandData.errorData.groups.length > 0 &&
                      _vm.loading === false
                        ? _c("p", [
                            _vm._v(
                              "設定失敗通行権限グループ: " +
                                _vm._s(
                                  _vm.expandData.errorData.groups.toString()
                                )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
              _vm._v(" "),
              _c("div", { staticClass: "title-block-card mt-10" }, [
                _vm._v("所属情報")
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "affiliation-information" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "col-12" }, [
                        _c("div", [_vm._v("所属名 ")])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "AffAddForm",
                              attrs: { "lazy-validation": "" }
                            },
                            [
                              _c("input-form", {
                                attrs: {
                                  rules: [_vm.rulesValidate.requiredName]
                                },
                                model: {
                                  value: _vm.departmentInfo.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.departmentInfo, "name", $$v)
                                  },
                                  expression: "departmentInfo.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "col-12" }, [
                        _c("div", [_vm._v("所属管理者 ")])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.listAff, function(item, i) {
                    return _c(
                      "v-row",
                      { key: i, staticClass: "mt-2 ml-5" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "col-4" },
                          [
                            _c("v-combobox", {
                              staticClass: "v-select-group",
                              attrs: {
                                items: _vm.dataAllow,
                                autocomplete: "off",
                                color: "green",
                                "hide-details": "auto",
                                "no-data-text": "",
                                outlined: "",
                                dense: "",
                                "menu-props": { contentClass: "v-select-item" },
                                disabled: _vm.dataAllow.length == 0
                              },
                              on: { change: _vm.changeAllow },
                              model: {
                                value: item.userId,
                                callback: function($$v) {
                                  _vm.$set(item, "userId", $$v)
                                },
                                expression: "item.userId"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "col-6" },
                          [
                            _c(
                              "v-row",
                              { staticClass: "item-check-box-detail" },
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    color: "green",
                                    dense: "",
                                    "hide-details": "",
                                    label: "ユーザー追加権限"
                                  },
                                  model: {
                                    value: item.checkOne,
                                    callback: function($$v) {
                                      _vm.$set(item, "checkOne", $$v)
                                    },
                                    expression: "item.checkOne"
                                  }
                                }),
                                _vm._v(" "),
                                _c("v-checkbox", {
                                  attrs: {
                                    color: "green",
                                    dense: "",
                                    "hide-details": "",
                                    label: "ユーザー編集権限"
                                  },
                                  model: {
                                    value: item.checkTwo,
                                    callback: function($$v) {
                                      _vm.$set(item, "checkTwo", $$v)
                                    },
                                    expression: "item.checkTwo"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mt-0 pt-0" },
                    [
                      _c("v-col", { staticClass: "col-2 pt-0" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "v-subheader theme--light ml-0 pa-0 mt-0 add-button",
                            on: { click: _vm.addAffiliation }
                          },
                          [_vm._v(" + 追加する ")]
                        )
                      ])
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title-block-card" }, [
                _vm._v("所属ユーザー")
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                [
                  _c("UsersAllowedTable", {
                    attrs: {
                      authority: _vm.authority,
                      departmentId: "",
                      dataList: _vm.dataDirectUser.data,
                      isPage: "affiliation-add-new",
                      loading: _vm.dataDirectUser.loading,
                      totalCount: _vm.dataDirectUser.total,
                      perPage: _vm.dataDirectUser.perPage,
                      page: _vm.dataDirectUser.page,
                      users: _vm.expandData.userAll,
                      dataRelationUser: {}
                    },
                    on: {
                      "change-page": function($event) {
                        return _vm.changePageUserDirect($event)
                      },
                      "search-user": function($event) {
                        return _vm.searchItemDirect($event)
                      },
                      "affiliation-add-item-direct": function($event) {
                        return _vm.affiliationAddItemDirect($event)
                      },
                      "affiliation-remove-item-direct": function($event) {
                        return _vm.affiliationRemoveItemDirect($event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title-block-card" }, [
                _vm._v("設定されている通行権限グループ")
              ]),
              _vm._v(" "),
              _c(
                "v-card",
                [
                  _c("AccessPermissionGroupTable", {
                    attrs: {
                      authority: _vm.authority,
                      dataGroupsModal: _vm.dataDirectGroup,
                      gateGroup: _vm.expandData.gateGroup,
                      isPage: "affiliation-add-new",
                      loading: _vm.dataDirectGroup.loading,
                      page: _vm.dataDirectGroup.page,
                      dataRelation: {},
                      groups: _vm.expandData.groupAll
                    },
                    on: {
                      "expand-gate-group": function($event) {
                        return _vm.$emit("expand-gate-group", $event)
                      },
                      "change-page": function($event) {
                        return _vm.changePageGroupDirect($event)
                      },
                      "change-page-gate-group": function($event) {
                        return _vm.$emit("change-page-gate-group", $event)
                      },
                      "remove-group": function($event) {
                        return _vm.removeGroupDirect($event)
                      },
                      "add-group": function($event) {
                        return _vm.addGroupDirect($event)
                      },
                      "load-list-groups": function($event) {
                        return _vm.$emit("load-list-groups", {
                          page: 1,
                          perPage: 25
                        })
                      },
                      "change-page-group-all": function($event) {
                        return _vm.$emit("change-page-group-all", $event)
                      },
                      "change-per-page-group-all": function($event) {
                        return _vm.$emit("change-per-page-group-all", $event)
                      },
                      "search-group": function($event) {
                        return _vm.searchGroupDirect($event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                { staticClass: "ma-2", attrs: { justify: "end" } },
                [
                  _vm.successMessageLogs !== "" &&
                  _vm.loading === false &&
                  _vm.expandData.errorData.groups.length === 0 &&
                  _vm.expandData.errorData.users.length === 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "font-weight-bold bg-success bg-no align-self-center mr-2"
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.successMessageLogs) +
                              "\n                  "
                          )
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "font-weight-bold bg-error bg-no align-self-center mr-2"
                        },
                        [
                          _vm.successMessageLogs !== "" && _vm.loading === false
                            ? _c("p", [_vm._v(_vm._s(_vm.successMessageLogs))])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.errorMessageLogs !== "" && _vm.loading === false
                            ? _c("p", [_vm._v(_vm._s(_vm.errorMessageLogs))])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.expandData.errorData.users.length > 0 &&
                          _vm.loading === false
                            ? _c("p", [
                                _vm._v(
                                  "登録失敗所属ユーザー: " +
                                    _vm._s(
                                      _vm.expandData.errorData.users.toString()
                                    )
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.expandData.errorData.groups.length > 0 &&
                          _vm.loading === false
                            ? _c("p", [
                                _vm._v(
                                  "設定失敗通行権限グループ: " +
                                    _vm._s(
                                      _vm.expandData.errorData.groups.toString()
                                    )
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-primary",
                      attrs: { outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.registerAff()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    作成する\n                  "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }