var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("UsersTemplate", {
    attrs: {
      users: _vm.users,
      loading: _vm.loading,
      loadingGroup: _vm.loadingGroup,
      maxRecord: _vm.maxRecord,
      page: _vm.currPage,
      pageGate: _vm.pageGate,
      groupId: _vm.groupId,
      loadingGate: _vm.loadingGate,
      isValid: _vm.isValid,
      perPage: _vm.perPage,
      isGetDataList: _vm.isGetDataList,
      showModalProcess: _vm.showModalProcess,
      progress: _vm.progress,
      isExport: _vm.isExport,
      showModalConfirmEmail: _vm.showModalConfirmEmail,
      typeConfirm: _vm.typeConfirm,
      success: _vm.success,
      message: _vm.message,
      totalDataRowCSV: _vm.totalDataRowCSV,
      tempGroupPassages: _vm.tempGroupPassages,
      cardItems: _vm.cardItems,
      phoneItems: _vm.phoneItems,
      errorMessageLogs: _vm.errorMessageLogs,
      successMessageLogs: _vm.successMessageLogs,
      listGroupPassages: _vm.listGroupPassages,
      listphones: _vm.listphones,
      listGatePassages: _vm.listGatePassages,
      successItemCSV: _vm.successItemCSV,
      errorMessage: _vm.errorMessage,
      showModalImport: _vm.showModalImport,
      exportType: _vm.exportType,
      showProcessImportExcel: _vm.showProcessImportExcel,
      successItem: _vm.successItem,
      totalDataRow: _vm.totalDataRow,
      errorMessageExcel: _vm.errorMessageExcel,
      isFinishImportExcel: _vm.isFinishImportExcel,
      sheetUserName: _vm.sheetUserName,
      ExpandedGroup: _vm.ExpandedGroup,
      gateList: _vm.gateList,
      pageGroup: _vm.pageGroup,
      groupDetail: _vm.groupDetail,
      typeAction: _vm.typeAction,
      isFinishProcess: _vm.isFinishProcess,
      errorMessageProcess: _vm.errorMessageProcess,
      showProgress: _vm.showProgress,
      totalDataRowProcess: _vm.totalDataRowProcess,
      successItemProcess: _vm.successItemProcess,
      showConfirm: _vm.showConfirm,
      action: _vm.action,
      userId: _vm.userId,
      isType: _vm.isType,
      sheet1Name: _vm.sheetUserName,
      successItemSheet1: _vm.successItem,
      progressSheet1: _vm.progress,
      totalDataRowSheet1: _vm.totalDataRow,
      successItemSheet2: _vm.successItemSheet2,
      progressSheet2: _vm.progressSheet2,
      totalDataRowSheet2: _vm.totalDataRowSheet2,
      sheet2Name: _vm.sheetGroupName,
      dataAllow: _vm.dataAllow,
      groupToSearch: _vm.groupToSearch,
      isHasLowerCaseCardNumberExcel: _vm.isHasLowerCaseCardNumberExcel,
      ExpandedData: _vm.ExpandedData,
      authority: _vm.authority,
      departments: _vm.departments
    },
    on: {
      searchUser: _vm.searchUser,
      clickAddUser: _vm.clickAddUser,
      clickEditUser: function($event) {
        return _vm.clickEditUser($event)
      },
      changeUserIsvalid: function($event) {
        return _vm.changeUserIsvalid($event)
      },
      changeMutiUserIsvalid: function($event) {
        return _vm.changeMutiUserIsvalid($event)
      },
      confirmAction: function($event) {
        return _vm.confirmAction($event)
      },
      changePerPage: function($event) {
        return _vm.changePerPage($event)
      },
      "change-page": function($event) {
        return _vm.changePage($event)
      },
      "click-leave-action": function($event) {
        return _vm.clickLeaveAction($event)
      },
      "change-expanded": function($event) {
        return _vm.changeExpanded($event)
      },
      clickExportCSV: _vm.clickExportCSV,
      clickExportCSVTimeUnlockGate: function($event) {
        return _vm.clickExportCSVTimeUnlockGate()
      },
      clickConfirmEmail: function($event) {
        return _vm.clickConfirmEmail($event)
      },
      closeModalProcessCSV: function($event) {
        return _vm.closeModalProcessCSV()
      },
      CancelProcessCSV: function($event) {
        return _vm.CancelProcessCSV()
      },
      closeImportCSV: function($event) {
        return _vm.closeImportCSV()
      },
      clickImportCSV: function($event) {
        return _vm.clickImportCSV($event)
      },
      clickImportCSVTimeUnlockGate: function($event) {
        return _vm.clickImportCSVTimeUnlockGate($event)
      },
      clickExportExcel: function($event) {
        return _vm.clickExportExcel($event)
      },
      clickImportExcel: function($event) {
        return _vm.clickImportExcel($event)
      },
      closeModalProcessImportExcel: function($event) {
        return _vm.closeModalProcessImportExcel()
      },
      CancelProcessImportExcel: function($event) {
        return _vm.CancelProcessImportExcel()
      },
      showDialogImport: function($event) {
        return _vm.showDialogImport($event)
      },
      closeModalProgress: function($event) {
        return _vm.closeModalProgress($event)
      },
      closeDialogConfirm: function($event) {
        return _vm.closeDialogConfirm()
      },
      "show-only-isVaild": function($event) {
        return _vm.ShowOnlyIsVaild($event)
      },
      "search-user-group": function($event) {
        return _vm.searchUserGroup($event)
      },
      "close-dialog-check-card-excel": function($event) {
        return _vm.closeDialogCheckCardExcel()
      },
      "accept-dialog-check-card-excel": function($event) {
        return _vm.acceptDialogCheckCardExcel()
      },
      clickCheckBoxExportUserInfoCSV: function($event) {
        return _vm.clickCheckBoxExportUserInfoCSV()
      },
      "loading-from-child": function($event) {
        return _vm.$emit("loading", $event)
      },
      "load-total": function($event) {
        return _vm.loadTotal($event)
      },
      "search-deparment-group": function($event) {
        return _vm.searchDeparmentGroup($event)
      },
      "get-list-department": function($event) {
        return _vm.getListDepartment($event)
      },
      "get-list-user": _vm.getList
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }