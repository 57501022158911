var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table allig-user-table" },
    [
      _vm.isExternalLogin === "registration"
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("CancelButton", {
                        attrs: { text: "戻る" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("goPageSetting")
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "mt-2 title-sub-login",
                      staticStyle: { "text-align": "center" },
                      attrs: { cols: "8" }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "center" } },
                        [
                          _vm.cognitoIdProviderName === "SAML"
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-subtitle-2 font-weight-bold",
                                  attrs: { "align-self": "start" }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.lableButtonLogin.oneLogin) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm.cognitoIdProviderName === "Google"
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    " mb-4 text-subtitle-2 font-weight-bold",
                                  attrs: { "align-self": "start" }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.lableButtonLogin.googleLogin) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm.cognitoIdProviderName === "Apple"
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    " mb-4 text-subtitle-2 font-weight-bold",
                                  attrs: { "align-self": "start" }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.lableButtonLogin.appleLogin) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-row", {
                staticClass: "ml-1 mt-5",
                attrs: { justify: "start" }
              }),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "round-action mb-5" },
                [
                  _c(
                    "v-row",
                    { staticClass: "btn-div", attrs: { justify: "end" } },
                    [
                      _c("v-col", { attrs: { cols: "10" } }, [
                        _c(
                          "div",
                          { staticClass: "area-search-box" },
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "", justify: "start" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { md: "5", xl: "4" } },
                                  [
                                    _c("searchbox", {
                                      staticClass: "input-search",
                                      attrs: {
                                        title: "メールアドレスで絞り込み",
                                        searchTargetItems: _vm.searchTargetItems
                                      },
                                      on: {
                                        search: function($event) {
                                          return _vm.$emit(
                                            "search-login",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "ml-10",
                                    attrs: { md: "5", xl: "3" }
                                  },
                                  [
                                    _c("v-autocomplete", {
                                      staticClass: "input-search",
                                      attrs: {
                                        items: _vm.userList,
                                        label: "ログインに使用するIDで絞り込み",
                                        "no-data-text": "",
                                        solo: "",
                                        clearable: "",
                                        "hide-details": "",
                                        disabled: _vm.isDisabled
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$emit(
                                            "select-user-login",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "btn-div",
                              attrs: { justify: "end" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-center mr-1" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "btn-add pt-0" },
                                    [
                                      _vm.cognitoIdProviderName === "SAML"
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "btn-primary mr-0",
                                              attrs: {
                                                outlined: "",
                                                disabled:
                                                  _vm.isSamlFederatedLoginService ===
                                                  "false"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "goPageExport",
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  ー括登録\n              "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "v-btn",
                                            {
                                              staticClass: "btn-primary mr-0",
                                              attrs: {
                                                outlined: "",
                                                disabled:
                                                  _vm.isFederatedLoginService ===
                                                  "false"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "goPageExport",
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  ー括登録\n              "
                                              )
                                            ]
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "row-message" },
                [
                  _vm.successMessageLogs !== ""
                    ? _c(
                        "v-col",
                        {
                          staticClass: "mb-4 font-weight-bold mt-4 bg-success"
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.successMessageLogs) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errorMessageLogs !== ""
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.errorMessageLogs) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errorMessageParams.email !== ""
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.errorMessageParams.email) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm.errorMessageParams.email === "" &&
                      _vm.errorMessageParams.userId !== ""
                    ? _c(
                        "v-col",
                        { staticClass: "mb-4 font-weight-bold mt-4 bg-error" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.errorMessageParams.userId) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "mt-4 mr-1",
                  attrs: {
                    "no-gutters": "",
                    justify: "end",
                    "align-content": "end"
                  }
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("show-per-page", {
                    staticClass: "ml-4 mb-2",
                    attrs: {
                      "per-page": _vm.innerPerPage,
                      page: _vm.page,
                      "items-length": _vm.maxRecord
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-data-table",
                {
                  staticClass: "border-table scroll-table",
                  attrs: {
                    "must-sort": "",
                    "custom-sort": _vm.customSort,
                    headers: _vm.headers,
                    items: _vm.realtionsUserList,
                    "no-data-text": "データーはありません",
                    "loading-text": "データを読み込中です",
                    "hide-default-footer": "",
                    "items-per-page": _vm.pageSizeList
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item",
                        fn: function(row) {
                          return [
                            _c("tr", [
                              _vm.selectedDisplayOption.includes(
                                "federatedLoginEmail"
                              )
                                ? _c("td", [
                                    _vm._v(_vm._s(row.item.federatedLoginEmail))
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.selectedDisplayOption.includes("userId")
                                ? _c("td", [_vm._v(_vm._s(row.item.userId))])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "last-col" },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { justify: "center" } },
                                    [
                                      _vm.cognitoIdProviderName === "SAML"
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                al: "",
                                                disabled:
                                                  _vm.isSamlFederatedLoginService ===
                                                  "false"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.showModal(
                                                    row.item.relationId
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "gray--text",
                                                  attrs: {
                                                    "align-self": "ceb="
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-trash-can-outline"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                al: "",
                                                disabled:
                                                  _vm.isFederatedLoginService ===
                                                  "false"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.showModal(
                                                    row.item.relationId
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "gray--text",
                                                  attrs: {
                                                    "align-self": "ceb="
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-trash-can-outline"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1981258235
                  )
                },
                [
                  _c("template", { slot: "body.prepend" }, [
                    _c("tr", { staticClass: "mt-4" }, [
                      _vm.selectedDisplayOption.includes("federatedLoginEmail")
                        ? _c(
                            "td",
                            [
                              _c("v-text-field", {
                                class:
                                  _vm.errorMessageParams.email !== ""
                                    ? "input-add input-error"
                                    : "input-add",
                                attrs: {
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.newUser.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.newUser, "email", $$v)
                                  },
                                  expression: "newUser.email"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.selectedDisplayOption.includes("userId")
                        ? _c(
                            "td",
                            [
                              _c("v-autocomplete", {
                                class:
                                  _vm.errorMessageParams.userId !== ""
                                    ? "input-add input-error"
                                    : "input-add",
                                attrs: {
                                  items: _vm.userList,
                                  color: "green",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "auto",
                                  "no-data-text": "",
                                  disabled: _vm.isDisabled,
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.newUser.userId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.newUser, "userId", $$v)
                                  },
                                  expression: "newUser.userId"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "align-self-center" },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _vm.cognitoIdProviderName === "SAML"
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-secondary",
                                      attrs: {
                                        disabled:
                                          _vm.isSamlFederatedLoginService ===
                                          "false",
                                        outlined: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "addRelation",
                                            _vm.newUser
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                追加\n              "
                                      )
                                    ]
                                  )
                                : _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-secondary",
                                      attrs: {
                                        disabled:
                                          _vm.isFederatedLoginService ===
                                          "false",
                                        outlined: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "addRelation",
                                            _vm.newUser
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                追加\n              "
                                      )
                                    ]
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ],
                2
              ),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "20px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "mt-3",
                  attrs: { "no-gutters": "", justify: "space-between" }
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mr-5 mb-2" },
                    [
                      _c("select-per-page", {
                        attrs: {
                          page: _vm.pageSizeList,
                          "per-page-items": [25, 50, 100],
                          "items-length": 1,
                          disabled: _vm.loading
                        },
                        model: {
                          value: _vm.innerPerPage,
                          callback: function($$v) {
                            _vm.innerPerPage = $$v
                          },
                          expression: "innerPerPage"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.disablePagination
                    ? _c("pagination", {
                        staticClass: "mb-1",
                        attrs: {
                          "page-length": Math.ceil(
                            _vm.maxRecord / _vm.pageSizeList
                          ),
                          disabled: _vm.loading
                        },
                        model: {
                          value: _vm.innerPage,
                          callback: function($$v) {
                            _vm.innerPage = $$v
                          },
                          expression: "innerPage"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-dialog",
                    {
                      attrs: { width: "500" },
                      model: {
                        value: _vm.isShowModal,
                        callback: function($$v) {
                          _vm.isShowModal = $$v
                        },
                        expression: "isShowModal"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("ModalTitle", {
                            attrs: { title: "ログインユーザーを削除" },
                            on: {
                              click: function($event) {
                                _vm.isShowModal = false
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            { staticClass: "pa-10 pt-5 pb-5" },
                            [_vm._v("ログインユーザーを削除しますか？")]
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-2",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c("success-button", {
                                    staticClass: "ml-3",
                                    attrs: { text: "削除" },
                                    on: { click: _vm.deleteRelation }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "ml-1 mt-5", attrs: { justify: "start" } },
                [
                  _c("CancelButton", {
                    attrs: { text: "戻る" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("goPageSetting")
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _c(
                "v-container",
                { staticClass: "info-export" },
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { width: "600", persistent: "" },
                      model: {
                        value: _vm.showModalImport,
                        callback: function($$v) {
                          _vm.showModalImport = $$v
                        },
                        expression: "showModalImport"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "background-color-green" },
                            [_vm._v(" CSVファイルエクスポート ")]
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row-message" },
                            [
                              _vm.errorMessage !== ""
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "mb-4 font-weight-bold mt-1 bg-error"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.errorMessage) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-row", {
                            staticStyle: { height: "5px" },
                            attrs: { "no-gutters": "" }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            { staticClass: "pa-4" },
                            [
                              _vm.errorMessage === ""
                                ? _c(
                                    "v-progress-linear",
                                    {
                                      attrs: {
                                        height: "25",
                                        color: "green darken-1",
                                        striped: ""
                                      },
                                      model: {
                                        value: _vm.progress,
                                        callback: function($$v) {
                                          _vm.progress = $$v
                                        },
                                        expression: "progress"
                                      }
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(Math.round(_vm.progress)) + "%"
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.successItem > 0
                                ? _c("span", { staticClass: "success-label" }, [
                                    _vm._v(
                                      _vm._s(_vm.totalDataRow) +
                                        "件中" +
                                        _vm._s(_vm.successItem) +
                                        "件をインポートしました。"
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.errorMessageExcel.length > 0
                                ? _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "error-box-title" },
                                      [_vm._v("インポート失敗")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "error-box" },
                                      [
                                        _vm._l(_vm.errorMessageExcel, function(
                                          item
                                        ) {
                                          return [
                                            _c("p", {
                                              key: item,
                                              domProps: {
                                                innerHTML: _vm._s(item)
                                              }
                                            })
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.errorMessage === "" &&
                              _vm.errorMessageExcel.length === 0 &&
                              Math.round(_vm.progress) === 100 &&
                              _vm.successItem === 0
                                ? _c("div", { staticClass: "success-label" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.totalDataRow) +
                                        "件中" +
                                        _vm._s(_vm.totalDataRow) +
                                        "件をインポートしました。\n              "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.success
                            ? _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "ma-2",
                                      attrs: { justify: "end" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn-secondary",
                                          attrs: { outlined: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "closeModalImport"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                OK\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "ml-4 mb-4 text-subtitle-2 border-left-blue font-weight-bold mt-10"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.cognitoIdProviderName) +
                              " ログインユーザー 一括登録\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-card", { staticClass: "mt-10 pa-5" }, [
                    _c("div", { staticClass: "alert alert-info col" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.cognitoIdProviderName) +
                          " ログインユーザーの登録を一括で実施することができます。"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n            登録するために必要なテンプレートCSVファイルは、”テンプレートのダウンロード”から取得してください。"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n            このアップロードでは、既に登録されているユーザー情報を変更することはできません。"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n            変更したいユーザーについては、削除を行ってから実施してください。\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "fallback mt-5" },
                      [
                        _c("p", { staticClass: "desc" }, [
                          _vm._v(
                            "自分のコンピューターからファイルを選択する ："
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "btn-upload",
                            attrs: { for: "file_csv" }
                          },
                          [
                            _vm._v(
                              "\n              CSVファイルを選択\n              "
                            ),
                            _c("input", {
                              ref: "myFiles",
                              staticClass: "custom-file-input",
                              staticStyle: { display: "none" },
                              attrs: {
                                type: "file",
                                id: "file_csv",
                                multiple: "",
                                accept: ".csv"
                              },
                              on: { change: _vm.previewFiles }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _vm.files
                          ? _c("span", { staticClass: "filename" }, [
                              _vm._v(_vm._s(_vm.files.name))
                            ])
                          : _c("span", { staticClass: "filename" }, [
                              _vm._v("選択されていません")
                            ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "error" }),
                        _vm._v(" "),
                        _c("div", { attrs: { id: "result" } }),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-primary mr-0",
                            attrs: { outlined: "" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("clickImport", {
                                  files: _vm.files
                                })
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                登録データのアップロード\n              "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { staticClass: "mt-10 pa-5" },
                    [
                      _c("div", { staticClass: "alert alert-info col" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.cognitoIdProviderName) +
                            " ログインユーザーの登録を一括で実施するテンプレートファイルをダウンロードできます。\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary mr-0 mt-10",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("clickExport", {
                                federatedLoginServiceId:
                                  _vm.federatedLoginServiceId
                              })
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                登録データのアップロード\n              "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }