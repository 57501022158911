



































import Vue from 'vue';
import UserAddTemplate from '@/components/templates/UserAddTemplate/UserAddTemplate.vue';
import * as Config from '@/config';
import moment from 'moment';
import store from '@/store';
export interface DataType {
  // Props
  isEdit: boolean;
  isDisabledGateOption: boolean;
  gateList: any;
  isDisabledGroupOptions: boolean;
  isDisabledPCodeOptions: boolean;
  gateGroupOptions: any;
  pCodeOptions: any;
  pageLoadGateList: number;
  userData: any;
  errorMessageLogs: string;
  successMessageLogs: string;
  listGatePassages: any;
  loading: boolean;
  // Page
  pagePCode: number;
  pageGroup: number;
  groupsAddFail: any;
  pCodeAddFail: any;
  gateAddFail: any;
  groupItemsOrigin: any;
  gateItemsOrigin: any;
  pcodeItemsOrigin: any;
  goBack: string;
  timeDefault: string;
  phoneItems: any;
  departments: any;
  getGroup: boolean;
  gateOfGroup: any;
  isGetDepartment: boolean;
  isGetDepartmentAll: boolean;
  authority: any;
  reLoad: boolean;
  getDepartments: any;
  dataFailed: any;
}
interface User {
  isAdmin: boolean;
  isMfaSms: boolean;
  userId: string;
  password?: string;
  name: string;
  furigana: string;
  phoneNumber?: string;
  option1: string;
  option2: string;
  email?: string;
  isPhoneAdmin?: boolean;
  isSendEmail?: boolean;
  isPasswordLoginValid: boolean;
  groups?: any;
  departments?: any;
  departmentIds?: any;
}
export default Vue.extend({
  name: 'UserAddPage',
  components: {
    UserAddTemplate,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: '',
    },
    departmentId: {
      type: String,
      default: '',
    },
  },
  created() {
    console.log('userId', this.userId);
    this.isGetDepartmentAll = true;
    if (this.$route.path === '/user/edit' || this.userId !== '') {
      this.isEdit = true;
      console.log('this.$route.query', this.$route.query);
      if ((this.$route.query.userId !== null && this.$route.query.userId !== undefined) || this.userId !== '') {
        const userId = this.userId !== '' ? this.userId : this.$route.query.userId;
        this.loading = true;
        this.getInFoUser(userId).then(async (value) => {
          if (value) {
            this.userData.groupIds = [];
            this.userData.pCodeIds = [];
            this.userData.phoneIds = [];
            this.userData.groupAdministrators = [];
            this.userData.departmentAdministrators = [];
            this.userData.groupAdministratorsOri = [];
            this.userData.departmentAdministratorsOri = [];
            this.userData.departmentOri = {
              relationId: '',
              departmentId: '',
            };
            this.getDepartmentUser(userId);
            this.getPcodeById(1, userId);
            this.getInforGatePassage(1, userId);
            if (this.userData.isAdmin !== true) {
              await this.getGroupAuth(userId);
              await this.getDepartmentAuth(userId);
            }
            this.getGroup = false;
            this.isDisabledGroupOptions = true;
            this.isGetDepartment = true;
            this.getListGate();
            this.getGroupList();
            this.getPCodeList();
            this.getDepartment(1);
            this.getPhone(1, userId);
            if (this.userData.groupAdministrators.length > 0 || this.userData.departmentAdministrators.length > 0) {
              this.userData.isAdmin = null;
            }
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
        console.log('userId', this.userData);
      }
      if (this.$route.query.goBack !== undefined) {
        this.goBack = this.$route.query.goBack.toString();
      }
    } else {
      if (this.departmentId !== '') {
        this.userData.departmentId = this.departmentId;
      }
      this.changeId();
      this.getGroup = false;
      this.isDisabledGroupOptions = true;
      this.isGetDepartment = true;
      this.getListGate();
      this.getGroupList();
      this.getPCodeList();
      this.getDepartment(1);
    }
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
    reLoad(val) {
      console.log('val', val + '' +  this.isEdit +  '' + this.$route.query.userId);
      if (val === false && this.isEdit === true) {
        if ((this.$route.query.userId !== null && this.$route.query.userId !== undefined) || this.userId !== '') {
          const userId = this.userId !== '' ? this.userId : this.$route.query.userId;
          this.getPcodeByIdAfterUpdate(1, userId);
        }
      }
    },
    async isGetDepartment(val: any) {
      if (val === false) {
        if (this.authority.isAdmin) {
          this.isGetDepartmentAll = false;
        } else {
          const data = this.getDepartments.map((item: any) => ({...item}));
          this.getDepartments = [];
          data.map((v: any) => Object.assign(v, {disabled: true}));
          const result: any = await this.getDepartmentAuthAll(localStorage.userProfileId, 1, data);
          this.getDepartments = result.filter((item: any) => item.disabled === false);
          this.isGetDepartmentAll = false;
        }
      }
    },
  },
  data: (): DataType => ({
    // Props
    isGetDepartment: false,
    getGroup: false,
    isEdit: false,
    isDisabledGateOption: false,
    gateList: [],
    isDisabledGroupOptions: false,
    isDisabledPCodeOptions: false,
    gateGroupOptions: [],
    pCodeOptions: [],
    pageLoadGateList: 1,
    userData: {
      isAdmin: false,
      isMfaSms: false,
      userId: '',
      password: '',
      passCode: '',
      name: '',
      furigana: '',
      phoneNumber: '',
      option1: '',
      option2: '',
      isSendEmail: false,
      isPasswordLoginValid: true,
      departmentAdministrators: [],
      groupAdministrators: [],
      departmentAdministratorsOri: [],
      groupAdministratorsOri: [],
      groups: [],
      departments: [],
      departmentId: '',
      departmentOri: {
        relationId: '',
        departmentId: '',
      },
    },
    errorMessageLogs: '',
    successMessageLogs: '',
    listGatePassages: [],
    // Page
    loading: false,
    goBack: 'user',
    pagePCode: 1,
    pageGroup: 1,
    groupsAddFail: [],
    pCodeAddFail: [],
    groupItemsOrigin: [],
    gateItemsOrigin: [],
    pcodeItemsOrigin: [],
    gateAddFail: [],
    timeDefault: '',
    phoneItems: [],
    departments: [],
    gateOfGroup:  {
      tmp: [],
      data: [],
      page: 1,
      perPage: 25,
      total : 1,
      loading: false,
    },
    authority: store.state.auth,
    reLoad: false,
    isGetDepartmentAll: false,
    getDepartments: [],
    dataFailed: {
      departmentAdministrators: {
        add: [],
        edit: [],
        del: [],
      },
      groupAdministrators: {
        add: [],
        edit: [],
        del: [],
      },
    },
  }),
  computed: {},
  methods: {
    backUserList() {
      if (this.goBack === 'personal') {
        this.$router.push({ path: '/' + this.goBack, query: {goBack: 'true'}});
      } else {
        this.$router.push({ path: '/' + this.goBack});
      }
    },
    initData() {
      this.userData = {
        isAdmin: false,
        isMfaSms: false,
        userId: '',
        password: '',
        name: '',
        furigana: '',
        phoneNumber: '',
        passCode: '',
        option1: '',
        option2: '',
        isSendEmail: false,
        isPasswordLoginValid: true,
        pCodeIds: [],
        groupIds: [],
        phoneIds: [],
        gate: {},
        departmentAdministrators: [],
        groupAdministrators: [],
        departmentAdministratorsOri: [],
        groupAdministratorsOri: [],
      };
    },
    async getDataAgain(data: any) {
      console.log(data);
      this.groupsAddFail = [];
      this.pCodeAddFail = [];
      this.gateAddFail = [];
      // GATE
      const gateListForm = data.gate;
      const gateList = this.gateItemsOrigin;
      if (gateListForm !== undefined && gateListForm.length > 0) {
        // gate del
        const deGateData = gateList.filter((o1: { gate: any; notAfter: any; notBefore: any}) =>
        !gateListForm.some((o2: { gate: any; notAfter: any; notBefore: any }) => o1.gate.value === o2.gate.value &&
        o1.notAfter === o2.notAfter && o1.notBefore === o2.notBefore));
        // gate add
        const postGateData = gateListForm.filter((o1: { gate: any; notAfter: any; notBefore: any}) =>
        !gateList.some((o2: { gate: any; notAfter: any; notBefore: any}) => o1.gate.value === o2.gate.value &&
        o1.notAfter === o2.notAfter && o1.notBefore === o2.notBefore));
        if (deGateData.length > 0) {
          for (const item of deGateData) {
            await this.delGatePassage(item.passageId, item.gate.text);
          }
        }
        if (postGateData.length > 0) {
          for (const item of postGateData) {
            const dataP = {
              userId: data.userInfo.userId,
              gateId: item.gate.value,
              notBefore: item.notBefore,
              notAfter: item.notAfter,
            };
            await this.postGatePassage(dataP, item.gate.text);
          }
        }
      } else if ((gateListForm !== undefined || gateListForm.length === 0) && gateList.length > 0) {
        for (const item of gateList) {
          await this.delGatePassage(item.passageId, item.gate.text);
        }
      }
       // CARD - PCODE
      const pcodeListForm = data.userInfo.pCodeIds;
      const pcodeList = this.pcodeItemsOrigin;
      if ( pcodeListForm !== undefined && pcodeListForm.length > 0) {
        // pcode delete
        const dePcodeData = pcodeList.filter((o1: { value: any; }) =>
        !pcodeListForm.some((o2: { value: any; }) => o1.value === o2.value));
        // pcode update
        const updatePcodeData = pcodeList.filter((o1: { value: any; }) =>
        pcodeListForm.some((o2: { value: any; }) => o1.value === o2.value));
        // pcode add
        const postPcodeData = pcodeListForm.filter((o1: { value: any; }) =>
        !pcodeList.some((o2: { value: any; }) => o1.value === o2.value));
        if (dePcodeData.length > 0) {
          for (const item of dePcodeData) {
            const dataP = {
              userId : '',
            };
            await this.delPcode(item.value, dataP, item.text);
          }
        }
        if (updatePcodeData.length > 0) {
          for (const item of updatePcodeData) {
            const dataP = {
              userId : data.userInfo.userId,
              name : item.text,
            };
            await this.delPcode(item.value, dataP, item.text);
          }
        }
        if (postPcodeData.length > 0) {
          for (const item of postPcodeData) {
            const dataP = {
              userId : data.userInfo.userId,
              type : item.type,
              pCodeId : item.value,
            };
            await this.addPcode(dataP, item.text);
          }
        }
      } else if ((pcodeListForm !== undefined || pcodeListForm.length === 0) && pcodeList.length > 0) {
        for (const item of pcodeList) {
          const dataP = {
            userId : '',
          };
          await this.delPcode(item.value, dataP, item.text);
        }
      }
      const resultId = await this.updateDepartmentId(data.userInfo);
      let text = '';
      if (resultId === false) {
        const itemDepartment = this.departments.filter((element: any, index: any) => {
          if (element.value === data.userInfo.departmentId) {
            return element;
          }
        });
        if (itemDepartment[0].text) {
          text += '所属選択( ' + itemDepartment[0].text  + ')の編集に失敗しています。 <br />';
        }
      }
      await this.deleteData(data);

      if (data.userInfo.isAdmin === null) {
        await this.addDepartmentAdministrators(data);
        await this.addGroupAdministrators(data);
      }
      // get error item
      const dataResult: any = await this.addErrorItem();
      console.log('dataResult', dataResult);
      if (dataResult.department.length > 0) {
        text += '所属権限( ' + dataResult.department.toString() + ')編集に失敗しています。<br />';
      }
      if (dataResult.group.length > 0) {
        text += '通行可能グループ権限( ' + dataResult.group.toString() + ')編集に失敗しています。<br />';
      }
      if (text !== '') {
        this.successMessageLogs = '';
        this.errorMessageLogs = Config.EDIT_USER_API_SUCCESS + '<br />';
        this.errorMessageLogs += text;
        this.errorMessageLogs += '失敗した項目は反映されませんので再度確認してください。';
      }
      this.groupItemsOrigin = [];
      this.pcodeItemsOrigin = [];
      this.gateItemsOrigin = [];
      this.listGatePassages = [];
      this.phoneItems = [];
      this.userData.phoneIds = [];
      this.userData.pCodeIds = [];
      this.pCodeOptions = [];
      if (this.userData.isAdmin !== true) {
        await this.getGroupAuth(data.userInfo.userId);
        await this.getDepartmentAuth(data.userInfo.userId);
      }
      this.getInforGatePassage(1, data.userInfo.userId);
      this.getPhone(1, data.userInfo.userId);
      this.isDisabledPCodeOptions = true;
      this.getPCodeList();
      this.reLoad = true;
      this.loading = false;
    },
    getAllPassable(page: any) {
      const apiLink = Config.PASSABLE_TIME_SETTING + '?limit=1000&page=' + page;
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.passableTimeSettings.forEach((element: any) => {
              if (element.isDefault === true) {
                this.timeDefault = element.passableTimeSettingId;
              }
            });
            if (response.body.maxRecords > response.body.endRecord && this.timeDefault === '') {
              this.getAllPassable(page + 1);
            }
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          }); // catch
    },
    getListGate() {
      this.isDisabledGroupOptions = true;
      this.isDisabledPCodeOptions = true;
      this.gateGroupOptions = [];
      this.pCodeOptions = [];
      this.pagePCode = 1;
      this.pageGroup = 1;
      this.$http.get(Config.GATES_LIST_API + '?limit=1000&page=' + this.pageLoadGateList + '&sortKey=asc:gateId&isValid=true&isExceptOptionalGate=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.gates.forEach((element: any) => {
              this.gateList.push({text: element.gateName, value: element.gateId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.pageLoadGateList += 1;
              this.getListGate();
            } else {
              this.isDisabledGateOption = false;
            }
          } else {
            this.isDisabledGateOption = false;
            this.pageLoadGateList = 1;
            this.gateList = [];
          }
        })
        .then( null, (err: any) => console.log('err: ', err)); // catch
    },
    getInforGatePassage(page: any, userId: any) {
      this.$http.get(Config.GATES_PASSAGE_API + '?userId=' + encodeURIComponent(userId) + '&embed=gate' + '&limit=1000&page=' + page + '&isExceptOptionalGate=true&sortKey=desc:notAfter')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.passages.forEach((element: any) => {
              this.listGatePassages.push({gate: {value: element.gateId, text: element.gate ? element.gate.name : ''},
              notAfter: element.notAfter, notBefore: element.notBefore});
              this.gateItemsOrigin.push({gate: {value: element.gateId, text: element.gate ? element.gate.name : ''},
              notAfter: element.notAfter, notBefore: element.notBefore, passageId: element.passageId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getInforGatePassage(page + 1, userId);
            }
          } else {
            this.listGatePassages = [];
            this.gateItemsOrigin = [];
          }
        })
        .then(null, (error) => {
          console.log(error);
        });
    },
    async clickSaveUser(data: any) {
      let result;
      this.loading = true;
      this.errorMessageLogs = '';
      this.successMessageLogs = '';
      this.dataFailed = {
        departmentAdministrators: {
          add: [],
          edit: [],
          del: [],
        },
        groupAdministrators: {
          add: [],
          edit: [],
          del: [],
        },
      };
      result = await this.postUser(data);
      if (result) {
        if (data.userInfo.isAdmin === null) {
          this.startAddGatePassage(data)
          .then(() => this.startAddPcode(data))
          .then(() => this.startAddGroupOne(data))
          .then(() => this.addDepartmentAdministrators(data))
          .then(() => this.addGroupAdministrators(data))
          .then(async () => {
            // get error item
            const dataResult: any = await this.addErrorItem();
            let text = '';
            if (dataResult.department.length > 0) {
              text += '所属権限( ' + dataResult.department.toString() + ')登録に失敗しています。<br />';
            }
            if (dataResult.group.length > 0) {
              text += '通行可能グループ権限( ' + dataResult.group.toString() + ')登録に失敗しています。<br />';
            }
            if (text !== '') {
              this.successMessageLogs = '';
              this.errorMessageLogs = 'ユーザーが追加されました。 <br />';
              this.errorMessageLogs += text;
              this.errorMessageLogs += '失敗した項目は反映されませんので再度確認してください。';
            }
            this.loading = false;
          });
        } else {
          this.startAddGatePassage(data)
          .then(() => this.startAddPcode(data))
          .then(() => this.startAddGroupOne(data))
          .then(() => {
            this.loading = false;
          });
        }
      } else {
        this.loading = false;
      }
    },
    postUser(data: any) {
      return new Promise((resolve, reject) => {
        this.loading = true;
        let user: User;
        user = {
          isAdmin: data.userInfo.isAdmin ? true : false,
          isMfaSms: data.userInfo.isMfaSms,
          userId: data.userInfo.userId,
          name: data.userInfo.name,
          furigana: data.userInfo.furigana,
          option1: data.userInfo.option1,
          option2: data.userInfo.option2,
          isSendEmail: data.userInfo.isSendEmail,
          password: data.userInfo.password,
          isPasswordLoginValid: data.userInfo.isPasswordLoginValid,
        };
        if (data.userInfo.departmentId !== undefined && data.userInfo.departmentId !== '') {
          user.departmentIds = [data.userInfo.departmentId];
        }
        if (data.userInfo.phoneNumber !== undefined && data.userInfo.phoneNumber !== null
        && data.userInfo.phoneNumber !== ''
        && data.userInfo.isMfaSms === true) {
          const phoneSelft = data.userInfo.phoneNumber.substring(1);
          user.phoneNumber = '+81' + phoneSelft;
        }
        if (data.userInfo.email !== undefined && data.userInfo.email !== null && data.userInfo.email !== '') {
          user.email = data.userInfo.email;
        }
        if (data.userInfo.isPhoneAdmin !== undefined && data.userInfo.isPhoneAdmin !== null
        && data.userInfo.isPhoneAdmin !== '') {
          user.isPhoneAdmin = data.userInfo.isPhoneAdmin;
        }
        console.log('data', data);
        this.$http.post(Config.USERS_LIST_API, JSON.stringify(user))
          .then((response: any) => {
            if (response.body.message === 'Success') {
              this.errorMessageLogs = '';
              this.successMessageLogs = 'ユーザーが追加されました。';
              this.listGatePassages = [];
              // if (data.userInfo.groupIds !== undefined &&
              // data.userInfo.groupIds !== null && data.userInfo.groupIds !== ''
              // && data.userInfo.groupIds.length > 0) {
              //   this.startAddGroup(data);
              // }
              // if (data.gate !== undefined &&
              // data.gate !== null && data.gate !== '' && data.gate.length > 0) {
              //   this.startAddGatePassage(data);
              // }
              resolve(true);
            } else {
              this.successMessageLogs = '';
              this.errorMessageLogs = Config.ADD_USER_API_ERROR;
              resolve(false);
            }
          })
          .then( null, (error: any) => {
            console.log('error', error);
            this.successMessageLogs = '';
            this.errorMessageLogs = '';
            switch (error.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                this.errorMessageLogs = Config.ERROR_403;
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 409:
                this.errorMessageLogs = Config.ERROR_409;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            resolve(false);
        });
      });
    },
    async startAddGroupOne(data: any) {
      if (data.addIds !== undefined && data.addIds !== null &&
      data.addIds !== '' && data.addIds.length > 0) {
        this.groupsAddFail = [];
        const arr: any = [];
        for (const item of data.addIds) {
          if (!arr.includes(item.groupId)) {
            const element = {
              value : item.groupId,
              text : item.groupId,
            };
            await this.AddGroupItem(element, data.userInfo.userId);
            arr.push(item.groupId);
          }
        }
      }
    },
    async startAddGroup(data: any) {
      if (data.selectedIds !== undefined && data.selectedIds !== null &&
      data.selectedIds !== '' && data.selectedIds.length > 0) {
        this.groupsAddFail = [];
        const arr: any = [];
        for (const item of data.selectedIds) {
          if (!arr.includes(item)) {
            const element = {
              value : item,
              text : item,
            };
            await this.AddGroupItem(element, data.userInfo.userId);
            arr.push(item);
          }
        }
      }
    },
    postGroupItem(element: any, ID: any) {
      return new Promise((resolve, reject) => {
        if (this.timeDefault !== '') {
          const dataInfo = {
            name: element.value,
            isValid: true,
            passableTimeSettingId: this.timeDefault,
          };
          this.$http.post(Config.GROUP_LIST_API, dataInfo)
            .then((response: any) => {
              if (response.body.message === 'Success') {
                this.$http.post(Config.GROUPS_PASSAGE_API, {groupId: response.body.groupId,
                  userId: ID})
                  .then((res: any) => {
                    resolve(true);
                  })
                  .then(null, (error: any) => {
                    this.loading = false;
                    switch (error.status) {
                      case 409:
                      this.groupsAddFail.push(element.text);
                      break;
                    }
                    resolve(true);
                  });
              } else {
                resolve(false);
              }
            })
            .then( null, (err: any) => {
              this.groupsAddFail.push(element.text);
              resolve(false);
            }); // catch
        } else {
          this.groupsAddFail.push(element.text);
          resolve(false);
        }
      });
    },
    AddGroupItem(element: any, ID: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.GROUPS_PASSAGE_API, {groupId: element.value,
          userId: ID})
          .then((response: any) => {
            resolve(true);
          })
          .then(null, (error: any) => {
            this.loading = false;
            switch (error.status) {
              case 409:
              this.groupsAddFail.push(element.text);
              break;
            }
            resolve(true);
          });
      });
    },
    async startAddPcode(data: any) {
      if (data.userInfo.pCodeIds !== undefined && data.userInfo.pCodeIds !== null &&
      data.userInfo.pCodeIds !== '' && data.userInfo.pCodeIds.length > 0) {
        this.pCodeAddFail = [];
        for (const item of data.userInfo.pCodeIds) {
          await this.addPcodeItem(item, data.userInfo.userId);
        }
      }
    },
    addPcodeItem(element: any, ID: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.PCODE_LIST_API, {pCodeId: element.value, userId: ID,
        type: element.type})
        .then((response: any) => {
          if (response.body.message === 'Success') {
            console.log('Success');
          } else {
            this.pCodeAddFail.push(element.text);
          }
          resolve(true);
        })
        .then(null, (error: any) => {
          switch (error.status) {
            case 409:
              const dataP = {
                userId : ID,
              };
              // this.delPcode(element.value, dataP);
              this.$http.put(Config.CARD_LIST_API + '/' + encodeURIComponent(element.value), dataP)
                .then((response: any) => {
                  console.log('Success');
                  resolve(true);
                })
                .then( null, (er: any) => {
                  this.pCodeAddFail.push(element.text);
                  resolve(true);
                });
              break;
            default:
              this.pCodeAddFail.push(element.text);
              resolve(true);
              break;
          }
        });
      });
    },
    async startAddGatePassage(data: any) {
      if (data.gate !== undefined && data.gate !== null && data.gate !== '' && data.gate.length > 0) {
        for (const item of data.gate) {
          await this.startAddGatePassageItem(item, data.userInfo.userId);
        }
      }
    },
    startAddGatePassageItem(element: any, ID: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.GATES_PASSAGE_API, {userId: ID, gateId: element.gate.value,
        notBefore: (element.notBefore), notAfter: (element.notAfter)})
          .then((response: any) => {
            if (response.body.message === 'Success') {
              console.log('Success');
            } else {
              this.gateAddFail.push(element.gate.text);
            }
            resolve(true);
          })
          .then(null, (error: any) => {
            switch (error.status) {
              case 409 : this.gateAddFail.push(element.gate.text); break;
            }
            resolve(true);
          });
      });
    },
    getGroupById(page: any, userId: any) {
      this.$http.get(Config.GROUP_PASSAGE + '?userId=' + encodeURIComponent(userId) + '&limit=1000&page=' + page + '&sortKey=desc:userId&embed=group')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            const groupData =  response.body.groupPassages;
            groupData.sort((a: any, b: any) =>
            (a.group.groupName.toLowerCase() < b.group.groupName.toLowerCase() ? -1 : 1));
            groupData.forEach((element: any) => {
              if (element.group !== undefined && element.group.isValid === 1) {
                const groupName = element.group.groupName;
                this.userData.groupIds.push({
                  text: groupName,
                  value: element.groupId,
                });
                this.groupItemsOrigin.push({
                  text: groupName,
                  value: element.groupId,
                  groupPassageId: element.groupPassageId,
                });
              }
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getGroupById(page + 1, userId);
            } else {
              this.loading = false;
            }
          } else {
            this.userData.groupIds = [];
            this.loading = false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.loading = false;
        }); // catch
    },
    getGroupByIdAfterUpdate(page: any, userId: any) {
      this.$http.get(Config.GROUP_PASSAGE + '?userId=' + encodeURIComponent(userId) + '&limit=1000&page=' + page + '&sortKey=desc:userId&embed=group')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            const groupData =  response.body.groupPassages;
            groupData.sort((a: any, b: any) =>
            (a.group.groupName.toLowerCase() < b.group.groupName.toLowerCase() ? -1 : 1));
            groupData.forEach((element: any) => {
              if (element.group !== undefined && element.group.isValid === 1) {
                console.log(element);
                const groupName = element.group.groupName;
                this.groupItemsOrigin.push({
                  text: groupName,
                  value: element.groupId,
                  groupPassageId: element.groupPassageId,
                });
              }
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getGroupByIdAfterUpdate(page + 1, userId);
            }
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
        }); // catch
    },
    getPcodeById(page: any, userId: any) {
      this.$http.get(Config.CARD_LIST_API + '?userId=' + encodeURIComponent(userId) + '&limit=1000&page=' + page + '&isValid=true&embed=user')
        .then((response: any) => {
          console.log(response);
          if (response.body.maxRecords > 0) {
            response.body.pCodes.forEach((element: any) => {
              console.log(element);
              const lable = element.pCodeId + (element.name !== '' ? '＜' + element.name + '＞' : '') +
              (element.user !== undefined ? '(' + element.user.name + ')' : '');
              this.userData.pCodeIds.push({text: lable, value: element.pCodeId, type: element.type});
              this.pcodeItemsOrigin.push({text: element.name, value: element.pCodeId, type: element.type});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getPcodeById(page + 1, userId);
            } else {
              this.loading = false;
            }
          } else {
            this.userData.pCodeIds = [];
            this.loading = false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.loading = false;
        }); // catch
    },
    getPcodeByIdAfterUpdate(page: any, userId: any) {
      this.$http.get(Config.CARD_LIST_API + '?userId=' + encodeURIComponent(userId) + '&limit=1000&page=' + page + '&isValid=true&embed=user')
        .then((response: any) => {
          console.log(response);
          if (response.body.maxRecords > 0) {
            response.body.pCodes.forEach((element: any) => {
              const lable = element.pCodeId + (element.name !== '' ? '＜' + element.name + '＞' : '') +
              (element.user !== undefined ? '(' + element.user.name + ')' : '');
              this.userData.pCodeIds.push({text: lable, value: element.pCodeId, type: element.type});
              this.pcodeItemsOrigin.push({text: element.name, value: element.pCodeId, type: element.type});
                 });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getPcodeByIdAfterUpdate(page + 1, userId);
            }
          } else {
            this.userData.pCodeIds = [];
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
        }); // catch
    },
    getGroupList() {
      this.$http.get(Config.GROUP_LIST_API + '?limit=1000' + '&page=' + this.pagePCode +
        '&keyword=' + '' + '&isValid=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.groups.forEach((element: any) => {
              this.gateGroupOptions.push({text: element.name, value: element.groupId, disabled: false});
              this.gateGroupOptions.sort((a: any, b: any) => (a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1));
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.pageGroup += 1;
              this.getGroupList();
            } else {
              this.isDisabledGroupOptions = false;
            }
          } else {
            this.loading = false;
            this.isDisabledGroupOptions = false;
          }
        })
        .then(null, (error: any) => {
          this.gateGroupOptions = [];
          this.isDisabledGroupOptions = false;
        });
    },
    getPCodeList() {
      this.$http.get(Config.PCODE_LIST_API + '?limit=1000&page=' + this.pagePCode + '&sortKey=desc:registered&isValid=true&embed=user')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.pCodes.forEach((pCode: any) => {
              const lable = pCode.pCodeId + (pCode.name !== '' ? '＜' + pCode.name + '＞' : '') +
              (pCode.user !== undefined ? '(' + pCode.user.name + ')' : '');
              this.pCodeOptions.push({text: lable, value: pCode.pCodeId, type: pCode.type});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.pagePCode += 1;
              this.getPCodeList();
            } else {
              this.isDisabledPCodeOptions = false;
              this.reLoad = false;
            }
          } else {
            this.isDisabledPCodeOptions = false;
            this.pCodeOptions = [];
            this.pagePCode = 1;
            this.reLoad = false;
          }
        })
        .then(null, (err: any) => {
          this.isDisabledPCodeOptions = true;
          this.pCodeOptions = [];
          this.reLoad = false;
        });
    },
    getInFoUser(userId: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
          .then((response: any) => {
            this.userData =  response.body.users[0];
            this.userData.phoneNumber = this.userData.phoneNumber.replace('+81', '0');
            this.userData.isMfaSms = localStorage.isMfa === 'true' ? response.body.users[0].isMfaSms : false;
            resolve(true);
          })
          .then(null, (error: any) => {
            resolve(false);
            switch (error.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (error.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
        });
      });
    },
    changePassword(data: any) {
      const credentials = {
        userId: data.userInfo.userId,
        newPassword: data.userInfo.newPassword,
        isSendEmail: data.userInfo.isSendEmail,
      };
      console.log(credentials);
      this.$http.post(Config.USER_CHANGE_PASSWORD_API, JSON.stringify(credentials))
        .then((response: any) => {
          console.log('response', response);
          this.updateUser(data);
          })
        .then(null, (error) => {
          this.loading = false;
          switch (error.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (error.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        });
    },
    async clickUpdateUser(data: any) {
      this.loading = true;
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      this.groupsAddFail = [];
      console.log(data);
      if (data.userInfo.newPassword !== undefined && data.userInfo.newPassword !== null && data.userInfo.newPassword !== '') {
        this.changePassword(data);
      } else {
        if (data.updatedPhonesList !== undefined &&
            data.updatedPhonesList !== null &&
            data.updatedPhonesList.length > 0) {
          await this.updatePhone(data.updatedPhonesList);
        }
        this.updateUser(data);
      }
    },
    delGatePassage(passageId: any, gateName: any) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.GATES_PASSAGE_API + '/' + encodeURIComponent(passageId))
          .then((response: any) => {
            console.log('Success');
            resolve(true);
          })
          .then( null, (err: any) => {
            this.gateAddFail.push(gateName);
            resolve(true);
            // this.successMessageLogs = '';
            // this.errorMessageLogs = 'サービスが利用不可。システムメンテナンス等の場合に利用します。';
          });
      });
    },
    postGatePassage(data: any, gateName: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.GATES_PASSAGE_API, data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              console.log('Success');
            } else {
              this.gateAddFail.push(gateName);
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            switch (err.status) {
              case 409 : this.gateAddFail.push(gateName); break;
            }
            resolve(true);
            // this.successMessageLogs = '';
            // this.errorMessageLogs = 'サービスが利用不可。システムメンテナンス等の場合に利用します。';
          });
      });
    },
    addPcode(data: any, pcodeName: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.CARD_LIST_API, data)
          .then((response: any) => {
            console.log('Success');
            resolve(true);
          })
          .then( null, (err: any) => {
            switch (err.status) {
              case 409:
                const dataP = {
                  userId : data.userId,
                };
                // this.delPcode(data.pCodeId, dataP);
                this.$http.put(Config.CARD_LIST_API + '/' + encodeURIComponent(data.pCodeId), dataP)
                  .then((response: any) => {
                    console.log('Success');
                    resolve(true);
                  })
                  .then( null, (er: any) => {
                    this.pCodeAddFail.push(pcodeName);
                    resolve(true);
                  });
                break;
              default:
                console.log(err);
                this.pCodeAddFail.push(pcodeName);
                resolve(true);
                break;
            }
            // this.successMessageLogs = '';
            // this.errorMessageLogs = 'サービスが利用不可。システムメンテナンス等の場合に利用します。';
          });
      });
    },
    delPcode(pCodeId: any, data: any, pcodeName: any) {
      return new Promise((resolve, reject) => {
        this.$http.put(Config.CARD_LIST_API + '/' + encodeURIComponent(pCodeId), data)
          .then((response: any) => {
            console.log('Success');
            resolve(true);
          })
          .then( null, (err: any) => {
            this.pCodeAddFail.push(pcodeName);
            resolve(true);
            // this.successMessageLogs = '';
            // this.errorMessageLogs = 'サービスが利用不可。システムメンテナンス等の場合に利用します。';
          });
      });
    },
    delGroup(groupPassageId: any, groupName: any) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.GROUPS_PASSAGE_API + '/' + encodeURIComponent(groupPassageId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            this.groupsAddFail.push(groupName);
            resolve(true);
            // this.successMessageLogs = '';
            // this.errorMessageLogs = 'groupPassageIdが存在しない。';
          });
      });
    },
    postGroup(data: any, groupName: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.GROUP_PASSAGE, data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              console.log('Success');
            } else {
              this.groupsAddFail.push(groupName);
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            switch (err.status) {
              case 409:
              this.groupsAddFail.push(groupName);
              break;
            }
            resolve(true);
          }); // catch
      });
    },
    updatePhone(phones: any) {
      return new Promise((resolve, reject) => {
        for (const item of phones) {
          let data = { isValid: false };
          if (item.text.startsWith('申請中 : ')) {
            data = { isValid: true };
          }
          this.$http.put(Config.PHONES_LIST_API + '/' + encodeURIComponent(item.value), data)
          .then((response: any) => {
            console.log('responseresponse', response);
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('errerrerr', err);
            resolve(true);
          });
        }
      });
    },
    updateUser(data: any) {
      const encodedUserId = encodeURIComponent(encodeURIComponent(data.userInfo.userId));
      if (localStorage.userProfileId === data.userInfo.userId) {
        const OuserInforSelft: any = {};
        OuserInforSelft.name = data.userInfo.name;
        OuserInforSelft.furigana = data.userInfo.furigana;
        OuserInforSelft.email = data.userInfo.email;
        OuserInforSelft.isSendEmail = data.userInfo.isSendEmail;
        OuserInforSelft.passCode = data.userInfo.passCode;
        OuserInforSelft.isMfaSms = data.userInfo.isMfaSms;
        if (data.userInfo.isMfaSms === true) {
          const phoneSelft = data.userInfo.phoneNumber.substring(1);
          OuserInforSelft.phoneNumber = '+81' + phoneSelft;
        }
        OuserInforSelft.isPasswordLoginValid = data.userInfo.isPasswordLoginValid;
        OuserInforSelft.password = data.userInfo.password;
        OuserInforSelft.option1 = data.userInfo.option1;
        OuserInforSelft.option2 = data.userInfo.option2;
        console.log(OuserInforSelft);
        this.$http.put(Config.USERS_LIST_API + '/' + encodedUserId, JSON.stringify(OuserInforSelft))
          .then((response: any) => {
            this.errorMessageLogs = '';
            this.successMessageLogs = Config.EDIT_USER_API_SUCCESS;
            this.getDataAgain(data);
          })
          .then(null, (error) => {
            this.successMessageLogs = '';
            this.loading = false;
            switch (error.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (error.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
          });
      } else {
        const OuserInfor: any = {};
        OuserInfor.name = data.userInfo.name;
        OuserInfor.furigana = data.userInfo.furigana;
        OuserInfor.isSendEmail = false;
        OuserInfor.email = data.userInfo.email;
        if (data.userInfo.thumbnail !== null && data.userInfo.thumbnail !== '') {
          OuserInfor.thumbnail = data.userInfo.thumbnail;
        }
        OuserInfor.passCode = data.userInfo.passCode;
        OuserInfor.isMfaSms = data.userInfo.isMfaSms;
        if (data.userInfo.isMfaSms === true) {
          const phone = data.userInfo.phoneNumber.substring(1);
          OuserInfor.phoneNumber = '+81' + phone;
        }
        OuserInfor.isPasswordLoginValid = data.userInfo.isPasswordLoginValid;
        OuserInfor.password = data.userInfo.password;
        if (this.authority.isAdmin) {
          OuserInfor.isAdmin = data.userInfo.isAdmin ? true : false;
          OuserInfor.isPhoneAdmin = data.userInfo.isPhoneAdmin;
          OuserInfor.shareKeyBeValid = data.userInfo.shareKeyBeValid;
        }
        OuserInfor.isValid = data.userInfo.isValid;
        OuserInfor.option1 = data.userInfo.option1;
        OuserInfor.option2 = data.userInfo.option2;
        OuserInfor.isSendEmail = data.userInfo.isSendEmail;
        console.log(OuserInfor);
        this.$http.put(Config.USERS_LIST_API + '/' + encodedUserId, JSON.stringify(OuserInfor))
          .then((response: any) => {
            this.successMessageLogs = Config.EDIT_USER_API_SUCCESS;
            this.errorMessageLogs = '';
            this.getDataAgain(data);
          })
          .then(null, (error) => {
            this.successMessageLogs = '';
            this.loading = false;
            switch (error.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (error.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_500;
                break;
            }
          });
      }
    },
    formatTimestamp(t: string) {
      return moment(t).unix();
    },
    getPhone(page: any, userId: any) {
      const validPhones: any = [];
      const applyingPhones: any = [];
      this.$http.get(Config.PHONES_LIST_API + '?limit=1000&page=' + page +
      '&sortKey=desc:registered' + '&userId=' + encodeURIComponent(userId))
      .then((response: any) => {
        if (response.body.maxRecords > 0) {
          response.body.phones.forEach((element: any) => {
            if (element.status === '申請中') {
              const textDisplay = '申請中 : ' + element.model + '(' + this.convertDate(element.registered) + ')';
              applyingPhones.push({text: textDisplay, value: element.phoneId});
            }
            if (element.status === '有効') {
              const textDisplay = element.model + '(' + this.convertDate(element.registered) + ')';
              validPhones.push({text: textDisplay, value: element.phoneId});
            }
          });
          if (response.body.maxRecords > response.body.endRecord) {
            this.getPhone(page + 1, userId);
          }
          applyingPhones.forEach((element: any) => {
            this.phoneItems.push(element);
          });
          validPhones.forEach((element: any) => {
            this.phoneItems.push(element);
            this.userData.phoneIds.push(element);
          });
        }
      })
      .then( null, (err: any) => {
        console.log('err: ', err);
      }); // catch
    },
    convertDate(t: any) {
      return moment(new Date(t * 1000)).format('YYYY/MM/DD');
    },
    getDepartment(page: any) {
      this.$http.get(Config.DEPARTMENT_API + '?limit=1000&page=' + page)
      .then((response: any) => {
        response.body.departments.forEach((element: any) => {
          this.departments.push({text: element.name, value: element.departmentId});
          this.getDepartments.push({text: element.name, value: element.departmentId});
          this.departments.sort((a: any, b: any) => (a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1));
          this.getDepartments.sort((a: any, b: any) => (a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1));
        });
        if (response.body.maxRecords > response.body.endRecord) {
          page++;
          this.getDepartment(page);
        } else {
          this.isGetDepartment = false;
        }
      }).
      then( null, (err: any) => {
        this.isGetDepartment = false;
      });
    },
    // 所属選択
    async updateDepartmentId(data: any) {
      if (data.departmentId !== data.departmentOri.departmentId) {
        let result: any = true;
        if (data.departmentOri.departmentId !== '') {
          result = await this.deleteDepartmentId(data.departmentOri.relationId);
        }
        if (result) {
          const res = await this.addDepartmentId({
            departmentId: data.departmentId,
            userId: data.userId,
          });
          return res;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    deleteDepartmentId(ID: any) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.RELATION_DEPARTMENT_USER_API + '/' + ID)
        .then((response: any) => {
          resolve(true);
        })
        .then(null, (error: any) => {
          resolve(false);
        });
      });
    },
    addDepartmentId(data: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.RELATION_DEPARTMENT_USER_API, data)
        .then((response: any) => {
          resolve(true);
        })
        .then(null, (error: any) => {
          resolve(false);
        });
      });
    },
    async deleteData(data: any) {
      this.dataFailed.departmentAdministrators.del = [];
      this.dataFailed.groupAdministrators.del = [];
      if (data.deleteData.relationIds.length > 0) {
        for (const item of data.deleteData.relationIds) {
          const result = await this.deleteDepartmentItem(item.relationId);
          if (result === false) {
            this.dataFailed.departmentAdministrators.del.push(item);
          }
        }
      }
      if (data.deleteData.groupAdministratorIds.length > 0) {
        for (const item of data.deleteData.groupAdministratorIds) {
          const result = await this.deleteGroupAdministratorsItem(item.groupAdministratorId);
          if (result === false) {
            this.dataFailed.groupAdministrators.del.push(item);
          }
        }
      }
    },
    async addDepartmentAdministrators(data: any) {
      if (data.listAff !== undefined && data.listAff !== null &&
      data.listAff !== '' && data.listAff.length > 0) {
        this.dataFailed.departmentAdministrators.add = [];
        this.dataFailed.departmentAdministrators.edit = [];
        for (const item of data.listAff) {
          if (item.departmentId.value !== undefined && item.departmentId.value !== '') {
            if (item.disabled) {
              const i = this.userData.departmentAdministratorsOri.findIndex((x: any) =>
              x.departmentId === item.departmentId.value);
              if (i >= 0) {
                const department = this.userData.departmentAdministratorsOri[i];
                if (department.isAdminToEditUser !== item.isAdminToEditUser ||
                department.isAdminToAddUser !== item.isAdminToAddUser
                || department.isAdminToSetPcodesToUser !== item.isAdminToSetPcodesToUser) {
                  await this.updateDepartmentItem(item, data.userInfo.userId);
                }
              }
            } else {
              await this.addDepartmentItem(item, data.userInfo.userId);
            }
          }
        }
      }
    },
    deleteDepartmentItem(ID: any) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.DEPARTMENT_ADMINISTRATOR_API + '/' + ID)
        .then((response: any) => {
          resolve(true);
        })
        .then(null, (error: any) => {
          resolve(false);
        });
      });
    },
    updateDepartmentItem(element: any, ID: any) {
      return new Promise((resolve, reject) => {
        this.$http.put(Config.DEPARTMENT_ADMINISTRATOR_API + '/' + element.relationId, {
          isAdminToEditUser: element.isAdminToEditUser, isAdminToAddUser: element.isAdminToAddUser ,
          isAdminToSetPcodesToUser: element.isAdminToSetPcodesToUser})
        .then((response: any) => {
          if (response.body.message === 'Success') {
            console.log('Success');
          } else {
            this.dataFailed.departmentAdministrators.edit.push(element);
          }
          resolve(true);
        })
        .then(null, (error: any) => {
          this.dataFailed.departmentAdministrators.edit.push(element);
          resolve(true);
        });
      });
    },
    addDepartmentItem(element: any, ID: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.DEPARTMENT_ADMINISTRATOR_API , {departmentId: element.departmentId.value, userId: ID,
          isAdminToEditUser: element.isAdminToEditUser, isAdminToAddUser: element.isAdminToAddUser,
          isAdminToSetPcodesToUser: element.isAdminToSetPcodesToUser})
        .then((response: any) => {
          if (response.body.message === 'Success') {
            console.log('Success');
          } else {
            this.dataFailed.departmentAdministrators.add.push(element);
          }
          resolve(true);
        })
        .then(null, (error: any) => {
          this.dataFailed.departmentAdministrators.add.push(element);
          resolve(true);
        });
      });
    },
    async addGroupAdministrators(data: any) {
      if (data.listGroup !== undefined && data.listGroup !== null &&
      data.listGroup !== '' && data.listGroup.length > 0) {
        this.dataFailed.groupAdministrators.add = [];
        this.dataFailed.groupAdministrators.edit = [];
        for (const item of data.listGroup) {
          if (item.groupId.value !== undefined && item.groupId.value !== '') {
            if (item.disabled) {
              const i = this.userData.groupAdministratorsOri.findIndex((x: any) =>
              x.groupId === item.groupId.value);
              const group = this.userData.groupAdministratorsOri[i];
              if (group.isAdminOfGatePassages !== item.isAdminOfGatePassages ||
              group.isAdminOfGroupPassages !== item.isAdminOfGroupPassages
              || group.isAdminOfRemoteUnlock !== item.isAdminOfRemoteUnlock) {
                await this.updateGroupAdministratorsItem(item, data.userInfo.userId);
              }
            } else {
              await this.addGroupAdministratorsItem(item, data.userInfo.userId);
            }
          }
        }
      }
    },
    updateGroupAdministratorsItem(element: any, ID: any) {
      return new Promise((resolve, reject) => {
        this.$http.put(Config.GROUP_ADMINISTRATOR_API + '/' + element.groupAdministratorId, {
          isAdminOfGroupPassages: element.isAdminOfGroupPassages,
          isAdminOfGatePassages: element.isAdminOfGatePassages, isAdminOfRemoteUnlock: element.isAdminOfRemoteUnlock})
        .then((response: any) => {
          if (response.body.message === 'Success') {
            console.log('Success');
          } else {
            this.dataFailed.groupAdministrators.edit.push(element);
          }
          resolve(true);
        })
        .then(null, (error: any) => {
          this.dataFailed.groupAdministrators.edit.push(element);
          resolve(true);
        });
      });
    },
    addGroupAdministratorsItem(element: any, ID: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.GROUP_ADMINISTRATOR_API, {groupId: element.groupId.value, userId: ID,
          isAdminOfGroupPassages: element.isAdminOfGroupPassages,
          isAdminOfGatePassages: element.isAdminOfGatePassages, isAdminOfRemoteUnlock: element.isAdminOfRemoteUnlock})
        .then((response: any) => {
          if (response.body.message === 'Success') {
            console.log('Success');
          } else {
            this.dataFailed.groupAdministrators.add.push(element);
          }
          resolve(true);
        })
        .then(null, (error: any) => {
          this.dataFailed.groupAdministrators.add.push(element);
          resolve(true);
        });
      });
    },
    deleteGroupAdministratorsItem(ID: any) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.GROUP_ADMINISTRATOR_API + '/' + ID)
        .then((response: any) => {
          resolve(true);
        })
        .then(null, (error: any) => {
          resolve(false);
        });
      });
    },
    getGroupAuth(userId: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.GROUP_ADMINISTRATOR_API + '?userId=' + (userId) + '&limit=1000')
        .then(async (response: any) => {
          this.userData.groupAdministrators = response.body.groupAdministrators;
          this.userData.groupAdministratorsOri = response.body.groupAdministrators;
          resolve(true);
        }).
        then( null, (err: any) => {
          resolve(false);
        });
      });
    },
    getDepartmentAuth(userId: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.DEPARTMENT_ADMINISTRATOR_API + '?limit=1000&userId=' + encodeURIComponent(userId))
        .then(async (response: any) => {
          this.userData.departmentAdministrators = response.body.departmentAdministrators;
          this.userData.departmentAdministratorsOri = response.body.departmentAdministrators;
          resolve(true);
        }).
        then( null, (err: any) => {
          resolve(false);
        });
      });
    },
    getDepartmentUser(userId: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.RELATION_DEPARTMENT_USER_API + `?userId=${encodeURIComponent(userId)}&embed=department`)
          .then((response: any) => {
            if (response.body.relationsDepartmentUser) {
              const relationsDepartmentUser = response.body.relationsDepartmentUser[0];
              this.userData.departmentId = relationsDepartmentUser.departmentId;
              this.userData.departmentOri.departmentId = relationsDepartmentUser.departmentId;
              this.userData.departmentOri.relationId = relationsDepartmentUser.relationId;
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            resolve(false);
          });
      });
    },
    getDepartmentAuthAll(userId: any, page = 1, data: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.DEPARTMENT_ADMINISTRATOR_API + '?userId=' + (userId) + '&limit=1000&page=' + page)
        .then(async (response: any) => {
          response.body.departmentAdministrators.forEach((element: any) => {
            const isAction = this.isEdit ? element.isAdminToEditUser : element.isAdminToAddUser;
            if (isAction) {
              const check = data.map((item: any) => item.value).indexOf(element.departmentId);
              if (check >= 0) {
                data[check].disabled = false;
              }
            }
          });
          if (response.body.maxRecords > response.body.endRecord) {
              page++;
              this.getDepartmentAuthAll(userId, page, data);
            } else {
              resolve(data);
            }
        }).
        then( null, (err: any) => {
          resolve(data);
        });
      });
    },
    changeId() {
      if (this.userData.departmentId === '' && this.userData.isAdmin !== true) {
        setTimeout(() => {
        this.userData.departmentId = '';
      }, 100);
        this.userData.departmentId = null;
      }
    },
    addErrorItem() {
      return new Promise((resolve, reject) => {
        const add: any = [];
        const edit: any = [];
        const del: any = [];
        const addGroup: any = [];
        const editGroup: any = [];
        const delGroup: any = [];
        if (this.dataFailed.departmentAdministrators.add.length > 0) {
          //
          this.dataFailed.departmentAdministrators.add.forEach((element: any) => {
            add.push(element.departmentId.text);
          });
        }
        if (this.dataFailed.departmentAdministrators.edit.length > 0) {
          //
          this.dataFailed.departmentAdministrators.edit.forEach((element: any) => {
            const item = this.departments.filter((el: any) => {
              if (el.value === element.departmentId.value) {
                return el;
              }
            });
            console.log('item', item);
            edit.push(item[0].text);
          });
        }
        if (this.dataFailed.departmentAdministrators.del.length > 0) {
          //
          this.dataFailed.departmentAdministrators.del.forEach((element: any) => {
            const item = this.departments.filter((el: any) => {
              if (el.value === element.departmentId.value) {
                return el;
              }
            });
            del.push(item[0].text);
          });
        }
        if (this.dataFailed.groupAdministrators.add.length > 0) {
          //
          this.dataFailed.groupAdministrators.add.forEach((element: any) => {
            addGroup.push(element.groupId.text);
          });
        }
        if (this.dataFailed.groupAdministrators.edit.length > 0) {
          //
          this.dataFailed.groupAdministrators.edit.forEach((element: any) => {
            const item = this.gateGroupOptions.filter((el: any) => {
              if (el.value === element.groupId.value) {
                return el;
              }
            });
            editGroup.push(item[0].text);
          });
        }
        if (this.dataFailed.groupAdministrators.del.length > 0) {
          //
          this.dataFailed.groupAdministrators.del.forEach((element: any) => {
            const item = this.gateGroupOptions.filter((el: any) => {
              if (el.value === element.groupId.value) {
                return el;
              }
            });
            delGroup.push(item[0].text);
          });
        }
        const data = {
          department : add.concat(edit).concat(del),
          group: addGroup.concat(editGroup).concat(delGroup),
        };
        resolve(data);
      });
    },
  },
});
