var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-reflection-status-template" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "div",
            { staticClass: "round-action" },
            [
              _c(
                "v-row",
                { staticClass: "btn-div", attrs: { justify: "end" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "end" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "btn-add-card" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("clickBackGate")
                                    }
                                  }
                                },
                                [_vm._v("\n              戻る\n              ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _c(
                      "div",
                      { staticClass: "area-search-box" },
                      [
                        _c(
                          "v-row",
                          {
                            attrs: {
                              "no-gutters": "",
                              justify: "space-between"
                            }
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "7" } },
                              [
                                _c("v-autocomplete", {
                                  staticClass: "input-search",
                                  attrs: {
                                    items: _vm.searchTargetTypeItems,
                                    label: "詳細で絞り込み",
                                    color: "green",
                                    solo: "",
                                    clearable: "",
                                    "hide-details": ""
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.$emit("select-detail", $event)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "4" } },
                              [
                                _c("v-autocomplete", {
                                  staticClass: "input-search",
                                  attrs: {
                                    items: _vm.searchTargetStatusItems,
                                    label: "ステータスで絞り込み",
                                    solo: "",
                                    clearable: "",
                                    "hide-details": ""
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.$emit("select-status", $event)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-row", {
            staticStyle: { height: "20px" },
            attrs: { "no-gutters": "" }
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("reflection-status-table", {
            attrs: {
              statusTotalCount: _vm.statusTotalCount,
              dataCommands: _vm.dataCommands,
              page: _vm.page,
              perPage: _vm.perPage,
              loading: _vm.loading,
              errorMessageLogs: _vm.errorMessageLogs
            },
            on: {
              "change-per-page": function($event) {
                return _vm.$emit("changePerPage", $event)
              },
              "change-page": function($event) {
                return _vm.$emit("changePage", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }