





































import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import CardLink from '@/components/molecules/CardLink/CardLink.vue';

/** 外部連携設定として表示する情報一覧 */
export interface ExternalInfo {
  /** 連携名称 */
  name: string;
  /** 画像パス。assetなどの画像をrequireで読み込み */
  imgSrc: string;
  /** trueでComingSoon表示 */
  isComingSoon: boolean;
}

export default Vue.extend({
  name: 'ExternalListTemplate',
  components: {
    InputForm,
    TextLink,
    SuccessButton,
    CancelButton,
    CardLink,
  },
  props: {
    externalList: {
      type: Array as PropType<ExternalInfo[]>,
      default: () => [],
    },
  },
});
