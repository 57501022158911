var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-list-template" },
    [
      _c(
        "v-card-title",
        { staticClass: "background-color-green" },
        [
          _vm._t("default", [
            _c(
              "v-row",
              { attrs: { "no-gutters": "", justify: "space-between" } },
              [
                _vm._v("全ての通行権限グループ一覧\n      "),
                _c(
                  "v-icon",
                  {
                    attrs: { color: "white" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("close-page-modal", $event)
                      }
                    }
                  },
                  [_vm._v("mdi-close")]
                )
              ],
              1
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("v-container", [
        _c(
          "div",
          { staticClass: "container-user pt-0" },
          [
            _c(
              "div",
              { staticClass: "round-action" },
              [
                _c(
                  "v-row",
                  { staticClass: "btn-div", attrs: { justify: "end" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pt-0 pb-0", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-row",
                          { staticClass: "btn-div", attrs: { justify: "end" } },
                          [
                            _vm.authority.isAdmin === true
                              ? _c(
                                  "div",
                                  { staticClass: "text-center mr-1" },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "btn-add pt-0" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-primary",
                                            attrs: { outlined: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit(
                                                  "clickAddGroup"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("グループの追加")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.authority.isAdmin === true
                              ? _c(
                                  "div",
                                  { staticClass: "text-center mr-4" },
                                  [
                                    _c("action-button", {
                                      attrs: {
                                        text: "アクション ▼",
                                        "action-items": _vm.actionItems,
                                        disabled:
                                          _vm.selectedGroupIds.length === 0
                                      },
                                      on: {
                                        "click-enable": function($event) {
                                          return _vm.showDialogConfirm(0)
                                        },
                                        "click-disable": function($event) {
                                          return _vm.showDialogConfirm(1)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "pb-0", attrs: { cols: "12" } },
                      [
                        _c(
                          "div",
                          { staticClass: "area-search-box" },
                          [
                            _c(
                              "v-row",
                              {
                                attrs: {
                                  "no-gutters": "",
                                  justify: "space-between"
                                }
                              },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("searchbox", {
                                      staticClass:
                                        "input-search btn-user-search",
                                      attrs: {
                                        searchTargetItems:
                                          _vm.searchTargetItems,
                                        title: "グループを検索"
                                      },
                                      on: {
                                        search: function($event) {
                                          return _vm.$emit(
                                            "search-group",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("group-list-modal-table", {
              attrs: {
                authority: _vm.authority,
                dataGroupsModal: _vm.dataGroupsModal,
                page: _vm.page,
                perPage: _vm.perPage,
                loading: _vm.loading
              },
              on: {
                "change-per-page": function($event) {
                  return _vm.$emit("changePerPage", $event)
                },
                "click-group-detail": function($event) {
                  return _vm.$emit("click-group-detail", $event)
                },
                "change-page": function($event) {
                  return _vm.$emit("changePage", $event)
                },
                "get-group-isValid": function($event) {
                  return _vm.$emit("get-group-isValid", $event)
                },
                "select-groups": function(groupIds) {
                  _vm.selectedGroupIds = groupIds
                },
                clickEditGroup: _vm.clickEditGroup
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showConfirm,
            callback: function($$v) {
              _vm.showConfirm = $$v
            },
            expression: "showConfirm"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("ModalTitle", {
                attrs: {
                  title:
                    _vm.typeAction === 0
                      ? "グループを有効化"
                      : "グループを無効化"
                },
                on: {
                  click: function($event) {
                    return _vm.closeDialogConfirm()
                  }
                }
              }),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _vm.typeAction === 0
                ? _c("v-card-text", { staticClass: "pa-4" }, [
                    _c("p", [_vm._v("チェックしたグループを有効化します。")])
                  ])
                : _vm.typeAction === 1
                ? _c("v-card-text", { staticClass: "pa-4" }, [
                    _c("p", [_vm._v("チェックしたグループを無効化します。")])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.clickGroupsAction()
                            }
                          }
                        },
                        [_vm._v("OK")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showProgress,
            callback: function($$v) {
              _vm.showProgress = $$v
            },
            expression: "showProgress"
          }
        },
        [
          _c(
            "v-card",
            [
              _vm.typeAction === 0
                ? _c(
                    "v-card-title",
                    { staticClass: "background-color-green" },
                    [_vm._v("グループを有効化")]
                  )
                : _vm.typeAction === 1
                ? _c(
                    "v-card-title",
                    { staticClass: "background-color-green" },
                    [_vm._v("グループを無効化")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _vm.totalDataRowProcess > 0
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-progress-linear",
                        {
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.progress,
                            callback: function($$v) {
                              _vm.progress = $$v
                            },
                            expression: "progress"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(Math.round(_vm.progress)) + "%")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.successItemProcess > 0 && _vm.typeAction === 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.totalDataRowProcess) +
                                "件中" +
                                _vm._s(_vm.successItemProcess) +
                                "件を有効化しました。"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.successItemProcess > 0 && _vm.typeAction === 1
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.totalDataRowProcess) +
                                "件中" +
                                _vm._s(_vm.successItemProcess) +
                                "件を無効化しました。"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageProcess.length > 0
                        ? _c("div", [
                            _vm.typeAction === 0
                              ? _c("span", { staticClass: "error-box-title" }, [
                                  _vm._v("有効化失敗")
                                ])
                              : _vm.typeAction === 1
                              ? _c("span", { staticClass: "error-box-title" }, [
                                  _vm._v("無効化失敗")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "error-box" },
                              _vm._l(_vm.errorMessageProcess, function(item) {
                                return _c("p", {
                                  key: item,
                                  domProps: { innerHTML: _vm._s(item) }
                                })
                              }),
                              0
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFinishProcess
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeModalProgress()
                                }
                              }
                            },
                            [_vm._v("OK")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.isGroupEditModalShow
        ? _c(
            "v-dialog",
            {
              attrs: { width: "1200", persistent: "" },
              model: {
                value: _vm.isGroupEditModalShow,
                callback: function($$v) {
                  _vm.isGroupEditModalShow = $$v
                },
                expression: "isGroupEditModalShow"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("GroupAddPage", {
                    on: {
                      "go-time-settings": function($event) {
                        return _vm.$emit("go-time-settings", $event)
                      },
                      deleteBridgeProperty: _vm.deleteBridgeProperty,
                      loading: function($event) {
                        return _vm.$emit("loading", $event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }