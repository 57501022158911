

































































































































































import Vue, { PropType } from 'vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import GateList from '@/components/organisms/GateList/GateList.vue';
import * as Config from '@/config';
import { Group, Gate, RelationGateGroup } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';

export interface DataType {
  selectedItem: [];
  loadGroups: any;
  showConfirm: boolean;
  groupPassage: any;
  groupItem: any;
}

export default Vue.extend({
  name: 'GroupGateTable',
  components: {
    Pagination,
    ShowPerPage,
    TextLink,
    GateList,
  },
  props: {
    isChange: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    groups: {
      type: Array,
      default: () => [],
    },
    gateList: {
      default: () => [],
    },
    groupTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageGroup: {
      type: Number,
      default: 1,
    },
    userId: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
    perPage: {
      type: Number,
      default: 25,
    },
    relationsGateGroupLoading: {
      type: Boolean,
      default: false,
    },
    loadingGate: {
      type: Boolean,
      default: false,
    },
    ExpandedGroup: {
      default: () => [],
    },
    groupDetail: {
      default: () => [],
    },
    errorMessageProcess: {
      default: () => [],
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    pageGate: {
      type: Number,
      default: 0,
    },
    messageSetting: {
      default: () => ({}),
    },
  },
  data: (): DataType => ({
    groupPassage: [],
    selectedItem: [],
    loadGroups: [],
    showConfirm: false,
    groupItem: '',
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'ゲート名',
            align: 'start',
            sortable: false,
            value: 'gateName',
          },
          {
            text: '適用されている通行権限グループ',
            align: 'start',
            sortable: false,
            value: 'groupName',
          },
          {
            text: '通行可能時間帯',
            align: 'start',
            sortable: false,
            width: '150px',
            value: 'passableTimeSettingName',
          },
        ];
        return headers;
      },
    },
    innerPage: {
      get(): number {
        return this.pageGroup;
      },
      set(val: number): void {
        this.$emit('change-page-group', {page: val, userId: this.userId, change: 'group' });
        return;
      },
    },
  },
  watch: {
    groups(val: any) {
      this.selectedItem = [];
    },
    selectedItem(val: []) {
      this.$emit('select-Ids', val.map((item: any) => item.groupPassageId));
    },
    loading(val: any) {
      if (val) {
        //
      } else {
        this.loadGroups = this.groups.length > 0 ? this.groups : [] ;
      }
    },
    isChange(val: any) {
      if (val) {
        this.loadGroups = [];
        const target = document.getElementById('expanded-data') as HTMLElement;
        target.scrollIntoView({block: 'center'});
      }
    },
  },
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
    clickDetail(ID: any) {
      this.$router.push({ path: Config.GROUP_DETAIL, query: {groupId: ID, goBack: 'user'}});
    },
    addGroupPass(item: any) {
      this.showConfirm = true;
      this.groupPassage.push({
        text: item.group.name,
        value: item.group.groupId,
      });
    },
    checkPassableTimeSetting(item: any) {
      if (item.passableTimeSetting !== undefined && item.passableTimeSetting.timeSettings !== undefined
      && item.passableTimeSetting.timeSettings !== null && item.passableTimeSetting.timeSettings.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
});
