var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("reflection-status-list-template", {
    attrs: {
      dataCommands: _vm.dataCommands,
      statusTotalCount: _vm.statusTotalCount,
      page: _vm.currPage,
      perPage: _vm.perPage,
      loading: _vm.loading,
      errorMessageLogs: _vm.errorMessageLogs
    },
    on: {
      changePerPage: function($event) {
        return _vm.changePerPage($event)
      },
      changePage: function($event) {
        return _vm.changePage($event)
      },
      "select-detail": function($event) {
        return _vm.selectDetail($event)
      },
      "select-status": function($event) {
        return _vm.selectStatus($event)
      },
      clickBackGate: function($event) {
        return _vm.clickBackGate($event)
      },
      "loading-from-child": function($event) {
        return _vm.$emit("loading", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }