









































































































































































































































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
const min: string[] = [];
for (let i = 0; i <= 59 ; i++) {
  let value = '';
  if (i < 10) {
    value = '0' + i;
  } else {
    value = i.toString();
  }
  min.push(value);
}
export interface DataType {
  modelItems: any;
  isMore: boolean;
  textError: boolean;
  isAntipass: boolean;
  antipassbackSetting: boolean;
  dataGate: any;
  inputGate: any;
  rulesValidate: any;
  hours: any[];
  minutes: any[];
  hourStart: string;
  minuteStart: string;
  antipassbackClearTime: string;
  isUpdate: boolean;
  configData: any;
  // productId: any;
  // productPass: any;
}

interface TypeSelect {
  text: string;
  value: string;
}

export default Vue.extend({
  name: 'ControlPanelAddForm',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
  },
  props: {
    confirm: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    step: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    typeItems: {
      type: Array as PropType<TypeSelect[]>,
      default: () => [],
    },
    dataAllGates: {
      default: () => [],
    },
    gateList: {
      default: () => [],
    },
    controllerModel: {
      default: () => [],
    },
    dataControlPanels: {
      default: {
        controllerId: '',
        name: '',
        phoneId: '',
        model: '',
        antipassbackSetting: 0,
        antipassbackClearTime: '',
        userId: '',
        password: '',
        gateCount: 1,
      },
    },
  },
  watch: {
    dataControlPanels(data: any) {
      if (data.antipassbackSetting !== undefined && data.antipassbackSetting === 2) {
        this.antipassbackSetting = true;
        if (data.antipassbackClearTime !== '99:99') {
          this.isAntipass = true;
        }
      } else {
        this.antipassbackSetting = false;
      }
      if (data.antipassbackClearTime !== '') {
        this.antipassbackClearTime = data.antipassbackClearTime;
      }
    },
    isAntipass(value: any) {
      if (value === false) {
        this.textError = false;
      } else {
        const pattern = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
        const error = pattern.test(this.antipassbackClearTime);
        if (error) {
          this.textError = false;
        } else {
          this.textError = true;
        }
      }
    },
    antipassbackSetting(value: any) {
      if (value === false) {
        this.isAntipass = false;
        this.textError = false;
      }
    },
    antipassbackClearTime(value: any) {
      if (value !== '' && this.isAntipass === true) {
        const pattern = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
        const error = pattern.test(value);
        if (error) {
          this.textError = false;
        } else {
          this.textError = true;
        }
      }
    },
    loading(val: any) {
      if (val === false && this.dataControlPanels.antipassbackClearTime !== '') {
        this.antipassbackClearTime = this.dataControlPanels.antipassbackClearTime;
        if (this.dataControlPanels.antipassbackClearTime === '99:99') {
          this.hourStart = '00';
          this.minuteStart = '00';
        }
      }
    },
    errorMessageLogs(val: any) {
      if (val !== '' && this.isEdit === false) {
        (this.$refs.formController as Vue & { resetValidation: () => boolean }).resetValidation();
      }
    },
    successMessageLogs(val: any) {
      if (val !== '' && this.isEdit === false) {
        (this.$refs.formController as Vue & { resetValidation: () => boolean }).resetValidation();
      }
    },
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'グループ名',
            align: 'start',
            sortable: false,
            width: '80%',
            value: 'data-table-expand',
          },
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'expand-action',
          },
        ];
        return headers;
      },
    },
  },
  data: (): DataType => ({
    hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    minutes: min,
    hourStart: '00',
    antipassbackClearTime: '',
    minuteStart: '00',
    modelItems: [
      {
        text: 'WA-01/02',
        value: 'WA-01/02',
      },
      {
        text: 'WA-08/16',
        value: 'WA-08/16',
      },
    ],
    isMore: false,
    configData: Config,
    isUpdate: false,
    isAntipass: false,
    textError: false,
    antipassbackSetting: false,
    dataGate: [],
    inputGate: [],
    // productId: '',
    // productPass: '',
    rulesValidate: {
      requiredName: (value: string) => !!value || '制御盤名は128文字以内で入力してください。',
      validateName: (name: string) => {
        console.log('name', name.length);
        return name.length <= 128 || '制御盤名は128文字以内で入力してください。';
      },
      validateUserId: (userId: string) => {
        const pattern = /^[a-zA-Z0-9@.!#$%&'*+/=?^_`{|}~-]{4,96}$/;
        if (userId !== '') {
        return pattern.test(userId) || 'ユーザーは半角英数字で4文字以上96文字以内で設定してください。';
        } else {
          return true;
        }
      },
      requiredModel: (value: any) => {
        if (value !== undefined && value.text !== undefined && value.text !== null && value.text !== '' ) {
          return true;
        } else {
          return Config.VALIDATE_MODEL;
        }
      },
      validatePassword: (password: string) => {
        const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$/;
        return pattern.test(password) || 'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。';
      },
      validateNewPassword: (password: string) => {
        if (password !== undefined && password !== null && password !== '') {
          const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$/;
          return pattern.test(password) || 'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。';
        } else {
          return true;
        }
      },
      validateConfirmPass(confirm: any, pass: any) {
        if (confirm !== undefined && confirm !== null && confirm !== '' && confirm !== pass) {
          return '「新しいパスワード」と一致しません。';
        } else {
          return true;
        }
      },
      validateTime: (antipassbackSetting: boolean, isAntipass: boolean, value: any) => {
        if (isAntipass === false) {
          return true;
        } else {
          const pattern = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
          const error = pattern.test(value);
          return pattern.test(value);
        }
      },
    },
  }),

  methods: {
    formatTime(hours: string, minute: number | string) {
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}`;
    },
    clickSaveControlPanel() {
      const validate = (this.$refs.formController as Vue & { validate: () => boolean }).validate();
      if (validate) {
        this.$emit('clickSaveControlPanel', {
          data: this.dataControlPanels,
          antipassbackSetting: this.antipassbackSetting,
          antipassbackClearTime: this.antipassbackClearTime,
          isAntipass: this.isAntipass,
        });
      } else {
        if (this.isAntipass) {
          const pattern = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
          const error = pattern.test(this.antipassbackClearTime);
          if (error) {
            this.textError = false;
          } else {
            this.textError = true;
          }
        }
      }
    },
    actionUpdate() {
      const validate = (this.$refs.formController as Vue & { validate: () => boolean }).validate();
      if (validate) {
        if (this.antipassbackSetting === true) {
          this.isUpdate = true;
        } else {
          this.clickUpdateControlPanel(false);
        }
      } else {
        if (this.isAntipass) {
          const pattern = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
          const error = pattern.test(this.antipassbackClearTime);
          if (error) {
            this.textError = false;
          } else {
            this.textError = true;
          }
        }
      }
    },
    clickUpdateControlPanel(val: any) {
      this.isUpdate = false;
      this.$emit('clickUpdateControlPanel', {
          data: this.dataControlPanels,
          antipassbackSetting: this.antipassbackSetting,
          antipassbackClearTime: this.antipassbackClearTime,
          check: val,
          isAntipass: this.isAntipass,
        });
    },
    showMore() {
      this.isMore = this.isMore ? false : true;
    },
  },
});
