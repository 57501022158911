var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-col",
            {
              staticClass:
                "text-subtitle-2 border-left-blue font-weight-bold mb-1 pb-0"
            },
            [_vm._v("\n      ゲート情報\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {},
            [
              _c(
                "v-form",
                { ref: "GateAddForm", attrs: { "lazy-validation": "" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-0 mt-0" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "6" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-6",
                              attrs: { id: "col-left", height: "100%" }
                            },
                            [
                              _c("div", { staticClass: "info-item" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col col-5" },
                                    [_c("v-subheader", [_vm._v("製品ID")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-body-2 col align-self-center"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.gateDetail.gateModelId) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "info-item" }, [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { "col-": "", cols: "5" } },
                                      [
                                        _c(
                                          "v-subheader",
                                          [
                                            _vm._v(
                                              "\n                      製品名 "
                                            ),
                                            _c(
                                              "font",
                                              { staticClass: "required" },
                                              [_vm._v("※必須")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "text-body-2 align-self-center"
                                      },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            items: _vm.listGateModel,
                                            "item-text": "gateModelName",
                                            "item-value": "gateModelId",
                                            "return-object": "",
                                            color: "green",
                                            "hide-details": "auto",
                                            outlined: "",
                                            dense: "",
                                            "no-data-text": "",
                                            rules: [
                                              _vm.rulesValidate
                                                .requiredGateModelId
                                            ]
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.clickChangeInfoGate(
                                                _vm.gateDetail.gateModel
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.gateDetail.gateModel,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.gateDetail,
                                                "gateModel",
                                                $$v
                                              )
                                            },
                                            expression: "gateDetail.gateModel"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "info-item" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col col-5" },
                                    [_c("v-subheader", [_vm._v("型番")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-body-2 col align-self-center"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.gateDetail.gateModelCode) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "info-item" },
                                [
                                  _vm.gateDetail.makerCode === 91
                                    ? _c(
                                        "v-row",
                                        { staticStyle: { display: "none" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "px-0",
                                              attrs: { "col-": "", cols: "5" }
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v(
                                                  "\n                      アイコンタイプ\n                    "
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "px-0 text-body-2 align-self-center"
                                            },
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  items:
                                                    _vm.listIconTypeForVirtualGate,
                                                  color: "green",
                                                  "hide-details": "auto",
                                                  outlined: "",
                                                  dense: "",
                                                  "no-data-text": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.gateDetail.iconType,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.gateDetail,
                                                      "iconType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "gateDetail.iconType"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "px-0",
                                              attrs: { "col-": "", cols: "5" }
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v(
                                                  "\n                      アイコンタイプ\n                    "
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "px-0 text-body-2 align-self-center"
                                            },
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  items: _vm.listIconType,
                                                  color: "green",
                                                  "hide-details": "auto",
                                                  outlined: "",
                                                  dense: "",
                                                  "no-data-text": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.gateDetail.iconType,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.gateDetail,
                                                      "iconType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "gateDetail.iconType"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.gateDetail.customizable &&
                              _vm.checkShowHide(
                                _vm.gateDetail.makerCode,
                                _vm.inputIndex.controlTime
                              )
                                ? _c("div", { staticClass: "info-item" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col col-5" },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("ターゲット動作設定Time")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-body-2 col align-self-center"
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              color: "green",
                                              "hide-details": "auto",
                                              type: "number",
                                              outlined: "",
                                              dense: "",
                                              autocomplete: "off",
                                              min: "0",
                                              max: "10000"
                                            },
                                            model: {
                                              value: _vm.gateDetail.controlTime,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.gateDetail,
                                                  "controlTime",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gateDetail.controlTime"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "info-item" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col col-5" },
                                    [
                                      _c(
                                        "v-subheader",
                                        [
                                          _vm._v("ゲート名 "),
                                          _c(
                                            "font",
                                            { staticClass: "required" },
                                            [_vm._v("※必須")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-body-2 col align-self-center"
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          color: "green",
                                          "hide-details": "auto",
                                          type: "text",
                                          outlined: "",
                                          dense: "",
                                          autocomplete: "off",
                                          rules: [
                                            _vm.rulesValidate.requiredName,
                                            _vm.rulesValidate.validateName
                                          ]
                                        },
                                        model: {
                                          value: _vm.gateDetail.gateName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.gateDetail,
                                              "gateName",
                                              $$v
                                            )
                                          },
                                          expression: "gateDetail.gateName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.checkShowHide(
                                _vm.gateDetail.makerCode,
                                _vm.inputIndex.needLocation
                              )
                                ? _c("div", { staticClass: "info-item" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col col-5" },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("位置情報取得")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-body-2 col" },
                                        [
                                          _c("v-switch", {
                                            staticClass:
                                              "v-input--reverse swicth-all",
                                            attrs: {
                                              color: "green",
                                              "hide-details": "auto",
                                              inset: "",
                                              label: _vm.gateDetail.needLocation
                                                ? "ON"
                                                : "OFF"
                                            },
                                            model: {
                                              value:
                                                _vm.gateDetail.needLocation,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.gateDetail,
                                                  "needLocation",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gateDetail.needLocation"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.checkShowHide(
                                _vm.gateDetail.makerCode,
                                _vm.inputIndex.isVisitor
                              )
                                ? _c("div", { staticClass: "info-item" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col col-5" },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("ゲート動作モード ")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-body-2 col align-self-center"
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.listIsVisitor,
                                              color: "green",
                                              "hide-details": "auto",
                                              outlined: "",
                                              dense: "",
                                              "no-data-text": ""
                                            },
                                            model: {
                                              value: _vm.gateDetail.isVisitor,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.gateDetail,
                                                  "isVisitor",
                                                  $$v
                                                )
                                              },
                                              expression: "gateDetail.isVisitor"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.checkShowHide(
                                _vm.gateDetail.makerCode,
                                _vm.inputIndex.isNFCValid
                              )
                                ? _c("div", { staticClass: "info-item" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col col-5" },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("NFCの有効 ")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-body-2 col" },
                                        [
                                          _c("v-switch", {
                                            staticClass:
                                              "v-input--reverse swicth-all",
                                            attrs: {
                                              color: "green",
                                              "hide-details": "auto",
                                              inset: "",
                                              label: _vm.gateDetail.isNFCValid
                                                ? "ON"
                                                : "OFF"
                                            },
                                            model: {
                                              value: _vm.gateDetail.isNFCValid,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.gateDetail,
                                                  "isNFCValid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gateDetail.isNFCValid"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.checkShowHide(
                                _vm.gateDetail.makerCode,
                                _vm.inputIndex.isTenKeyValid
                              )
                                ? _c("div", { staticClass: "info-item" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col col-5" },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("テンキーの有効・無効 ")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-body-2 col" },
                                        [
                                          _c("v-switch", {
                                            staticClass:
                                              "v-input--reverse swicth-all",
                                            attrs: {
                                              color: "green",
                                              "hide-details": "auto",
                                              inset: "",
                                              label: _vm.gateDetail
                                                .isTenKeyValid
                                                ? "ON"
                                                : "OFF"
                                            },
                                            model: {
                                              value:
                                                _vm.gateDetail.isTenKeyValid,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.gateDetail,
                                                  "isTenKeyValid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gateDetail.isTenKeyValid"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.checkShowHide(
                                _vm.gateDetail.makerCode,
                                _vm.inputIndex.isGatePasswordValid
                              )
                                ? _c("div", { staticClass: "info-item" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col col-5" },
                                        [
                                          _c("v-subheader", [
                                            _vm._v(
                                              "ゲートパスワードの有効・無効 "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-body-2 col" },
                                        [
                                          _c("v-switch", {
                                            staticClass:
                                              "v-input--reverse swicth-all",
                                            attrs: {
                                              color: "green",
                                              "hide-details": "auto",
                                              inset: "",
                                              label: _vm.gateDetail
                                                .isGatePasswordValid
                                                ? "ON"
                                                : "OFF",
                                              disabled: _vm.gateDetail.isVisitor
                                            },
                                            model: {
                                              value:
                                                _vm.gateDetail
                                                  .isGatePasswordValid,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.gateDetail,
                                                  "isGatePasswordValid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gateDetail.isGatePasswordValid"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.checkShowHide(
                                _vm.gateDetail.makerCode,
                                _vm.inputIndex.gatePasswordLength
                              )
                                ? _c("div", { staticClass: "info-item" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col col-5" },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("ゲートパスワードの桁数 ")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-body-2 col align-self-center"
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.listPassLenght,
                                              color: "green",
                                              "hide-details": "auto",
                                              outlined: "",
                                              dense: "",
                                              "no-data-text": "",
                                              disabled: _vm.getEditStatus()
                                            },
                                            model: {
                                              value:
                                                _vm.gateDetail
                                                  .gatePasswordLength,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.gateDetail,
                                                  "gatePasswordLength",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gateDetail.gatePasswordLength"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.checkShowHide(
                                _vm.gateDetail.makerCode,
                                _vm.inputIndex.gatePasswordSeed
                              )
                                ? _c("div", { staticClass: "info-item" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col col-5" },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("ゲートパスワードのseed値")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-body-2 col align-self-center"
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              color: "green",
                                              "hide-details": "auto",
                                              type: "text",
                                              outlined: "",
                                              dense: "",
                                              autocomplete: "off",
                                              disabled: _vm.getEditStatus(),
                                              rules: [
                                                _vm.rulesValidate.validateIsGatePasswordValid(
                                                  _vm.gateDetail
                                                    .isGatePasswordValid,
                                                  _vm.gateDetail
                                                    .gatePasswordSeed
                                                )
                                              ]
                                            },
                                            model: {
                                              value:
                                                _vm.gateDetail.gatePasswordSeed,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.gateDetail,
                                                  "gatePasswordSeed",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gateDetail.gatePasswordSeed"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "6" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-6",
                              attrs: { id: "col-right", height: "100%" }
                            },
                            [
                              _vm.checkShowHide(
                                _vm.gateDetail.makerCode,
                                _vm.inputIndex.isTwoStepAuthValid
                              )
                                ? _c("div", { staticClass: "info-item" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col col-5" },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("2段階認証の有効・無効 ")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-body-2 col" },
                                        [
                                          _c("v-switch", {
                                            staticClass:
                                              "v-input--reverse swicth-all",
                                            attrs: {
                                              color: "green",
                                              "hide-details": "auto",
                                              inset: "",
                                              label: _vm.gateDetail
                                                .isTwoStepAuthValid
                                                ? "ON"
                                                : "OFF",
                                              disabled:
                                                _vm.gateDetail.isVisitor ||
                                                _vm.disableTwoStepAuth()
                                            },
                                            model: {
                                              value:
                                                _vm.gateDetail
                                                  .isTwoStepAuthValid,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.gateDetail,
                                                  "isTwoStepAuthValid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gateDetail.isTwoStepAuthValid"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.checkShowHide(
                                _vm.gateDetail.makerCode,
                                _vm.inputIndex.bleIntensity
                              )
                                ? _c("div", { staticClass: "info-item" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col col-5" },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("BLE出力設定 ")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-body-2 col align-self-center"
                                        },
                                        [
                                          _vm.gateDetail.makerCode === 2 ||
                                          _vm.gateDetail.makerCode === 4
                                            ? _c("v-autocomplete", {
                                                attrs: {
                                                  items:
                                                    _vm.listBleIntensityForWafer,
                                                  color: "green",
                                                  "hide-details": "auto",
                                                  outlined: "",
                                                  dense: "",
                                                  "no-data-text": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.gateDetail.bleIntensity,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.gateDetail,
                                                      "bleIntensity",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "gateDetail.bleIntensity"
                                                }
                                              })
                                            : _c("v-autocomplete", {
                                                attrs: {
                                                  items: _vm.listBleIntensity,
                                                  color: "green",
                                                  "hide-details": "auto",
                                                  outlined: "",
                                                  dense: "",
                                                  "no-data-text": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.gateDetail.bleIntensity,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.gateDetail,
                                                      "bleIntensity",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "gateDetail.bleIntensity"
                                                }
                                              }),
                                          _vm._v(" "),
                                          _vm.gateDetail.makerCode === 2 ||
                                          _vm.gateDetail.makerCode === 4
                                            ? _c(
                                                "p",
                                                { staticClass: "note-text" },
                                                [
                                                  _vm._v(
                                                    "\n                      「とても強い」「強い」に設定した場合、電池の消耗が早くなります。"
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.checkShowHide(
                                _vm.gateDetail.makerCode,
                                _vm.inputIndex.buzzerPattern
                              )
                                ? _c("div", { staticClass: "info-item" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col col-5" },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("ブザー鳴動設定 ")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-body-2 col align-self-center"
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.listBuzzerPattern,
                                              color: "green",
                                              "hide-details": "auto",
                                              outlined: "",
                                              dense: "",
                                              "no-data-text": ""
                                            },
                                            model: {
                                              value:
                                                _vm.gateDetail.buzzerPattern,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.gateDetail,
                                                  "buzzerPattern",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gateDetail.buzzerPattern"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "info-item" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col col-5 align-self-center"
                                    },
                                    [_c("v-subheader", [_vm._v("備考.1")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-body-2 col align-self-center"
                                    },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          color: "green",
                                          "hide-details": "auto",
                                          type: "text",
                                          outlined: "",
                                          dense: "",
                                          rows: "2",
                                          autocomplete: "off",
                                          placeholder: "備考.1",
                                          rules: [
                                            _vm.rulesValidate.validateOption1
                                          ]
                                        },
                                        model: {
                                          value: _vm.gateDetail.option1,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.gateDetail,
                                              "option1",
                                              $$v
                                            )
                                          },
                                          expression: "gateDetail.option1"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "info-item" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col col-5 align-self-center"
                                    },
                                    [_c("v-subheader", [_vm._v("備考.2")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-body-2 col align-self-center"
                                    },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          color: "green",
                                          "hide-details": "auto",
                                          type: "text",
                                          outlined: "",
                                          dense: "",
                                          rows: "2",
                                          autocomplete: "off",
                                          placeholder: "備考.2",
                                          rules: [
                                            _vm.rulesValidate.validateOption2
                                          ]
                                        },
                                        model: {
                                          value: _vm.gateDetail.option2,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.gateDetail,
                                              "option2",
                                              $$v
                                            )
                                          },
                                          expression: "gateDetail.option2"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.isEdit
                                ? _c("div", [
                                    _vm.checkShowHide(
                                      _vm.gateDetail.makerCode,
                                      _vm.inputIndex.gateSNMain
                                    )
                                      ? _c(
                                          "div",
                                          { staticClass: "info-item" },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col col-5" },
                                                [
                                                  _c("v-subheader", [
                                                    _vm._v("ゲート個体番号1")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-body-2 col align-self-center"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.gateDetail
                                                          .gateSNMain
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.checkShowHide(
                                      _vm.gateDetail.makerCode,
                                      _vm.inputIndex.gateSNBLE
                                    )
                                      ? _c(
                                          "div",
                                          { staticClass: "info-item" },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col col-5" },
                                                [
                                                  _c("v-subheader", [
                                                    _vm._v("ゲート個体番号2")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-body-2 col align-self-center"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.gateDetail.gateSNBLE
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.checkShowHide(
                                      _vm.gateDetail.makerCode,
                                      _vm.inputIndex.gateFWMain
                                    )
                                      ? _c(
                                          "div",
                                          { staticClass: "info-item" },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col col-5" },
                                                [
                                                  _c("v-subheader", [
                                                    _vm._v(
                                                      "適用されているファームウェアバージョン（メインボード）"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-body-2 col align-self-center"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.gateDetail
                                                          .gateFWMain
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.checkShowHide(
                                      _vm.gateDetail.makerCode,
                                      _vm.inputIndex.gateFWBLE
                                    )
                                      ? _c(
                                          "div",
                                          { staticClass: "info-item" },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col col-5" },
                                                [
                                                  _c("v-subheader", [
                                                    _vm._v(
                                                      "適用されているファームウェアバージョン（BLE）"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-body-2 col align-self-center"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.gateDetail.gateFWBLE
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.checkShowHide(
                                      _vm.gateDetail.makerCode,
                                      _vm.inputIndex.gateFWBLE
                                    )
                                      ? _c(
                                          "div",
                                          { staticClass: "info-item" },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col col-5" },
                                                [
                                                  _c("v-subheader", [
                                                    _vm._v("登録日")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-body-2 col align-self-center"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.converDate(
                                                          _vm.gateDetail
                                                            .registered
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.checkShowHide(
                                      _vm.gateDetail.makerCode,
                                      _vm.inputIndex.updated
                                    )
                                      ? _c(
                                          "div",
                                          { staticClass: "info-item" },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col col-5" },
                                                [
                                                  _c("v-subheader", [
                                                    _vm._v("設定更新日")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-body-2 col align-self-center"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.converDate(
                                                          _vm.gateDetail.updated
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.checkShowHide(
                                      _vm.gateDetail.makerCode,
                                      _vm.inputIndex.reported
                                    )
                                      ? _c(
                                          "div",
                                          { staticClass: "info-item" },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col col-5" },
                                                [
                                                  _c("v-subheader", [
                                                    _vm._v(
                                                      "ゲートへ設定情報を適用した日時"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-body-2 col align-self-center"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.converDate(
                                                          _vm.gateDetail
                                                            .reported
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.checkShowHide(
                                      _vm.gateDetail.makerCode,
                                      _vm.inputIndex.revisionDesired
                                    )
                                      ? _c(
                                          "div",
                                          { staticClass: "info-item" },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col col-5" },
                                                [
                                                  _c("v-subheader", [
                                                    _vm._v(
                                                      "個人コード照合データー：最新リビジョン"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-body-2 col align-self-center"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.gateDetail
                                                          .revisionDesired
                                                      )
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.checkShowHide(
                                      _vm.gateDetail.makerCode,
                                      _vm.inputIndex.revisionReported
                                    )
                                      ? _c(
                                          "div",
                                          { staticClass: "info-item" },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col col-5" },
                                                [
                                                  _c("v-subheader", [
                                                    _vm._v(
                                                      "個人コード照合データー：ゲートへ設定済みのリビジョン"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-body-2 col align-self-center"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.gateDetail
                                                          .revisionReported
                                                      )
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.checkShowHide(
                                      _vm.gateDetail.makerCode,
                                      _vm.inputIndex.voltage
                                    )
                                      ? _c(
                                          "div",
                                          { staticClass: "info-item" },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col col-5" },
                                                [
                                                  _c("v-subheader", [
                                                    _vm._v("電池電圧")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-body-2 col align-self-center"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.gateDetail.voltage
                                                      )
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.checkShowHide(
                                      _vm.gateDetail.makerCode,
                                      _vm.inputIndex.scanDate
                                    )
                                      ? _c(
                                          "div",
                                          { staticClass: "info-item" },
                                          [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col col-5" },
                                                [
                                                  _c("v-subheader", [
                                                    _vm._v("電池電圧更新日")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-body-2 col align-self-center"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.converDate(
                                                          _vm.gateDetail
                                                            .scanDate
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "info-item" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col col-5" },
                                    [
                                      _c("v-subheader", [_vm._v("ステータス ")])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "text-body-2 col" },
                                    [
                                      _c("v-switch", {
                                        staticClass:
                                          "v-input--reverse swicth-all",
                                        attrs: {
                                          color: "green",
                                          "hide-details": "auto",
                                          inset: "",
                                          label: _vm.gateDetail.isValid
                                            ? "ON"
                                            : "OFF"
                                        },
                                        model: {
                                          value: _vm.gateDetail.isValid,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.gateDetail,
                                              "isValid",
                                              $$v
                                            )
                                          },
                                          expression: "gateDetail.isValid"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.checkShowAnti()
                                ? _c("div", { staticClass: "info-item" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col col-5" },
                                        [
                                          _c("v-subheader", [
                                            _vm._v(
                                              "アンチパスバックの有効・無効 "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-body-2 col" },
                                        [
                                          _c("v-switch", {
                                            staticClass:
                                              "v-input--reverse swicth-all",
                                            attrs: {
                                              color: "green",
                                              "hide-details": "auto",
                                              inset: "",
                                              label: _vm.gateDetail
                                                .isAntiPassback
                                                ? "ON"
                                                : "OFF",
                                              disabled: _vm.disableAntiPass()
                                            },
                                            model: {
                                              value:
                                                _vm.gateDetail.isAntiPassback,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.gateDetail,
                                                  "isAntiPassback",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gateDetail.isAntiPassback"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.checkShowAnti()
                                ? _c("div", { staticClass: "info-item" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col col-5" },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("アンチパスバックのモード ")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-body-2 col align-self-center"
                                        },
                                        [
                                          _vm.gateDetail.isAntiPassback
                                            ? _c("v-autocomplete", {
                                                attrs: {
                                                  items: _vm.listAntiMode,
                                                  color: "green",
                                                  "hide-details": "auto",
                                                  outlined: "",
                                                  dense: "",
                                                  "no-data-text": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.gateDetail.antiPassback
                                                      .mode,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.gateDetail
                                                        .antiPassback,
                                                      "mode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "gateDetail.antiPassback.mode"
                                                }
                                              })
                                            : _c("v-autocomplete", {
                                                attrs: {
                                                  items: [],
                                                  color: "green",
                                                  "hide-details": "auto",
                                                  outlined: "",
                                                  dense: "",
                                                  "no-data-text": "",
                                                  disabled: !_vm.gateDetail
                                                    .isAntiPassback
                                                },
                                                model: {
                                                  value:
                                                    _vm.gateDetail.antiPassback
                                                      .mode,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.gateDetail
                                                        .antiPassback,
                                                      "mode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "gateDetail.antiPassback.mode"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.checkShowAnti()
                                ? _c("div", { staticClass: "info-item" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "col col-5" },
                                        [
                                          _c("v-subheader", [
                                            _vm._v(
                                              "アンチパスバックの対向ゲート名 "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text-body-2 col align-self-center"
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass:
                                              "antiPassback-gateName",
                                            attrs: {
                                              color: "green",
                                              "hide-details": "auto",
                                              type: "text",
                                              outlined: "",
                                              dense: "",
                                              autocomplete: "off",
                                              disabled: "",
                                              rules: [
                                                _vm.rulesValidate.validateAntiPassback(
                                                  _vm.gateDetail.isAntiPassback,
                                                  _vm.gateDetail.antiPassback
                                                    .gateId
                                                )
                                              ]
                                            },
                                            model: {
                                              value:
                                                _vm.gateDetail.antiPassback
                                                  .gateName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.gateDetail.antiPassback,
                                                  "gateName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "gateDetail.antiPassback.gateName"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("p"),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "btn-primary",
                                              attrs: {
                                                outlined: "",
                                                disabled: _vm.disableAntiModes()
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.getGate()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      対向ゲートの選択\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "600" },
              model: {
                value: _vm.isShow,
                callback: function($$v) {
                  _vm.isShow = $$v
                },
                expression: "isShow"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("ModalTitle", {
                    attrs: { title: "アンチパスバック対向ゲートの選択" },
                    on: {
                      click: function($event) {
                        _vm.isShow = false
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "ユーザーID、ユーザー名検索",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.searchText,
                                  callback: function($$v) {
                                    _vm.searchText = $$v
                                  },
                                  expression: "searchText"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-primary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "search-gate",
                                        _vm.searchText
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                検索\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-row", {
                        staticStyle: { height: "20px" },
                        attrs: { "no-gutters": "" }
                      }),
                      _vm._v(" "),
                      _c("v-data-table", {
                        staticClass: "border-table scroll-table list-gate",
                        attrs: {
                          "must-sort": "",
                          headers: _vm.headersGate,
                          items: _vm.listAntiPassGates,
                          "item-key": "gateId",
                          "hide-default-footer": "",
                          "items-per-page": 25,
                          "show-select": true,
                          "single-select": true,
                          search: _vm.search
                        },
                        model: {
                          value: _vm.selected,
                          callback: function($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-3",
                          attrs: { "no-gutters": "", justify: "space-between" }
                        },
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c("pagination", {
                            staticClass: "mb-1",
                            attrs: {
                              "page-length": Math.ceil(_vm.totalGate / 25),
                              disabled: _vm.loading
                            },
                            model: {
                              value: _vm.innerPage,
                              callback: function($$v) {
                                _vm.innerPage = $$v
                              },
                              expression: "innerPage"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-row", {
                        staticStyle: { height: "20px" },
                        attrs: { "no-gutters": "" }
                      }),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.setGate(_vm.selected)
                                }
                              }
                            },
                            [_vm._v("\n              選択\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isEdit
            ? _c(
                "v-expansion-panels",
                {
                  staticClass: "mt-3 px-3",
                  attrs: { multiple: "", focusable: "" }
                },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v("\n          ゲートグループ\n        ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-5 mb-5",
                              attrs: { justify: "end" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: {
                                    outlined: "",
                                    disabled: _vm.selectGroup.length === 0
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.isShowConfirmGroup = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              チェックしたゲートグループの関連付け解除\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("group-of-gate-lock-table", {
                            attrs: {
                              groupOfGate: _vm.relationsGateGroup,
                              pageGroup: _vm.pageGroup,
                              isGroupOfGate: _vm.isGroupOfGate,
                              isShowConfirm: _vm.isShowConfirmGroup,
                              showProgress: _vm.showProgressGroup,
                              total: _vm.groupTotalCount,
                              totalDataRowProcess: _vm.totalDataRowProcessGroup,
                              progress: _vm.progressGroup,
                              successItemProcess: _vm.successItemProcessGroup,
                              errorMessageProcess: _vm.errorMessageProcessGroup,
                              isFinishProcess: _vm.isFinishProcessGroup
                            },
                            on: {
                              "select-group": function($event) {
                                return _vm.getSelectGroup($event)
                              },
                              clickRemoveGroup: function($event) {
                                return _vm.clickRemoveGroup($event)
                              },
                              closeModalProgress: function($event) {
                                return _vm.$emit("closeModalProgressGroup")
                              },
                              "change-page-group": function($event) {
                                return _vm.$emit("change-page-group", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(
                          "\n          期限付きゲートを持つユーザー\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-5 mb-5",
                              attrs: { justify: "end" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: {
                                    outlined: "",
                                    disabled:
                                      _vm.selectUser.length === 0 ||
                                      _vm.isUserOfGate === true
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.isShowConfirmUser = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              チェックしたユーザーの関連付け解除\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("user-of-gate-lock-table", {
                            attrs: {
                              userOfGate: _vm.userOfGate,
                              isUserOfGate: _vm.isUserOfGate,
                              pageUser: _vm.pageUser,
                              isShowConfirm: _vm.isShowConfirmUser,
                              showProgress: _vm.showProgressUser,
                              userTotalCount: _vm.userTotalCountDel,
                              total: _vm.userTotalCount,
                              totalDataRowProcess: _vm.totalDataRowProcessUser,
                              progress: _vm.progressUser,
                              successItemProcess: _vm.successItemProcessUser,
                              errorMessageProcess: _vm.errorMessageProcessUser,
                              isFinishProcess: _vm.isFinishProcessUser
                            },
                            on: {
                              "select-user": function($event) {
                                return _vm.getSelectUser($event)
                              },
                              clickRemoveUser: function($event) {
                                return _vm.clickRemoveUser($event)
                              },
                              closeModalProgress: function($event) {
                                return _vm.$emit("closeModalProgressUser")
                              },
                              "change-page-user": function($event) {
                                return _vm.$emit("change-page-user", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mt-3 px-3", attrs: { justify: "end" } },
            [
              _vm.successMessageLogs !== "" && _vm.loading === false
                ? _c(
                    "v-col",
                    {
                      staticClass:
                        "mb-4 font-weight-bold mt-4 bg-success messageRow bg-no",
                      attrs: { cols: "11", align: "right" }
                    },
                    [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.successMessageLogs) }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.errorMessageLogs !== "" && _vm.loading === false
                ? _c(
                    "v-col",
                    {
                      staticClass:
                        "mb-4 font-weight-bold mt-4 bg-error messageRow bg-no",
                      attrs: { cols: "11", align: "right" }
                    },
                    [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.errorMessageLogs) }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "messageRow",
                  attrs: { cols: "1", align: "right" }
                },
                [
                  _vm.isEdit
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: { click: _vm.editGate }
                        },
                        [_vm._v("\n          保存\n        ")]
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: { click: _vm.addGate }
                        },
                        [_vm._v("\n          保存\n        ")]
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }