














































import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import InRoomModalForm from '../../organisms/InRoomModalForm/InRoomModalForm.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Alert from '@/components/molecules/Alert/Alert.vue';
import { Room, Gate } from '@/types/alligate';

export interface DataType {
  breadcrumbs: BreadcrumbItem[];
  showSuccessMessages: boolean[];
}

export default Vue.extend({
    name: 'InRoomModalTemplate',
    components: {
      Breadcrumbs,
      InRoomModalForm,
      Modal,
      Alert,
    },
    props: {
      successMessages: {
        type: Array,
        default: () => [],
      },
      errorMessages: {
        type: Array,
        default: () => [],
      },
      allGates: {
        type: Array as PropType<Gate[]>,
        default: () => [],
      },
      roomInfo: {
        type: Object as PropType<Room>,
        default: () => ({
          roomId: '',
          name: '',
          relationsRoomGate: [],
        }),
      },
      roomInfoLoading: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Object as PropType<Room>,
        default: () => ({
          roomId: '',
          name: '',
        }),
      },
      authority: {
        type: Object,
        default: ({}),
      },
    },
    data: (): DataType => ({
      breadcrumbs: [
        { text: '部屋情報', disabled: true },
      ],
      showSuccessMessages: [],
    }),
    computed: {
      innerValue: {
        get(): Room {
          return this.value;
        },
        set(val: Room): void {
          this.$emit('input', val);
        },
      },
    },
    watch: {
      successMessages(messages: string[]) {
        const shows: boolean[] = [];
        messages.forEach((message) => {
          shows.push(message !== '');
        });
        this.showSuccessMessages = shows;
      },
    },
  });
