























































































































import Vue from 'vue';
export default Vue.extend({
  name: 'Header',
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: '',
    },
  },
});
