var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "mb-0" }, [
    _c(
      "div",
      { staticClass: "allig-reflection-card-table" },
      [
        _c(
          "div",
          { staticClass: "round-action" },
          [
            _c(
              "v-row",
              { staticClass: "btn-div", attrs: { justify: "end" } },
              [
                _c(
                  "v-col",
                  { staticClass: "mt-0" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "btn-div", attrs: { justify: "end" } },
                      [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "btn-add pt-0",
                                attrs: { cols: "12" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "btn-primary",
                                    attrs: {
                                      outlined: "",
                                      disabled: _vm.idSelected.length === 0
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit(
                                          "add-group",
                                          _vm.idSelected
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    グループを追加する\n                  "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              {
                staticClass: "mt-3 border-bottom-bold",
                attrs: {
                  "no-gutters": "",
                  justify: "space-between",
                  "align-content": "center"
                }
              },
              [
                _c("show-per-page", {
                  attrs: {
                    "per-page": _vm.perPage,
                    page: _vm.page,
                    "items-length": _vm.totalCount
                  }
                }),
                _vm._v(" "),
                _c("v-spacer")
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "round-data" },
          [
            _c(
              "v-data-table",
              {
                staticClass: "border-table scroll-table modal-table",
                attrs: {
                  "must-sort": "",
                  headers: _vm.headers,
                  items: _vm.getData,
                  "items-per-page": _vm.perPage,
                  "item-key": "groupId",
                  "no-data-text": "データーはありません",
                  "loading-text": "データを読み込中です",
                  loading: _vm.loading,
                  "hide-default-footer": "",
                  "show-select": true,
                  "custom-sort": _vm.customSort
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.userId",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", { staticClass: "item-user-id" }, [
                            _vm._v(_vm._s(item.user.userId))
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.userName",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("text-link", {
                            staticClass: "item-user-name",
                            attrs: { text: item.user.name },
                            on: {
                              click: function($event) {
                                _vm.isShowUserDetail = true
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.data-table-select",
                      fn: function(ref) {
                        var item = ref.item
                        var isSelected = ref.isSelected
                        var select = ref.select
                        return [
                          item.disable
                            ? _c("v-simple-checkbox", {
                                attrs: {
                                  value: true,
                                  readonly: item.disable,
                                  disabled: item.disable,
                                  ripple: false
                                },
                                on: {
                                  input: function($event) {
                                    return select($event)
                                  }
                                }
                              })
                            : _c("v-simple-checkbox", {
                                attrs: {
                                  value: isSelected,
                                  readonly: item.disabled,
                                  disabled: item.disabled,
                                  ripple: false
                                },
                                on: {
                                  input: function($event) {
                                    return select($event)
                                  }
                                }
                              })
                        ]
                      }
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.idSelected,
                  callback: function($$v) {
                    _vm.idSelected = $$v
                  },
                  expression: "idSelected"
                }
              },
              [_vm._v(">\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("v-row", {
          staticStyle: { height: "20px" },
          attrs: { "no-gutters": "" }
        }),
        _vm._v(" "),
        _c("v-spacer"),
        _vm._v(" "),
        _c(
          "v-row",
          {
            staticClass: "mt-3",
            attrs: { "no-gutters": "", justify: "space-between" }
          },
          [
            _c("v-spacer"),
            _vm._v(" "),
            _c(
              "v-col",
              { staticClass: "mr-5 mb-2" },
              [
                _c("select-per-page", {
                  attrs: {
                    page: _vm.page,
                    "per-page-items": [25, 50, 100, 1000],
                    "items-length": _vm.totalCount,
                    disabled: _vm.loading
                  },
                  model: {
                    value: _vm.innerPerPage,
                    callback: function($$v) {
                      _vm.innerPerPage = $$v
                    },
                    expression: "innerPerPage"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              staticClass: "mb-1",
              attrs: {
                "page-length": Math.ceil(_vm.totalCount / _vm.perPage),
                disabled: _vm.loading
              },
              model: {
                value: _vm.innerPage,
                callback: function($$v) {
                  _vm.innerPage = $$v
                },
                expression: "innerPage"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }