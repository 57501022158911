
































































import {Vue } from 'vue-property-decorator';
import AffiliationListModalTemplate from '@/components/templates/AffiliationListModalTemplate/AffiliationListModalTemplate.vue';
import * as Config from '@/config';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import store from '@/store';
export interface DataType {
  perPage: number;
  currPage: number;
  keyword: string;
  scorp: any;
  keywordUser: string;
  scorpUser: any;
  loading: boolean;
  errorMessageLogs: string;
  successMessageLogs: string;
  dataGroupsModal: any;
  rowinErrDialog: number;
  successItemProcess: number;
  errorMessageProcess: any;
  progress: number;
  isFinishProcess: boolean;
  totalDataRowProcess: number;
  countGroupDetail: number;
  expandData: any;
  dataRelationUser: any;
  dataRelationGroup: any;
  userItems: any;
  authority: any;
  isGetUser: boolean;
  countGetUser: number;
  isShowValidUser: boolean;
  isAffiliationAdd: boolean;
}
export default Vue.extend({
  name: 'AffiliationListModalPage',
  components: {
    AffiliationListModalTemplate,
  },
  props: {
    isPage: {
      type: String,
      default: '',
    },
    department: {
      type: Object,
      default: ({}),
    },
  },
  data: (): DataType => ({
    perPage: Config.PAGE_DEFAULT,
    currPage: 1,
    scorp: ['groupName'],
    keyword: '',
    scorpUser: ['name'],
    keywordUser: '',
    loading: false,
    errorMessageLogs: '',
    successMessageLogs: '',
    dataGroupsModal: {
      dataOri: [],
      data: [],
      total: 0,
    },
    rowinErrDialog: 0,
    successItemProcess: 0,
    errorMessageProcess: [],
    progress: 0,
    isFinishProcess: false,
    totalDataRowProcess: 0,
    expandData: {
      userAll: {
        data: [],
        total: 0,
        loading: false,
        page: 1,
        perPage: 25,
        keyword: '',
        scorp: ['userId', 'name'],
      },
      groupAll: {
        data: [],
        total: 0,
        loading: false,
        page: 1,
        perPage: 25,
        keyword: '',
        scorp: ['groupId', 'name'],
      },
      user: {
        data: [],
        dataOri: [],
        total: 0,
        loading: false,
        page: 1,
        perPage: 25,
        keyword: '',
      },
      group: {
        data: [],
        total: 0,
        page: 1,
        loading: false,
        dataOri: [],
        keyword: '',
      },
      gateGroup: {
        data: [],
        total: 0,
        loading: false,
        page: 1,
      },
      departmentAd: {
        data: [],
        total: 0,
        loading: false,
        page: 1,
        errorMessage: '',
        sucessMessage: '',
      },
      errorData: {
        users: [],
        groups: [],
      },
    },
    countGroupDetail: 0,
    dataRelationUser: {
      total: 0,
      progressLoad: 0,
      successItem: 0,
      errorMessage: [],
      isFinish: false,
    },
    dataRelationGroup: {
      total: 0,
      progressLoad: 0,
      successItem: 0,
      errorMessage: [],
      isFinish: false,
    },
    userItems: [],
    authority: store.state.auth,
    isGetUser: false,
    countGetUser: 0,
    isShowValidUser: false,
    isAffiliationAdd: false,
  }),
  created() {
    //
    if (typeof localStorage.isVaildDataGroupModal === 'undefined') {
      localStorage.setItem('isVaildDataGroupModal', 'true');
    }
    if (typeof localStorage.groupModalSortKey === 'undefined') {
      localStorage.setItem('groupModalSortKey', 'registered');
    }
    if (typeof localStorage.groupModalSortName === 'undefined') {
      localStorage.setItem('groupModalSortName', 'desc');
    }
    if (typeof localStorage.pageSizeGroupModal === 'undefined') {
      localStorage.setItem('pageSizeGroupModal', Config.PAGE_DEFAULT.toString());
      this.perPage = Config.PAGE_DEFAULT;
    } else {
      this.perPage = parseInt(localStorage.pageSizeGroupModal);
    }
    if (this.isPage === 'detail' ) {
      this.loadAffDetail({isAction: 'detail', departmentId: this.department.departmentId});
    } else {
      this.getListGroupsModal();
    }
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
    countGroupDetail(val: any) {
      if (val >= this.expandData.group.data.length) {
        this.expandData.group.loading = false;
      }
    },
    countGetUser(value: any) {
      if (value >= 2 ) {
        this.isGetUser = true;
      }
    },
  },
  mounted() {
    //
  },
  methods: {
    //
    searchUserAddPage(item: any) {
      let keyword = item.text;
      keyword = keyword.replace(/\s/g, ',');
      this.expandData.userAll.keyword = keyword;
      const data = {
        page: this.expandData.userAll.page,
        perPage: this.expandData.userAll.perPage,
        keyword: this.expandData.userAll.keyword,
      };
      this.loadListUsers(data);
    },
    searchGroupAddPage(item: any) {
      console.log('item', item);
      let keyword = item.text;
      keyword = keyword.replace(/\s/g, ',');
      this.expandData.groupAll.keyword = keyword;
      const data = {
        page: this.expandData.groupAll.page,
        perPage: this.expandData.groupAll.perPage,
        keyword: this.expandData.groupAll.keyword,
      };
      this.loadListGroups(data);
    },
    searchUser(item: any) {
      this.expandData.user.loading = true;
      const keyword = item.agr.text;
      // keyword = keyword.replace(/\s/g, ',');
      this.keywordUser = encodeURIComponent(keyword);
      this.scorpUser = item.agr.targets;
      console.log('keyword', keyword);
      const data = this.expandData.user.dataOri.filter((element: any) =>
      element.user.userId.includes(keyword) === true || element.user.name.includes(keyword) === true);
      this.expandData.user.data = data;
      this.expandData.user.total = data.length;
      this.expandData.user.perPage = 25;
      this.expandData.user.page = 1;
      this.expandData.user.keyword = keyword;
      setTimeout(() => {
        this.expandData.user.loading = false;
      }, 100);
      // this.getDepartmentUser(item.departmentId, 25, 1);
    },
    changePageAndPerPageUser(item: any) {
      this.expandData.user.perPage = item.agr.perPage;
      this.expandData.user.page = item.agr.page;
      this.expandData.user.loading = true;
      let dataOri = this.expandData.user.dataOri;
      if (this.expandData.user.keyword !== '') {
        dataOri = this.expandData.user.dataOri.filter((element: any) =>
        element.user.userId.includes(this.expandData.user.keyword) === true
        || element.user.name.includes(this.expandData.user.keyword) === true);
      }
      const data  = Array.of(...dataOri);
      this.expandData.user.data =
      data.slice((item.agr.page - 1) * item.agr.perPage, item.agr.page * item.agr.perPage);
      setTimeout(() => {
        this.expandData.user.loading = false;
      }, 100);
      // this.getDepartmentUser(item.departmentId, item.agr.perPage, item.agr.page);
    },
    deleteDepartment(data: any) {
      this.deleteDepartmentItem(data);
    },
    changePageGroup(item: any) {
      console.log('item', item);
      // this.getGateGroup(data.groupId, data.page);
      this.expandData.group.page = item.page;
      this.expandData.group.loading = true;
      let dataOri = this.expandData.group.dataOri;
      if (this.expandData.group.keyword !== '') {
        dataOri = this.expandData.group.dataOri.filter((element: any) =>
        element.group.groupId.includes(this.expandData.group.keyword) === true ||
        element.group.name.includes(this.expandData.group.keyword) === true);
      }
      const data  = Array.of(...dataOri);
      this.expandData.group.data =
      data.slice((item.page - 1) * 25, item.page * 25);
      setTimeout(() => {
        this.expandData.group.loading = false;
      }, 100);
      // this.getDepartmentGroup(data.departmentId, data.page);
    },
    searchGroupDe(item: any) {
      this.expandData.group.loading = true;
      const keyword = item.agr.text;
      // keyword = keyword.replace(/\s/g, ',');
      this.keywordUser = encodeURIComponent(keyword);
      this.scorpUser = item.agr.targets;
      console.log('keyword', item);
      const data = this.expandData.group.dataOri.filter((element: any) =>
      element.group.name.includes(keyword) === true);
      this.expandData.group.data = data;
      this.expandData.group.total = data.length;
      this.expandData.group.perPage = 25;
      this.expandData.group.page = 1;
      this.expandData.group.keyword = keyword;
      setTimeout(() => {
        this.expandData.group.loading = false;
      }, 100);
      // this.getDepartmentUser(item.departmentId, 25, 1);
    },
    changePageGateGroup(item: any) {
      console.log('item', item);
      // this.getGateGroup(data.groupId, data.page);
      this.expandData.gateGroup.perPage = 25;
      this.expandData.gateGroup.page = item.page;
      this.expandData.gateGroup.loading = true;
      let dataOri = this.expandData.gateGroup.dataOri;
      console.log(dataOri);
      if (this.expandData.gateGroup.keyword !== '') {
        dataOri = this.expandData.gateGroup.dataOri.filter((element: any) =>
        element.gateGroup.groupId.includes(this.expandData.gateGroup.keyword) === true ||
        element.gateGroup.group.name.includes(this.expandData.gateGroup.keyword) === true);
      }
      const data  = Array.of(...dataOri);
      console.log('data', data);
      this.expandData.gateGroup.data =
      data.slice((this.expandData.gateGroup.page - 1) * 25, this.expandData.gateGroup.page * 25);
      setTimeout(() => {
        this.expandData.gateGroup.loading = false;
      }, 100);
    },
    expandGateGroup(data: any) {
      if (data.value) {
        this.expandData.gateGroup.data = [];
        this.expandData.gateGroup.dataOri = [];
        this.expandData.gateGroup.keyword = '';
        this.expandData.gateGroup.page = 1;
        this.getGateGroup(data.item.group.groupId, 1);
      }
    },
    loadAffDetail(item: any) {
      console.log('item');
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      if (item.isAction !== 'detail') {
        this.isGetUser = false;
        this.countGetUser = 1;
        this.getUsers(1);
      }
      this.expandData.user.data = [];
      this.expandData.user.dataOri = [];
      this.expandData.user.total = 0;
      this.expandData.user.perPage = 25;
      this.expandData.user.page = 1;
      this.expandData.user.keyword = '';
      this.expandData.group.data = [];
      this.expandData.group.dataOri = [];
      this.expandData.group.total = 0;
      this.expandData.group.perPage = 25;
      this.expandData.group.page = 1;
      this.expandData.group.keyword = '';
      this.expandData.departmentAd.data = [];
      this.getDepartmentAuth(item.departmentId);
      this.getDepartmentUser(item.departmentId, 25, 1);
      this.getDepartmentGroup(item.departmentId, 1);
    },
    changeExpanded(data: any): any {
      console.log('item', data);
      if (data.change.value) {
        const dt = {
          tab : data.tab !== '' ?  data.tab : 0,
          departmentId: data.change.item.departmentId,
        };
        this.changeTab(dt);
      }
    },
    changeTab(item: any): any {
      console.log('item', item);
      if (item.tab === 0) {
        this.expandData.user.data = [];
        this.expandData.user.total = 0;
        this.expandData.user.perPage = 25;
        this.expandData.user.page = 1;
        this.expandData.user.keyword = '';
        this.getDepartmentUser(item.departmentId, 25, 1);
      } else {
        this.expandData.group.loading = true;
        this.expandData.group.data = [];
        this.expandData.group.dataOri = [];
        this.expandData.group.total = 0;
        this.expandData.group.perPage = 25;
        this.expandData.group.page = 1;
        this.expandData.group.keyword = '';
        this.getDepartmentGroup(item.departmentId, 1);
      }
    },
    changePerPage(value: any): any {
      this.perPage = value;
      localStorage.setItem('pageSizeGroupModal', value);
      if (this.authority.isAdmin) {
        this.getListGroupsModal();
      } else {
        this.getDepartmentUserLoginChange();
      }
    },
    changePage(value: any): any {
      this.currPage = value;
      if (this.authority.isAdmin) {
        this.getListGroupsModal();
      } else {
        this.getDepartmentUserLoginChange();
      }
    },
    searchGroup(value: any): any {
      this.keyword = value.text;
      this.scorp = value.targets;
      if (this.authority.isAdmin) {
        this.getListGroupsModal();
      } else {
        this.getDepartmentUserLoginChange();
      }
    },
    getDepartmentUser(departmentId: any, perPage: any, page: any) {
      this.expandData.user.loading = true;
      const apiLink = Config.RELATION_DEPARTMENT_USER_API + '?departmentId=' + encodeURIComponent(departmentId) + '&limit=' + perPage + '&page=' + page + '&embed=user';
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            this.expandData.user.data = [];
            this.expandData.user.dataOri = [];

            if (this.isShowValidUser === true) {
              this.expandData.user.data = response.body.relationsDepartmentUser.filter(
                (element: any) =>  element.user.isValid === this.isShowValidUser,
              );
              this.expandData.user.dataOri = this.expandData.user.data;
              this.expandData.user.total = this.expandData.user.dataOri.length;
            } else {
              this.expandData.user.total = response.body.maxRecords;
              response.body.relationsDepartmentUser.forEach((element: any) => {
                this.expandData.user.data.push(element);
                this.expandData.user.dataOri.push(element);
              });
            }

            if (response.body.maxRecords > response.body.endRecord) {
              page++;
              this.getDepartmentUser(departmentId, perPage, page);
            } else {
              this.expandData.user.loading = false;
              const i = this.dataGroupsModal.data.findIndex((x: any) =>
              x.departmentId === departmentId);
              if (i >= 0) {
                const count = this.expandData.user.data.filter(
                (element: any) =>  element.user.isValid === true,
              );
                this.dataGroupsModal.data[i].userCount = count.length;
              }
            }
          } else {
            this.expandData.user.loading = false;
            const i = this.dataGroupsModal.data.findIndex((x: any) =>
            x.departmentId === departmentId);
            if (i >= 0) {
                const count = this.expandData.user.data.filter(
                (element: any) =>  element.user.isValid === true,
              );
                this.dataGroupsModal.data[i].userCount = count.length;
              }
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.expandData.user.loading = false;
        });
    },
    getDepartmentUserValid(event: any) {
      this.isShowValidUser = event.value;
      if (this.isAffiliationAdd) {
        this.getUsers(1);
      } else {
        this.getDepartmentUser(event.departmentId, 25, 1);
      }
    },
    getDepartmentGroup(departmentId: any, page: any) {
      this.$http.get(Config.RELATION_DEPARTMENT_GROUP_API + '?departmentId=' + encodeURIComponent(departmentId) + '&limit=25&page=' + page + '&embed=group')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            this.expandData.group.total = response.body.maxRecords;
            response.body.relationsDepartmentGroup.forEach((element: any, i: any) => {
              element.group.gateCount = 0;
              this.expandData.group.data.push(element);
              this.expandData.group.dataOri.push(element);
              this.getGroupDetail(element.group.groupId, i);
            });
            if (response.body.maxRecords > response.body.endRecord) {
              page++;
              this.getDepartmentGroup(departmentId, page);
            }
          } else {
            this.expandData.group.loading = false;
            const i = this.dataGroupsModal.data.findIndex((x: any) =>
            x.departmentId === departmentId);
            if (i >= 0) {
              this.dataGroupsModal.data[i].groupCount = this.expandData.group.data.length;
            }
          }
        })
        .then( null, (err: any) => {
          this.expandData.group.loading = false;
          console.log('err: ', err);
        });
    },
    getListGroupsModal(): any {
      this.resetData();
      if (this.authority.isAdmin) {
        this.getDeaprtment();
      } else {
        this.loading = true;
        this.getDepartmentUserLogin(1);
      }
    },
    getDepartmentUserLoginChange() {
      this.loading = true;
      let dataOri = this.dataGroupsModal.dataOri;
      if (this.keyword !== '') {
        dataOri = this.dataGroupsModal.dataOri.filter((element: any) =>
        element.name.toLowerCase().includes(this.keyword.toLowerCase()) === true);
      }
      const data  = Array.of(...dataOri);
      this.dataGroupsModal.data =
      data.slice((this.currPage - 1) * this.perPage, this.currPage * this.perPage);
      setTimeout(() => {
        this.loading = false;
      }, 100);
    },
    getDepartmentUserLogin(page: any) {
      const apiLink = Config.DEPARTMENT_ADMINISTRATOR_API +
      '?userId=' + encodeURIComponent(localStorage.userProfileId ) + '&limit=1000' + '&page=' + page;
      this.$http.get(apiLink)
        .then(async (response: any) => {
          if (response.body.maxRecords > 0) {
            const data = response.body.departmentAdministrators;
            const item: any = [];
            for (let i = 0; i < data.length; i += 10) {
              const chunk = data.slice(i, i + 10);
              const arrayPromise: any = [];
              for (const [index, element] of  chunk.entries()) {
                this.dataGroupsModal.dataOri.push(element);
                const result: any = this.getDepartmentItem(element);
                arrayPromise.push(result);
              }
              await Promise.all(arrayPromise);
            }
            this.dataGroupsModal.data  = this.dataGroupsModal.dataOri;
            this.dataGroupsModal.total = this.dataGroupsModal.dataOri.length;
            this.currPage = 1;
            if (response.body.maxRecords > response.body.endRecord) {
              page++;
              this.getDepartmentUserLogin(page);
            } else {
              //
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.loading = false;
        });
    },
    getDepartmentItem(item: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.DEPARTMENT_API + '?limit=25&departmentId=' + encodeURIComponent(item.departmentId))
        .then((response: any) => {
          const index = this.dataGroupsModal.dataOri.findIndex((x: any) =>
          x.departmentId === item.departmentId);
          if (index >= 0) {
            let data: any = {};
            data = response.body.departments[0];
            this.dataGroupsModal.dataOri[index].groupCount = data.groupCount;
            this.dataGroupsModal.dataOri[index].name = data.name;
            this.dataGroupsModal.dataOri[index].userCount = data.userCount;
          }
          resolve(true);
        }).
        then( null, (err: any) => {
          resolve(false);
        });
      });
  },
    getDeaprtment() {
      this.loading = true;
      let apiLink = Config.DEPARTMENT_API + '?limit=' + localStorage.pageSizeGroupModal +
      '&page=' + this.currPage;
      if (this.keyword !== null && this.keyword !== undefined && this.keyword !== '') {
        apiLink += '&keyword=' + this.keyword + '&scorp=' + this.scorp;
      }
      if (localStorage.isVaildDataGroupModal === 'true') {
        apiLink += '&isValid=true';
      }
      this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.departments)) {
            this.dataGroupsModal.data = response.body.departments;
            this.dataGroupsModal.total = response.body.maxRecords;
            this.currPage = Math.ceil(response.body.startRecord / this.perPage);
          } else {
            this.dataGroupsModal.total = 0;
            this.currPage = 1;
          }
          this.loading = false;
        })
        .then( null, (err: any) => {
          this.loading = false;
          switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
        }); // catch
    },
    async clickGroupsAction(eventArgs: any) {
      this.rowinErrDialog = 0;
      this.errorMessageProcess = [];
      this.successItemProcess = 0;
      this.progress = 0;
      this.isFinishProcess = false;

      this.totalDataRowProcess = eventArgs.data.length;
      const percent = (100 / this.totalDataRowProcess);

      for (const item of  eventArgs.data) {
        let result;
        if (eventArgs.typeAction === 0) {
          //  enable
          result = await this.enableOrDisableGroup(item, true);
        } else if (eventArgs.typeAction === 1) {
          //  disable
          result = await this.enableOrDisableGroup(item, false);
        }
        if (result) {
          this.successItemProcess++;
        }
        this.progress += percent;
      }
      this.isFinishProcess = true;
    },
    enableOrDisableGroup(groupId: string, isEnable: boolean) {
      return new Promise((resolve) => {
        const data = {
          isValid: isEnable,
        };
        this.$http.put(Config.GROUP_LIST_API + '/' + encodeURIComponent(groupId), data)
        .then((response: any) => {
          console.log(response);
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = 'groupIdが存在しない。';
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = groupId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        });
      });
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.errorMessageLogs = '';
      this.successMessageLogs = '';
      this.dataGroupsModal = {
        dataOri: [],
        data: [],
        total: 0,
      };
    },
    getGroupDetail(groupId: any, i: any) {
      this.expandData.group.data[i].group.gateCount = 0;
      const apiLink = Config.GROUP_LIST_API + '?groupId=' + encodeURIComponent(groupId) +
      '&embed=passableTimeSetting';
      this.$http.get(apiLink)
        .then((response: any) => {
          if (!this.isEmpty(response.body.groups) && response.body.groups.length > 0) {
            this.expandData.group.data[i].gateCount = response.body.groups[0].gateCount;
            this.expandData.group.data[i].group.gateCount = response.body.groups[0].gateCount;
            this.countGroupDetail++;
          }
        })
        .then( null, (err: any) => {
          //
          this.countGroupDetail++;
        }); // catch
    },
    getGateGroup(groupId: string, page: any) {
      return new Promise((resolve, reject) => {
        this.expandData.gateGroup.loading = true;
        this.$http.get(Config.RELATION_GATE_GROUP_API + '?groupId=' + encodeURIComponent(groupId) + '&embed=gate&limit=50&page=' + page)
          .then(async (response: any) => {
            if (response.body.maxRecords > 0) {
            this.expandData.gateGroup.total = response.body.maxRecords;
            response.body.relationsGateGroup.forEach((element: any) => {
              this.expandData.gateGroup.data.push(element);
              this.expandData.gateGroup.dataOri.push(element);
            });
            if (response.body.maxRecords > response.body.endRecord) {
              page++;
              this.getGateGroup(groupId, page);
            } else {
              this.expandData.gateGroup.loading = false;
              resolve(true);
            }
          } else {
            this.expandData.gateGroup.loading = false;
            resolve(true);
          }
          })
          .then(null, (error) => {
            console.log(error);
            this.expandData.gateGroup.loading = false;
            resolve(false);
          });
      });
    },
    deleteDepartmentItem(departmentId: any): any {
      this.loading = true;
      this.$http.delete(Config.DEPARTMENT_API + '/' + encodeURIComponent(departmentId))
        .then((response: any) => {
          //
          this.loading = false;
        })
        .then( null, (err: any) => {
          this.loading = false;
          switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
        }); // catch
    },
    changePageUserAll(val: any) {
      this.expandData.userAll.page = val;
      const data = {
        page: this.expandData.userAll.page,
        perPage: this.expandData.userAll.perPage,
      };
      this.loadListUsers(data);
    },
    changePerPageUserAll(val: any) {
      this.expandData.userAll.perPage = val;
      const data = {
        page: this.expandData.userAll.page,
        perPage: this.expandData.userAll.perPage,
      };
      this.loadListUsers(data);
    },
    changePageGroupAll(val: any) {
      this.expandData.groupAll.page = val;
      const data = {
        page: this.expandData.groupAll.page,
        perPage: this.expandData.groupAll.perPage,
      };
      this.loadListGroups(data);
    },
    changePerPageGroupAll(val: any) {
      this.expandData.groupAll.perPage = val;
      const data = {
        page: this.expandData.groupAll.page,
        perPage: this.expandData.groupAll.perPage,
      };
      this.loadListGroups(data);
    },
    loadListUsers(item: any) {
      this.expandData.userAll.loading = true;
      let apiLink = Config.USERS_LIST_API + '?limit=' + item.perPage + '&page=' + item.page + '&isValid=true';
      if (this.expandData.userAll.keyword !== '') {
        apiLink += '&keyword=' + this.expandData.userAll.keyword + '&scorp=' + this.expandData.userAll.scorp;
      }
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            this.expandData.userAll.data = response.body.users;
            this.expandData.userAll.total = response.body.maxRecords;
            this.expandData.userAll.data.map((v: any) => Object.assign(v, {user: {
              userId: v.userId,
              name: v.name,
            }}));
            this.expandData.userAll.loading = false;
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.expandData.userAll.loading = false;
        }); // catch
  },
  loadListGroups(item: any) {
    this.expandData.groupAll.loading = true;
    let apiLink = Config.GROUP_LIST_API + '?limit=' + item.perPage + '&page=' + item.page + '&isValid=true&embed=passableTimeSetting';
    if (this.expandData.groupAll.keyword !== '') {
      apiLink += '&keyword=' + this.expandData.groupAll.keyword + '&scorp=' + this.expandData.groupAll.scorp;
    }
    this.$http.get(apiLink)
      .then((response: any) => {
        if (response.body.maxRecords > 0) {
          this.expandData.groupAll.data = response.body.groups;
          this.expandData.groupAll.total = response.body.maxRecords;
          this.expandData.groupAll.loading = false;
          this.expandData.groupAll.data.map((v: any) => Object.assign(v, {group: {
              groupId: v.groupId,
              name: v.name,
              gateCount: v.gateCount,
              passableTimeSetting: v.passableTimeSetting,
            }}));
        }
        this.expandData.groupAll.loading = false;
      })
      .then( null, (err: any) => {
        console.log('err: ', err);
        this.expandData.groupAll.loading = false;
      }); // catch
  },
  async addUsers(data: any) {
    console.log('data', data);
    let rowinErrDialog = 0;
    let successItemProcess = 0;
    let progress = 0;
    let isFinishProcess = false;
    const errorMessageProcess: any = [];
    const totalDataRowProcess = data.users.length;
    const percent = (100 / totalDataRowProcess);
    for (const item of data.users) {
      let result;
      result  = await this.postUserDepartment(item.userId, data.departmentId);
      if (result === true) {
        successItemProcess++;
      } else {
        const rowstatus = item.userId + '>><span>' + result + '</span>';
        this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
      }
      progress += percent;
    }
    isFinishProcess = true;
    this.dataRelationUser = {
      total: totalDataRowProcess,
      progressLoad: progress,
      successItem: successItemProcess,
      errorMessage: errorMessageProcess,
      isFinish: isFinishProcess,
    };
  },
  postUserDepartment(userId: string, departmentId: string) {
    return new Promise((resolve, reject) => {
      this.$http.post(Config.RELATION_DEPARTMENT_USER_API , {userId: (userId),
        departmentId: (departmentId)})
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          resolve(errorName);
        }); // catch
    });
  },
  async removeUsers(data: any) {
    console.log('data', data);
    this.dataRelationUser = {
      total: 0,
      progressLoad: 0,
      successItem: 0,
      errorMessage: [],
      isFinish: false,
    };
    let rowinErrDialog = 0;
    let successItemProcess = 0;
    let progress = 0;
    let isFinishProcess = false;
    const errorMessageProcess: any = [];
    const totalDataRowProcess = data.users.length;
    const percent = (100 / totalDataRowProcess);
    for (const item of data.users) {
      let result;
      result  = await this.removeUserDepartment(item.relationId);
      if (result === true) {
        successItemProcess++;
      } else {
        const rowstatus = item.userId + '>><span>' + result + '</span>';
        this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
      }
      progress += percent;
    }
    isFinishProcess = true;
    this.dataRelationUser = {
      total: totalDataRowProcess,
      progressLoad: progress,
      successItem: successItemProcess,
      errorMessage: errorMessageProcess,
      isFinish: isFinishProcess,
    };
  },
  removeUserDepartment(relationId: string) {
    return new Promise((resolve, reject) => {
      this.$http.delete(Config.RELATION_DEPARTMENT_USER_API + '/' + encodeURIComponent(relationId))
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          resolve(errorName);
        }); // catch
    });
  },
  async removeGroups(data: any) {
    console.log('data', data);
    this.dataRelationGroup = {
      total: 0,
      progressLoad: 0,
      successItem: 0,
      errorMessage: [],
      isFinish: false,
    };
    let rowinErrDialog = 0;
    let successItemProcess = 0;
    let progress = 0;
    let isFinishProcess = false;
    const errorMessageProcess: any = [];
    const totalDataRowProcess = data.groups.length;
    const percent = (100 / totalDataRowProcess);
    for (const item of data.groups) {
      let result;
      result  = await this.removeGroupDepartment(item.relationId);
      if (result === true) {
        successItemProcess++;
      } else {
        const rowstatus = item.group.name + '>><span>' + result + '</span>';
        this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
      }
      progress += percent;
    }
    isFinishProcess = true;
    this.dataRelationGroup = {
      total: totalDataRowProcess,
      progressLoad: progress,
      successItem: successItemProcess,
      errorMessage: errorMessageProcess,
      isFinish: isFinishProcess,
    };
  },
  removeGroupDepartment(relationId: string) {
    return new Promise((resolve, reject) => {
      this.$http.delete(Config.RELATION_DEPARTMENT_GROUP_API + '/' + encodeURIComponent(relationId))
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          resolve(errorName);
        }); // catch
    });
  },
  async addGroups(data: any) {
    console.log('data', data);
    this.dataRelationGroup = {
      total: 0,
      progressLoad: 0,
      successItem: 0,
      errorMessage: [],
      isFinish: false,
    };
    let rowinErrDialog = 0;
    let successItemProcess = 0;
    let progress = 0;
    let isFinishProcess = false;
    const errorMessageProcess: any = [];
    const totalDataRowProcess = data.groups.length;
    const percent = (100 / totalDataRowProcess);
    for (const item of data.groups) {
      let result;
      result  = await this.addGroupDepartment(item.groupId, data.departmentId);
      if (result === true) {
        successItemProcess++;
      } else {
        const rowstatus = item.name + '>><span>' + result + '</span>';
        this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
      }
      progress += percent;
    }
    isFinishProcess = true;
    this.dataRelationGroup = {
      total: totalDataRowProcess,
      progressLoad: progress,
      successItem: successItemProcess,
      errorMessage: errorMessageProcess,
      isFinish: isFinishProcess,
    };
  },
  addGroupDepartment(group: string, ID: string) {
    return new Promise((resolve, reject) => {
      this.$http.post(Config.RELATION_DEPARTMENT_GROUP_API, {
        departmentId: ID,
        groupId: group,
      })
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = Config.ERROR_404;
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          resolve(errorName);
        }); // catch
    });
  },
  getDepartmentAuth(departmentId: any) {
    this.loading = true;
    this.expandData.departmentAd.errorMessage = '';
    this.expandData.departmentAd.sucessMessage = '';
    this.$http.get(Config.DEPARTMENT_ADMINISTRATOR_API + '?limit=1000&departmentId=' + departmentId)
    .then(async (response: any) => {
      const userPromises = response.body.departmentAdministrators.map(async (element: any) => {
        const userName: any = await this.getUserName(element.userId);
        element.userName = userName;
        return element;
      });
      this.expandData.departmentAd.data = await Promise.all(userPromises);
      this.expandData.departmentAd.loading = response.body.departmentAdministrators;
      this.loading = false;
      this.countGetUser++;
    }).
    then( null, (err: any) => {
      this.loading = false;
      this.countGetUser++;
    });
  },
  getUserName(userId: string) {
    return new Promise((resolve) => {
      this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
        .then((res: any) => {
          if (res.data.users !== undefined && res.data.users !== null && res.data.users.length > 0) {
            resolve(res.data.users[0].name);
          } else {
            resolve(null);
          }
        }, (err) => {
          console.log(err);
          resolve(null);
        });
    });
  },
  removeAffDetail(ID: any) {
    this.loading = true;
    this.expandData.departmentAd.errorMessage = '';
    this.expandData.departmentAd.sucessMessage = '';
    this.$http.delete(Config.DEPARTMENT_API + '/' + ID)
      .then((response: any) => {
        this.loading = false;
        this.expandData.departmentAd.sucessMessage = '所属が削除されました。';
      })
      .then( null, (err: any) => {
        this.loading = false;
        switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        // this.expandData.departmentAd.errorMessage = this.errorMessageLogs;
      }); // catch
  },
  updateAff(data: any) {
    console.log('data', data);
    this.loading = true;
    this.expandData.departmentAd.errorMessage = '';
    this.expandData.departmentAd.sucessMessage = '';
    this.successMessageLogs = '';
    this.$http.put(Config.DEPARTMENT_API + '/' + data.departmentId, {
        name: data.name,
      })
      .then(async (response: any) => {
        //
        for (const item of data.data) {
          if (item.disabled) {
            await this.updateDepartmentItem(data.departmentId, item);
          }
          await this.addDepartmentItem(data.departmentId, item);
        }
        this.loading = false;
        this.successMessageLogs = Config.EDIT_AFFILIATION_SUCCESS;
      })
      .then( null, (err: any) => {
        this.loading = false;
        switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        this.expandData.departmentAd.errorMessage = this.errorMessageLogs;
      }); // catch
  },
  getUsers(page: any) {
    const isValid = this.isShowValidUser ? '&isValid=true' : '';

    this.$http.get(Config.USERS_LIST_API + '?limit=1000&page=' + page + '&sortKey=asc:userId' + isValid + '&isExceptOptionalUser=true&exclude=thumbnail')
      .then((response: any) => {
        if (response.body.maxRecords > 0) {
          response.body.users.forEach((element: any) => {
            this.userItems.push({text: element.name, value: element.userId});
          });
          if (response.body.maxRecords > response.body.endRecord) {
            this.getUsers(page + 1);
          }
        } else {
          this.userItems = [];
          this.countGetUser++;
        }
      })
      .then( null, (err: any) => {
        console.log('err: ', err);
        this.countGetUser++;
      }); // catch
  },
  updateDepartmentItem(ID: any, data: any) {
    return new Promise((resolve, reject) => {
      this.$http.put(Config.DEPARTMENT_ADMINISTRATOR_API + '/' + data.relationId, {
        isAdminToEditUser: data.checkOne,
        isAdminToAddUser: data.checkTwo,
      })
      .then((response: any) => {
        //
        resolve(true);
      })
      .then(null, (error: any) => {
        //
        resolve(true);
      });
    });
  },
  addDepartmentItem(ID: any, data: any) {
    return new Promise((resolve, reject) => {
      this.$http.post(Config.DEPARTMENT_ADMINISTRATOR_API, {
        departmentId: ID,
        userId: data.userId,
        isAdminToEditUser: data.checkOne,
        isAdminToAddUser: data.checkTwo,
      })
      .then((response: any) => {
        //
        resolve(true);
      })
      .then(null, (error: any) => {
        //
        resolve(false);
      });
    });
  },
  loadAllUserForAdd(item: any) {
    this.isAffiliationAdd = true;
    this.successMessageLogs = '';
    this.errorMessageLogs = '';
    this.getUsers(1);
    const data = {
      page: this.expandData.userAll.page,
      perPage: this.expandData.userAll.perPage,
    };
    this.loadListUsers(data);
    const dataG = {
      page: this.expandData.groupAll.page,
      perPage: this.expandData.groupAll.perPage,
    };
    this.loadListGroups(dataG);
  },
  registerAff(data: any) {
    console.log('data', data);
    this.loading = true;
    this.expandData.departmentAd.errorMessage = '';
    this.expandData.departmentAd.sucessMessage = '';
    this.successMessageLogs = '';
    this.$http.post(Config.DEPARTMENT_API, {
        name: data.name,
      })
      .then(async (response: any) => {
        //
        if ( data.data .length > 0) {
          for (const item of data.data) {
            await this.addDepartmentItem(response.body.departmentId, item);
          }
        }
        if ( data.groupIds .length > 0) {
          for (const item of data.groupIds) {
            const result = await this.addGroupDepartment(item.groupId, response.body.departmentId);
            if (result !== true) {
              this.expandData.errorData.groups.push(item.name);
            }
          }
        }
        if ( data.userIds .length > 0) {
          for (const item of data.userIds) {
            const result = await this.postUserDepartment(item.userId, response.body.departmentId);
            if (result !== true) {
              this.expandData.errorData.users.push(item.name);
            }
          }
        }
        this.loading = false;
        this.successMessageLogs = Config.ADD_AFFILIATION_SUCCESS;
      })
      .then( null, (err: any) => {
        this.loading = false;
        switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        this.expandData.departmentAd.errorMessage = this.errorMessageLogs;
      }); // catch
  },
  reloadDataGroup(item: any) {
    this.expandData.group.data = [];
    this.expandData.group.dataOri = [];
    this.expandData.group.total = 0;
    this.expandData.group.loading = true;
    this.getDepartmentGroup(item.departmentId, 1);
  },
},
});
