






































import {Vue } from 'vue-property-decorator';
import ControlPanelDetailTemplate from '@/components/templates/ControlPanelDetailTemplate/ControlPanelDetailTemplate.vue';
import * as Config from '@/config';

export interface DataType {
  loading: boolean;
  errorMessageLogs: string;
  controlPanelDetail: any;
  gateList: any;
  dataAllUses: any;
  successMessageLogs: string;
  showModal: boolean;
  showModalClear: boolean;
  showProgress: boolean;
  successMessageModal: string;
  errorMessageModal: string;
  successItemProcess: number;
  progress: number;
  isFinishProcess: boolean;
  totalDataRowProcess: number;
  rowinErrDialog: number;
  countUsers: number;
  errorMessageProcess: any;
  isControlPanelEditModalShow: boolean;
  isGateDetailModalShow: boolean;
}
export default Vue.extend({
  name: 'ControlPanelDetailPage',
  components: {
    ControlPanelDetailTemplate,
  },
  props: {
    authority: {
      type: Object,
      default: ({}),
    },
  },
  data: (): DataType => ({
    loading: false,
    errorMessageLogs: '',
    controlPanelDetail: {
      userId: '',
      controllerId: '',
      user: {
        userId: '',
        name: '',
        furigana: '',
        email: '',
        phoneNumber: '',
        isAdmin: false,
        isPhoneAdmin: false,
        shareKeyBeValid: false,
        isMfaSms: false,
        passCode: '',
        registered: null,
        updated: null,
        isValid: false,
        option1: '',
        option2: '',
        isPasswordLoginValid: false,
      },
      controller: {
        controllerId: '',
        name: '',
        phoneId: '',
        model: '',
        antipassbackSetting: 0,
        antipassbackClearTime: '',
        registered: null,
        updated: null,
        connectionStatus: false,
        commandStatus: 1,
      },
    },
    gateList: [],
    successMessageLogs: '',
    showModal: false,
    showModalClear: false,
    successMessageModal: '',
    errorMessageModal: '',
    dataAllUses: [],
    successItemProcess: 0,
    progress: 0,
    isFinishProcess: false,
    showProgress: false,
    totalDataRowProcess: 0,
    countUsers: 0,
    rowinErrDialog: 0,
    errorMessageProcess: [],
    isControlPanelEditModalShow: false,
    isGateDetailModalShow: false,
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  mounted() {
    this.countUsers = 0;
    this.loadData();
    this.getAllUsers(1);
  },
  methods: {
    getAllUsers(page: any) {
      this.$http.get(Config.USERS_LIST_API + '?limit=100&page=' + page + '&sortKey=desc:userId&isExceptOptionalUser=true&isValid=true&exclude=thumbnail')
        .then((response: any) => {
          this.countUsers = response.body.maxRecords;
          if (response.body.maxRecords > 0) {
            response.body.users.forEach((element: any) => {
              this.dataAllUses.push({text: element.name, value: element.userId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getAllUsers(page + 1);
            }
          } else {
            this.dataAllUses = [];
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
        }); // catch
    },
    async clickAntiPassBackClear(data: any) {
      this.errorMessageModal = '';
      this.successMessageModal = '';
      this.showModal = false;
      this.showModalClear = false;
      this.showProgress = true;
      const controllerId = data.controllerId;
      const userIds = data.data;
      this.successItemProcess = 0;
      this.progress = 0;
      this.isFinishProcess = false;
      this.totalDataRowProcess = 0;
      this.rowinErrDialog = 0;
      if (userIds !== undefined && userIds !== null && userIds !== '' && userIds.length > 0) {
        const percent = (100 / userIds.length);
        this.totalDataRowProcess = userIds.length;
        for (const item of userIds) {
          const dt = {
            type : 0,
            userId: item.value,
          };
          let result;
          result = await this.clearItem(controllerId, dt);
          if (result) {
            this.successItemProcess++;
          }
          this.progress += percent;
        }
      } else {
        const dt = {
          type : 0,
        };
        let result;
        result  = await this.clearItem(controllerId, dt);
        if (result) {
          this.successItemProcess++;
        }
        this.progress = 100;
      }
      this.isFinishProcess = true;
    },
    clearItem(controllerId: any, data: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.CONTROLLER_API + '/' + encodeURIComponent(controllerId) + '/antipassback-clear', data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .then( null, (error: any) => {
            let errorName = '';
            switch (error.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (error.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = data.userId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    clickConfirmClear() {
      this.errorMessageModal = '';
      this.successMessageModal = '';
      this.showModal = false;
      this.showModalClear = true;
    },
    closeModalClear() {
      this.errorMessageModal = '';
      this.successMessageModal = '';
      this.showModal = false;
      this.showModalClear = false;
    },
    closeModalProgress() {
      this.errorMessageModal = '';
      this.successMessageModal = '';
      this.showModal = false;
      this.showModalClear = false;
      this.showProgress = false;
      this.successItemProcess = 0;
      this.progress = 0;
      this.isFinishProcess = false;
      this.totalDataRowProcess = 0;
      this.rowinErrDialog = 0;
    },
    closeModal() {
      this.errorMessageModal = '';
      this.successMessageModal = '';
      this.showModal = false;
    },
    clickReconfigure(value: any) {
      this.errorMessageModal = '';
      this.successMessageModal = '';
      this.showModal = true;
    },
    postReconfigure(controllerId: any) {
      this.loading = true;
      this.$http.post(Config.CONTROLLER_API + '/' + encodeURIComponent(controllerId) + '/reconfigure')
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.loading = false;
            this.successMessageModal = '制御盤再設定の要求が完了しました。';
          } else {
            this.loading = false;
            this.errorMessageModal = Config.ERROR_DEFAULT;
          }
        })
        .then( null, (err: any) => {
          switch (err.status) {
            case 400:
              this.errorMessageModal = Config.ERROR_400;
              break;
            case 403:
              this.errorMessageModal = Config.ERROR_403;
              break;
            case 404:
              this.errorMessageModal = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageModal = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageModal = Config.ERROR_503;
              break;
            default:
              this.errorMessageModal = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
        }); // catch
    },
    clickBackControlPanel() {
      this.$router.push({ path: Config.CARD_LIST});
    },
    clickEditControlPanel(value: any) {
      this.$router.push({ path: Config.CARD_EDIT, query: {controllerId: value}});
    },
    clickBackController() {
      this.$emit('deleteBridgeProperty');
    },
    clickEditController() {
      localStorage.setItem('isEditFlag', 'true');
      this.isControlPanelEditModalShow = true;
    },
    ChangeProperty(value: string) {
      this.isControlPanelEditModalShow = false;
      this.isGateDetailModalShow = false;
    },
    loadData() {
      const dataQuery = this.$route.query;
      if (dataQuery.controllerId !== undefined) {
        this.getControlPanel(dataQuery.controllerId.toString());
      }
      // 外だしこれがいいのかは誰かに確認が必要
      const Id = localStorage.getItem('controllerId');
      this.getControlPanel(Id);
    },
    getControlPanel(controllerId: any): any {
      this.loading = true;
      this.errorMessageLogs = '';
      const apiLink = Config.CONTROLLER_MANAGEMENT_USER_API + '?controllerId=' + encodeURIComponent(controllerId) + '&embed=user,controller';
      this.$http.get(apiLink)
        .then((response: any) => {
          this.loading = false;
          if (!this.isEmpty(response.body.controllerManagementUsers)) {
            this.controlPanelDetail = response.body.controllerManagementUsers[0];
            this.gateList = [];
            this.getListGateByControlId(1, controllerId);
          }
        })
        .then( null, (err: any) => {
          this.loading = false;
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          }); // catch
    },
    getListGateByControlId(page: any, controllerId: string) {
      const apiLinkGetGate = Config.RELATION_CONTROLLER_GATE + '?controllerId=' + encodeURIComponent(controllerId) +
      '&limit=1000&page=' + page +
      '&sortKey=desc:gateId&embed=gate';
      this.$http.get(apiLinkGetGate)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.relationsControllerGate.forEach((element: any) => {
              this.gateList.push({text: element.gate.gateName, value: element.gateId,
              controllerGateNo: element.controllerGateNo });
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getListGateByControlId(page + 1, controllerId);
            } else {
              this.gateList.sort((a: any, b: any) => {
                return a.controllerGateNo - b.controllerGateNo;
              });
              this.loading = false;
            }
          }
        })
        .then( null, (err: any) => console.log('err: ', err)); // catch
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    clickGateDetail(Id: any) {
      this.isGateDetailModalShow = true;
      localStorage.setItem('gateId', Id);
      // ゲートモデルの型番名も渡す必要がある？
      // getListGateByControlId()で取得してくる?or APIをたたく？
    },
  },
});
