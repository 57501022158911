






























































































































import Vue from 'vue';
export default Vue.extend({
  name: 'SideNavigation',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    selectedItemNav: {
      type: Number,
      default: 0,
    },
    authority: {
      type: Object,
      default: ({}),
    },
    showSubMenu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerShow: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        this.$emit('input', val);
        return;
      },
    },
  },
  data: () => ({
    mini : true,
    selectedItem: 0,
    // isAdmin: false,
  }),
  mounted() {
    console.log('A');
    // this.isAdmin = localStorage.isAdmin === 'true' ? true : false;
  },
  methods: {
    //
  },
  watch: {
    selectedItemNav(value) {
      console.log('val', value);
      this.selectedItem = value;
      // if (value !== 1) {
      //   const el = document.getElementById('tab-user') as HTMLElement;
      //   console.log('el', el);
      //   if (el) {
      //     el.classList.remove('v-list-item--active');
      //   }
      // }
    },
  },
});
