






































































import Vue, { PropType } from 'vue';
import DatePicker from '@/components/molecules/DatePicker/DatePicker.vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import TimelineTable, { TimelineItem } from '@/components/molecules/TimelineTable/TimelineTable.vue';
import ReloadButton from '@/components/molecules/ReloadButton/ReloadButton.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import ProgressModal from '@/components/molecules/ProgressModal/ProgressModal.vue';
import { User, UserRoomLog } from '@/types/alligate';

export interface DataType {
  showExportExcelModal: boolean;
  showProgreeModal: boolean;
  endHour: number;
  today: string;
}

export default Vue.extend({
  name: 'InRoomUserTimelineTable',
  components: {
    DatePicker,
    Loading,
    CancelButton,
    SuccessButton,
    TimelineTable,
    ReloadButton,
    Modal,
    ProgressModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    // 選択中のユーザ情報
    user: {
      type: Object as PropType<User>,
      default: () => ({
        userId: '',
        name: '',
        furigana: '',
        email: '',
        phoneNumber: '',
        isAdmin: false,
        isPhoneAdmin: false,
        shareKeyBeValid: false,
        isMfaSms: false,
        passCode: '',
        isValid: false,
        option1: '',
        option2: '',
        isPasswordLoginValid: false,
        groupCount: 0,
        gateCount: 0,
        timelimitedGateCount: 0,
      }),
    },
    userRoomLogs: {
      type: Array as PropType<UserRoomLog[]>,
      default: () => [],
    },
    date: {
      type: String,
      default: new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10),
    },
    progressMessage: {
      type: String,
      default: '',
    },
    progressErrorMessage: {
      type: String,
      default: '',
    },
  },
  watch: {
    userRoomLogs() {
      this.updateEndHour();
    },
    date() {
      this.updateEndHour();
    },
  },
  computed: {
    innerDate: {
      get(): string {
        return this.date;
      },
      set(val: string): void {
        this.$emit('input-date', val);
        return;
      },
    },
    innerTimeline: {
      get(): TimelineItem[] {
        const ret: TimelineItem[] = [];
        this.userRoomLogs.forEach((log) => {
          const r: TimelineItem = {
            id: log.roomId,
            title: log.roomName,
            timelines: [],
          };
          log.roomLogs.forEach((roomLog, index) => {
            // roomLogのentryとexitのどちらかが存在する場合
            if (roomLog.entry || roomLog.exit) {
              const startTimestamp = roomLog.entry ? roomLog.entry.timestamp : null;
              const endTimestamp = roomLog.exit ? roomLog.exit.timestamp : null;

              let start;
              let end;
              if (startTimestamp) {
                const startISOString = new Date(startTimestamp * 1000 + 9 * 60 * 60 * 1000).toISOString();
                // 選択された日付でない場合はnullにする
                start = startISOString.slice(0, 10) === this.date ? startISOString.slice(11, 16) : undefined;
              }
              if (endTimestamp) {
                const endISOString = new Date(endTimestamp * 1000 + 9 * 60 * 60 * 1000).toISOString();
                // 選択された日付でない場合はnullにする
                end = endISOString.slice(0, 10) === this.date ? endISOString.slice(11, 16) : undefined;
              }

              // startとend両方nullの場合はスルーする
              r.timelines.push({
                start,
                end,
              });
            }
          });
          ret.push(r);
        });
        return ret;
      },
    },
    innerProgressMessage: {
      get(): string {
        if (this.progressMessage === '') {
          return `出力処理が完了しました。`;
        } else {
          return this.progressMessage;
        }
      },
    },
    progressModalTitle: {
      get(): string {
        if (this.progressMessage === '') {
          return `${this.user.name}さんの集計情報の出力が完了しました`;
        } else {
          return `${this.user.name}さんの集計情報を出力中です....`;
        }
      },
    },
  },
  data: (): DataType => ({
    showExportExcelModal: false,
    showProgreeModal: false,
    endHour: 24,
    today: new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10),
  }),
  methods: {
    clickExportExcel() {
      this.$emit('click-export-excel');
      this.showProgreeModal = true;
      this.showExportExcelModal = false;
    },
    updateEndHour() {
      const now = new Date(Date.now() + 9 * 60 * 60 * 1000);
      const today = now.toISOString().slice(0, 10);
      this.endHour = today === this.date ? Math.min(now.getUTCHours() + 1, 24) : 24;
    },
    updateToday() {
      this.today = new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10);
    },
  },
  mounted() {
    this.updateEndHour();
  },
});
