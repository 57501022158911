
























































































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import Search, { SearchTargetItem, SearchValue } from '@/components/molecules/Search/Search.vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import ErrorButton from '@/components/molecules/ErrorButton/ErrorButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import Alert from '@/components/molecules/Alert/Alert.vue';
import { Room } from '@/types/alligate';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';

export interface TypeItem {
  title: string;
}
export interface DataType {
  showSuccessMessages: boolean[];
  searchTargetItems: SearchTargetItem[];
  deleteRoomModal: boolean;
  room: Room;
}
export default Vue.extend({
  name: 'InAllRoomModalForm',
  components: {
    TextLink,
    Loading,
    Search,
    Modal,
    ErrorButton,
    CancelButton,
    Alert,
    Pagination,
  },
  props: {
    inAllRoomLoading: {
      type: Boolean,
      default: false,
    },
    inAllRoom: {
      type: Array,
      default: () => [],
    },
    inAllRoomSearchValue: {
      type: Object as PropType<SearchValue>,
      default: () => ({
        text: '',
        targets: ['name'],
      }),
    },
    successMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    cardTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    roomTotalCount: {
      type: Number,
      default: 0,
    },
  },
  data: (): DataType => ({
    showSuccessMessages: [],
    searchTargetItems: [
      { label: '名前', value: 'name' },
    ],
    deleteRoomModal: false,
    room: {
      roomId: '',
      name: '',
    },
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '名前',
            align: 'start',
            sortable: true,
            width: '',
            value: 'name',
          },
        ];
        headers.push({
          text: '',
          align: 'end',
          sortable: false,
          width: '150px',
          value: 'actions',
        });
        return headers;
      },
    },
    innerInAllRoomSearchValue: {
      get(): SearchValue {
        return this.inAllRoomSearchValue;
      },
      set(val: SearchValue): void {
        this.$emit('input-in-all-room-search-value', val);
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
  },
  methods: {
    handleSearch() {
      this.$emit('search-in-all-room', this.innerInAllRoomSearchValue);
    },
    handleClickLookRoom(room: Room) {
      this.$emit('clicklookroom', room);
    },
    handleDeleteRoom() {
      this.$emit('delete-room', this.room.roomId);
      this.deleteRoomModal = false;
    },
    handleClickDelete(room: Room) {
      this.room = room;
      this.deleteRoomModal = true;
    },
    handleClickEdit(room: Room) {
      this.$emit('click-edit-room', room);
    },
    handleClickCreateRoom() {
      this.$emit('click-create-room');
    },
  },
  watch: {
    successMessages(messages: string[]) {
      const shows: boolean[] = [];
      messages.forEach((message) => {
        shows.push(message !== '');
      });
      this.showSuccessMessages = shows;
    },
  },
});
