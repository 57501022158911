var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _c(
      "div",
      { staticClass: "allig-reflection-card-table" },
      [
        _c(
          "div",
          { staticClass: "round-action pb-0" },
          [
            _vm.isPage === "affiliation-add" || _vm.isPage === "detail"
              ? _c(
                  "v-row",
                  { staticClass: "btn-div", attrs: { justify: "end" } },
                  [
                    _c("v-col", { attrs: { md: "12", xl: "12" } }, [
                      _c(
                        "div",
                        { staticClass: "area-search-box" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between"
                              }
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("searchbox", {
                                    staticClass: "input-search btn-user-search",
                                    attrs: {
                                      searchTargetItems: _vm.searchTargetItems,
                                      title: "ユーザーを検索",
                                      disabled: _vm.loading
                                    },
                                    on: {
                                      search: function($event) {
                                        return _vm.$emit("search-user", $event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              : _vm.isPage === "affiliation" ||
                _vm.isPage === "affiliation-add-new"
              ? _c(
                  "v-row",
                  { staticClass: "btn-div", attrs: { justify: "end" } },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-row",
                          { staticClass: "btn-div", attrs: { justify: "end" } },
                          [
                            _c(
                              "div",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "btn-add pt-0 btn-add-user",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _vm.authority.isAdmin === true
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-primary",
                                            attrs: { outlined: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadShowUser()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    既存ユーザーを追加する\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "btn-add pt-0 btn-add-user mr-8",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _vm.authority.isAdmin === true
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-second",
                                            attrs: {
                                              outlined: "",
                                              disabled:
                                                _vm.idSelected.length === 0
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.isConfirm = true
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    チェックしたユーザーを削除する\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      {
                        staticClass: "pt-0 pb-0",
                        attrs: { md: "12", xl: "12", sm: "12" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "area-search-box" },
                          [
                            _c(
                              "v-row",
                              {
                                attrs: {
                                  "no-gutters": "",
                                  justify: "space-between"
                                }
                              },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("searchbox", {
                                      staticClass:
                                        "input-search btn-user-search",
                                      attrs: {
                                        searchTargetItems:
                                          _vm.searchTargetItems,
                                        title: "ユーザーを検索",
                                        disabled: _vm.loading
                                      },
                                      on: {
                                        search: function($event) {
                                          return _vm.$emit(
                                            "search-user",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm.isPage === "group"
              ? _c(
                  "v-row",
                  { staticClass: "btn-div", attrs: { justify: "end" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "col-sm-3 col-lg-3 col-xl-3 col" },
                      [
                        _c("h3", { staticClass: "mt-3" }, [
                          _vm._v(
                            "その他の通行可能ユーザー（" +
                              _vm._s(_vm.totalCount) +
                              "件）"
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-row",
                          { staticClass: "btn-div", attrs: { justify: "end" } },
                          [
                            _c(
                              "div",
                              { staticClass: "text-center " },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "btn-add btn-add-user pt-0",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _vm.authority.isAdmin === true ||
                                    _vm.authority.isAdminOfGroupPassages ===
                                      true
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-primary",
                                            attrs: { outlined: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.loadShowUser()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    他のユーザーを追加する\n                  "
                                            )
                                          ]
                                        )
                                      : _c("div", {
                                          staticStyle: { width: "200px" }
                                        })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  {
                                    staticClass:
                                      "btn-add btn-add-user pt-0 mr-6",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _vm.authority.isAdmin === true ||
                                    _vm.authority.isAdminOfGroupPassages ===
                                      true
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-second",
                                            attrs: {
                                              outlined: "",
                                              disabled:
                                                _vm.idSelected.length === 0
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.isConfirm = true
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    チェックしたユーザーを削除する\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      {
                        staticClass: "pt-0 pb-0",
                        attrs: { cols: "12", lg: "12", sm: "12" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "area-search-box" },
                          [
                            _c(
                              "v-row",
                              {
                                attrs: {
                                  "no-gutters": "",
                                  justify: "space-between"
                                }
                              },
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("searchbox", {
                                      staticClass:
                                        "input-search btn-user-search",
                                      attrs: {
                                        searchTargetItems:
                                          _vm.searchTargetItems,
                                        title: "ユーザーを検索",
                                        disabled: _vm.loading
                                      },
                                      on: {
                                        search: function($event) {
                                          return _vm.$emit(
                                            "search-user",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              : _c(
                  "v-row",
                  { staticClass: "btn-div", attrs: { justify: "end" } },
                  [
                    _c("v-col", { attrs: { md: "4", xl: "5" } }, [
                      _c(
                        "div",
                        { staticClass: "area-search-box" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between"
                              }
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("searchbox", {
                                    staticClass: "input-search btn-user-search",
                                    attrs: {
                                      searchTargetItems: _vm.searchTargetItems,
                                      title: "ユーザーを検索",
                                      disabled: _vm.loading
                                    },
                                    on: {
                                      search: function($event) {
                                        return _vm.$emit("search-user", $event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-row",
                          { staticClass: "btn-div", attrs: { justify: "end" } },
                          [
                            _c(
                              "div",
                              { staticClass: "text-center mr-10" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "btn-add pt-0",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _vm.authority.isAdmin === true ||
                                    _vm.authority.isAdminOfGroupPassages ===
                                      true
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-second",
                                            attrs: {
                                              outlined: "",
                                              disabled:
                                                _vm.idSelected.length === 0
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    チェックしたユーザーを削除する\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "btn-add pt-0",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _vm.authority.isAdmin === true ||
                                    _vm.authority.isAdminOfGroupPassages ===
                                      true
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-primary",
                                            attrs: { outlined: "" },
                                            on: {
                                              click: function($event) {
                                                _vm.isShow = true
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    ユーザーを追加する\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
            _vm._v(" "),
            _c(
              "v-row",
              {
                staticClass: "mt-5",
                attrs: {
                  "no-gutters": "",
                  justify: "space-between",
                  "align-content": "center"
                }
              },
              [
                _c("show-per-page", {
                  staticClass: "ml-5",
                  attrs: {
                    "per-page": _vm.perPage,
                    page: _vm.page,
                    "items-length": _vm.totalCount
                  }
                }),
                _vm._v(" "),
                _c("v-spacer"),
                _vm._v(" "),
                _c("v-checkbox", {
                  staticClass: "text-caption-checkbox mr-5",
                  attrs: {
                    label: "有効なユーザーのみ表示",
                    color: "green",
                    dense: "",
                    "hide-details": "",
                    disabled: _vm.isPage === "affiliation-add-new"
                  },
                  on: {
                    click: function($event) {
                      return _vm.Change()
                    }
                  },
                  model: {
                    value: _vm.isShowValidUser,
                    callback: function($$v) {
                      _vm.isShowValidUser = $$v
                    },
                    expression: "isShowValidUser"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "text-caption display-option included-option text-sm-caption text-md-caption text-lg-caption text-xl-caption",
                    on: {
                      click: function() {
                        _vm.showOption = !_vm.showOption
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "display-option-title" }, [
                      _vm._v("\n          表示オプション\n        ")
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      [
                        _c("v-icon", { attrs: { dense: "" } }, [
                          _vm._v("mdi-chevron-down")
                        ])
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.showOption
              ? _c(
                  "v-row",
                  { staticClass: "pt-0 pb-10 mt-0 " },
                  [
                    _c(
                      "v-container",
                      { staticClass: "pt-0" },
                      [
                        _c("display-option", {
                          attrs: {
                            isShowOption: _vm.showOption,
                            "display-option-items": _vm.displayOptionItems,
                            disabled: _vm.loading,
                            isVaildOnly: _vm.isVaildOnly,
                            isVaildOnlyShow: false
                          },
                          on: {
                            "show-only-isVaild": function($event) {
                              return _vm.$emit("show-only-isVaild", $event)
                            },
                            input: function($event) {
                              return _vm.CheckColumn($event)
                            }
                          },
                          model: {
                            value: _vm.selectedDisplayOption,
                            callback: function($$v) {
                              _vm.selectedDisplayOption = $$v
                            },
                            expression: "selectedDisplayOption"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-row",
          { staticClass: "pa-3" },
          [
            _c("v-col", { staticClass: "pt-0", attrs: { sm: "12" } }, [
              _c(
                "div",
                {
                  staticClass:
                    "round-data round-data-user-aff table-affiliation"
                },
                [
                  _c("v-data-table", {
                    attrs: {
                      "must-sort": "",
                      headers: _vm.headers,
                      items: _vm.dataList,
                      "items-per-page": _vm.perPage,
                      "item-key": "user.userId",
                      "no-data-text": "データーはありません",
                      "loading-text": "データを読み込中です",
                      loading: _vm.loading,
                      "hide-default-footer": "",
                      "show-select": true,
                      "custom-sort": _vm.customSort
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.isPage === "affiliation-add"
                          ? {
                              key: "item.data-table-select",
                              fn: function(ref) {
                                var item = ref.item
                                var isSelected = ref.isSelected
                                var select = ref.select
                                return [
                                  item.company !== ""
                                    ? _c("v-simple-checkbox", {
                                        attrs: {
                                          value: false,
                                          readonly: "",
                                          disabled: "",
                                          ripple: false
                                        },
                                        on: {
                                          input: function($event) {
                                            return select($event)
                                          }
                                        }
                                      })
                                    : _c("v-simple-checkbox", {
                                        attrs: {
                                          value: isSelected,
                                          ripple: false
                                        },
                                        on: {
                                          input: function($event) {
                                            return select($event)
                                          }
                                        }
                                      })
                                ]
                              }
                            }
                          : null,
                        {
                          key: "item.userId",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", { staticClass: "item-user-id" }, [
                                _vm._v(_vm._s(item.user.userId))
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.userName",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm.authority.isAdmin &&
                              _vm.isPage !== "affiliation-add-new"
                                ? _c("text-link", {
                                    staticClass: "item-user-name",
                                    attrs: { text: item.user.name },
                                    on: {
                                      click: function($event) {
                                        return _vm.showUserDetail(
                                          item.user.userId
                                        )
                                      }
                                    }
                                  })
                                : _c("text-link", {
                                    staticClass: "item-user-name",
                                    attrs: { text: item.user.name }
                                  })
                            ]
                          }
                        },
                        {
                          key: "item.user.registered",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.converDateShort(item.user.registered)
                                  ) +
                                  "\n          "
                              )
                            ]
                          }
                        },
                        {
                          key: "item.user.updated",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.converDateShort(item.user.updated)
                                  ) +
                                  "\n          "
                              )
                            ]
                          }
                        },
                        {
                          key: "item.user.isValid",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.user.isValid ? "有効" : "無効") +
                                  "\n          "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: _vm.idSelected,
                      callback: function($$v) {
                        _vm.idSelected = $$v
                      },
                      expression: "idSelected"
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("v-spacer"),
        _vm._v(" "),
        _c(
          "v-row",
          {
            staticClass: "mt-3",
            attrs: { "no-gutters": "", justify: "space-between" }
          },
          [
            _c("v-spacer"),
            _vm._v(" "),
            _c("v-col", { staticClass: "mr-5 mb-2" }),
            _vm._v(" "),
            _vm.loading === false || _vm.isPage === "affiliation-add"
              ? _c("pagination", {
                  staticClass: "mb-1",
                  attrs: {
                    "page-length": Math.ceil(_vm.totalCount / _vm.perPage),
                    disabled: _vm.loading
                  },
                  model: {
                    value: _vm.innerPage,
                    callback: function($$v) {
                      _vm.innerPage = $$v
                    },
                    expression: "innerPage"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-dialog",
          {
            attrs: { width: "1200", persistent: "" },
            model: {
              value: _vm.isShow,
              callback: function($$v) {
                _vm.isShow = $$v
              },
              expression: "isShow"
            }
          },
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "modal-title", attrs: { color: "green" } },
                  [
                    _vm._t("default", [
                      _c(
                        "v-row",
                        {
                          attrs: { "no-gutters": "", justify: "space-between" }
                        },
                        [
                          _vm._v(
                            "\n              新規ユーザー追加\n              "
                          ),
                          _c(
                            "v-icon",
                            {
                              attrs: { color: "white" },
                              on: {
                                click: function($event) {
                                  _vm.isShow = false
                                  _vm.$emit("close-modal")
                                }
                              }
                            },
                            [_vm._v("mdi-close")]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.isShow
                  ? _c("UserAddPage", {
                      attrs: {
                        isModal: true,
                        userId: _vm.userId,
                        authority: _vm.authority,
                        departmentId: _vm.departmentId
                      },
                      on: {
                        "close-modal-edit": function($event) {
                          _vm.isShow = false
                        },
                        loading: function($event) {
                          return _vm.$emit("loading-from-child", $event)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-dialog",
          {
            attrs: { width: "1200", persistent: "" },
            model: {
              value: _vm.isShowUserDetail,
              callback: function($$v) {
                _vm.isShowUserDetail = $$v
              },
              expression: "isShowUserDetail"
            }
          },
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "modal-title", attrs: { color: "green" } },
                  [
                    _vm._t("default", [
                      _c(
                        "v-row",
                        {
                          attrs: { "no-gutters": "", justify: "space-between" }
                        },
                        [
                          _vm._v(
                            "\n              ユーザー詳細\n              "
                          ),
                          _vm.isPage === "group"
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { color: "white" },
                                  on: {
                                    click: function($event) {
                                      _vm.isShowUserDetail = false
                                      _vm.$emit("close-modal")
                                    }
                                  }
                                },
                                [_vm._v("mdi-close")]
                              )
                            : _c(
                                "v-icon",
                                {
                                  attrs: { color: "white" },
                                  on: {
                                    click: function($event) {
                                      _vm.isShowUserDetail = false
                                    }
                                  }
                                },
                                [_vm._v("mdi-close")]
                              )
                        ],
                        1
                      )
                    ])
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.isShowUserDetail
                  ? _c("UserDetailPage", {
                      attrs: {
                        titlePage: "ユーザー情報",
                        isModal: true,
                        userIdModal: _vm.userId,
                        authority: _vm.authority
                      },
                      on: {
                        loading: function($event) {
                          return _vm.$emit("loading-from-child", $event)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-dialog",
          {
            attrs: { width: "800", persistent: "" },
            model: {
              value: _vm.isShowUser,
              callback: function($$v) {
                _vm.isShowUser = $$v
              },
              expression: "isShowUser"
            }
          },
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "modal-title", attrs: { color: "green" } },
                  [
                    _vm._t("default", [
                      _c(
                        "v-row",
                        {
                          attrs: { "no-gutters": "", justify: "space-between" }
                        },
                        [
                          _vm._v("\n              ユーザー\n              "),
                          _c(
                            "v-icon",
                            {
                              attrs: { color: "white" },
                              on: {
                                click: function($event) {
                                  _vm.isShowUser = false
                                  _vm.$emit("close-modal")
                                }
                              }
                            },
                            [_vm._v("mdi-close")]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.isShowUser
                  ? _c("ModalPageUser", {
                      attrs: {
                        isPage:
                          _vm.isPage === "affiliation"
                            ? "aff-load-all-user"
                            : _vm.isPage === "affiliation-add-new"
                            ? "affiliation-add-new"
                            : "group-load-all-user",
                        isAction:
                          _vm.isPage === "affiliation" ? "add-user-to-aff" : "",
                        groupId: _vm.groupId,
                        departmentId: _vm.departmentId,
                        groupPassages:
                          _vm.isPage === "group"
                            ? _vm.dataGroupPassages.dataOri
                            : _vm.dataList,
                        dataAdded:
                          _vm.isPage === "affiliation-add-new"
                            ? _vm.dataList
                            : []
                      },
                      on: {
                        "change-page-group": function($event) {
                          return _vm.$emit("change-page-group", $event)
                        },
                        loading: function($event) {
                          return _vm.$emit("loading-from-child", $event)
                        },
                        "close-modal": function($event) {
                          return _vm.$emit("close-modal", $event)
                        },
                        "affiliation-add-item-direct": function($event) {
                          _vm.$emit("affiliation-add-item-direct", $event)
                          _vm.isShowUser = false
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-dialog",
          {
            attrs: { width: "600", persistent: "" },
            model: {
              value: _vm.showProgress,
              callback: function($$v) {
                _vm.showProgress = $$v
              },
              expression: "showProgress"
            }
          },
          [
            _c(
              "v-card",
              [
                _c("v-card-title", { staticClass: "background-color-green" }, [
                  _vm._v("ユーザー")
                ]),
                _vm._v(" "),
                _c("v-divider"),
                _vm._v(" "),
                _c("v-row", {
                  staticStyle: { height: "5px" },
                  attrs: { "no-gutters": "" }
                }),
                _vm._v(" "),
                _vm.dataRelationUser.total >= 0
                  ? _c(
                      "v-card-text",
                      { staticClass: "pa-4" },
                      [
                        _c(
                          "v-progress-linear",
                          {
                            staticStyle: { "pointer-events": "none" },
                            attrs: {
                              height: "25",
                              color: "green darken-1",
                              striped: ""
                            },
                            model: {
                              value: _vm.dataRelationUser.progressLoad,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.dataRelationUser,
                                  "progressLoad",
                                  $$v
                                )
                              },
                              expression: "dataRelationUser.progressLoad"
                            }
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  Math.round(_vm.dataRelationUser.progressLoad)
                                ) + "%"
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _vm.dataRelationUser.successItem > 0
                          ? _c("span", { staticClass: "success-label" }, [
                              _vm._v(
                                _vm._s(_vm.dataRelationUser.total) +
                                  "件中" +
                                  _vm._s(_vm.dataRelationUser.successItem) +
                                  " "
                              ),
                              _vm.type === "add"
                                ? _c("span", [_vm._v("件を追加しました。")])
                                : _c("span", [_vm._v("件を削除しました。")])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.dataRelationUser.errorMessage.length > 0
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "error-box" },
                                [
                                  _vm._l(
                                    _vm.dataRelationUser.errorMessage,
                                    function(item) {
                                      return [
                                        _c("p", {
                                          key: item,
                                          domProps: { innerHTML: _vm._s(item) }
                                        })
                                      ]
                                    }
                                  )
                                ],
                                2
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.dataRelationUser.isFinish
                  ? _c(
                      "v-card-actions",
                      [
                        _c(
                          "v-row",
                          { staticClass: "ma-2", attrs: { justify: "end" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-secondary",
                                attrs: { outlined: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.closeModalUser()
                                  }
                                }
                              },
                              [_vm._v("OK")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-dialog",
          {
            attrs: { width: "600", persistent: "" },
            model: {
              value: _vm.isConfirm,
              callback: function($$v) {
                _vm.isConfirm = $$v
              },
              expression: "isConfirm"
            }
          },
          [
            _c(
              "v-card",
              [
                _c(
                  "v-card-title",
                  { staticClass: "background-color-green" },
                  [
                    _vm._t("default", [
                      _c(
                        "v-row",
                        {
                          attrs: { "no-gutters": "", justify: "space-between" }
                        },
                        [
                          _vm._v("ユーザー\n              "),
                          _c(
                            "v-icon",
                            {
                              attrs: { color: "white" },
                              on: {
                                click: function($event) {
                                  _vm.isConfirm = false
                                }
                              }
                            },
                            [_vm._v("mdi-close")]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  2
                ),
                _vm._v(" "),
                _c("v-card-text", { staticClass: "pt-5 pb-5" }, [
                  _vm._v("ユーザーを削除しますか？")
                ]),
                _vm._v(" "),
                _c(
                  "v-card-actions",
                  [
                    _c(
                      "v-row",
                      { staticClass: "ma-2", attrs: { justify: "end" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-primary",
                            attrs: { outlined: "" },
                            on: {
                              click: function($event) {
                                return _vm.removeUser(_vm.idSelected)
                              }
                            }
                          },
                          [_vm._v("\n              削除\n            ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }