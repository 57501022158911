var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-users-template" },
    [
      _vm.isModal === false
        ? _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "container-common" },
        [
          _c("UserAddForm", {
            attrs: {
              loading: _vm.loading,
              isModal: _vm.isModal,
              isEdit: _vm.isEdit,
              departments: _vm.departments,
              isDisabledGateOption: _vm.isDisabledGateOption,
              gateList: _vm.gateList,
              isDisabledGroupOptions: _vm.isDisabledGroupOptions,
              isDisabledPCodeOptions: _vm.isDisabledPCodeOptions,
              gateGroupOptions: _vm.gateGroupOptions,
              phoneItems: _vm.phoneItems,
              pCodeOptions: _vm.pCodeOptions,
              userInfo: _vm.userData,
              listGatePassages: _vm.listGatePassages,
              successMessageLogs: _vm.successMessageLogs,
              errorMessageLogs: _vm.errorMessageLogs,
              groupsAddFail: _vm.groupsAddFail,
              pCodeAddFail: _vm.pCodeAddFail,
              gateAddFail: _vm.gateAddFail,
              isGetDepartment: _vm.isGetDepartment,
              isGetDepartmentAll: _vm.isGetDepartmentAll,
              authority: _vm.authority,
              getDepartments: _vm.getDepartments,
              dataFailed: _vm.dataFailed
            },
            on: {
              getListGate: function($event) {
                return _vm.$emit("getListGate")
              },
              clickSaveUser: function($event) {
                return _vm.$emit("clickSaveUser", $event)
              },
              clickUpdateUser: function($event) {
                return _vm.$emit("clickUpdateUser", $event)
              },
              "init-data": function($event) {
                return _vm.$emit("init-data", $event)
              },
              "change-page-group": function($event) {
                return _vm.$emit("change-page-group", $event)
              },
              "close-modal-edit": function($event) {
                return _vm.$emit("close-modal-edit")
              },
              "change-id": function($event) {
                return _vm.$emit("change-id")
              },
              "change-departmentId": function($event) {
                return _vm.$emit("change-departmentId", $event)
              },
              backUserList: function($event) {
                return _vm.$emit("backUserList", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }