














































































































































































































































































import Vue, { PropType } from 'vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import Alert from '@/components/molecules/Alert/Alert.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Tabs, { TabItem } from '@/components/molecules/Tabs/Tabs.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InRoomDetail, { TAB_VALUE_IN_ROOM_USER } from '@/components/organisms/InRoomDetail/InRoomDetail.vue';
import RoomForm from '@/components/organisms/RoomForm/RoomForm.vue';
import { Room, User, RoomRoomLog, UserRoomLog, Gate } from '@/types/alligate';
import { SearchValue } from '@/components/molecules/Search/Search.vue';
import Searchbox, {SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import InRoomModalPage from '../../pages/in-room-modal/InRoomModalPage.vue';
import InAllRoomModalPage from '../../pages/in-all-room-modal/InAllRoomModalPage.vue';
import UserDetailPageInRoom from '@/components/pages/user/UserDetailPage.vue';

export interface DataType {
  showSuccessMessages: boolean[];
  breadcrumbs: BreadcrumbItem[];

  showRoomFormModal: boolean;
  isRoomEdit: boolean;
  searchTargetItems: SearchTargetItem[];
  isShowUserDetail: boolean;
}

export default Vue.extend({
  name: 'inRoomTemplate',
  components: {
    Loading,
    Alert,
    Breadcrumbs,
    Modal,
    Tabs,
    CancelButton,
    SuccessButton,
    InRoomDetail,
    RoomForm,
    Searchbox,
    TextLink,
    InRoomModalPage,
    InAllRoomModalPage,
    UserDetailPageInRoom,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    roomInfoLoading: {
      type: Boolean,
      default: false,
    },
    successMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    value: {
      type: Object as PropType<Room>,
      default: () => ({
        roomId: '',
        name: '',
      }),
    },

    tab: {
      type: String,
      default: TAB_VALUE_IN_ROOM_USER,
    },
    rooms: {
      type: Array as PropType<Room[]>,
      default: () => [],
    },

    roomLoading: {
      type: Boolean,
      default: false,
    },
    room: {
      type: Object as PropType<Room>,
      default: () => ({
        roomId: '',
        name: '',
      }),
    },
    roomEdit: {
      type: Object as PropType<Room>,
      default: () => ({
        roomId: '',
        name: '',
      }),
    },

    roomSuccessMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    roomErrorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },

    inRoomUserLoading: {
      type: Boolean,
      default: false,
    },
    inRoomUsers: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
    inRoomUserTotalCount: {
      type: Number,
      default: 0,
    },
    inRoomUserPage: {
      type: Number,
      default: 1,
    },
    inRoomUserPerPage: {
      type: Number,
      default: 25,
    },
    inRoomUserSearchValue: {
      type: Object as PropType<SearchValue>,
      default: () => ({
        text: '',
        targets: ['userId', 'name'],
      }),
    },

    roomRoomLogLoading: {
      type: Boolean,
      default: false,
    },
    roomRoomLogs: {
      type: Array as PropType<RoomRoomLog[]>,
      default: () => [],
    },
    roomRoomLogTotalCount: {
      type: Number,
      default: 0,
    },
    roomRoomLogPage: {
      type: Number,
      default: 1,
    },
    roomRoomLogPerPage: {
      type: Number,
      default: 25,
    },
    roomRoomLogDate: {
      type: String,
      default: new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10),
    },
    roomRoomLogSearchValue: {
      type: Object as PropType<SearchValue>,
      default: () => ({
        text: '',
        targets: ['name'],
      }),
    },
    roomRoomLogProgressMessage: {
      type: String,
      default: '',
    },
    roomRoomLogProgressErrorMessage: {
      type: String,
      default: '',
    },

    userRoomLogLoading: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object as PropType<User>,
      default: () => ({
        userId: '',
        name: '',
        furigana: '',
        email: '',
        phoneNumber: '',
        isAdmin: false,
        isPhoneAdmin: false,
        shareKeyBeValid: false,
        isMfaSms: false,
        passCode: '',
        isValid: false,
        option1: '',
        option2: '',
        isPasswordLoginValid: false,
        groupCount: 0,
        gateCount: 0,
        timelimitedGateCount: 0,
      }),
    },
    userRoomLogs: {
      type: Array as PropType<UserRoomLog[]>,
      default: () => [],
    },
    userRoomLogDate: {
      type: String,
      default: new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10),
    },
    userRoomLogProgressMessage: {
      type: String,
      default: '',
    },
    userRoomLogProgressErrorMessage: {
      type: String,
      default: '',
    },
    userRoomLogSuccessMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    userRoomLogErrorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },

    roomFormLoading: {
      type: Boolean,
      default: false,
    },
    roomFormSuccessMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    roomFormErrorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },

    allGates: {
      type: Array as PropType<Gate[]>,
      default: () => [],
    },
    allGate: {
      type: Array as PropType<Gate[]>,
      default: () => [],
    },
    isLookRoomModalShow: {
      type: Boolean,
      default: false,
    },
    isAllRoomModalShow: {
      type: Boolean,
      default: false,
    },
    tabItemsUI: {
      type: Array,
      default: [],
    },
    userFullList: {
      type: Array,
      default: () => [],
    },
    roomInfo: {
      type: Object as PropType<Room>,
      default: () => ({
        roomId: '',
        name: '',
        relationsRoomGate: [],
      }),
    },
    inAllRoom: {
      type: Array,
      default: () => [],
    },
    inAllRoomLoading: {
      type: Boolean,
      default: false,
    },
    roomTotalCount: {
      type: Number,
      default: 0,
    },
    inAllRoomSearchValue: {
      type: Object as PropType<SearchValue>,
      default: () => ({
        text: '',
        targets: ['name'],
      }),
    },
    page: {
      type: Number,
      default: 1,
    },
    authority: {
      type: Object,
      default: ({}),
    },
  },
  data: (): DataType => ({
    searchTargetItems: [
      { label: '部屋', value: 'name', isDisabled: true },
    ],
    showSuccessMessages: [],
    breadcrumbs: [
      { text: '在室状況', disabled: true },
    ],

    showRoomFormModal: false,
    isRoomEdit: false,
    isShowUserDetail: false,
  }),
  computed: {
    innerValue: {
      get(): Room {
        return this.value;
      },
      set(val: Room): void {
        this.$emit('input', val);
      },
    },
    innerTab: {
      get(): string {
        return this.tab;
      },
      set(val: string) {
        this.$emit('input-tab', val);
      },
    },
    innerRoomTab: {
      get(): string {
        return this.room.roomId;
      },
      set(val: string): void {
        this.$emit('click-room', val);
      },
    },
    innerUser: {
      get(): string {
        return this.user.userId;
      },
      set(val: string): void {
        this.$emit('click-in-room-user', val);
      },
    },
    tabItems: {
      get(): TabItem[] {
        return this.rooms.map((room) => ({
          text: room.name,
          value: room.roomId,
        }));
      },
    },
    innerInRoomUserSearchValue: {
      get(): SearchValue {
        return this.inRoomUserSearchValue;
      },
      set(val: SearchValue): void {
        this.$emit('input-in-room-user-search-value', val);
        return;
      },
    },
    innerInAllRoomSearchValue: {
      get(): SearchValue {
        return this.inAllRoomSearchValue;
      },
      set(val: SearchValue): void {
        this.$emit('input-in-all-room-search-value', val);
        return;
      },
    },
    innerRoomRoomLogDate: {
      get(): string {
        return this.roomRoomLogDate;
      },
      set(val: string): void {
        this.$emit('input-room-room-log-date', val);
        return;
      },
    },
    innerRoomRoomLogSearchValue: {
      get(): SearchValue {
        return this.roomRoomLogSearchValue;
      },
      set(val: SearchValue): void {
        this.$emit('input-room-room-log-search-value', val);
        return;
      },
    },
    innerUserRoomLogDate: {
      get(): string {
        return this.userRoomLogDate;
      },
      set(val: string): void {
        this.$emit('input-user-room-log-date', val);
        return;
      },
    },
  },
  watch: {
    successMessages(messages: string[]) {
      const shows: boolean[] = [];
      messages.forEach((message) => {
        shows.push(message !== '');
      });
      this.showSuccessMessages = shows;
    },
  },
  methods: {
    clickEditRoom(room: Room) {
      this.$emit('start-edit-room', room);
      this.isRoomEdit = true;
      this.showRoomFormModal = true;
    },
    clickCreateRoom() {
      this.$emit('start-create-room');
      this.isRoomEdit = false;
      this.showRoomFormModal = true;
    },
    deleteBridgeProperty() {
      this.$emit('ChangeProperty');
    },
  },
});
