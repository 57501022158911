var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("UserAddTemplate", {
    attrs: {
      isEdit: _vm.isEdit,
      isModal: _vm.isModal,
      isDisabledGateOption: _vm.isDisabledGateOption,
      gateList: _vm.gateList,
      loading: _vm.loading,
      isGetDepartment: _vm.isGetDepartment,
      isGetDepartmentAll: _vm.isGetDepartmentAll,
      isDisabledGroupOptions: _vm.isDisabledGroupOptions,
      isDisabledPCodeOptions: _vm.isDisabledPCodeOptions,
      gateGroupOptions: _vm.gateGroupOptions,
      pCodeOptions: _vm.pCodeOptions,
      groupsAddFail: _vm.groupsAddFail,
      pCodeAddFail: _vm.pCodeAddFail,
      gateAddFail: _vm.gateAddFail,
      userData: _vm.userData,
      goBack: _vm.goBack,
      errorMessageLogs: _vm.errorMessageLogs,
      successMessageLogs: _vm.successMessageLogs,
      listGatePassages: _vm.listGatePassages,
      phoneItems: _vm.phoneItems,
      departments: _vm.departments,
      authority: _vm.authority,
      getDepartments: _vm.getDepartments,
      dataFailed: _vm.dataFailed
    },
    on: {
      clickSaveUser: _vm.clickSaveUser,
      clickUpdateUser: _vm.clickUpdateUser,
      backUserList: _vm.backUserList,
      "init-data": function($event) {
        return _vm.initData()
      },
      "close-modal-edit": function($event) {
        return _vm.$emit("close-modal-edit")
      },
      "change-id": function($event) {
        return _vm.changeId()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }