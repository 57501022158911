





















































































































































import Vue, { PropType } from 'vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';

export interface SearchTargetItem {
  label: string;
  value: string;
  isDisabled?: boolean;
}

export interface DataType {
  text: string;
  showCustomSearch: boolean;
  targets: any;
  items: any;
  itemsTwo: any;
  typeSearch: string;
}

export default Vue.extend({
  name: 'Searchbox',
  components: {
    SuccessButton,
  },
  props: {
    searchTargetItems: {
      type: Array as PropType<SearchTargetItem[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isPageLog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    goBack: {
      type: String,
      default: '',
    },
  },
  data(): DataType {
    return {
      text: '',
      showCustomSearch: false,
      targets: [],
      items: [],
      itemsTwo: [],
      typeSearch: '0',
    };
  },
  watch: {
    goBack(value: any) {
      if (value !== '') {
        if (typeof localStorage.gateName !== undefined && localStorage.gateName !== 'undefined' &&
        localStorage.gateName !== '' && this.goBack === '/groups') {
          this.text = localStorage.gateName;
        }
        if (typeof localStorage.userName !== undefined && localStorage.userName !== 'undefined' &&
        localStorage.userName !== '' && this.goBack === '/user') {
          this.text = localStorage.userName;
        }
      }
    },
  },
  computed: {
    // targets: {
    //   get(): string[] {
    //     return this.searchTargetItems.map((item) => item.value);
    //   },
    //   set(): void {
    //     return;
    //   },
    // },
  },
  mounted() {
    this.targets = this.searchTargetItems.map((item) => item.value);
    const searchTargetItems = this.searchTargetItems;
    if (this.isPageLog) {
      this.ChangeSearch('0');
      this.items = searchTargetItems.slice(0, 5);
      this.itemsTwo = searchTargetItems.slice(5, 8);
    }
  },
  methods: {
    clickShowCustomSearch(value: any) {
      this.showCustomSearch = true;
    },
    ChangeSearch(value: any) {
      this.targets = [];
      if (value === '0') {
        this.targets.push('userName', 'gateName', 'logLabel', 'userId', 'deviceId');
      } else {
        this.targets.push('email', 'option1', 'option2');
      }
    },
    onClickOutsideCustomSearch() {
      if (this.showCustomSearch) {
        this.showCustomSearch = false;
      }
    },
    included() {
      return [document.querySelector('.included')];
    },
    search(event: any) {
      if (!this.disabled && event.keyCode === 13) {
        this.$emit('search', {
          text: this.text,
          targets: this.targets,
        });
      }
    },
  },
});
