






















































































































































import {Vue } from 'vue-property-decorator';
import GateListTemplate from '@/components/templates/GateListTemplate/GateListTemplate.vue';
import * as Config from '@/config';
import { SafieRelationGateDevice } from '@/types/alligate';
import store from '@/store';
import moment from 'moment';
export interface DataType {
  dataGates: any;
  dataGatesLock: any;
  perPage: number;
  perPageLock: number;
  currPage: number;
  keyword: string;
  scorp: any;
  gateTotalCount: number;
  loading: boolean;
  errorMessageLogs: string;
  successMessageLogs: string;
  gateId: string;
  rowinErrDialog: number;
  successItemProcess: number;
  progress: number;
  showProgress: boolean;
  showConfirm: boolean;
  isFinishProcess: boolean;
  isGetDataList: boolean;
  totalDataRowProcess: number;
  errorMessageProcess: any;
  userIds: any;
  gatePassword: string;


  // groupExpand
  gateExpanedGroup: Map<string, GroupGateUser>; // data Group of Gate
  isReRenderGroup: number;
  isRemoveGroupSelected: number;

  gateExpanedUser: Map<string, GroupGateUser>; // data User of Group
  isReRenderUser: number;
  isRemoveUserSelected: number;
  gateLockExpanedGroup: Map<string, GroupGateUser>; // data Group of Gate
  gateLockExpanedUser: Map<string, GroupGateUser>; // data Group of Gate

  typeAction: number;
  dataAction: any;
  selectedIdAction: string;
  successMessageModal: string;
  errorMessageModal: string;
  gateName: string;
  showModal: boolean;

  safieRelationsGateDevice: any;
  isGetDataListLock: boolean;
  currPageLock: number;
  keywordLock: string;
  gateTotalCountLock: number;
   // CSV
  showProgressExportCSV: boolean;
  errorMessageCsvXlsx: string;
  isExportGateInfoCSV: boolean;
  // Excel
  showModalImport: boolean;
  showProgressImport: boolean;
  progressCsvXlsx: number;
  isShowViewCSV: boolean;
  successItemImport: number;
  totalDataRowImport: number;
  percentProcess: number;
  errorMessageImport: any;
  isLoading: boolean;
  dataImport: any;
  sheetUserName: string;
  maxPagePcode: number;
  exportDataCSV: any;
  isFinishProcessEnable: boolean;
  userOfGate: any;
  isShowConfirmUser: boolean;
  showProgressUser: boolean;
  userTotalCountDel: number;
  totalDataRowProcessUser: number;
  progressUser: number;
  successItemProcessUser: number;
  errorMessageProcessUser: any;
  isFinishProcessUser: boolean;
  isShowConfirmGroup: boolean;
  showProgressGroup: boolean;
  groupTotalCountDel: number;
  totalDataRowProcessGroup: number;
  progressGroup: number;
  successItemProcessGroup: number;
  errorMessageProcessGroup: any;
  exportGateLockData: any;
  isFinishProcessGroup: boolean;
  percentGateLock: number;
  gateLockLoad: number;
  totalCSV: number;
  exeCountCSV: number;
  listGateGroupCube: any;
  btnUnlock: any;
  groupPassages: any;
  listPhoneCubes: any;
  errorMessageLogsPhone: string;
  successMessageLogsPhone: string;
  listPhones: any;
  listPhonesNoConnect: any;
  listPhoneRSSI: any;
  sequentialNo: string;
  currentGateId: string;
  lastLog: any;
  isFinishHandleCommand: boolean;
  showConfirmUpdateValidLock: boolean;
  actionUpdateValidLock: number;
  dataUpdateValid: any;
  showProgressUpdateValidLock: boolean;
  totalDataRowProcessUpdateValidLock: number;
  successItemProcessUpdateValidLock: number;
  progressUpdateValidLock: number;
  errorMessageProcessUpdateValidLock: any;
  isFinishProcessUpdateValidLock: boolean;
  isReflectionStatusListModalShow: boolean;
  isGateEditModalShow: boolean;
  isGateLockModalShow: boolean;
  dataRelationGate: any;
  expandData: any;
  authority: any;
}

export interface GroupGateUser {
  currPage?: number;
  currPageLock?: number;
  totalCount?: number;
  isGetData?: boolean;
  data?: any;
}

export default Vue.extend({
  name: 'GateListPage',
  components: {
    GateListTemplate,
  },
  props: {
    inPage: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
  },
  data: (): DataType => ({
    perPage: Config.PAGE_DEFAULT,
    perPageLock: Config.PAGE_DEFAULT,
    currPage: 1,
    scorp: ['gateId'],
    keyword: '',
    gateTotalCount: 0,
    dataGates: [],
    dataGatesLock: [],
    loading: false,
    errorMessageLogs: '',
    successMessageLogs: '',

    gateId: '',
    gateName: '',
    rowinErrDialog: 0,
    successItemProcess: 0,
    progress: 0,
    showProgress: false,
    isFinishProcess: false,
    showConfirm: false,
    isGetDataList: false,
    totalDataRowProcess: 0,
    errorMessageProcess: [],
    userIds: [],

    gateExpanedGroup: new Map(),
    gateLockExpanedGroup: new Map(),
    gateLockExpanedUser: new Map(),
    isReRenderGroup: 0,
    isRemoveGroupSelected: 0,

    gateExpanedUser: new Map(),
    isReRenderUser: 0,
    isRemoveUserSelected: 0,

    typeAction: 0,
    dataAction: {},
    selectedIdAction: '',
    successMessageModal: '',
    errorMessageModal: '',
    showModal: false,

    safieRelationsGateDevice: {},
    isGetDataListLock: false,
    currPageLock: 1,
    keywordLock: '',
    gateTotalCountLock: 0,
    showProgressExportCSV: false,
    errorMessageCsvXlsx: '',
    isExportGateInfoCSV: false,
    // Excel
    showModalImport: false,
    showProgressImport: false,
    progressCsvXlsx: 0,
    isShowViewCSV: false,
    successItemImport: 0,
    totalDataRowImport: 0,
    percentProcess: 0,
    errorMessageImport: [],
    isLoading: false,
    dataImport: [],
    sheetUserName: '',
    maxPagePcode: 0,
    exportDataCSV: [],
    isFinishProcessEnable: false,
    userOfGate: [],
    isShowConfirmUser: false,
    showProgressUser: false,
    userTotalCountDel: 0,
    totalDataRowProcessUser: 0,
    progressUser: 0,
    successItemProcessUser: 0,
    errorMessageProcessUser: [],
    isFinishProcessUser: false,
    isShowConfirmGroup: false,
    showProgressGroup: false,
    groupTotalCountDel: 0,
    totalDataRowProcessGroup: 0,
    progressGroup: 0,
    successItemProcessGroup: 0,
    errorMessageProcessGroup: [],
    exportGateLockData: [],
    isFinishProcessGroup: false,
    percentGateLock: 0,
    gateLockLoad: 0,
    totalCSV: 0,
    exeCountCSV: 0,
    gatePassword: '',
    listGateGroupCube: {},
    btnUnlock: [],
    groupPassages: [],
    listPhoneCubes: [],
    errorMessageLogsPhone: '',
    successMessageLogsPhone: '',
    listPhones: [],
    listPhonesNoConnect: [],
    listPhoneRSSI: [],
    sequentialNo: '',
    currentGateId: '',
    lastLog: [],
    isFinishHandleCommand: false,
    showConfirmUpdateValidLock: false,
    actionUpdateValidLock: 0,
    dataUpdateValid: {},
    showProgressUpdateValidLock: false,
    totalDataRowProcessUpdateValidLock: 0,
    successItemProcessUpdateValidLock: 0,
    progressUpdateValidLock: 0,
    errorMessageProcessUpdateValidLock: [],
    isFinishProcessUpdateValidLock: false,
    isReflectionStatusListModalShow: false,
    isGateEditModalShow: false,
    isGateLockModalShow: false,
    dataRelationGate: {
      totalDataRowProcess: 0,
      progress: 0,
      successItemProcess: '',
      errorMessageProcess: [],
      isFinishProcess: false,
    },
    expandData: {
      gateAll: {
          data: [],
          total: 0,
          loading: false,
          page: 1,
          perPage: 25,
        },
    },
    authority: store.state.auth,
  }),
  mounted() {
    this.$emit('activeMenu', 'gates');
  },
  watch: {
    loading(value) {
      console.log('来てる');
      this.$emit('loading', value);
    },
    gateLockLoad(newVal, oldVal) {
      if (newVal >= this.totalCSV) {
        this.downloadCSV();
        // this.reset();
        // this.success = true;
        this.isFinishProcess = true;
      } else if (newVal > 0) {
        this.getExportGateLockData(newVal + 1);
      }
    },
    exeCountCSV(newVal, oldVal) {
      console.log(this.totalDataRowImport);
      console.log(newVal);
      if (newVal > 0 && newVal < this.totalDataRowImport) {
        const dataCSV = {
          gateId: this.dataImport[newVal][0],
          gateName: this.dataImport[newVal][1],
          gateModelId: this.dataImport[newVal][2],
          gateModelName: this.dataImport[newVal][3],
          gateModelCode: this.dataImport[newVal][4],
          iconType: this.dataImport[newVal][5],
          makerCode: this.dataImport[newVal][6],
          customizable: this.dataImport[newVal][7],
          target: this.dataImport[newVal][8],
          controlTime: this.dataImport[newVal][9],
          timeUnit: this.dataImport[newVal][10],
          isVisitor: this.dataImport[newVal][11],
          isNFCValid: this.dataImport[newVal][12],
          isTenKeyValid: this.dataImport[newVal][13],
          isGatePasswordValid: this.dataImport[newVal][14],
          gatePasswordLength: this.dataImport[newVal][15],
          gatePasswordSeed: this.dataImport[newVal][16],
          isTwoStepAuthValid: this.dataImport[newVal][17],
          bleIntensity: this.dataImport[newVal][18],
          buzzerPattern: this.dataImport[newVal][19],
          option1: this.dataImport[newVal][20],
          option2: this.dataImport[newVal][21],
          isValid: this.dataImport[newVal][33],
          z: (newVal + 2),
          percent: this.percentProcess,
        };
        if (this.dataImport[newVal][0].length > 0) {
          this.editExe(dataCSV);
        } else {
          this.addExe(dataCSV);
        }
      } else if (newVal > 0 && newVal === this.totalDataRowImport) {
        this.isFinishProcess = true;
        this.exeCountCSV = 0;
        this.dataImport = [];
      }
    },
  },
  created() {
    if (typeof localStorage.gateSortKey === 'undefined') {
      localStorage.setItem('gateSortKey', 'registered');
    }
    if (typeof localStorage.gateSortName === 'undefined') {
      localStorage.setItem('gateSortName', 'desc');
    }
    if (typeof localStorage.pageSizeGate === 'undefined') {
      localStorage.setItem('pageSizeGate', Config.PAGE_DEFAULT.toString());
      this.perPage = Config.PAGE_DEFAULT;
    } else {
      this.perPage = parseInt(localStorage.pageSizeGate);
    }
    if (typeof localStorage.pageSizeGateLock === 'undefined') {
      localStorage.setItem('pageSizeGateLock', Config.PAGE_DEFAULT.toString());
      this.perPageLock = Config.PAGE_DEFAULT;
    } else {
      this.perPageLock = parseInt(localStorage.pageSizeGateLock);
    }
    localStorage.setItem('isVaildGate', '');
    this.getSafieRelationGateDevice(1);
    this.perPage = 25;
    if (this.$route.query.goBack !== undefined && this.$route.query.goBack === 'gate-lock') {
      this.getListGatesLock();
    } else {
      // this.getListGates();
      this.getRelationGateGroup(1);
    }
  },
  methods: {
    clickEditGateLock(value: any) {
      this.isGateLockModalShow = true;
      localStorage.setItem('gateId', value);
    },
    passwordGenerate(args: any) {
      this.loading = true;
      this.$http.get(Config.GATES_LIST_API + '/' +
      encodeURIComponent(encodeURIComponent(args.gateId)) + '/password?date=' + args.date + '&scorpId=' + args.scorpId)
        .then((response: any) => {
          this.gatePassword = response.body.gatePassword;
          this.loading = false;
        })
        .then( null, (err: any) => {
          this.loading = false;
          switch (err.status) {
            case 400:
              this.gatePassword = Config.ERROR_400;
              break;
            case 403:
              this.gatePassword = 'ゲートパスワードの取得権限がありません。';
              break;
            case 404:
              this.gatePassword = '該当するデーターがありません。';
              break;
            case 500:
              this.gatePassword = Config.ERROR_500;
              break;
            case 503:
              this.gatePassword = Config.ERROR_503;
              break;
            default:
              this.gatePassword = Config.ERROR_DEFAULT;
              break;
          }
        }); // catch
    },
    closePasswordGenerate() {
      this.gatePassword = '';
    },
    changePageUserLock(item: any): any {
      console.log(item);
      const gate: GroupGateUser = {
        isGetData: false,
        currPage: item.page.page,
        totalCount: 0,
        data: [],
      };
      this.gateLockExpanedUser = new Map();
      this.gateLockExpanedUser.set(item.gateId, gate);
      this.getUserOfGateLock(item.gateId, item.page.page);
    },
    changePageGroupLock(item: any): any {
      this.getGroupOfGate(item.gateId, item.page.page);
    },
    showDialogImport(isShowCSV: boolean) {
      this.showModalImport = true;
      this.isShowViewCSV = isShowCSV;
    },
    clickExportGateLockCSV() {
      this.showModalImport = false;
      this.errorMessageLogs = '';
      this.showProgressExportCSV = true;
      this.exportGateLockData = [];
      this.progressCsvXlsx = 0;
      this.gateLockLoad = 0;
      if (this.isExportGateInfoCSV) {
        this.getExportGateLockData(1);
      } else {
        this.progressCsvXlsx = 100;
        this.downloadCSV();
        this.isFinishProcess = true;
      }
    },
    clickCheckBoxExportGateInfoCSV() {
      this.isExportGateInfoCSV = !this.isExportGateInfoCSV;
    },
    closeModalProcessCSV() {
      this.showModalImport = false;
      this.errorMessageLogs = '';
      this.showProgressExportCSV = false;
      this.exportGateLockData = [];
      this.progressCsvXlsx = 0;
      this.gateLockLoad = 0;
    },
    closeModalProgressImport() {
      this.isLoading = false;
      this.progressCsvXlsx = 0;
      this.successItemImport = 0;
      this.isFinishProcess = false;
      this.totalDataRowImport = 0;
      this.errorMessageImport = [];
      this.rowinErrDialog = 0;
      this.showProgressImport = false;
      this.errorMessageCsvXlsx = '';
      this.dataImport = [];
      this.exportDataCSV = [];
      this.maxPagePcode = 1;
      this.showProgressExportCSV = false;
      this.getListGatesLock();
    },
    getExportGateLockData(page: any): any {
      // this.loading = true;
      const apiLink = Config.GATES_LIST_API + '?limit=25&page=' + page;
      this.$http.get(apiLink)
        .then( (response: any) => {
          this.totalCSV = Math.ceil(response.body.maxRecords / 25);
          this.percentGateLock = (100 / this.totalCSV);
          response.body.gates.forEach((item: any) => {
            this.exportGateLockData.push({
              ['ゲートID']: item.gateId,
              ['ゲート名']: item.gateName,
              ['製品ID']: item.gateModelId,
              ['製品名']: item.gateModelName,
              ['型番']: item.gateModelCode,
              ['アイコンタイプ']: item.iconType,
              ['メーカーコード']: item.makerCode,
              ['カスタム可否']: this.converToBit(item.customizable),
              ['ターゲット']: item.target,
              ['ターゲット動作設定Time']: item.controlTime,
              ['ターゲット動作設定TimeUnit']: item.timeUnit,
              ['ゲート動作モード']: this.converToBit(item.isVisitor),
              ['NFCの有効・無効(1:有効.0:無効)']: this.converToBit(item.isNFCValid),
              ['テンキーの有効・無効(1:有効.0:無効)']: this.converToBit(item.isTenKeyValid),
              ['ゲートパスワードの有効・無効(1:有効.0:無効)']: this.converToBit(item.isGatePasswordValid),
              ['ゲートパスワードの桁数']: item.gatePasswordLength,
              ['ゲートパスワードのseed値']: item.gatePasswordSeed,
              ['2段階認証の有効・無効']: this.converToBit(item.isTwoStepAuthValid),
              ['BLE出力設定']: item.bleIntensity,
              ['ブザー鳴動設定']: item.buzzerPattern,
              ['備考１']: item.option1.replace('\n', ''),
              ['備考２']: item.option2.replace('\n', ''),
              ['ゲート個体番号１（読み取り専用）']: item.gateSNMain,
              ['ゲート個体番号２（読み取り専用）']: item.gateSNBLE,
              ['適用されているファームウェアバージョン（メイン）（読み取り専用）']: item.gateFWMain,
              ['適用されているファームウェアバージョン（BLE）（読み取り専用）']: item.gateFWBLE,
              ['登録日（読み取り専用）']: this.converToDate(item.registered),
              ['更新日（読み取り専用）']: this.converToDate(item.updated),
              ['ゲートへ設定情報を適用した日（読み取り専用）']: this.converToDate(item.reported),
              ['個人コード照合データー：最新リビジョン（読み取り専用）']: item.revisionDesired,
              ['個人コード照合データー：設定済みリビジョン（読み取り専用）']: item.revisionReported,
              ['電池電圧（読み取り専用）']: item.voltage,
              ['電池電圧更新日（読み取り専用）']: this.converToDate(item.scanDate),
              ['ステータスの有効・無効(1:有効.0:無効)']: this.converToBit(item.isValid),
              ['ゲートへの設定反映状況（読み取り専用）']: item.status,
            });
          });
          this.progressCsvXlsx += this.percentGateLock;
          this.gateLockLoad++;
        })
        .then( null, (err: any) => {
          // this.loading = false;
          this.resetData();
        }); // catch
    },
    closeImportCSV() {
      this.showModalImport = false;
    },
    resetAllValue() {
      // value of import excel and CSV
      this.isLoading = false;
      this.progressCsvXlsx = 0;
      this.successItemImport = 0;
      this.isFinishProcess = false;
      this.totalDataRowImport = 0;
      this.errorMessageImport = [];
      this.rowinErrDialog = 0;
      this.showProgressImport = false;
      this.errorMessageCsvXlsx = '';
      this.dataImport = [];
      this.exportDataCSV = [];
      this.maxPagePcode = 1;
      this.showProgressExportCSV = false;
    },
    clickImportCSV(files: any) {
      this.resetAllValue();
      this.isLoading = true;
      setTimeout(() => {
        this.importCSV(files);
      }, 200);
    },

    importCSV(data: any): void {
      const files = data.files;
      if (files !== null && files !== undefined && files.length !== 0) {
        const reader = new FileReader();
        reader.onload = (): void => {
          const read =  reader.result as string;
          const lineArr = read.split('\n');
          const itemArr = [];
          this.isLoading = false;
          this.showProgressImport = true;
          for (let i = 0; i < lineArr.length; i++) {
            itemArr[i] = lineArr[i].split(',');
          }
          if (itemArr.length > 1) {
            for (let k = 1; k < itemArr.length; k++) {
              if (itemArr[k].length === 35 && itemArr[k][1].length > 0) {
                this.dataImport.push(itemArr[k]);
              }
            }
            if (this.dataImport.length > 0) {
              this.totalDataRowImport = this.dataImport.length;
              this.percentProcess = (100 / (this.totalDataRowImport));
              // Send data
              const dataCSV = {
                gateId: this.dataImport[0][0],
                gateName: this.dataImport[0][1],
                gateModelId: this.dataImport[0][2],
                gateModelName: this.dataImport[0][3],
                gateModelCode: this.dataImport[0][4],
                iconType: this.dataImport[0][5],
                makerCode: this.dataImport[0][6],
                customizable: this.dataImport[0][7],
                target: this.dataImport[0][8],
                controlTime: this.dataImport[0][9],
                timeUnit: this.dataImport[0][10],
                isVisitor: this.dataImport[0][11],
                isNFCValid: this.dataImport[0][12],
                isTenKeyValid: this.dataImport[0][13],
                isGatePasswordValid: this.dataImport[0][14],
                gatePasswordLength: this.dataImport[0][15],
                gatePasswordSeed: this.dataImport[0][16],
                isTwoStepAuthValid: this.dataImport[0][17],
                bleIntensity: this.dataImport[0][18],
                buzzerPattern: this.dataImport[0][19],
                option1: this.dataImport[0][20],
                option2: this.dataImport[0][21],
                isValid: this.dataImport[0][33],
                z: (0 + 2),
                percent: this.percentProcess,
              };
              if (this.dataImport[0][0].length > 0) {
                this.editExe(dataCSV);
              } else {
                this.addExe(dataCSV);
              }
            } else {
              this.isFinishProcess = true;
              this.errorMessageCsvXlsx = '不正なファイルです。正しいファイルを選択しなおしてください。';
            }
          } else {
            this.isFinishProcess = true;
            this.errorMessageCsvXlsx = '不正なファイルです。正しいファイルを選択しなおしてください。';
          }
        };
        reader.readAsText(files, 'Shift_JIS');
      } else {
        this.isLoading = false;
        this.showProgressImport = true;
        this.errorMessageCsvXlsx = 'CSVのファイルを選択してください。';
        this.isFinishProcess = true;
      }
    },
    editExe(item: any) {
      let Infor: any = {};
      Infor = {
        gateName: item.gateName,
        gateModelId: item.gateModelId,
        gateModelName: item.gateModelName,
        gateModelCode: item.gateModelCode,
        iconType: parseInt(item.iconType),
        makerCode: parseInt(item.makerCode),
        customizable: this.convertStringToBoolean(item.customizable),
        target: parseInt(item.target),
        controlTime: parseInt(item.controlTime),
        timeUnit: parseInt(item.timeUnit),
        isVisitor: this.convertStringToBoolean(item.isVisitor),
        isNFCValid: this.convertStringToBoolean(item.isNFCValid),
        isTenKeyValid: this.convertStringToBoolean(item.isTenKeyValid),
        isGatePasswordValid: this.convertStringToBoolean(item.isGatePasswordValid),
        isTwoStepAuthValid: this.convertStringToBoolean(item.isTwoStepAuthValid),
        bleIntensity: parseInt(item.bleIntensity),
        buzzerPattern: parseInt(item.buzzerPattern),
        option1: item.option1,
        option2: item.option2,
        isValid: this.convertStringToBoolean(item.isValid),
      };
      if (Infor.isGatePasswordValid) {
        Infor.gatePasswordLength = parseInt(item.gatePasswordLength);
        if (item.gatePasswordSeed.length > 0) {
          Infor.gatePasswordSeed = item.gatePasswordSeed;
        }
      }
      this.$http.put(Config.GATES_LIST_API + '/' + encodeURIComponent(encodeURIComponent(item.gateId)), Infor)
        .then((response: any) => {
          this.progressCsvXlsx += item.percent;
          this.successItemImport++;
          this.exeCountCSV++;
        })
        .then( null, (error: any) => {
          let errorName = '';
          switch (error.status) {
            case 401:
              if (error.body.message === 'The incoming token has expired') {
                errorName = '';
              } else {
                errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
              }
              break;
            case 400:
              errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
              break;
            case 403:
              errorName = 'ゲートの編集権限がありません。';
              break;
            case 404:
              errorName = '該当するデーターがありません。';
              break;
            case 409:
              errorName = '入力されたゲート名は既に使用されています。他のゲート名を入力してください。';
              break;
            case 500:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
            case 503:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
            default:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
          }
          const rowstatus = item.z + ' 行目>>' + item.gateId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageImport, item.z, rowstatus);
          this.progressCsvXlsx += item.percent;
          this.exeCountCSV++;
        });
    },
    addExe(item: any) {
      let Infor: any = {};
      Infor = {
        gateName: item.gateName,
        gateModelId: item.gateModelId,
        gateModelName: item.gateModelName,
        gateModelCode: item.gateModelCode,
        iconType: parseInt(item.iconType),
        makerCode: parseInt(item.makerCode),
        customizable: this.convertStringToBoolean(item.customizable),
        target: parseInt(item.target),
        controlTime: parseInt(item.controlTime),
        timeUnit: parseInt(item.timeUnit),
        isVisitor: this.convertStringToBoolean(item.isVisitor),
        isNFCValid: this.convertStringToBoolean(item.isNFCValid),
        isTenKeyValid: this.convertStringToBoolean(item.isTenKeyValid),
        isGatePasswordValid: this.convertStringToBoolean(item.isGatePasswordValid),
        isTwoStepAuthValid: this.convertStringToBoolean(item.isTwoStepAuthValid),
        bleIntensity: parseInt(item.bleIntensity),
        buzzerPattern: parseInt(item.buzzerPattern),
        option1: item.option1,
        option2: item.option2,
        isValid: this.convertStringToBoolean(item.isValid),
      };
      if (Infor.isGatePasswordValid) {
        Infor.gatePasswordLength = parseInt(item.gatePasswordLength);
        if (item.gatePasswordSeed.length > 0) {
          Infor.gatePasswordSeed = item.gatePasswordSeed;
        }
      }
      this.$http.post(Config.GATES_LIST_API, Infor)
        .then((response: any) => {
          this.progressCsvXlsx += item.percent;
          this.successItemImport++;
          this.exeCountCSV++;
        })
        .then( null, (error: any) => {
          let errorName = '';
          switch (error.status) {
            case 401:
              if (error.body.message === 'The incoming token has expired') {
                errorName = '';
              } else {
                errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
              }
              break;
            case 400:
              errorName = '入力したパラメーターが誤っています。入力内容をご確認ください。';
              break;
            case 403:
              errorName = 'ゲートの編集権限がありません。';
              break;
            case 404:
              errorName = '該当するデーターがありません。';
              break;
            case 409:
              errorName = '入力されたゲート名は既に使用されています。他のゲート名を入力してください。';
              break;
            case 500:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
            case 503:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
            default:
              errorName = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
              break;
          }
          const rowstatus = item.z + ' 行目>>' + item.gateName + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageImport, item.z, rowstatus);
          this.progressCsvXlsx += item.percent;
          this.exeCountCSV++;
        });
    },
    ShowOnlyIsVaild(data: any) {
      localStorage.setItem('isVaildDataGate', data.toString());
      this.getListGatesLock();
    },
    changeTab(val: any) {
      console.log('AAA');
      this.dataGates = [];
      this.dataGatesLock = [];
      this.errorMessageLogs = '';
      if (val === 1) {
        this.getListGatesLock();
      } else {
        this.getListGates();
      }
    },
    clickUnClock(data: any) {
      this.gateId = data.gateId;
      this.gateName = data.gateName;
      this.showModal = true;
      this.successMessageModal = '';
      this.errorMessageModal = '';
    } ,
    closeModalUnClock() {
      this.showModal = false;
    } ,
    changeExpandedGate(event: any) {
      if  (event.value) {
        const data = event.item;

        this.gateExpanedGroup = new Map();
        const gate: GroupGateUser = {
          isGetData: false,
          currPage: 1,
          totalCount: 0,
          data: [],
        };
        this.gateExpanedGroup.set(data.gateId, gate);
        this.getGroupOfGate(data.gateId, 1);
      }
    },
    changeExpandedGateLock(event: any) {
      if  (event.value) {
        const data = event.item;

        this.gateExpanedGroup = new Map();
        const gate: GroupGateUser = {
          isGetData: false,
          currPage: 1,
          totalCount: 0,
          data: [],
        };
        this.gateExpanedGroup.set(data.gateId, gate);
        this.getGroupOfGate(data.gateId, 1);
      }
    },

    getUserOfGateLock(gateId: string, page: number) {
      const user = this.gateLockExpanedUser.get(gateId);
      console.log(gateId);
      if (user !== undefined) {
        user.isGetData = true;
        user.currPage = page;
        this.reRenderUser();
        this.userOfGate = [];
        this.$http.get(Config.GATES_PASSAGE_API + '?gateId=' +
        encodeURIComponent(gateId) + '&limit=25&page=' + page + '&isExceptOptionalGate=true')
          .then(async (response: any) => {
            if (response.body.maxRecords > 0 && !this.isEmpty(response.body.passages)) {
              const dt = response.body.passages;
              user.totalCount = response.body.maxRecords;
              user.currPage = Math.ceil(response.body.startRecord / 25);
              let check;
              for (let i = 0; i < dt.length; i++) {
                this.userOfGate.push(dt[i]);
                check = await this.getUserItemLock(dt[i].userId, i);
                if (check === 3) {
                  break;
                }
              }
            } else {
              user.totalCount = 0;
              user.currPage = 1;
            }
            user.data = this.userOfGate;
            user.isGetData = false;
            this.reRenderUser();
            this.removeUserSelected();
          })
          .then( null, (err: any) => {
            user.isGetData = false;
            this.reRenderUser();
            this.removeUserSelected();
          }); // catch
      }
    },
    getUserItemLock(userId: any, i: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
          .then((response: any) => {
            if (this.userOfGate[i] === undefined) {
              resolve(3);
            } else {
              this.userOfGate[i].user = response.body.users[0];
              resolve(1);
            }
          })
          .then(null, (error: any) => {
            console.log('error: ', error);
            resolve(2);
          });
      });
      },
    getGroupOfGateLock(gateId: string, page: number) {
      const data = this.gateLockExpanedGroup.get(gateId);
      if (data !== undefined) {
        data.isGetData = true;
        data.currPage = page;
        this.reRenderGroup();
        const apiLink = Config.RELATION_GATE_GROUP +
          '?gateId=' + encodeURIComponent(gateId) + '&limit=25&page=' + page + '&sortKey=desc:groupId&embed=group&isExceptOptionalGate=true';
        this.$http.get(apiLink)
          .then((response: any) => {
            if (response.body.maxRecords > 0 && !this.isEmpty(response.body.relationsGateGroup)) {
              data.totalCount = response.body.maxRecords;
              data.currPage = Math.ceil(response.body.startRecord / 25);
              data.data = response.body.relationsGateGroup;
            } else {
              data.totalCount = 0;
              data.currPage = 1;
            }
            data.isGetData = false;
            this.reRenderGroup();
            this.removeGroupSelected();
          })
          .then( null, (err: any) => {
            data.isGetData = false;
            this.reRenderGroup();
            this.removeGroupSelected();
          }); // catch
      }
    },
    getGroupOfGate(gateId: string, page: number) {
      const data = this.gateExpanedGroup.get(gateId);
      if (data !== undefined) {
        data.isGetData = true;
        data.currPage = page;
        this.reRenderGroup();
        const apiLink = Config.RELATION_GATE_GROUP +
          '?gateId=' + encodeURIComponent(gateId) + '&limit=25&page=' + page + '&sortKey=desc:groupId&embed=group&isExceptOptionalGate=true';
        this.$http.get(apiLink)
          .then((response: any) => {
            if (response.body.maxRecords > 0 && !this.isEmpty(response.body.relationsGateGroup)) {
              data.totalCount = response.body.maxRecords;
              data.currPage = Math.ceil(response.body.startRecord / 25);
              data.data = response.body.relationsGateGroup;
              data.data.forEach((element: any) => {
                element.group.userCount = 0;
                this.$http.get(Config.GROUP_PASSAGE + '?groupId=' + encodeURIComponent(element.group.groupId) + '&limit=25&page=1')
                  .then((res: any) => {
                    element.group.userCount = res.body.maxRecords;
                  });
              });
            } else {
              data.totalCount = 0;
              data.currPage = 1;
            }
            data.isGetData = false;
            this.reRenderGroup();
            this.removeGroupSelected();
          })
          .then( null, (err: any) => {
            data.isGetData = false;
            this.reRenderGroup();
            this.removeGroupSelected();
          }); // catch
      }
    },
    changePageGroup(event: any) {
      this.getGroupOfGate(event.gateId, event.page);
    },
    reRenderGroup() {
      if (this.isReRenderGroup < 100) {
          this.isReRenderGroup++;
        } else {
          this.isReRenderGroup = 0;
        }
    },
    removeGroupSelected() {
      if (this.isRemoveGroupSelected < 100) {
        this.isRemoveGroupSelected++;
      } else {
        this.isRemoveGroupSelected = 0;
      }
    },


    changeExpandedGroup(event: any) {
      if  (event.value) {
        const data = event.item;
        this.gateExpanedUser = new Map();
        const gate: GroupGateUser = {
          isGetData: false,
          currPage: 1,
          totalCount: 0,
          data: [],
        };
        this.gateExpanedUser.set(data.group.groupId, gate);
        this.getUserOfGroup(data.group.groupId, 1);
      }
    },

    changeExpandedGroupLock(event: any) {
      if (event.value) {
        const data = event.item;
        this.gateExpanedUser = new Map();
        const gate: GroupGateUser = {
          isGetData: false,
          currPage: 1,
          totalCount: 0,
          data: [],
        };
        this.gateExpanedUser.set(data.group.groupId, gate);
        this.getUserOfGroup(data.group.groupId, 1);
      }
    },

    changePageUser(event: any) {
      this.getUserOfGroup(event.groupId, event.page);
    },

    getUserOfGroup(groupId: string, page: number) {
      const data = this.gateExpanedUser.get(groupId);
      if (data !== undefined) {
        data.isGetData = true;
        data.currPage = page;
        this.reRenderUser();
        const apiLink = Config.GROUP_PASSAGE +
          '?groupId=' + encodeURIComponent(groupId) + '&limit=25&page=' + page + '&sortKey=desc:userId&embed=user';
        this.$http.get(apiLink)
          .then((response: any) => {
            if (response.body.maxRecords > 0 && !this.isEmpty(response.body.groupPassages)) {
              data.totalCount = response.body.maxRecords;
              data.currPage = Math.ceil(response.body.startRecord / 25);
              data.data = response.body.groupPassages;
            } else {
              data.totalCount = 0;
              data.currPage = 1;
            }
            data.isGetData = false;
            this.reRenderUser();
            this.removeUserSelected();
          })
          .then( null, (err: any) => {
            data.isGetData = false;
            this.reRenderUser();
            this.removeUserSelected();
          }); // catch
      }
    },
    reRenderUser() {
      if (this.isReRenderUser < 100) {
          this.isReRenderUser++;
        } else {
          this.isReRenderUser = 0;
        }
    },
    removeUserSelected() {
      if (this.isRemoveUserSelected < 100) {
        this.isRemoveUserSelected++;
      } else {
        this.isRemoveUserSelected = 0;
      }
    },

    closeModalProgress(value: string) {
      this.showProgress = false;
      if (this.successItemProcess > 0) {
        if (this.typeAction === 0) {
          // group
          this.removeGroupSelected();
          this.gateExpanedGroup = new Map();
          const gate: GroupGateUser = {
            isGetData: false,
            currPage: 1,
            totalCount: 0,
            data: [],
          };
          this.gateExpanedGroup.set(this.selectedIdAction, gate);
          this.getGroupOfGate(this.selectedIdAction, 1);
        } else if (this.typeAction === 1) {
          // user
          this.removeUserSelected();
          this.gateExpanedUser = new Map();
          const gate: GroupGateUser = {
            isGetData: false,
            currPage: 1,
            totalCount: 0,
            data: [],
          };
          this.gateExpanedUser.set(this.selectedIdAction, gate);
          this.getUserOfGroup(this.selectedIdAction, 1);
        }
        this.getListGates();
      }
    },
    goReflectionPage(value: string) {
      localStorage.setItem('controllerId', value);
      this.isReflectionStatusListModalShow = true;
    },
    ChangeProperty(value: string) {
      this.isReflectionStatusListModalShow = false;
      this.isGateEditModalShow = false;
      this.isGateLockModalShow = false;
    },
    goLogPage(value: string) {
      console.log('value', value);
      localStorage.setItem('gateName', value);
      this.$router.push({ path: Config.LOG, query: {tabId: 'gate'}});
    },
    clickRemoteUnlock() {
      this.successMessageModal = '';
      this.errorMessageModal = '';
      this.loading = true;
      this.remoteUnlock(this.gateId);
    },
    clickAddGate() {
      this.$router.push({ path: 'gate/add'});
    },
    clickEditGate(value: any) {
      // this.$router.push({ path: 'gate/edit', query: {gateId: value}});
      this.isGateEditModalShow = true;
      localStorage.setItem('gateId', value.gateId);
      localStorage.setItem('isEditFlag', 'true');
    },
    changePerPage(value: any): any {
      this.perPage = value;
      localStorage.setItem('pageSizeGate', value);
      this.dataGates = [];
      this.getRelationGateGroup(this.currPage);
    },
    changePerPageLock(value: any): any {
      this.perPageLock = value;
      localStorage.setItem('pageSizeGateLock', value);
      this.getListGatesLock();
    },
    changePage(value: any): any {
      this.currPage = value;
      this.dataGates = [];
      this.getRelationGateGroup(value);
    },
    changePageLock(value: any): any {
      this.currPageLock = value;
      this.getListGatesLock();
    },
    searchGate(value: any): any {
      console.log(value);
      this.scorp = [];
      this.keyword = value.text;
      this.scorp = value.targets;
      if (this.groupId === 'no-id') {
        this.getRelationGateAll(1);
      } else {
        this.getRelationGateGroup(1);
      }
    },
    searchGateLock(value: any): any {
      this.scorp = [];
      console.log(value);
      this.keywordLock = value.text;
      this.scorp = value.targets;
      this.getListGatesLock();
    },
    getExpandedUser(gateId: any) {
      // DataTest
      // this.maxUser = 10;
      // this.ExpandedUser[gateId] = {
      //   maxRecords: 10,
      //   passages: [
      //     {
      //       passageId: '1B2ED4C3-0B39-4DBF-8016-2F6C9B9B4C2B',
      //       notBefore: 1531148400,
      //       notAfter: 1531393200,
      //       gateId: 'zpsHmwSwxfSw',
      //       userId: 'xcvxcvx',
      //     },
      //     {
      //       passageId: '36600E8C-F668-4A23-AA8C-BC854CC805E2',
      //       notBefore: 1531148400,
      //       notAfter: 1531393200,
      //       gateId: 'A7f/lfZLUFj7',
      //       userId: 'xcvxcvx',
      //     },
      //   ],
      // };
      // this.$http.get(Config.GATES_PASSAGE_API + '?gateId=' +
      // encodeURIComponent(gateId) + '&limit=25&page=' + this.pageUser + '&embed=group')
      //   .then((response: any) => {
      //     console.log(response);
      //     this.maxUser = response.body.maxRecords;
      //     if (response.body.maxRecords > 0) {
      //       this.userTotalCount = response.body.maxRecords;
      //       const res = response.body;
      //       this.ExpandedUser[gateId] = {
      //         maxRecords: res.maxRecords,
      //         groups: res.passages,
      //       };
      //       this.userIds = '';
      //       this.gateId = '';
      //     } else {
      //       this.ExpandedUser = {};
      //     }
      //   })
      //   .then( null, (err: any) => {
      //     this.loadingUser = false;
      //     console.log(err);
      //   });
    },
    clickConfirm(data: any) {
      this.typeAction = data.type;
      this.dataAction = data.data;
      if (data.selectedId !== undefined) {
        this.selectedIdAction = data.selectedId;
      }
      this.showConfirm = true;
      // this.gateId =  data.gateId;
      // this.userIds = data.userIds;
      // this.rowinErrDialog = 0;
      // this.successItemProcess = 0;
      // this.progress = 0;
      // this.showProgress = false;
      // this.totalDataRowProcess = 0;
      // this.showConfirm = true;
    },
    closeDialogConfirm() {
      this.showConfirm = false;
    },

    async clickGateAction() {
      this.showConfirm = false;
      this.showProgress = true;
      this.rowinErrDialog = 0;
      this.errorMessageProcess = [];
      this.successItemProcess = 0;
      this.progress = 0;
      this.isFinishProcess = false;

      this.totalDataRowProcess = this.dataAction.length;
      const percent = (100 / this.totalDataRowProcess);
      console.log(this.dataAction);
      for (const item of this.dataAction) {
        let result;
        if (this.typeAction === 0) {
          //  remove Group
          result = await this.removeGateFromGroup(item.relationId, item.gateId);
        } else if (this.typeAction === 1) {
          //  remove user
          result = await this.removeUserFromGroup(item.groupPassageId, item.user.userId);
        }
        if (result) {
          this.successItemProcess++;
        }
        this.progress += percent;
      }
      this.isFinishProcess = true;
    },
    removeUserFromGroup(groupPassageId: string, userId: string) {
      return new Promise((resolve) => {
        this.$http.delete(Config.GROUP_PASSAGE +
          '/' + encodeURIComponent(groupPassageId))
        .then((response: any) => {
          resolve(true);
        })
        .then( null, (err: any) => {
          let errorName = '';
          switch (err.status) {
            case 400:
              errorName = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                errorName = Config.ERROR_403;
              } else {
                errorName = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              errorName = 'groupPassageIdが存在しない。';
              break;
            case 500:
              errorName = Config.ERROR_500;
              break;
            case 503:
              errorName = Config.ERROR_503;
              break;
            default:
              errorName = Config.ERROR_DEFAULT;
              break;
          }
          const rowstatus = userId + '>><span>' + errorName + '</span>';
          this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
          resolve(false);
        }); // catch
      });
    },


    async clickLeaveUser() {
      const userIds = this.userIds;
      this.gateId =  this.gateId;
      this.rowinErrDialog = 0;
      this.successItemProcess = 0;
      this.progress = 0;
      this.showProgress = true;
      this.totalDataRowProcess = userIds.length;
      this.showConfirm = false;
      const percent = (100 / this.totalDataRowProcess);
      for (const item of userIds) {
        let result;
        result  = await this.deleteGatePassage(item);
        if (result) {
          this.successItemProcess++;
        }
        this.progress += percent;
      }
      this.isFinishProcess = true;
    },
    removeGateFromGroup(passageId: string, gateId: any) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.RELATION_GATE_GROUP + '/' + encodeURIComponent(passageId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = passageId + '>>' + Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = gateId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    deleteGatePassage(passageId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.GATES_PASSAGE_API + '/' + encodeURIComponent(passageId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = passageId + '>>' + Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = passageId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcess, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    getListGates(): any {
      this.isGetDataList = true;
      // this.loading = true;
      let apiLink = Config.RELATION_CONTROLLER_GATE + '?limit=' + localStorage.pageSizeGate +
      '&page=' + this.currPage + '&embed=gate,controller';
      if (this.keyword !== '') {
        apiLink += '&keyword=' + this.keyword + '&scorp=' + this.scorp;
      }
      this.$http.get(apiLink)
        .then((response: any) => {
          this.resetData();
          this.isGetDataList = false;
          if (!this.isEmpty(response.body.relationsControllerGate)) {
            this.dataGates = response.body.relationsControllerGate;
            this.gateTotalCount = response.body.maxRecords;
            this.currPage = Math.ceil(response.body.startRecord / this.perPage);
          } else {
            this.gateTotalCount = 0;
            this.currPage = 1;
          }
        })
        .then( null, (err: any) => {
          // this.loading = false;
          this.isGetDataList = false;
          this.gateTotalCount = 0;
          this.currPage = 1;
          this.resetData();
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        }); // catch
    },
    getSafieRelationGateDevice(page: number): Promise<void> {
      return new Promise((resolve, reject) => {
        const limit = 25;
        const apiLink = `${Config.SAFIE_RELATIONS_GATE_API}?limit=${limit}`
          + `&page=${page}&sortKey=ASC:deviceId`;
        this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.relationsGateDevice) {
            response.body.relationsGateDevice.forEach((gateDevice: SafieRelationGateDevice, index: number) => {
              if (typeof this.safieRelationsGateDevice[gateDevice.gateId] === 'undefined') {
                this.safieRelationsGateDevice[gateDevice.gateId] = [];
              }
              // gateIdで引き出しやすいように最初から整形して格納
              this.safieRelationsGateDevice[gateDevice.gateId].push({
                deviceId: gateDevice.deviceId,
                notes: gateDevice.notes,
              });
            });
            if (response.body.endRecord !== response.body.maxRecords) {
              this.getSafieRelationGateDevice(page + 1).then(resolve).catch(resolve);
            } else {
              resolve();
            }
          }
        })
        .then( null, (err: any) => {
          reject(err);
        }); // catch
      });
    },
    getListGatesLock(): any {
      this.resetData();
      this.isGetDataListLock = true;
      // this.loading = true;
      let apiLink = Config.GATES_LIST_API + '?limit=' + localStorage.pageSizeGateLock +
      '&page=' + this.currPageLock + '&isExceptOptionalGate=true&isExceptProGate=true';
      if (this.keywordLock !== '') {
        apiLink = Config.GATES_LIST_API + '?limit=' + localStorage.pageSizeGateLock +
        '&page=' + this.currPageLock + '&keyword=' + this.keywordLock + '&scorp=' + this.scorp + '&isExceptOptionalGate=true&isExceptProGate=true';
      }
      if (localStorage.isVaildDataGate === 'true') {
        apiLink += '&isValid=true';
      }
      this.$http.get(apiLink)
        .then( (response: any) => {
          this.isGetDataListLock = false;
          this.dataGatesLock = response.body.gates;
          if (!this.isEmpty(this.dataGatesLock)) {
            this.gateTotalCountLock = response.body.maxRecords;
            this.currPageLock = Math.ceil(response.body.startRecord / this.perPageLock);
            for (let i = 0; i < this.dataGatesLock.length; i++) {
              this.dataGatesLock[i].groupCount = 0;
              this.dataGatesLock[i].userCount = 0;
              this.getCountGroupItem(this.dataGatesLock[i].gateId, i);
            }
          } else {
            this.gateTotalCountLock = 0;
            this.currPageLock = 1;
          }
        })
        .then( null, (err: any) => {
          // this.loading = false;
          this.isGetDataListLock = false;
          this.gateTotalCountLock = 0;
          this.currPageLock = 1;
          this.resetData();
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        }); // catch
    },
    getCountUserItem(groupId: any, i: any) {
        return new Promise((resolve, reject) => {
          this.$http.get(Config.GROUPS_PASSAGE_API + '?groupId=' + encodeURIComponent(groupId) + '&limit=25&isExceptOptionalGate=true')
            .then((response: any) => {
              this.dataGatesLock[i].userCount += response.body.maxRecords;
              resolve(true);
            })
            .then(null, (error: any) => {
              resolve(false);
            });
        });
    },
    getCountGroupItem(gateId: any, i: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.RELATION_GATE_GROUP + '?gateId=' +
        encodeURIComponent(gateId) + '&isExceptOptionalGate=true')
          .then((response: any) => {
            this.dataGatesLock[i].groupCount = response.body.maxRecords;
            this.$set(this.listGateGroupCube, gateId, response.body.relationsGateGroup);
            if (this.listGateGroupCube[gateId].length > 0) {
              if (this.btnUnlock.includes(gateId) === false) {
                this.btnUnlock.push(gateId);
              }
            } else {
              this.listGateGroupCube[gateId] = [];
            }
            for (const group of response.body.relationsGateGroup) {
              this.getCountUserItem(group.groupId, i);
            }

            resolve(true);
          })
          .then( null, (err: any) => {
            this.listGateGroupCube[gateId] = [];
            this.dataGatesLock[i].groupCount = 0;
            resolve(false);
            //
          }); // catch
      });
      },
    closeModalProgressUserLock(item: any) {
      console.log('item', item);
      this.showProgressUser = false;
      this.rowinErrDialog = 0;
      this.errorMessageProcessUser = [];
      this.successItemProcessUser = 0;
      this.progressUser = 0;
      this.isFinishProcessUser = false;
      this.userOfGate = [];
      this.gateLockExpanedUser = new Map();
      const gate: GroupGateUser = {
        isGetData: false,
        currPage: 1,
        totalCount: 0,
        data: [],
      };
      const gateUser: GroupGateUser = {
        isGetData: false,
        currPage: 1,
        totalCount: 0,
        data: [],
      };
      this.gateLockExpanedUser.set(item.gateId, gateUser);
      this.getUserOfGateLock(item.gateId, 1);
      // this.getUserOfGate(1, this.$route.query.gateId);'
    },
    closeModalProgressGroupLock(item: any) {
      console.log(item);
      this.showProgressGroup = false;
      this.rowinErrDialog = 0;
      this.errorMessageProcessGroup = [];
      this.successItemProcessGroup = 0;
      this.progressGroup = 0;
      this.isFinishProcessGroup = false;
      this.gateLockExpanedGroup = new Map();
      const gate: GroupGateUser = {
        isGetData: false,
        currPage: 1,
        totalCount: 0,
        data: [],
      };
      const gateUser: GroupGateUser = {
        isGetData: false,
        currPage: 1,
        totalCount: 0,
        data: [],
      };
      this.gateLockExpanedGroup.set(item.gateId, gateUser);
      this.getGroupOfGateLock(item.gateId, 1);
      // this.getUserOfGate(1, this.$route.query.gateId);'
    },
    async clickRemoveUserLock(data: any) {
      this.showProgressUser = true;
      this.rowinErrDialog = 0;
      this.errorMessageProcessUser = [];
      this.successItemProcessUser = 0;
      this.progressUser = 0;
      this.isFinishProcessUser = false;

      this.totalDataRowProcessUser = data.length;
      const percent = (100 / this.totalDataRowProcessUser);
      for (const item of data) {
        let result;
        result = await this.deleteGatePassageLock(item.passageId);
        if (result) {
          this.successItemProcessUser++;
        }
        this.progressUser += percent;
      }
      this.isFinishProcessUser = true;
    },
    deleteGatePassageLock(passageId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.GATES_PASSAGE_API + '/' + encodeURIComponent(passageId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = passageId + '>>' + Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = passageId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcessUser, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    async clickRemoveGroupLock(data: any) {
      this.showProgressGroup = true;
      this.rowinErrDialog = 0;
      this.errorMessageProcessGroup = [];
      this.successItemProcessGroup = 0;
      this.progressGroup = 0;
      this.isFinishProcessGroup = false;

      this.totalDataRowProcessGroup = data.length;
      const percent = (100 / this.totalDataRowProcessGroup);
      for (const item of data) {
        let result;
        result = await this.deleteRelationGateGroup(item.relationId);
        if (result) {
          this.successItemProcessGroup++;
        }
        this.progressGroup += percent;
      }
      this.isFinishProcessGroup = true;
    },
    deleteRelationGateGroup(relationId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.RELATION_GATE_GROUP_API + '/' + encodeURIComponent(relationId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = relationId + '>>' + Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = relationId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcessGroup, ++this.rowinErrDialog, rowstatus);
            resolve(false);
          }); // catch
      });
    },
    remoteUnlock(gateId: any) {
      this.$http.post(Config.GATES_LIST_API + '/' + encodeURIComponent(gateId) + '/remote-unlock')
        .then((response: any) => {
          if (response.body.message === 'Success') {
            this.successMessageModal = 'ゲートへリモート解錠要求を行いました。';
            this.loading = false;
          }
          this.loading = false;
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          switch (err.status) {
            case 400:
              this.errorMessageModal = 'リモート解錠要求に失敗しました。再度やり直してください。';
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageModal = Config.ERROR_403;
              } else {
                this.errorMessageModal = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageModal = 'リモート解錠要求に失敗しました。以下の原因の可能性があります。<br> ・ログインユーザーが組織管理権限を持っていない場合 <br> ・ログインユーザーがこのゲートに紐づくグループに登録されていない場合 <br>・ログインユーザーが個人コードを持っていない場合';
              break;
            case 500:
              this.errorMessageModal = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageModal = Config.ERROR_503;
              break;
            default:
              this.errorMessageModal = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
        }); // catch
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    resetData() {
      this.dataGates = [];
      this.dataGatesLock = [];
      this.errorMessageLogs = '';
      // this.gateTotalCount = 0;
    },
    converToBit(text: any) {
      let str = text.toString();
      if (str !== null && str !== '') {
        str = str.trim().toLowerCase();
      } else {
        str = 'false';
      }
      switch (str) {
        case 'true':
          return 1;
        case 'false':
          return 0;
        default:
          return 1;
      }
    },
    converToDate(t: any) {
      if (t > 0) {
        return moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm:ss');
      } else {
        return 'ー';
      }
    },
    downloadCSV() {
      const saveAs = require('file-saver');
      const exportData = this.exportGateLockData;
      if (exportData.length === 0) {
        exportData.push({
          ['ゲートID']: '',
          ['ゲート名']: '',
          ['製品ID']: '',
          ['製品名']: '',
          ['型番']: '',
          ['アイコンタイプ']: '',
          ['メーカーコード']: '',
          ['カスタム可否']: '',
          ['ターゲット']: '',
          ['ターゲット動作設定Time']: '',
          ['ターゲット動作設定TimeUnit']: '',
          ['ゲート動作モード']: '',
          ['NFCの有効・無効(1:有効.0:無効)']: '',
          ['テンキーの有効・無効(1:有効.0:無効)']: '',
          ['ゲートパスワードの有効・無効(1:有効.0:無効)']: '',
          ['ゲートパスワードの桁数']: '',
          ['ゲートパスワードのseed値']: '',
          ['2段階認証の有効・無効']: '',
          ['BLE出力設定']: '',
          ['ブザー鳴動設定']: '',
          ['備考１']: '',
          ['備考２']: '',
          ['ゲート個体番号１（読み取り専用）']: '',
          ['ゲート個体番号２（読み取り専用）']: '',
          ['適用されているファームウェアバージョン（メイン）（読み取り専用）']: '',
          ['適用されているファームウェアバージョン（BLE）（読み取り専用）']: '',
          ['登録日（読み取り専用）']: '',
          ['更新日（読み取り専用）']: '',
          ['ゲートへ設定情報を適用した日（読み取り専用）']: '',
          ['個人コード照合データー：最新リビジョン（読み取り専用）']: '',
          ['個人コード照合データー：設定済みリビジョン（読み取り専用）']: '',
          ['電池電圧（読み取り専用）']: '',
          ['電池電圧更新日（読み取り専用）']: '',
          ['ステータスの有効・無効(1:有効.0:無効)']: '',
          ['ゲートへの設定反映状況（読み取り専用）']: '',
        });
      }
      const csv = this.convertArrayOfObjectsToCSV({
        data: exportData,
      });
      const filename = 'gate-data.csv';
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([bom, csv], { type: 'text/csv;charset=utf-8;' });
      // ダウンロード実行
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      const nav = (window.navigator as any);
      if (isSafari) {
        saveAs(blob, filename);
        console.log('DDD1');
      } else {
        if (nav.msSaveOrOpenBlob !== undefined) {
          // IEの場合
          nav.msSaveOrOpenBlob(blob, filename);
        } else {
          // IE以外(Chrome, Firefox)
          // const downloadLink = $('<a></a>');
          const downloadLink = document.createElement('a');
          downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
          downloadLink.setAttribute('download', filename);
          downloadLink.setAttribute('target', '_blank');
          // $('body').append(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }
        console.log('DDD2');
      }
      console.log('DDD');
    },
    convertArrayOfObjectsToCSV(args: any) {
      let result: any;
      let ctr: number;
      let keys: any[];
      let columnDelimiter: string | undefined;
      let lineDelimiter: any;
      let data: any;
      data = args.data || null;
      if (data == null || !data.length) {
        return null;
      }
      columnDelimiter = args.columnDelimiter || ',';
      lineDelimiter = args.lineDelimiter || '\n';
      keys = Object.keys(data[0]);
      result = '';
      result += keys.join(columnDelimiter);
      result += lineDelimiter;
      data.forEach((item: { [x: string]: any; }) => {
        ctr = 0;
        keys.forEach((key: any) => {
          if (ctr > 0) {
            result += columnDelimiter;
          }
          result += item[key];
          ctr++;
        });
        result += lineDelimiter;
      });
      return result;
    },
    convertStringToBoolean(text: any) {
      const str = text.trim().toLowerCase();
      switch (str) {
        case '1':
          return true;
        case '0':
          return false;
        default:
          return true;
      }
    },
    getLog() {
      this.getLastLog(this.currentGateId);
    },
    openHandleCommand(val: any) {
      this.currentGateId = val;
      this.isFinishHandleCommand = false;
      this.errorMessageLogsPhone = '';
      this.successMessageLogsPhone = '';
      this.getLastLog(this.currentGateId);
    },
    handleCommand(val: any) {
      this.loading = true;
      this.currentGateId = val;
      // this.errorMessageLogs = ''
      // this.errorMessageLogsPhone = ''
      // this.successMessageLogsPhone = ''
      // this.currentGateId = ''
      // this.selectedPhone = ''
      // this.currentGateId = gateId
      console.log(this.listGateGroupCube);
      if (typeof this.listGateGroupCube[this.currentGateId] !== 'undefined' &&
      this.listGateGroupCube[this.currentGateId] !== undefined && this.listGateGroupCube[this.currentGateId] !== null
      && this.listGateGroupCube[this.currentGateId] !== '') {
        /* Get phoneId
        ** Step 1 : get list userId : API GET  /relation/gate-group
        ** Step 2 : Remove duplicate userId
        ** Step 3 : Get list phoneId : connectedStatus, userId
        ** Step 4 : classify phoneId disconnected and connected
        ** Step 5 : Get one phoneId base on GET/register/cube/command
        ** Final : Register Cube with phoneId, gateId
        */
        return this.getListUserId(this.currentGateId) // Step 1
          .then(() => this.removeDuplicates()) // Step 2
          .then(() => this.getListPhoneCube()) // Step 3
          .then(() => this.getPhoneConnectedStatus()) // Step 4
          .then(() => this.getListPhoneRSSI()) // Step 5
          .then(() => this.checkRemoteUnlock(this.currentGateId)) // Final
          .then(() => this.getLastLog(this.currentGateId)) // Final
          .then(() => {
            // Show popup message
            this.isFinishHandleCommand = true;
          });
      } else {
        console.log('データーはありません。');
        this.loading = false;
        this.isFinishHandleCommand = true;
      }
    },
    async getListUserId(gateId: any) {
      for (const item of this.listGateGroupCube[gateId]) {
        await this.getUserId(item.groupId, 1);
      }
      console.log('getUserId: End');
    },
    getUserId(groupId: any, page: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.GROUPS_PASSAGE_API + '?groupId=' + encodeURIComponent(groupId)
        + '&limit=1000&page=' + page)
          .then((response: any) => {
            console.log('groupId', groupId);
            const maxRecords = Math.ceil((response.body.maxRecords) / 1000);
            const rMaxG = response.body.maxRecords;
            const rEndG = response.body.endRecord;
            if (page <= maxRecords) {
              const responsegroupPassages = response.body.groupPassages;
              responsegroupPassages.forEach((element: any) => {
                this.groupPassages.push(element.userId);
              });
              if (rEndG < rMaxG) {
                page++;
                resolve(this.getUserId(groupId, page));
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            console.log(err.body.message);
            resolve(false);
          });
      });
    },
    /* Step 2
    ** remove duplicate value in array
    */
    removeDuplicates() {
      const array = this.groupPassages;
      const uniqueChars: any[] = [];
      return new Promise((resolve, reject) => {
        array.forEach((c: any) => {
          if (!uniqueChars.includes(c)) {
            uniqueChars.push(c);
          }
        });
        this.groupPassages = uniqueChars;
        resolve(true);
      });
    },
    /* Step 3
    **
    */
    async getListPhoneCube() {
      this.listPhoneCubes = [];
      await this.wait(2000);
      await this.getPhoneCube(1);
    },
    // Get phone of userId with max registered
    getPhoneCube(page: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.PHONES_LIST_API + '?keyword=CubeJ&scorp=model&limit=1000&page=' + page + '&sortKey=desc:registered')
          .then((response: any) => {
            const maxRecords = Math.ceil((response.body.maxRecords) / 1000);
            const rMax = response.body.maxRecords;
            const rEnd = response.body.endRecord;
            if (page <= maxRecords) {
              response.body.phones.forEach((item: any) => {
                this.listPhoneCubes.push({
                  phoneId: item.phoneId,
                  userId: item.userId,
                  connectedStatus: item.connectedStatus,
                });
              });
              if (rEnd < rMax) {
                page++;
                resolve(this.getPhoneCube(page));
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            console.log(err);
            resolve(false);
          });
      });
    },
    /* Step 4
    ** Get phone connected|disconnected
    */
    getPhoneConnectedStatus() {
      return new Promise((resolve, reject) => {
        const arrayGroupId = this.groupPassages;
        this.listPhones = [];
        this.listPhonesNoConnect = [];
        this.listPhoneCubes.forEach((item: any) => {
          if (arrayGroupId.includes(item.userId)) {
            if (item.connectedStatus === 'connected') {
              this.listPhones.push(item.phoneId);
            } else {
              this.listPhonesNoConnect.push(item.phoneId);
            }
          }
        });
        resolve(true);
      });
    },
    /* Step 5
    ** Get phone
    */
    async getListPhoneRSSI() {
      console.log('listPhones', this.listPhones.length);
      console.log('listPhonesNoConnect', this.listPhonesNoConnect.length);
      // if only one phone -> remoteUnlock
      this.listPhoneRSSI = [];
      if (this.listPhones.length === 1) {
        this.listPhoneRSSI.push({
          phoneId: this.listPhones[0],
          RSSI: '',
        });
      } else {
        const arrayListPhone = this.listPhones;
        for (const item of arrayListPhone) {
          await this.postPhoneRSSI(item);
          await this.wait(3000);
          await this.getPhoneRSSI(item);
        }
      }
    },
    postPhoneRSSI(ID: any) {
      return new Promise((resolve, reject) => {
        const cubeInfor = {
          cmdId: 6,
          phoneId: ID,
        };
        this.sequentialNo = '';
        this.$http.post(Config.CUBE_REGISTER_COMMAND_API, cubeInfor)
          .then((response: any) => {
            console.log('response', response.status);
            this.sequentialNo = response.body.sequentialNo;
            resolve(true);
          })
          .then( null, (err: any) => {
            console.log('error', err.status);
            resolve(false);
          });
      });
    },
    getPhoneRSSI(phoneId: any) {
      return new Promise((resolve, reject) => {
        if (this.sequentialNo === '') {
          resolve(false);
        } else {
          this.$http.get(Config.CUBE_REGISTER_COMMAND_API + '?sequentialNo=' +
          this.sequentialNo + '&keyword=' + phoneId + '&scorp=phoneId&limit=25')
            .then((response: any) => {
              console.log('response.body.commands', response.body.commands);
              const responseCommands = response.body.commands;
              console.log('responseCommands', responseCommands);
              responseCommands.forEach((item: any) => {
                /* Condition filter
                ** cmdid：6
                ** commands[*].message : have gateInfo[].gateId = current gateId
                ** Only get one phoneId have max RSSI (commands[*].message)
                */
                if (item.cmdId === 6) {
                  console.log('item', item);
                  const message = JSON.parse(item.message);
                  const gateInfo = message.gateInfo;
                  console.log('message', message);
                  console.log('gateInfo', gateInfo);
                  console.log('this.currentGateId', this.currentGateId);
                  const obj = gateInfo.find((o: { gateId: any; }) => o.gateId === this.currentGateId);
                  if (obj !== undefined) {
                    if (this.listPhoneRSSI.length === 0 && obj.RSSI !== 0) {
                      this.listPhoneRSSI.push({
                        phoneId: message.alligatePhoneId,
                        RSSI: obj.RSSI,
                      });
                    } else {
                      if (obj.RSSI > this.listPhoneRSSI[0].RSSI && obj.RSSI !== 0) {
                        this.listPhoneRSSI = [];
                        this.listPhoneRSSI.push({
                          phoneId: message.alligatePhoneId,
                          RSSI: obj.RSSI,
                        });
                      }
                    }
                  }
                }
              });
              resolve(true);
            })
            .then( null, (err: any) => {
              console.log(err);
              resolve(false);
            });
        }
      });
    },
    // Final
    async checkRemoteUnlock(gateId: any) {
      console.log('listPhoneRSSI.length', this.listPhoneRSSI.length);
      console.log('listPhonesNoConnect.length', this.listPhonesNoConnect.length);
      if (this.groupPassages.length === 0 || (this.listPhoneRSSI.length === 0 &&
      this.listPhonesNoConnect.length === 0)) {
        this.errorMessageLogsPhone = 'ゲートウェイ機器（Cube）がゲートに紐づいていません。<br> 設定を確認して下さい。';
      } else if (this.listPhoneRSSI.length === 0 && this.listPhonesNoConnect.length > 0) {
        this.errorMessageLogsPhone = 'ゲートウェイ機器（cube等）との通信に失敗しました。 ゲートウェイ機器の通信状態を確認してください。';
      } else {
        await this.remoteUnlockCube(gateId, this.listPhoneRSSI[0].phoneId);
      }
    },
    // Final
    remoteUnlockCube(gate: any, phone: any) {
      return new Promise((resolve, reject) => {
        const cubeInfor = {
          cmdId: 4,
          option: {
            gateId: gate,
          },
          phoneId: phone,
        };
        this.errorMessageLogsPhone = '';
        this.successMessageLogsPhone = '';
        this.$http.post(Config.CUBE_REGISTER_COMMAND_API, cubeInfor)
          .then((response: any) => {
            console.log('response', response.status);
            this.successMessageLogsPhone = '遠隔解錠要求を実施しました。 \n <br> 更新ボタンをクリックして、遠隔解錠状況の結果を確認してください。';
            resolve(true);
          })
          .then( null, (error: any) => {
            switch (error.status) {
              case 404:
              case 400:
                this.errorMessageLogsPhone = '遠隔解錠要求を失敗しました。ゲートウェイの情報をご確認下さい。';
                break;
              case 500:
              case 503:
                this.errorMessageLogsPhone = '遠隔解錠要求を失敗しました。しばらく経ってからやり直してください。';
                break;
              default:
                this.errorMessageLogsPhone = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                break;
            }
            resolve(false);
          });
      });
    },
    getLastLog(gateId: any) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.lastLog = [];
        this.$http.get(Config.CUBE_REGISTER_COMMAND_API + '?keyword=' + this.currentGateId + '&scorp=option')
          .then((response: any) => {
            const responseCommands = response.body.commands;
            responseCommands.forEach((item: any) => {
              if (this.lastLog.length === 0) {
                this.lastLog.push({
                  registered: item.registered,
                  status: item.status,
                });
              }
            });
            this.loading = false;
            resolve(true);
          })
          .then( null, (err: any) => {
            this.loading = false;
            resolve(false);
            console.log(err);
          });
      });
    },
    wait(ms: any) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, ms);
      });
    },
    resetCube() {
      this.errorMessageLogsPhone = '';
      this.successMessageLogsPhone = '';
    },
    clickUpdateValid(data: any) {
      console.log(data);
      this.dataUpdateValid = data;
      this.showConfirmUpdateValidLock = true;
      if (this.dataUpdateValid.isValid) {
        this.actionUpdateValidLock = 0;
      } else {
        this.actionUpdateValidLock = 1;
      }
    },
    noChoiceUpdateValidLock() {
      this.dataUpdateValid = {};
      this.showConfirmUpdateValidLock = false;
    },
    closeModalProgressUpdateValidLock() {
      this.dataUpdateValid = {};
      this.showProgressUpdateValidLock = false;
      this.getRelationGateGroup(1);
    },
    yesChoiceUpdateValidLock() {
      this.showConfirmUpdateValidLock = false;
      this.showProgressUpdateValidLock = true;
      this.isFinishProcessUpdateValidLock = false;
      this.successItemProcessUpdateValidLock = 0;
      this.progressUpdateValidLock = 0;
      this.errorMessageProcessUpdateValidLock = [];
      this.totalDataRowProcessUpdateValidLock = 1;
      const data = {
        isValid: this.dataUpdateValid.isValid,
      };
      return new Promise((resolve, reject) => {
        this.$http.put(Config.GATES_LIST_API + '/' + encodeURIComponent(this.dataUpdateValid.gateId), data)
          .then((response: any) => {
            this.successItemProcessUpdateValidLock++;
            this.progressUpdateValidLock = 100;
            this.isFinishProcessUpdateValidLock = true;
            resolve(true);
          })
          .then( null, (error: any) => {
            let errorName = '';
            switch (error.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (error.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            const rowstatus = this.dataUpdateValid.gateId + '>><span>' + errorName + '</span>';
            this.$set(this.errorMessageProcessUpdateValidLock, ++this.rowinErrDialog, rowstatus);
            this.progressUpdateValidLock = 100;
            this.isFinishProcessUpdateValidLock = true;
            resolve(false);
          });
        });
    },
    getRelationGateGroup(page: number) {
      if (this.groupId === 'no-id') {
        this.getRelationGateAll(page);
      } else {
        this.getRelationGateGroupId(page);
      }
    },
    getRelationGateGroupId(page: number) {
      this.isGetDataList = true;
      const apiLink = Config.RELATION_GATE_GROUP +
        '?groupId=' + encodeURIComponent(this.groupId) + '&limit=' + this.perPage + '&page=' + page + '&embed=group,gate';
      this.$http.get(apiLink)
        .then(async (response: any) => {
          let data: any = [];
          if (localStorage.isVaildGate) {
            data = response.body.relationsGateGroup.filter(
                  (element: any) =>  element.gate.isValid === true);
          } else {
            data = response.body.relationsGateGroup;
          }
          this.gateTotalCount = response.body.maxRecords;
          this.dataGates = [];
          this.currPage = page;
          for (let i = 0; i < data.length; i += 5) {
            if (this.$route.query.tab === undefined) {
              break;
            }
            const chunk = data.slice(i, i + 5);
            const arrayPromise: any = [];
            for (const [index, item] of  chunk.entries()) {
              this.dataGates.push(item);
              const result  =  this.getContrGate(item);
              arrayPromise.push(result);
            }
            await Promise.all(arrayPromise);
          }
          await this.concatDataGate();
          this.isGetDataList = false;
        })
        .then( null, (err: any) => {
          this.isGetDataList = false;
          switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
        }); // catch
    },
    getRelationGateAll(page: number) {
      this.isGetDataList = true;
      this.errorMessageLogs = '';
      let apiLink = Config.GATES_LIST_API + '?limit=' + this.perPage + '&page=' + page;
      if (localStorage.isVaildGate === 'true') {
        apiLink += '&isValid=true';
      }
      if (this.keyword !== '') {
        apiLink += '&keyword=' + this.keyword + '&scorp=' + this.scorp;
      }
      this.$http.get(apiLink)
        .then(async (response: any) => {
          const data = response.body.gates;
          this.gateTotalCount = response.body.maxRecords;
          this.dataGates = [];
          this.currPage = page;
          for (let i = 0; i < data.length; i += 5) {
            // await this.getContrGate(this.dataGates[i], i);
            const chunk = data.slice(i, i + 5);
            const arrayPromise: any = [];
            for (const [index, item] of  chunk.entries()) {
              const tmp: any = {};
              tmp.relationId = item.gateId;
              tmp.gate = item;
              tmp.gateId = item.gateId;
              tmp.gate.name = item.gateName;
              this.dataGates.push(tmp);
              const result  =  this.getContrGate(tmp);
              arrayPromise.push(result);
            }
            await Promise.all(arrayPromise);
          }
          await this.concatDataGate();
          this.isGetDataList = false;
        })
        .then( null, (err: any) => {
          this.isGetDataList = false;
          switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
        }); // catch
    },
    concatDataGate() {
      return new Promise((resolve) => {
        let dataController: any = [];
        let dataNoController: any = [];
        this.dataGates.forEach((element: any) => {
          if (element !== undefined) {
            if (element.controller !== undefined && element.gate.gateModelCode === 'ALIG-030') {
              element.controllerName = element.controller.name;
              dataController.push(element);
            } else {
              if (element.gate.gateModelCode !== 'ALIG-030') {
                element.index = dataNoController.length + 1;
                element.controllerName = '';
                dataNoController.push(element);
              } else {
                console.log('element.gate.gateId', element.gate.gateId);
                console.log('element.gate.name', element.gate.name);
              }
            }
          }
        });
        dataNoController = dataNoController.filter((x: any) => x);
        dataController = dataController.filter((x: any) => x);
        dataController.sort((a: any, b: any) => (a.controller.name.toLowerCase() <
        b.controller.name.toLowerCase() ? -1 : 1));
        const newData: any = [];
        dataController.forEach((element: any) => {
          const item = element;
          item.class = 'show-row';
          const i = newData.map((e: { controller: any; }) =>
          e.controller.controllerId).indexOf(element.controller.controllerId);
          if (i >= 0) {
            item.class = 'hide-row';
          }
          newData.push(item);
        });
        this.dataGates = [];
        this.dataGates = newData.concat(dataNoController);
        resolve(true);
      });
    },
    getContrGate(item: any) {
      //
      return new Promise((resolve) => {
        this.$http.get(Config.RELATION_CONTROLLER_GATE + '?limit=25&embed=controller&gateId='
        + encodeURIComponent(item.gateId))
          .then(async (response: any) => {
            const i = this.dataGates.map((e: { relationId: any; }) => e.relationId).indexOf(item.relationId);
            if (i >= 0) {
              this.dataGates[i].controller = response.body.relationsControllerGate[0].controller;
            }
            const result  = await this.checkGroupItemUnLock(item.gateId);
            resolve(result);
          })
          .then( null, async (err: any) => {
            //
            const result  = await this.checkGroupItemUnLock(item.gateId);
            resolve(result);
          });
        });
    },
    async removeGate(data: any) {
      console.log('data', data);
      this.dataRelationGate = {
        total: 0,
        progressLoad: 0,
        successItem: 0,
        errorMessage: [],
        isFinish: false,
      };
      let rowinErrDialog = 0;
      let successItemProcess = 0;
      let progress = 0;
      let isFinishProcess = false;
      const errorMessageProcess: any = [];
      const totalDataRowProcess = data.length;
      const percent = (100 / totalDataRowProcess);
      for (const item of data) {
        let result;
        result  = await this.deleteGateItem(item.relationId);
        if (result === true) {
          successItemProcess++;
        } else {
          const rowstatus = item.gate.name + '>><span>' + result + '</span>';
          this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
        }
        progress += percent;
      }
      isFinishProcess = true;
      this.dataRelationGate = {
        total: totalDataRowProcess,
        progressLoad: progress,
        successItem: successItemProcess,
        errorMessage: errorMessageProcess,
        isFinish: isFinishProcess,
      };
    },
    deleteGateItem(relationId: string) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.RELATION_GATE_GROUP + '/' + encodeURIComponent(relationId))
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            resolve(errorName);
          }); // catch
      });
    },
    async addGate(data: any) {
      console.log('data', data);
      this.dataRelationGate = {
        total: 0,
        progressLoad: 0,
        successItem: 0,
        errorMessage: [],
        isFinish: false,
      };
      let rowinErrDialog = 0;
      let successItemProcess = 0;
      let progress = 0;
      let isFinishProcess = false;
      const errorMessageProcess: any = [];
      const totalDataRowProcess = data.length;
      const percent = (100 / totalDataRowProcess);
      for (const item of data) {
        let result;
        result  = await this.addGateItem(item.gateId, this.groupId);
        if (result === true) {
          successItemProcess++;
        } else {
          const rowstatus = item.gateName + '>><span>' + result + '</span>';
          this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
        }
        progress += percent;
      }
      isFinishProcess = true;
      this.dataRelationGate = {
        total: totalDataRowProcess,
        progressLoad: progress,
        successItem: successItemProcess,
        errorMessage: errorMessageProcess,
        isFinish: isFinishProcess,
      };
    },
    addGateItem(gate: string, group: any) {
      return new Promise((resolve, reject) => {
        this.$http.post(Config.RELATION_GATE_GROUP, {
          gateId: gate,
          groupId: group,
        })
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            resolve(errorName);
          }); // catch
      });
    },
    async confirmAction(data: any) {
      console.log('data', data);
      this.dataRelationGate = {
        total: 0,
        progressLoad: 0,
        successItem: 0,
        errorMessage: [],
        isFinish: false,
      };
      let rowinErrDialog = 0;
      let successItemProcess = 0;
      let progress = 0;
      let isFinishProcess = false;
      const errorMessageProcess: any = [];
      const totalDataRowProcess = data.selectedItem.length;
      const percent = (100 / totalDataRowProcess);
      for (const item of data.selectedItem) {
        let result;
        console.log('data.isVaild)', data.isVaild);
        result  = await this.updateGateItem(item.gate.gateId, data.isValid);
        if (result === true) {
          successItemProcess++;
        } else {
          const rowstatus = item.gate.name + '>><span>' + result + '</span>';
          this.$set(errorMessageProcess, ++rowinErrDialog, rowstatus);
        }
        progress += percent;
      }
      isFinishProcess = true;
      this.dataRelationGate = {
        total: totalDataRowProcess,
        progressLoad: progress,
        successItem: successItemProcess,
        errorMessage: errorMessageProcess,
        isFinish: isFinishProcess,
      };
    },
    updateGateItem(gateId: string, val: any) {
      return new Promise((resolve, reject) => {
        this.$http.put(Config.GATES_LIST_API + '/' + (encodeURIComponent(gateId)), {isValid: val})
          .then((response: any) => {
            resolve(true);
          })
          .then( null, (err: any) => {
            let errorName = '';
            switch (err.status) {
              case 400:
                errorName = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  errorName = Config.ERROR_403;
                } else {
                  errorName = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                errorName = Config.ERROR_404;
                break;
              case 500:
                errorName = Config.ERROR_500;
                break;
              case 503:
                errorName = Config.ERROR_503;
                break;
              default:
                errorName = Config.ERROR_DEFAULT;
                break;
            }
            resolve(errorName);
          }); // catch
      });
    },
    loadGates(page: any) {
      this.dataGates = [];
      this.getRelationGateGroup(page);
    },
    loadAllGates(item: any) {
      this.loadGateList({perPage: 25, page: 1});
    },
    changePerPageGateAll(val: any) {
      this.expandData.userAll.perPage = val;
      const data = {
        page: this.expandData.gateAll.page,
        perPage: this.expandData.gateAll.perPage,
      };
      this.loadGateList(data);
    },
    changePageGateAll(val: any) {
      this.expandData.gateAll.page = val;
      const data = {
        page: this.expandData.gateAll.page,
        perPage: this.expandData.gateAll.perPage,
      };
      this.loadGateList(data);
    },
    loadGateList(item: any) {
        this.expandData.gateAll.loading = true;
        this.$http.get(Config.GATES_LIST_API + '?limit=' + item.perPage + '&page=' + item.page + '&sortKey=desc:registered&isExceptOptionalGate=true&isValid=true')
          .then((response: any) => {
            if (response.body.maxRecords > 0) {
              this.expandData.gateAll.data = response.body.gates;
              this.expandData.gateAll.total = response.body.maxRecords;
              this.expandData.gateAll.loading = false;
            }
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            this.expandData.gateAll.loading = false;
          }); // catch
    },
    checkGroupItemUnLock(gateId: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.RELATION_GATE_GROUP + '?limit=25&gateId=' +
        encodeURIComponent(gateId) + '&isExceptOptionalGate=true')
          .then((response: any) => {
            this.$set(this.listGateGroupCube, gateId, response.body.relationsGateGroup);
            if (response.body.maxRecords > 0) {
              if (this.btnUnlock.includes(gateId) === false) {
                this.btnUnlock.push(gateId);
              }
            }
            resolve(true);
          })
          .then( null, (err: any) => {
            resolve(false);
            //
          }); // catch
      });
    },
    closeModalGate() {
      this.dataGates = [];
      this.getRelationGateGroup(1);
      this.$emit('close-modal-control');
    },
    getGateIsValid(val: any) {
      localStorage.setItem('isVaildGate', val.toString());
      if (this.inPage !== '') {
        this.getRelationGateAll(1);
      } else {
        this.getRelationGateGroupId(1);
      }
    },
},
});
