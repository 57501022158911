








































































































import Vue, { PropType } from 'vue';
import { Room, RoomRoomLog } from '@/types/alligate';
import Loading from '@/components/molecules/Loading/Loading.vue';
import DatePicker from '@/components/molecules/DatePicker/DatePicker.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import Search, { SearchValue, SearchTargetItem } from '@/components/molecules/Search/Search.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import TimelineTable, { TimelineItem } from '@/components/molecules/TimelineTable/TimelineTable.vue';
import ReloadButton from '@/components/molecules/ReloadButton/ReloadButton.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import ProgressModal from '@/components/molecules/ProgressModal/ProgressModal.vue';

export interface DataType {
  showExportExcelModal: boolean;
  showProgreeModal: boolean;
  searchTargetItems: SearchTargetItem[];
  endHour: number;
  today: string;
}

export default Vue.extend({
  name: 'InRoomTimelineTable',
  components: {
    Loading,
    DatePicker,
    Pagination,
    Search,
    CancelButton,
    SuccessButton,
    TextLink,
    TimelineTable,
    ReloadButton,
    Modal,
    ProgressModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    // 選択中の部屋情報
    room: {
      type: Object as PropType<Room>,
      default: () => ({
        roomId: '',
        name: '',
      }),
    },
    roomRoomLogs: {
      type: Array as PropType<RoomRoomLog[]>,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    date: {
      type: String,
      default: new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10),
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    searchValue: {
      type: Object as PropType<SearchValue>,
      default: () => ({
        text: '',
        targets: ['name'],
      }),
    },
    progressMessage: {
      type: String,
      default: '',
    },
    progressErrorMessage: {
      type: String,
      default: '',
    },
  },
  data: (): DataType => ({
    showExportExcelModal: false,
    showProgreeModal: false,
    searchTargetItems: [
      { label: '名前', value: 'userName' },
    ],
    endHour: 24,
    today: new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10),
  }),
  watch: {
    roomRoomLogs() {
      this.updateEndHour();
    },
    date() {
      this.updateEndHour();
    },
  },
  computed: {
    innerDate: {
      get(): string {
        return this.date;
      },
      set(val: string): void {
        this.$emit('input-date', val);
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerSearchValue: {
      get(): SearchValue {
        return this.searchValue;
      },
      set(val: SearchValue): void {
        this.$emit('input-search-value', val);
        return;
      },
    },
    innerTimeline: {
      get(): TimelineItem[] {
        const ret: TimelineItem[] = [];
        this.roomRoomLogs.forEach((log) => {
          const r: TimelineItem = {
            id: log.userId,
            title: log.userName,
            timelines: [],
          };

          log.roomLogs.forEach((roomLog, index) => {
            // roomLogのentryとexitのどちらかが存在する場合
            if (roomLog.entry || roomLog.exit) {
              const startTimestamp = roomLog.entry ? roomLog.entry.timestamp : null;
              const endTimestamp = roomLog.exit ? roomLog.exit.timestamp : null;

              let start;
              let end;
              if (startTimestamp) {
                const startISOString = new Date(startTimestamp * 1000 + 9 * 60 * 60 * 1000).toISOString();
                // 選択された日付でない場合はnullにする
                start = startISOString.slice(0, 10) === this.date ? startISOString.slice(11, 16) : undefined;
              }
              if (endTimestamp) {
                const endISOString = new Date(endTimestamp * 1000 + 9 * 60 * 60 * 1000).toISOString();
                // 選択された日付でない場合はnullにする
                end = endISOString.slice(0, 10) === this.date ? endISOString.slice(11, 16) : undefined;
              }

              // startとend両方nullの場合はスルーする
              r.timelines.push({
                start,
                end,
              });
            }
          });
          ret.push(r);
        });
        return ret;
      },
    },
    innerProgressMessage: {
      get(): string {
        if (this.progressMessage === '') {
          return `出力処理が完了しました。`;
        } else {
          return this.progressMessage;
        }
      },
    },
    progressModalTitle: {
      get(): string {
        if (this.progressMessage === '') {
          return `${this.room.name}の集計情報の出力が完了しました`;
        } else {
          return `${this.room.name}の集計情報を出力中です....`;
        }
      },
    },
  },
  methods: {
    clickExportExcel() {
      this.$emit('click-export-excel');
      this.showProgreeModal = true;
      this.showExportExcelModal = false;
    },
    updateEndHour() {
      const now = new Date(Date.now() + 9 * 60 * 60 * 1000);
      const today = now.toISOString().slice(0, 10);
      this.endHour = today === this.date ? Math.min(now.getUTCHours() + 1, 24) : 24;
    },
    updateToday() {
      this.today = new Date(Date.now() + 9 * 60 * 60 * 1000).toISOString().slice(0, 10);
    },
  },
  mounted() {
    this.updateEndHour();
  },
});
