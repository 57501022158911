var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("gate-detail-template", {
    attrs: {
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      successMessageModal: _vm.successMessageModal,
      errorMessageModal: _vm.errorMessageModal,
      gateDetail: _vm.gateDetail,
      groupList: _vm.groupList,
      showConfirm: _vm.showConfirm,
      groupId: _vm.groupId,
      dayTypes: _vm.dayTypes,
      dataUnlockingTime: _vm.dataUnlockingTime,
      groupsAddFail: _vm.groupsAddFail,
      isFinishDetailGate: _vm.isFinishDetailGate,
      isGateEditModalShow: _vm.isGateEditModalShow,
      isRemoteUnlockValid: _vm.isRemoteUnlockValid,
      authority: _vm.authority
    },
    on: {
      clickBackGate: function($event) {
        return _vm.clickBackGate($event)
      },
      clickRemoteUnlock: function($event) {
        return _vm.remoteUnlock($event)
      },
      clickConfirm: function($event) {
        return _vm.clickConfirm($event)
      },
      closeModalConfirm: function($event) {
        return _vm.closeModalConfirm($event)
      },
      clickEditGate: function($event) {
        return _vm.clickEditGate($event)
      },
      ChangeProperty: _vm.ChangeProperty,
      "loading-from-child": function($event) {
        return _vm.$emit("loading", $event)
      },
      clickGroupDetail: _vm.clickGroupDetail
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }