



















































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';

import { DataTableHeader } from 'vuetify';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import moment from 'moment';
import InRoomTemplate from '@/components/templates/InRoomTemplate/InRoomTemplate.vue';

export interface TypeItem {
  title: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  idSelected: any;
  showOption: boolean;
  isVaildOnly: boolean;
  isShow: boolean;
  isShowUserDetail: boolean;
  getData: any;
  searchTargetItems: SearchTargetItem[];
  // checkedColumnNames: any;
}

export default Vue.extend({
  name: 'UsersListTable',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
    TextLink,
  },
  watch: {
    idSelected(val: any) {
      this.$emit('select-Ids', val.map((item: any) => item.groupId));
    },
    loading(val) {
      if (val === false ) {
        this.getData = this.dataModalUser.data;
        this.getData.map((o: { disable: boolean; }) => o.disable = false);
        this.getData.forEach((element: any, key: any) => {
          const check = this.groupPassages.some((item: any) => item.userId === element.userId);
          if (check) {
            this.getData[key].disable = true;
          }
        });
        this.idSelected = [];
        if (this.dataAdded.length > 0 && this.isPage === 'affiliation-add-new') {
          this.idSelected = this.dataAdded;
        }
      }
    },
    groupPassages(val: any) {
      if (val.length > 0 && this.loading === false ) {
        this.getData = this.dataModalUser.data;
        this.getData.map((o: { disable: boolean; }) => o.disable = false);
        this.getData.forEach((element: any, key: any) => {
          const check = this.groupPassages.some((item: any) => item.userId === element.userId);
          if (check) {
            this.getData[key].disable = true;
          }
        });
        this.idSelected = [];
        if (this.dataAdded.length > 0 && this.isPage === 'affiliation-add-new') {
          this.idSelected = this.dataAdded;
        }
      }
    },
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    dataModalUser: {
      type: Object,
      default: {
        data: [],
        total: 0,
        page: 1,
        perPage: 25,
      },
    },
    groupPassages: {
      type: Array,
      default: [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    groupPassagesLoading: {
      type: Boolean,
      default: false,
    },
    departmentId: {
      type: String,
      default: '',
    },
    isPage: {
      type: String,
      default: '',
    },
    authority: {
      type: Object,
      default: ({}),
    },
    dataAdded: {
      type: Array,
      default: [],
    },
  },
  mounted() {
    //
    if (this.departmentId !== '' || this.isPage === 'affiliation-add-new') {
      this.selectedDisplayOption = [
        'groupId',
        'name',
        'departmentName',
      ];
    }
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'ユーザーID',
            align: 'start',
            sortable: true,
            value: 'userId',
          },
        ];
        if (this.selectedDisplayOption.includes('name')) {
          headers.push({
            text: 'ユーザー名',
            align: 'start',
            sortable: true,
            value: 'name',
          });
        }
        if (this.selectedDisplayOption.includes('departmentName')) {
          headers.push({
            text: '所属名',
            align: 'start',
            sortable: false,
            value: 'departmentName',
          });
        }
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.dataModalUser.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.dataModalUser.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
    filteredItems: {
      get(): any {
        if (this.authority.isAdmin) {
          return this.getData.filter((i: any) => {
            return i;
          });
        } else {
          return this.getData.filter((i: any) => {
            return (i.isAdmin === false);
          });
        }
      },
    },
  },
  data: (): DataType => ({
    getData: [],
    isShow: false,
    isShowUserDetail: false,
    selectedDisplayOption: [
      'groupId',
      'name',
    ],
    displayOptionItems: [
      { label: 'グループID', value: 'groupId', isDisabled: true },
      { label: 'グループ名', value: 'name', isDisabled: false },
      { label: 'departmentName', value: 'departmentName'},
    ],
    idSelected: [],
    showOption: false,
    isVaildOnly: localStorage.isVaildDataCard === 'true' ? true : false,
    searchTargetItems: [
      { label: 'ユーザーID', value: 'userId' },
      { label: 'ユーザー名', value: 'name' },
    ],
  }),
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
  },
});
