































































































































































































































































































































import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';

import * as Config from '@/config';
export interface DataType {
  groupNameValue: string;
  detailValue: string;
  gateValue: TypeSelect[];
  userIdValue: TypeSelect[];
  dataTimeByGroup: any;
  inputTime: any;
  rulesValidate: any;
  isGateLock: number;
  listGroup: any;
  deleteData: any;
  dataAllow: any;
}

interface TypeSelect {
  text: string;
  value: string;
}
interface GroupDetail {
  pCodeId: string;
  userId: string;
  type: number;
  isValid: boolean;
}
export default Vue.extend({
  name: 'GroupAddForm',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    TextLink,
  },
  props: {
    confirm: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    gateItems: {
      type: Array as PropType<TypeSelect[]>,
      default: () => [],
    },
    userItems: {
      type: Array as PropType<TypeSelect[]>,
      default: () => [],
    },
    groupDetail: {
      type: Object,
      default: [],
    },
    dataAllUses: {
      type: Array as PropType<TypeSelect[]>,
      default: [],
    },
    dataAllGates: {
      type: Array as PropType<TypeSelect[]>,
      default: [],
    },
    dataAllTimes: {
      type: Array,
      default: [],
    },
    expand: {
      type: Number,
      default: 0,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    GateSubmittedFail: {
      default: [],
    },
    administratorsAddFail: {
      default: [],
    },
    UserSubmittedFail: {
      default: [],
    },
    gateGroupOptions: {
      type: Array,
      default: () => [],
    },
    isDisabledGroupOptions: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.gateValue = this.gateItems;
    this.userIdValue = this.userItems;
    if (this.groupDetail.groupName !== '') {
      this.groupNameValue = this.groupDetail.groupName;
    }
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'グループ名',
            align: 'start',
            sortable: false,
            width: '80%',
            value: 'data-table-expand',
          },
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 'expand-action',
          },
        ];
        return headers;
      },
    },
    icon() {
      if (Object.keys(this.gateValue).length === Object.keys(this.dataAllGates).length) {
        return 'mdi-close-box';
      } else if (Object.keys(this.gateValue).length > 0 &&
      Object.keys(this.gateValue).length !== Object.keys(this.dataAllGates).length) {
        return 'mdi-minus-box';
      } else {
        return 'mdi-checkbox-blank-outline';
      }
    },
    iconUser() {
      if (Object.keys(this.userIdValue).length === Object.keys(this.dataAllUses).length) {
        return 'mdi-close-box';
      } else if (Object.keys(this.userIdValue).length > 0 &&
      Object.keys(this.userIdValue).length < Object.keys(this.dataAllUses).length) {
        return 'mdi-minus-box';
      } else {
        return 'mdi-checkbox-blank-outline';
      }
    },
  },
  data: (): DataType => ({
    listGroup: [],
    isGateLock: 0,
    groupNameValue: '',
    detailValue: '',
    gateValue: [],
    userIdValue: [],
    dataTimeByGroup: [],
    inputTime: [],
    rulesValidate: {
      validateGroupName: (groupName: string) => {
        const pattern = /^.{1,64}$/;
        return !!groupName && pattern.test(groupName) || Config.VALIDATE_GROUP_NAME;
      },
    },
    deleteData: [],
    dataAllow: [],
  }),
  watch: {
    userItems(value) {
      this.userIdValue = value;
    },
    gateItems(value) {
      this.gateValue = value;
    },
    groupDetail(value) {
      this.setValue(value);
      if (this.groupDetail.passableTimeSettingId !== undefined) {
        this.inputTime = {
          text : this.groupDetail.passableTimeSettingId,
          value : this.groupDetail.passableTimeSettingId,
        };
      }
      console.log(this.dataTimeByGroup);
    },
    expand(value) {
      this.dataTimeByGroup = [
      {
        name : this.groupDetail.passableTimeSetting.name,
        passableTimeSettingId: this.groupDetail.passableTimeSetting.passableTimeSettingId,
        timeSettings: this.groupDetail.passableTimeSetting.timeSettings,
      },
      ];
    },
    successMessageLogs(val: any) {
      if (val !== '' && this.isEdit === false) {
        (this.$refs.formPassable as Vue & { reset: () => boolean }).reset();
      }
    },
    gateValue(val: any) {
      this.isGateLock = 0;
      const gatePro = val.find((c: { gateModelCode: string; }) => c.gateModelCode === 'ALIG-030' );
      const gateLock = val.find((c: { gateModelCode: string; }) => c.gateModelCode !== 'ALIG-030' );
      console.log('gatePro', gatePro);
      console.log('gateLock', gateLock);
      if (gatePro !== undefined && gateLock !== undefined) {
        this.isGateLock = 1;
      } else if (gatePro === undefined && gateLock !== undefined) {
        this.isGateLock = 2;
      }
    },
    isDisabledGroupOptions(val: any) {
      if (val === false && this.isEdit === true) {
        if (this.groupDetail.groupAdministrators && this.groupDetail.groupAdministrators.length > 0) {
          this.listGroup = [];
          this.deleteData = [];
          this.groupDetail.groupAdministrators.forEach((element: any) => {
            this.listGroup.push({
              Ids: {
                value: element.userId,
                text: element.userId,
                disabled: true,
              },
              isAdminToView: true,
              isAdminOfGroupPassages: element.isAdminOfGroupPassages,
              isAdminOfGatePassages: element.isAdminOfGatePassages,
              isAdminOfRemoteUnlock: element.isAdminOfRemoteUnlock,
              disabled: true,
              groupAdministratorId: element.groupAdministratorId,
            });
          });
        } else {
          this.listGroup = [{
            Ids: '',
            isAdminToView: true,
            isAdminOfGroupPassages: false,
            isAdminOfGatePassages: false,
            isAdminOfRemoteUnlock: false,
          }];
        }
      }
    },
    loading(val: any) {
      if (val === false) {
        this.dataAllow = this.dataAllUses;
        if (this.isEdit === false) {
          this.listGroup = [{
            Ids: '',
            isAdminToView: true,
            isAdminOfGroupPassages: false,
            isAdminOfGatePassages: false,
            isAdminOfRemoteUnlock: false,
          }];
        } else {
          this.changeData();
        }
      }
    },
  },
  methods: {
    clickSaveGroup() {
      const validate = (this.$refs.formGroup as Vue & { validate: () => boolean }).validate();
      if (validate) {
        this.$emit('clickSaveGroup', {
          groupName: this.groupNameValue,
          detail: this.detailValue,
          dataUserId: this.userIdValue,
          dataGateId: this.gateValue,
          dataTime: this.inputTime,
          listGroup: this.listGroup,
        });
      }
    },
    clickUpdateGroup() {
      const validate = (this.$refs.formGroup as Vue & { validate: () => boolean }).validate();
      if (validate) {
        this.$emit('clickUpdateGroup', {
          groupName: this.groupNameValue,
          detail: this.detailValue,
          dataUserId: this.userIdValue,
          dataGateId: this.gateValue,
          dataTime: this.inputTime,
          groupId: this.groupDetail.groupId,
          groupDetail: this.groupDetail,
          deleteData: this.deleteData,
          listGroup: this.listGroup,
        });
      }
    },
    setValue(data: any) {
      const obj = JSON.parse(JSON.stringify(data));
      console.log('obj', obj);
      if (obj.name !== '') {
        this.groupNameValue = obj.name;
      }
      this.detailValue = '';
      // this.gateValue = (obj.type).toString();
      // this.userIdValue = obj.userId;
    },
    toggleUser() {
      this.$nextTick(() => {
        if (Object.keys(this.userIdValue).length === Object.keys(this.dataAllUses).length) {
          this.userIdValue = [];
        } else {
          this.userIdValue = this.dataAllUses.slice();
        }
      });
      },
    toggle() {
        this.$nextTick(() => {
          if (Object.keys(this.gateValue).length === Object.keys(this.dataAllGates).length) {
            this.gateValue = [];
          } else {
            this.gateValue = this.dataAllGates.slice();
          }
        });
      },
    deleteTime(ID: string) {
      console.log(ID);
      for (const i of Object.keys(this.dataTimeByGroup)) {
        if (this.dataTimeByGroup[parseInt(i)].passableTimeSettingId === ID) {
          this.dataTimeByGroup.splice(parseInt(i), 1);
        }
      }
    },
    addTime() {
      if (this.inputTime.length !== 0) {
        this.dataTimeByGroup.push({
          name: this.inputTime.text,
          passableTimeSettingId: this.inputTime.value,
          timeSettings: [],
        });
      }
    },
    addGroup() {
      //
      this.listGroup.push({
        Ids: '',
        isAdminToView: true,
        isAdminOfGroupPassages: false,
        isAdminOfGatePassages: false,
        isAdminOfRemoteUnlock: false,
      });
    },
    deleteItem(index: any) {
      const i = this.dataAllow.map((e: { value: any; }) =>
      e.value).indexOf(this.listGroup[index].Ids.value);
      if (i >= 0) {
        this.dataAllow[i].disabled = false;
      }
      if (this.listGroup[index].disabled) {
        this.deleteData.push(this.listGroup[index].groupAdministratorId);
      }
      this.listGroup.splice(parseInt(index), 1);
    },
    changeData() {
      this.dataAllow.forEach((element: any, key: any) => {
        const check = this.listGroup.some((item: any) => item.Ids.value === element.value);
        if (check) {
          this.dataAllow[key].disabled = true;
        }
      });
    },
    clickTimeSettings() {
      //
      this.$emit('go-time-settings');
    },
    checkShow(data: any) {
      if (data.disabled) {
        return this.dataAllow.some((item: any) => data.Ids.value === item.value);
      } else {
        return true;
      }
    },
  },
});
