
















































































































































































































import Vue, { PropType } from 'vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import GroupListTable from '@/components/organisms/GroupListTable/GroupListTable.vue';
import GateListPage from '@/components/pages/gate/GateListPage.vue';
import UsersAllowedTable from '@/components/organisms/UsersAllowedTable/UsersAllowedTable.vue';
import AffiliationAllowedTable from '@/components/organisms/UsersAllowedTable/AffiliationAllowedTable.vue';
import TimePeriodAllowed from '@/components/organisms/TimePeriodAllowed/TimePeriodAllowed.vue';
import TemporaryAccessAuthority from '@/components/organisms/TemporaryAccessAuthority/TemporaryAccessAuthority.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import GroupListModalPage from '@/components/pages/group-modal/GroupListModalPage.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import GateListTable from '@/components/organisms/GateListTable/GateListTable.vue';
import Searchbox, {
  SearchTargetItem,
} from '@/components/molecules/Searchbox/Searchbox.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import * as Config from '@/config';

export interface DataType {
  searchTargetItems: SearchTargetItem[];
  selectedGroupIds: string[];
  actionItems: any;
  tab: any;
  isShow: boolean;
  isShowGate: boolean;
  groupItem: any;
  isLoading: any;
}
interface Type {
  groupId: string;
  groupName: string;
  userId: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
  userCount: number;
}
interface ArrayTime {
  passableTimeSettingId: string;
  name: string;
  dayTypeName: string;
  startTime: string;
  endTime: string;
}
export default Vue.extend({
  name: 'GroupListTemplate',
  components: {
    Searchbox,
    SuccessButton,
    GroupListTable,
    Breadcrumbs,
    ActionButton,
    GateListPage,
    UsersAllowedTable,
    AffiliationAllowedTable,
    TimePeriodAllowed,
    TemporaryAccessAuthority,
    TextLink,
    GroupListModalPage,
    GateListTable,
  },
  props: {
    groupTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    departments: {
      type: Array,
      default: () => [],
    },
    gateList: {
      type: Array,
      default: () => [],
    },
    userList: {
      type: Array,
      default: () => [],
    },
    groupList: {
      type: Array,
      default: () => [],
    },
    dataGroups: {
      type: Array as PropType<Type[]>,
      default: () => [],
    },
    dataGroup: {
      type: Object,
      default: () => ({}),
    },
    dataRelationDepartmentGroup: {
      type: Object,
      default: () => ({}),
    },
    dataGatePass: {
      type: Object,
      default: () => ({}),
    },
    dataRelationUserGroup: {
      type: Object,
      default: () => ({}),
    },
    optionDepartments: {
      type: Object,
      default: () => ({}),
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageProcess: {
      default: [],
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    typeAction: {
      type: Number,
      default: 0,
    },
    showConfirm: {
      type: Boolean,
      default: false,
    },

    groupExpanedGate: {
      default: [],
    },
    isReRenderGate: {
      default: 0,
    },

    groupExpanedUser: {
      default: [],
    },
    isReRenderUser: {
      default: 0,
    },
    isRemoveGateSelected: {
      default: 0,
    },
    isRemoveUserSelected: {
      default: 0,
    },
    isRemoveGroupSelected: {
      default: 0,
    },
    authority: {
      type: Object,
      default: ({}),
    },
    loadingAff: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    isShow: false,
    isShowGate: false,
    tab: 0,
    searchTargetItems: [
      { label: 'グループ名', value: 'name', isDisabled: true },
    ],
    selectedGroupIds: [],
    actionItems: [
      { title: '制御盤/ゲート', tabname: 'tab1' },
      { title: '通行可能ユーザー', tabname: 'tab2' },
      { title: '通行可能時間帯', tabname: 'tab3' },
      { title: '一時通行権限', tabname: 'tab4' },
    ],
    groupItem: '',
    isLoading: false,
  }),
  watch: {
    groupList(data: any) {
      if (data.length > 0) {
        if (localStorage.groupId1 !== undefined && localStorage.prevRoute === Config.LOG) {
          this.groupItem = localStorage.groupId1;
          localStorage.removeItem('groupId1');
          localStorage.removeItem('prevRoute');
        } else {
          this.groupItem = this.groupItem !== '' ? this.groupItem :  data[0].value;
          localStorage.setItem('groupId1', data[0].value);
          this.$emit('change-groupId', {tab: this.tab, groupId: this.groupItem});
        }
      }
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ゲート通行権限', disabled: true },
        ];
      },
    },
  },
  methods: {
    //
    clickGroupDetail(item: any) {
      this.groupItem = '';
      this.isShow = false;
      this.isShowGate = false;
      setTimeout(() => {
        this.groupItem = item;
        this.$emit('change-groupId', {tab: this.tab, groupId: this.groupItem});
      }, 100);
    },
    changeGroupId(item: any) {
      this.groupItem = '';
      this.isShow = false;
      setTimeout(() => {
        this.groupItem = item;
        this.$emit('change-groupId', {tab: this.tab, groupId: this.groupItem});
      }, 100);
    },
    loadGroupId(item: any) {
      this.groupItem = '';
      setTimeout(() => {
        this.groupItem = item;
        this.$emit('load-groupId', {tab: this.tab, groupId: this.groupItem});
      }, 100);
    },
    goTimeSettings() {
      this.isShow = false;
      this.$router.push({ path: Config.SETTING});
    },
    isShowGroup() {
      localStorage.setItem('isVaildDataGroupModal', 'false');
      this.isShow = true;
    },
    loadingFromChild(val: any) {
      this.isLoading = val;
    },
  },
});
