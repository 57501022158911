var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-user-table-detail" },
    [
      _c(
        "v-container",
        { staticClass: "mt-1" },
        [
          _c(
            "v-row",
            [
              _vm.successMessageLogs !== "" && _vm.loading === false
                ? _c(
                    "v-col",
                    {
                      staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-success"
                    },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.successMessageLogs) + "\n    "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.errorMessageLogs !== "" && _vm.loading === false
                ? _c(
                    "v-col",
                    { staticClass: "mt-5 mb-4 font-weight-bold mt-1 bg-error" },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.errorMessageLogs) + "\n    "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "pa-6 mb-5" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text-subtitle-2 border-left-blue font-weight-bold"
                    },
                    [_vm._v("\n        ユーザー情報\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _vm.isModal === true
                        ? _c(
                            "v-row",
                            {
                              staticClass: "btn-round mb-5",
                              attrs: { justify: "end" }
                            },
                            [
                              _vm.authority.isAdmin === true ||
                              (_vm.authority.isAdminToEditUser === true &&
                                _vm.user.departmentId !== undefined &&
                                  _vm.dataAllow.includes(_vm.user.departmentId))
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-primary",
                                      attrs: {
                                        outlined: "",
                                        disabled: _vm.user.userId === ""
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.isShow = true
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n          ユーザー情報を編集する\n        "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "ユーザーID", text: _vm.user.userId }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "ユーザー名", text: _vm.user.name }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "ふりがな", text: _vm.user.furigana }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "メールアドレス", text: _vm.user.email }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: {
                  title: "所属",
                  text:
                    _vm.user.department !== undefined ? _vm.user.department : ""
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "info-item ml-5" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "col col-3" },
                        [_c("v-subheader", [_vm._v("所属カード")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-body-2",
                          attrs: { "align-self": "center" }
                        },
                        [
                          _c(
                            "v-row",
                            _vm._l(_vm.cardItems, function(item) {
                              return _c(
                                "v-col",
                                {
                                  key: item.value,
                                  attrs: { md: "2", "align-self": "center" }
                                },
                                [_vm._v(_vm._s(item.text))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.user.isAdmin && _vm.loading === false
                ? _c("info-item", {
                    staticClass: "ml-5",
                    attrs: { title: "権限", text: "管理者" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.user.isAdmin === false && _vm.loading === false
                ? _c("info-item", {
                    staticClass: "ml-5",
                    attrs: { title: "権限", text: "一般ユーザー" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.user.isAdmin === null && _vm.loading === false
                ? _c("info-item", {
                    staticClass: "ml-5",
                    attrs: { title: "権限", text: "カスタマイズ権限" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.user.isAdmin === null && _vm.loading === false
                ? _c(
                    "div",
                    { staticClass: "ml-5" },
                    [
                      _vm.user.departmentAdministrators &&
                      _vm.user.departmentAdministrators.length > 0
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { class: [_vm.isModal ? "col-1" : "col-3"] },
                                [
                                  _c("div", {
                                    staticClass: "v-subheader theme--light"
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-col", { staticClass: "col-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light ml-0 pa-0 mt-0"
                                  },
                                  [_vm._v(" 所属管理者")]
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.user.departmentAdministrators, function(
                        item,
                        i
                      ) {
                        return _c(
                          "v-row",
                          { key: i, staticClass: "mt-0 ml-5" },
                          [
                            _c(
                              "v-col",
                              { class: [_vm.isModal ? "col-1" : "col-3"] },
                              [
                                _c("div", {
                                  staticClass: "v-subheader theme--light"
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("v-col", { staticClass: "col-2" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "v-subheader theme--light ml-0 pa-0 mt-0"
                                },
                                [_vm._v(" " + _vm._s(item.name))]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { class: [_vm.isModal ? "col-8" : "col-7"] },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "item-check-box" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            color: "grey",
                                            dense: "",
                                            "hide-details": "",
                                            disabled: "",
                                            label: "ユーザー閲覧権限"
                                          },
                                          model: {
                                            value: item.isAdminToView,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "isAdminToView",
                                                $$v
                                              )
                                            },
                                            expression: "item.isAdminToView"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "green-check",
                                          attrs: {
                                            color: "green",
                                            dense: "",
                                            "hide-details": "",
                                            disabled: "",
                                            label: "ユーザー追加権限"
                                          },
                                          model: {
                                            value: item.isAdminToAddUser,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "isAdminToAddUser",
                                                $$v
                                              )
                                            },
                                            expression: "item.isAdminToAddUser"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "green-check",
                                          attrs: {
                                            color: "green",
                                            dense: "",
                                            "hide-details": "",
                                            disabled: "",
                                            label: "ユーザー編集権限"
                                          },
                                          model: {
                                            value: item.isAdminToEditUser,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "isAdminToEditUser",
                                                $$v
                                              )
                                            },
                                            expression: "item.isAdminToEditUser"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "3" } },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "green-check",
                                          attrs: {
                                            color: "green",
                                            dense: "",
                                            "hide-details": "",
                                            disabled: "",
                                            label: "カード設定権限"
                                          },
                                          model: {
                                            value:
                                              item.isAdminToSetPcodesToUser,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "isAdminToSetPcodesToUser",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.isAdminToSetPcodesToUser"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _vm.user.groupAdministrators &&
                      _vm.user.groupAdministrators.length > 0
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { class: [_vm.isModal ? "col-1" : "col-3"] },
                                [
                                  _c("div", {
                                    staticClass: "v-subheader theme--light"
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-col", { staticClass: "col-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light ml-0 pa-0 mt-0"
                                  },
                                  [_vm._v(" 通行権限グループ")]
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.user.groupAdministrators, function(item, j) {
                        return _c(
                          "v-row",
                          { key: j + 1, staticClass: "mt-0 ml-5" },
                          [
                            _c(
                              "v-col",
                              { class: [_vm.isModal ? "col-1" : "col-3"] },
                              [
                                _c("div", {
                                  staticClass: "v-subheader theme--light",
                                  attrs: { "data-v-f4f20dd2": "" }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("v-col", { staticClass: "col-2" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "v-subheader theme--light ml-0 pa-0 mt-0"
                                },
                                [_vm._v(" " + _vm._s(item.name))]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { class: [_vm.isModal ? "col-8" : "col-7"] },
                              [
                                _c(
                                  "v-row",
                                  {
                                    class: [
                                      _vm.isModal
                                        ? "item-check-box col-modal"
                                        : "item-check-box"
                                    ]
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        class: [_vm.isModal ? "col-5" : "col-2"]
                                      },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            disabled: "",
                                            color: "grey",
                                            dense: "",
                                            "hide-details": "",
                                            label: "ゲート閲覧権限"
                                          },
                                          model: {
                                            value: item.isAdminToView,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "isAdminToView",
                                                $$v
                                              )
                                            },
                                            expression: "item.isAdminToView"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        class: [_vm.isModal ? "col-5" : "col-4"]
                                      },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "green-check",
                                          attrs: {
                                            disabled: "",
                                            color: "green",
                                            dense: "",
                                            "hide-details": "",
                                            label:
                                              "通行可能所属/ユーザーの設定権限"
                                          },
                                          model: {
                                            value: item.isAdminOfGroupPassages,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "isAdminOfGroupPassages",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.isAdminOfGroupPassages"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        class: [_vm.isModal ? "col-5" : "col-4"]
                                      },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "green-check",
                                          attrs: {
                                            disabled: "",
                                            color: "green",
                                            dense: "",
                                            "hide-details": "",
                                            label:
                                              "一時通行可能ユーザーの設定権限"
                                          },
                                          model: {
                                            value: item.isAdminOfGatePassages,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "isAdminOfGatePassages",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.isAdminOfGatePassages\t"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        class: [_vm.isModal ? "col-5" : "col-2"]
                                      },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "green-check",
                                          attrs: {
                                            disabled: "",
                                            color: "green",
                                            dense: "",
                                            "hide-details": "",
                                            label: "遠隔解錠権限"
                                          },
                                          model: {
                                            value: item.isAdminOfRemoteUnlock,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "isAdminOfRemoteUnlock",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.isAdminOfRemoteUnlock"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: {
                  title: "ゲート設定権限",
                  text: _vm.user.isPhoneAdmin ? "ON" : "OFF"
                }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: {
                  title: "アプリへのログイン",
                  text: _vm.user.isPasswordLoginValid ? "ON" : "OFF"
                }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: {
                  title: "Web管理画面ログイン時のSMS認証",
                  text: _vm.user.isMfaSms ? "ON" : "OFF"
                }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "電話番号", text: _vm.user.phoneNumber }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: {
                  title: "権限",
                  text: _vm.user.isAdmin ? "管理者" : "一般ユーザ"
                }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: {
                  title: "ゲート設定権限",
                  text: _vm.user.isPhoneAdmin ? "ON" : "OFF"
                }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: {
                  title: "アプリへのログイン",
                  text: _vm.user.isPasswordLoginValid ? "ON" : "OFF"
                }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: {
                  title: "Web管理画面ログイン時のSMS認証",
                  text: _vm.user.isMfaSms ? "ON" : "OFF"
                }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "電話番号", text: _vm.user.phoneNumber }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "info-item ml-5" },
                [
                  _c(
                    "v-row",
                    [
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(
                                "v-subheader",
                                [
                                  _vm._v(
                                    "解錠時の二段階認証パスワード\n        "
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { icon: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-help-circle")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2569386827
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          " 二段階認証パスワードは、カード＋テンキーによる二段階の解錠用パスワードです。"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "Lock Proでご利用いただくには、リーダーの設定変更が必要です。"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-body-2",
                          attrs: { "align-self": "center" }
                        },
                        [
                          _vm._t("text", [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.user.passCode) +
                                " \n      "
                            )
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: {
                  title: "鍵共有権限",
                  text: _vm.user.shareKeyBeValid ? "ON" : "OFF"
                }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "備考.1", text: _vm.user.option1 }
              }),
              _vm._v(" "),
              _c("info-item", {
                staticClass: "ml-5",
                attrs: { title: "備考.2", text: _vm.user.option2 }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.authority.isAdmin
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text-subtitle-2 border-left-blue font-weight-bold"
                    },
                    [_vm._v("\n        通行可能なゲート\n        ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "mt-1 pa-5 mb-5" },
            [
              _c(
                "v-container",
                [
                  _vm.loading === false
                    ? _c("ModalPageGroupGateInDetail", {
                        attrs: {
                          userName: _vm.user.name,
                          isPage: "detail-user-group-gate",
                          userId: _vm.user.userId
                        },
                        on: {
                          "select-Ids": function(Ids) {
                            _vm.selectedIds = Ids
                          },
                          "change-page-group": function($event) {
                            return _vm.$emit("change-page-group", $event)
                          },
                          loading: function($event) {
                            return _vm.$emit("loading-from-child", $event)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-subtitle-2 border-left-blue font-weight-bold"
                },
                [_vm._v("期限付き解錠可能ゲート")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "mt-1 pa-5" },
            [
              _c(
                "v-data-table",
                {
                  staticClass: "mt-5 border-table",
                  attrs: {
                    "items-per-page": -1,
                    headers: _vm.headerGateTable,
                    items: _vm.dataGatePassages,
                    "hide-default-footer": "",
                    "item-key": "gateId",
                    "no-data-text": "データーはありません"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function(row) {
                        return [
                          _c("tr", [
                            _c("td", [
                              row.item.gateName
                                ? _c("span", [
                                    _vm._v(_vm._s(row.item.gateName))
                                  ])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatDateTime(
                                      new Date(row.item.notBefore * 1000)
                                    )
                                  ) +
                                  " ~ " +
                                  _vm._s(
                                    _vm.formatDateTime(
                                      new Date(row.item.notAfter * 1000)
                                    )
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "last-col" },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { justify: "center" } },
                                  [
                                    _vm.authority.isAdmin
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteGate(
                                                  row.item.passageId
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "gray--text",
                                                attrs: { "align-self": "ceb=" }
                                              },
                                              [_vm._v("mdi-trash-can-outline")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("template", { slot: "body.prepend" }, [
                    _c("tr", { staticClass: "mt-4" }, [
                      _c(
                        "td",
                        { attrs: { width: "200" } },
                        [
                          _c("v-autocomplete", {
                            staticClass: "input-add",
                            attrs: {
                              items: _vm.gateList,
                              "return-object": "",
                              color: "green",
                              outlined: "",
                              dense: "",
                              "hide-details": "auto",
                              "no-data-text": ""
                            },
                            model: {
                              value: _vm.itemGate,
                              callback: function($$v) {
                                _vm.itemGate = $$v
                              },
                              expression: "itemGate"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("DateTimePicker", {
                            attrs: {
                              isUserPage: true,
                              dateStartValue: _vm.dateStartGate,
                              dateEndValue: _vm.dateEndGate,
                              timeSValue: _vm.timeStartGate,
                              timeEValue: _vm.timeEndGate
                            },
                            on: {
                              getNewTimeStart: _vm.getNewTimeStart,
                              getNewTimeEnd: _vm.getNewTimeEnd,
                              getNewDateStart: _vm.getNewDateStart,
                              getNewDateEnd: _vm.getNewDateEnd
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "align-self-center" },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _vm.authority.isAdmin
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-secondary",
                                      attrs: {
                                        disabled:
                                          _vm.gateList.length > 0
                                            ? false
                                            : true,
                                        outlined: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.addGate()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  追加\n                "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm.errGate.errAdd !== "" ||
                    _vm.errGate.errorGate !== "" ||
                    _vm.errGate.errorTime !== ""
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "4" } }, [
                            _vm.errGate.errAdd !== ""
                              ? _c(
                                  "p",
                                  { staticClass: "error-special add hours" },
                                  [_vm._v(_vm._s(_vm.errGate.errAdd))]
                                )
                              : _vm.errGate.errorGate !== ""
                              ? _c(
                                  "p",
                                  { staticClass: "error-special add hours" },
                                  [_vm._v(_vm._s(_vm.errGate.errorGate))]
                                )
                              : _vm.errGate.errorTime !== ""
                              ? _c(
                                  "p",
                                  { staticClass: "error-special add hours" },
                                  [_vm._v(_vm._s(_vm.errGate.errorTime))]
                                )
                              : _vm._e()
                          ])
                        ])
                      : _vm._e()
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "1200", persistent: "" },
          model: {
            value: _vm.isShow,
            callback: function($$v) {
              _vm.isShow = $$v
            },
            expression: "isShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v("ユーザー編集")
              ]),
              _vm._v(" "),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "ml-5", attrs: { justify: "start" } },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-5 mb-3 mr-9" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary ",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.$emit(
                                        "load-user-again",
                                        _vm.user.userId
                                      )
                                      _vm.isShow = false
                                    }
                                  }
                                },
                                [_vm._v("\n              戻る\n              ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.isShow
                ? _c("UserAddPage", {
                    attrs: { isModal: true, userId: _vm.user.userId },
                    on: {
                      "close-modal-edit": function($event) {
                        _vm.isShow = false
                      },
                      loading: function($event) {
                        return _vm.$emit("loading-from-child", $event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showConfirm,
            callback: function($$v) {
              _vm.showConfirm = $$v
            },
            expression: "showConfirm"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "background-color-green",
                  staticStyle: { "font-size": "15px" }
                },
                [_vm._v("関連付け削除 ")]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _c("v-card-text", { staticClass: "pa-4" }, [
                _c("p", [_vm._v("ゲートを削除しますか ?")])
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.noChoice()
                            }
                          }
                        },
                        [_vm._v("キャンセル")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.yesChoice()
                            }
                          }
                        },
                        [_vm._v("\n              削除\n            ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showProgress,
            callback: function($$v) {
              _vm.showProgress = $$v
            },
            expression: "showProgress"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v("関連付け削除")
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-row", {
                staticStyle: { height: "5px" },
                attrs: { "no-gutters": "" }
              }),
              _vm._v(" "),
              _vm.dataProgress.total >= 0
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-progress-linear",
                        {
                          staticStyle: { "pointer-events": "none" },
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.dataProgress.progressLoad,
                            callback: function($$v) {
                              _vm.$set(_vm.dataProgress, "progressLoad", $$v)
                            },
                            expression: "dataProgress.progressLoad"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                Math.round(_vm.dataProgress.progressLoad)
                              ) + "%"
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.dataProgress.successItem > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.dataProgress.total) +
                                "件中" +
                                _vm._s(_vm.dataProgress.successItem) +
                                " 件を削除ました。"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.dataProgress.errorMessage.length > 0
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "error-box" },
                              [
                                _vm._l(_vm.dataProgress.errorMessage, function(
                                  item
                                ) {
                                  return [
                                    _c("p", {
                                      key: item,
                                      domProps: { innerHTML: _vm._s(item) }
                                    })
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dataProgress.isFinish
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeModalGate()
                                }
                              }
                            },
                            [_vm._v("OK")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }