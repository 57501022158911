var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("UserDetailTemplate", {
    attrs: {
      loading: _vm.loading,
      tempGroupPassages: _vm.tempGroupPassages,
      cardItems: _vm.cardItems,
      phoneItems: _vm.phoneItems,
      errorMessageLogs: _vm.errorMessageLogs,
      listphones: _vm.listphones,
      listGatePassagesInUser: _vm.listGatePassagesInUser,
      userData: _vm.userData,
      gateList: _vm.gateList,
      goBack: _vm.goBack,
      isModal: _vm.isModal,
      ExpandedData: _vm.ExpandedData,
      rowinErrDialog: _vm.rowinErrDialog,
      errorMessageProcess: _vm.errorMessageProcess,
      successItemProcess: _vm.successItemProcess,
      totalDataRowProcess: _vm.totalDataRowProcess,
      showProgress: _vm.showProgress,
      isFinishProcess: _vm.isFinishProcess,
      showConfirm: _vm.showConfirm,
      progress: _vm.progress,
      messageSetting: _vm.messageSetting,
      authority: _vm.authority,
      dataProgress: _vm.dataProgress,
      dataAllow: _vm.dataAllow,
      titlePage: _vm.titlePage
    },
    on: {
      "click-leave-action": function($event) {
        return _vm.clickLeaveAction($event)
      },
      confirmAction: function($event) {
        return _vm.confirmAction($event)
      },
      closeDialogConfirm: function($event) {
        return _vm.closeDialogConfirm()
      },
      closeModalProgress: function($event) {
        return _vm.closeModalProgress($event)
      },
      "add-gate": function($event) {
        return _vm.addGate($event)
      },
      "loading-from-child": function($event) {
        return _vm.$emit("loading", $event)
      },
      "del-gate": function($event) {
        return _vm.delGate($event)
      },
      "close-modal-gate": function($event) {
        return _vm.closeModalGate($event)
      },
      "load-user-again": function($event) {
        return _vm.loadUserAgain($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }