

































import Vue, { PropType } from 'vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import Loading from '@/components/molecules/Loading/Loading.vue';
import { Room, User, RoomRoomLog, UserRoomLog, Gate, RelationRoomGate } from '@/types/alligate';
import {Group} from '@/types/alligate';
import { Emit } from 'vue-property-decorator';

export interface TypeItem {
  title: string;
}
interface Type {
  pCodeId: string;
  type: number;
  userId: string;
  isValid: boolean;
  detail: string;
}
export interface DataType {
  showRoomFormModal: boolean;
  room: any;
}
export default Vue.extend({
  name: 'InRoomModalForm',
  components: {
    TextLink,
    SuccessButton,
    Loading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    roomInfo: {
      type: Object as PropType<Room>,
      default: () => ({
        roomId: '',
        name: '',
        relationsRoomGate: [] as RelationRoomGate[],
      }),
    },
    allGates: {
      type: Array as PropType<Gate[]>,
      default: () => [],
    },
    roomInfoLoading: {
      type: Boolean,
      default: false,
    },
    authority: {
      type: Object,
      default: ({}),
    },
    successMessages: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data: (): DataType => ({
    showRoomFormModal: false,
    room: {
      relationsRoomGate: [],
    },
  }),
  computed: {
    gateItems(): TypeItem[] {
      const relationsRoomGateIds = this.roomInfo.relationsRoomGate
        ?.map((item: RelationRoomGate) => item.gateId) ?? [];

      const gateNames = this.allGates
      .filter((item: Gate) => relationsRoomGateIds.includes(item.gateId))
      .map((item: Gate) => ({
        title: item.gateName,
      }));

      return gateNames;
    },
  },
  methods: {
  },
});
