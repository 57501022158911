
































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ReflectionStatusTable from '@/components/organisms/ReflectionStatusTable/ReflectionStatusTable.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';

import {Group} from '@/types/alligate';
export interface TypeItem {
  title: string;
}
export interface DataType {
  searchTargetStatusItems: any;
  searchTargetTypeItems: any;
}
interface Type {
  revision: number;
  content: string;
  status: string;
}
export default Vue.extend({
  name: 'ReflectionStatusList',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    Searchbox,
    ShowPerPage,
    Pagination,
    ReflectionStatusTable,
    Breadcrumbs,
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    statusTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataCommands: {
      type: Array as PropType<Type[]>,
      default: () => [],
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ゲート', disabled: true },
          { text: '反映状況一覧', disabled: true },
        ];
      },
    },
  },
  data: (): DataType => ({
    searchTargetStatusItems: [
      { text: '未送信', value: '0' },
      { text: '送信済み', value: '1' },
      { text: '成功', value: '2' },
      { text: '失敗', value: '3' },
      { text: 'キャンセル', value: '4' },
    ],
    searchTargetTypeItems: [
      {
        value: 'アンチパスバッククリア時間の設定',
        text: 'アンチパスバッククリア時間の設定',
      },
      {
        value: 'アンチパスバックのクリア',
        text: 'アンチパスバックのクリア',
      },
      {
        value: 'カレンダーの設定',
        text: 'カレンダーの設定',
      },
      {
        value: '通行可能時間の設定',
        text: '通行可能時間の設定',
      },
      {
        value: '個人コードの設定',
        text: '個人コードの設定',
      },
      {
        value: '個人コード読み取り位置の更新',
        text: '個人コード読み取り位置の更新',
      },
    ],
  }),
});
