















































































import Vue, { PropType } from 'vue';
import GateLockForm from '@/components/organisms/GateLockForm/GateLockForm.vue';
import { Group } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import * as Config from '@/config';
export interface TypeItem {
  title: string;
}
export interface TypeDessert {
  name: string;
  isVaild: boolean;
}
export interface DataType {
  Message: any;
}
export default Vue.extend({
  name: 'GateEditTemplate',
  components: {
    GateLockForm,
    Breadcrumbs,
  },
  props: {
    unlockTimes: {
      type: Array as PropType<TypeDessert[]>,
      default: () => [],
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isUserOfGate: {
      type: Boolean,
      default: false,
    },
    isGroupOfGate: {
      type: Boolean,
      default: false,
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
    goBack: {
      type: String,
      default: '',
    },
    errorMessageTime: {
      type: String,
      default: '',
    },
    gateDetail: {
      type: Object,
      default: () => ({}),
    },
    groupsAddFail: {
      default: [],
    },
    listGateModel: {
      type: Array,
      default: () => [],
    },
    dataUnlockingTime: {
      default: [],
    },
    dayTypes: {
      default: [],
    },
    isFinishEditGate: {
      default: false,
    },
    loading: {
      default: false,
    },
    gateListPages: {
      type: Number,
      default: 0,
    },
    totalGate: {
      type: Number,
      default: 0,
    },
    groupTotalCount: {
      type: Number,
      default: 0,
    },
    pageGroup: {
      type: Number,
      default: 1,
    },
    userTotalCount: {
      type: Number,
      default: 0,
    },
    pageUser: {
      type: Number,
      default: 1,
    },
    page: {
      type: Number,
      default: 0,
    },
    listAntiPassGates: {
      type: Array,
      default: () => [],
    },
    relationsGateGroup: {
      type: Array,
      default: () => [],
    },
    userOfGate: {
      type: Array,
      default: () => [],
    },
    showProgressUser: {
      type: Boolean,
      default: false,
    },
    progressUser: {
      type: Number,
      default: 0,
    },
    isFinishProcessUser: {
      type: Boolean,
      default: false,
    },
    userTotalCountDel: {
      type: Number,
      default: 0,
    },
    totalDataRowProcessUser: {
      type: Number,
      default: 0,
    },
    successItemProcessUser: {
      type: Number,
      default: 0,
    },
    errorMessageProcessUser: {
      type: Array,
      default: () => [],
    },
    showProgressGroup: {
      type: Boolean,
      default: false,
    },
    progressGroup: {
      type: Number,
      default: 0,
    },
    isFinishProcessGroup: {
      type: Boolean,
      default: false,
    },
    groupTotalCountDel: {
      type: Number,
      default: 0,
    },
    totalDataRowProcessGroup: {
      type: Number,
      default: 0,
    },
    successItemProcessGroup: {
      type: Number,
      default: 0,
    },
    errorMessageProcessGroup: {
      type: Array,
      default: () => [],
    },
    isEditGate: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    Message : Config,
  }),
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '能時間設定',
            align: 'start',
            sortable: true,
            value: 'name',
            class: 'grey',
          },
          {
            text: '詳細',
            align: 'start',
            sortable: true,
            value: 'isValid',
            class: 'grey',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ゲート', disabled: true },
          { text: this.isEdit ? 'ゲート編集' : '新規ゲート登録', disabled: true },
        ];
      },
    },
  },
});
