

































































































import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import UserDetailTable from '@/components/organisms/UserDetailTable/UserDetailTable.vue';
import ModalTitle from '@/components/molecules/ModalTitle/ModalTitle.vue';
import * as Config from '@/config';
import { User, Group, RelationGateGroup } from '@/types/alligate';
export interface DataType {
  actionItems: ActionItem[];
  selectedUserIds: string[];
}
export default Vue.extend({
  name: 'UsersTemplate',
  components: {
    Breadcrumbs,
    SuccessButton,
    UserDetailTable,
    ActionButton,
    ModalTitle,
  },
  props: {
    isGetDepartment: {
      type: Boolean,
      default: false,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    errorMessageProcess: {
      default: [],
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    showConfirm: {
      type: Boolean,
      default: false,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    messageSetting: {
      default: () => ({}),
    },
    dataProgress: {
      default: () => ({}),
    },
    listphones: {
      type: Array,
      default: () => [],
    },
    listGatePassagesInUser: {
      type: Array,
      default: () => [],
    },
    tempGroupPassages: {
      default: () => [],
    },
    userData: {
      type: Object as PropType<User>,
      default: () => ({
        userId: '',
        name: '',
        furigana: '',
        email: '',
        phoneNumber: '',
        isAdmin: false,
        isPhoneAdmin: false,
        shareKeyBeValid: false,
        isMfaSms: false,
        passCode: '',
        isValid: false,
        option1: '',
        option2: '',
        isPasswordLoginValid: false,
        groupCount: 0,
        gateCount: 0,
        timelimitedGateCount: 0,
        department: '',
        groupAdministrators: [{
          name: '',
          isAdminToViewGate: false,
          isAdminOfGroupPassages: false,
          isAdminOfGatePassages: false,
          isAdminOfRemoteUnlock: false,
        }],
        departmentAdministrators: [{
          name: '',
          isAdminToViewUser: false,
          isAdminToAddUser: false,
          isAdminToEditUser: false,
        }],
      }),
    },
    cardItems: {
      default: () => [],
    },
    phoneItems: {
      default: () => [],
    },
    gateList: {
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    goBack: {
      type: String,
      default: '',
    },
    authority: {
      type: Object,
      default: ({}),
    },
    dataAllow: {
      default: () => [],
    },
    titlePage: {
      type: String,
      default: '',
    },
  },
  data: (): DataType => ({
    actionItems: [
      { title: '有効化する', emitName: 'click-enable' },
      { title: '無効化する', emitName: 'click-disable' },
    ],
    selectedUserIds: [],
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ユーザー', disabled: true },
          { text: 'ユーザー詳細', disabled: true },
        ];
      },
    },
  },
  methods: {
    goUserEdit(value: any) {
      this.$router.push({ path:  Config.USER_EDIT, query: {userId: value}});
    },
    goUserList() {
      this.$router.push({ path: '/' + this.goBack});
    },
  },
});
