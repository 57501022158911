import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import * as Config from '@/config';
import Store from '@/store';
import HomePage from '@/components/pages/HomePage.vue';
import CardListPage from '@/components/pages/card/CardListPage.vue';
import CardAddPage from '@/components/pages/card/CardAddPage.vue';
import CardDetailPage from '@/components/pages/card/CardDetailPage.vue';
import CardImportPage from '@/components/pages/card/CardImportPage.vue';
import SettingPage from '@/components/pages/setting/SettingPage.vue';
import ReflectionStatusListPage from '@/components/pages/reflection-status/ReflectionStatusListPage.vue';
import NoticeListPage from '@/components/pages/notice-list/NoticeListPage.vue';
import ManuallyScheduledPage from '@/components/pages/manually-scheduled/ManuallyScheduledPage.vue';
import GateListPage from '@/components/pages/gate/GateListPage.vue';
import GateAddUserPage from '@/components/pages/gate/GateAddUserPage.vue';
import GateAddGroupPage from '@/components/pages/gate/GateAddGroupPage.vue';
import GateEditPage from '@/components/pages/gate/GateEditPage.vue';
import GateDetailPage from '@/components/pages/gate/GateDetailPage.vue';
import GateLockDetailPage from '@/components/pages/gate/GateLockDetailPage.vue';
import GateLockAddPage from '@/components/pages/gate/GateLockAddPage.vue';
import GateLockAddUserPage from '@/components/pages/gate/GateLockAddUserPage.vue';
import GateLockAddGroupPage from '@/components/pages/gate/GateLockAddGroupPage.vue';
import GroupListPage from '@/components/pages/group/GroupListPage.vue';
import GroupAddPage from '@/components/pages/group/GroupAddPage.vue';
import GroupAddUserPage from '@/components/pages/group/GroupAddUserPage.vue';
import GroupDetailPage from '@/components/pages/group/GroupDetailPage.vue';
import LoginPage from '@/components/pages/login/LoginPage.vue';
import PersonalSettingPage from '@/components/pages/personal-setting/PersonalSettingPage.vue';
import UserChangePasswordPage from '@/components/pages/user/UserChangePasswordPage.vue';
import ControlSettingTemplate from '@/components/templates/ControlSettingTemplate/ControlSettingTemplate.vue';
import LayoutTemplate from '../components/pages/layout/LayoutPage.vue';
import DashBoard from '@/components/pages/dashboard/DashBoard.vue';
// import ControlPanelPage from '@/components/pages/controlPanel/ControlPanelPage.vue';
import ControlPanelDetailPage from '@/components/pages/controlPanel/ControlPanelDetailPage.vue';
import ControlPanelAddPage from '@/components/pages/controlPanel/ControlPanelAddPage.vue';
import UserListPage from '@/components/pages/user/UserListPage.vue';
import UserAddPage from '@/components/pages/user/UserAddPage.vue';
import UserAddGroupPage from '@/components/pages/user/UserAddGroupPage.vue';
import TimeAddGroupPage from '@/components/pages/setting/TimeAddGroupPage.vue';
import UserGateAddGroupPage from '@/components/pages/user/UserGateAddGroupPage.vue';
import UserDetail from '@/components/pages/user/UserDetailPage.vue';
import InRoomManagementPage from '@/components/pages/in-room-management/InRoomManagementPage.vue';
import InRoomManagementRoomPage from '@/components/pages/in-room-management/InRoomManagementRoomPage.vue';
import InRoomManagementUserPage from '@/components/pages/in-room-management/InRoomManagementUserPage.vue';
import ExternalListPage from '@/components/pages/external/ExternalListPage.vue';
import SafieListPage from '@/components/pages/safie/SafiePage.vue';
import TbeyeListPage from '@/components/pages/tbeye/TbEyePage.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: Config.LOGIN,
    name: 'login',
    component: LoginPage,
    beforeEnter: (to, from, next) => {
      if (!Store.state.auth.isLoggedIn) {
        // まだログイン場合
        next();
      } else {
        next({
          // ログインした場合
          path: Config.DASHBOARD,
        });
      }
    },
  },
  {
    path: '/',
    component: LayoutTemplate,
    children: [
      {
        path: '/',
        name: 'Home',
        component: DashBoard,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.DASHBOARD,
        name: 'DashBoard',
        component: DashBoard,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.PERSONAL_SETTING,
        name: 'PersonalSetting',
        component: PersonalSettingPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.USER_CHANGE_PASSWORD,
        name: 'UserChangePassword',
        component: UserChangePasswordPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.USER_LIST,
        name: 'UserList',
        component: UserListPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.USER_DETAIL,
        name: 'UserDetail',
        component: UserDetail,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.USER_ADD,
        name: 'UserAdd',
        component: UserAddPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.USER_EDIT,
        name: 'UserEdit',
        component: UserAddPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.USER_ADD_GROUP,
        name: 'UserAddGroupPage',
        component: UserAddGroupPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.USER_ADD_GROUP_GATE,
        name: 'UserAddGroupGatePage',
        component: UserGateAddGroupPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GROUP_ADD,
        name: 'GroupAddPage',
        component: GroupAddPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GROUP_EDIT,
        name: 'GroupEditPage',
        component: GroupAddPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GROUP_DETAIL,
        name: 'GroupDetailPage',
        component: GroupDetailPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GROUP_ADD_GATE,
        name: 'GroupAddGatePage',
        component: UserGateAddGroupPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GROUP_ADD_USER,
        name: 'GroupAddUserPage',
        component: GroupAddUserPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.CARD_LIST,
        name: 'CardListPage',
        component: CardListPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.CARD_ADD,
        name: 'CardAddPage',
        component: CardAddPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.CARD_EDIT,
        name: 'CardEditPage',
        component: CardAddPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.CARD_DETAIL,
        name: 'CardDetailPage',
        component: CardDetailPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.CARD_IMPORT,
        name: 'CardImportPage',
        component: CardImportPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.NOTICE_LIST,
        name: 'NoticeListPage',
        component: NoticeListPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.SETTING,
        name: 'SettingPage',
        component: SettingPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.TIME_ADD_GROUP,
        name: 'TimeAddGroupPage',
        component: TimeAddGroupPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.CONFIRM_SETTING,
        name: 'ControlSettingTemplate',
        component: ControlSettingTemplate,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.REFLECTION_STATUS,
        name: 'ReflectionStatusListPage',
        component: ReflectionStatusListPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.LOG,
        name: 'Log',
        component: ManuallyScheduledPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GATE,
        name: 'GateListPage',
        component: GateListPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GATE_ADD_USER,
        name: 'GateAddUserPage',
        component: GateAddUserPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GATE_ADD_GROUP,
        name: 'GateAddGroupPage',
        component: GateAddGroupPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GATE_ADD_USER_GROUP,
        name: 'GateAddUserGroupPage',
        component: GroupAddUserPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GATE_EDIT,
        name: 'GateEditPage',
        component: GateEditPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GATE_DETAIL,
        name: 'GateDetailPage',
        component: GateDetailPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GATE_LOCK_ADD_USER,
        name: 'GateLockAddUserPage',
        component: GateLockAddUserPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GATE_LOCK_ADD_GROUP,
        name: 'GateLockAddGroupPage',
        component: GateLockAddGroupPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GATE_LOCK_DETAIL,
        name: 'GateLockDetailPage',
        component: GateLockDetailPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GATE_LOCK_ADD,
        name: 'GateLockAddlPage',
        component: GateLockAddPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GATE_LOCK_EDIT,
        name: 'GateLockEditlPage',
        component: GateLockAddPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.GROUP,
        name: 'GroupListPage',
        component: GroupListPage,
        beforeEnter: requireAuthRole,
      },
      // {
      //   path: Config.CONTROL_PANEL,
      //   name: 'ControlPanelPage',
      //   component: ControlPanelPage,
      //   beforeEnter: requireAuthRole,
      // },
      {
        path: Config.CONTROL_PANEL_DETAIL,
        name: 'ControlPanelDetailPage',
        component: ControlPanelDetailPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.CONTROL_PANEL_ADD,
        name: 'ControlPanelAddPage',
        component: ControlPanelAddPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.SAFIE_RELATIONS_GATE,
        name: 'SafieGatePage',
        component: SafieListPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.TBEYE_RELATIONS_GATE,
        name: 'TbeyeGatePage',
        component: TbeyeListPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.EXTERNAL_RELATION_LIST,
        name: 'ExternalListPage',
        component: ExternalListPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.CONTROL_PANEL_EDIT,
        name: 'ControlPanelEditPage',
        component: ControlPanelAddPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.IN_ROOM_MANAGEMENT,
        name: 'InRoomManagementPage',
        component: InRoomManagementPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.IN_ROOM_MANAGEMENT_STATUS_ROOM,
        name: 'InRoomManagementRoomPage',
        component: InRoomManagementRoomPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.IN_ROOM_MANAGEMENT_STATUS_USER,
        name: 'InRoomManagementUserPage',
        component: InRoomManagementUserPage,
        beforeEnter: requireAuthRole,
      },
    ],
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../pages/About.vue'),
  // },
];

const router = new VueRouter({
  routes,
});

function requireAuth(to: any, from: any, next: any) {
  if (!Store.state.auth.isLoggedIn) {
    next({
      // まだログイン場合
      path: Config.LOGIN,
    });
  } else {
    next();
  }
}

function requireAuthRole(to: any, from: any, next: any) {
  if (!Store.state.auth.isLoggedIn) {
    next({
      // まだログイン場合
      path: Config.LOGIN,
    });
  } else {
    next();
  }
}

export default router;
