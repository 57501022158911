var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mb-0" },
    [
      _c("v-autocomplete", {
        staticClass: "input-search mt-2",
        attrs: {
          clearable: "",
          label: "",
          solo: "",
          "hide-details": "",
          "no-data-text": "",
          items: _vm.dataModalGroup.data,
          disabled: _vm.dataModalGroup.data.length === 0,
          "return-object": "",
          chips: "",
          multiple: "",
          "item-value": "groupId",
          "item-text": "name"
        },
        model: {
          value: _vm.idSelected,
          callback: function($$v) {
            _vm.idSelected = $$v
          },
          expression: "idSelected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }