var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("group-list-modal-template", {
    attrs: {
      page: _vm.currPage,
      perPage: _vm.perPage,
      loading: _vm.loading,
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      dataGroupsModal: _vm.dataGroupsModal,
      rowinErrDialog: _vm.rowinErrDialog,
      errorMessageProcess: _vm.errorMessageProcess,
      successItemProcess: _vm.successItemProcess,
      progress: _vm.progress,
      isFinishProcess: _vm.isFinishProcess,
      totalDataRowProcess: _vm.totalDataRowProcess,
      authority: _vm.authority,
      isGroupEditModalShow: _vm.isGroupEditModalShow
    },
    on: {
      changePage: function($event) {
        return _vm.changePage($event)
      },
      changePerPage: function($event) {
        return _vm.changePerPage($event)
      },
      "search-group": function($event) {
        return _vm.searchGroup($event)
      },
      "click-groups-action": function($event) {
        return _vm.clickGroupsAction($event)
      },
      "get-list-group-modal": _vm.getListGroupsModal,
      clickAddGroup: function($event) {
        return _vm.clickAddGroup($event)
      },
      "click-group-detail": function($event) {
        return _vm.$emit("click-group-detail", $event)
      },
      ChangeProperty: function($event) {
        return _vm.ChangeProperty($event)
      },
      clickEditGroup: _vm.clickEditGroup,
      "close-page-modal": function($event) {
        return _vm.$emit("close-page-modal", $event)
      },
      loading: function($event) {
        return _vm.$emit("loading", $event)
      },
      "go-time-settings": function($event) {
        return _vm.$emit("go-time-settings", $event)
      },
      "get-group-isValid": function($event) {
        return _vm.getGroupIsValid($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }