


























































































































































































































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import { Group } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';

const min: string[] = [];
for (let i = 0; i <= 59 ; i++) {
  let value = '';
  if (i < 10) {
    value = '0' + i;
  } else {
    value = i.toString();
  }
  min.push(value);
}
export interface TypeItem {
  title: string;
}
export interface TypeDessert {
  name: string;
  isVaild: boolean;
}
export interface TimeSettings {
  dayTypeId: number;
  dayTypeName: string;
  allDay: number;
  startTime: string;
  endTime: string;
  pos?: number;
  action: string;
  listTime: any[];
}
export interface DataType {
  groupValue: string;
  inputSpecialday: any;
  hours: any[];
  minutes: any[];
  timeS: string;
  hourStartArr: any[];
  minuteStartArr: any[];
  hourEndArr: any[];
  minuteEndArr: any[];
  hoursStartSpecialArr: any[];
  hoursEndSpecialArr: any[];
  errorAddListTime: string;
  errorGate: string;
  allDay: boolean;
  arrayAddSpecialTime: any;
  arrayDelSpecialTime: any;
  rulesValidate: any;
  tmpUnlockingTime: any;
  Message: any;
  tmpArray: any[];
  errorTimeArr: any[];
  gateId: any;
}
export default Vue.extend({
  name: 'GateEditForm',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    TextLink,
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    unlockTimes: {
      type: Array as PropType<TypeDessert[]>,
      default: () => [],
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
    gateDetail: {
      type: Object,
      default: () => ({}),
    },
    groupList: {
      default: [],
    },
    dayTypes: {
      type: Array,
      default: [],
    },
    dataUnlockingTimeConvert: {
      type: Array as PropType<TimeSettings[]>,
      default: [],
    },
    dataUnlockingTime: {
      type: Array as PropType<TimeSettings[]>,
      default: [],
    },
    isFinishEditGate: {
      default: false,
    },
    groupsAddFail: {
      default: [],
    },
    loading: {
      default: false,
    },
    errorMessageTime: {
      type: String,
      default: '',
    },
    listGateModel: {
      type: Array,
      default: () => [],
    },
  },
  data: (): DataType => ({
    groupValue: '',
    inputSpecialday: [],
    hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    minutes: min,
    timeS: '00:00',
    hourStartArr: [],
    minuteStartArr: [],
    hourEndArr: [],
    minuteEndArr: [],
    hoursStartSpecialArr: [],
    hoursEndSpecialArr: [],
    errorTimeArr: ['', '', '', '', ''],
    errorAddListTime: '',
    errorGate: '',
    allDay: false,
    arrayAddSpecialTime: [],
    arrayDelSpecialTime: [],
    tmpUnlockingTime: [],
    rulesValidate: {
      requiredName: (value: string) => !!value || 'ゲート名は64文字以内で入力してください',
      validateName: (name: string) => {
        return name.length <= 64 || 'ゲート名は64文字以内で入力してください。';
      },
      validateOption1: (option: string) => {
      if (option !== null && option !== undefined && option !== '') {
          return option.length <= 64 || '備考1は64文字以内で入力してください。';
        } else {
          return true;
        }
      },
      validateOption2: (option: string) => {
      if (option !== null && option !== undefined && option !== '') {
          return option.length <= 64 || '備考2は64文字以内で入力してください。';
        } else {
          return true;
        }
      },
    },
    Message : Config,
    tmpArray: [],
    gateId: localStorage.getItem('gateId'),
  }),
  mounted() {
    this.tmpArray = [0];
  },
  watch: {
    groupList(value) {
      this.groupValue = this.groupId;
    },
    dataUnlockingTimeConvert(value) {
      this.tmpUnlockingTime = this.dataUnlockingTimeConvert;
    },
    isFinishEditGate(value) {
      if (value) {
        this.arrayAddSpecialTime = [];
        this.arrayDelSpecialTime = [];
      }
    },
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '曜日',
            align: 'start',
            sortable: false,
            value: 'date',
          },
          {
            text: '期間指定',
            align: 'start',
            sortable: false,
            value: 'time',
          },
          {
            text: '',
            align: 'center',
            sortable: false,
            width: '250px !important',
            value: 'delete',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
    filteredItems: {
      get(): any {
        return this.tmpUnlockingTime.filter((i: any) => {
          return (i.action !== 'del');
        });
      },
    },
  },
  methods: {
    clickUpdateGate() {
      const result = (this.$refs.GateAddForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        this.$emit('clickUpdateGate', {
          dataUnlockingTime: this.dataUnlockingTime,
          tmpUnlockingTime: this.tmpUnlockingTime,
          gateDetail: this.gateDetail,
          groupId: this.groupValue,
        });
      }
    },
    formatTime(hours: string, minute: number | string) {
      minute = minute || '00';
      hours = hours || '00';
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}`;
    },
    addSpecialTime() {
      let isExist: boolean = false;
      this.errorAddListTime = '';
      this.errorGate = '';
      let isError = false;

      if (this.inputSpecialday.length === 0 || this.inputSpecialday.value === '') {
        isError = true;
        this.errorGate = '曜日を指定してください。';
        this.errorAddListTime = '';
      }

      for (const item of this.dataUnlockingTimeConvert) {
        const check = (item.action === 'del' ? 0 : item.listTime.length) + this.hoursStartSpecialArr.length;
        const check2 = (item.action === 'del' ? 0 : item.listTime.length) + this.hoursEndSpecialArr.length;

        if (item.dayTypeId === this.inputSpecialday.value && (check > 5 || check2 > 5)) {
          this.errorAddListTime = '同一の曜日での設定が上限を達しております。追加する場合は、一度削除してから実施してください。';
          this.errorGate = '';
          isExist = true;
          break;
        }
      }

      const pattern = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;

      if (this.allDay === false) {
        if (this.hoursStartSpecialArr.length === 0) {
          isError = true;
          this.$set(this.errorTimeArr, 0, '時間を指定してください。');
        }

        for (const index in this.hoursStartSpecialArr) {
          if (this.hoursStartSpecialArr.hasOwnProperty(index)) {

            if (pattern.test(this.hoursStartSpecialArr[index]) === false) {
              isError = true;
              this.$set(this.errorTimeArr, index, '時間を指定してください。');
            }

            if (pattern.test(this.hoursEndSpecialArr[index]) === false) {
              isError = true;
              this.$set(this.errorTimeArr, index, '時間を指定してください。');
            }

            if (this.hoursStartSpecialArr[index] >= this.hoursEndSpecialArr[index]) {
              isError = true;
              this.$set(this.errorTimeArr, index, Config.ERROR_END_TIME);
            }

            if (isError === false) {
              this.$set(this.errorTimeArr, index, '');
            }

            if (this.tmpArray.length > 1 &&
              this.hoursStartSpecialArr[index] === '' &&
              this.hoursEndSpecialArr[index] === '') {
              this.hoursStartSpecialArr.splice(Number(index), 1);
            }
          }
        }
      }



      if (!isExist && isError === false || (!isExist && this.allDay === true && this.inputSpecialday.length !== 0)) {

        const i = this.tmpUnlockingTime.findIndex((e: {
          dayTypeId: any; startTime: string; endTime: string;
          action: string;
        }) => e.dayTypeId === this.inputSpecialday.value && e.action === 'del');

        if (i >= 0) {
          this.tmpUnlockingTime[i].action = 'edit';
          this.tmpUnlockingTime[i].allDay = this.allDay === true ? 1 : 0;

          this.tmpUnlockingTime[i].listTime = this.hoursStartSpecialArr.map((item: any, index: any) => {
            return {
              startTime: this.allDay === true ? '00:00' : this.hoursStartSpecialArr[index],
              endTime: this.allDay === true ? '00:00' : this.hoursEndSpecialArr[index],
            };
          });
        } else {

          const ii = this.tmpUnlockingTime.findLastIndex((e: {
            dayTypeId: any; startTime: string; endTime: string;
            action: string;
          }) => e.dayTypeId === this.inputSpecialday.value);

          const tpmIndex = this.tmpUnlockingTime.findIndex((item: any) =>
            item.dayTypeId === this.inputSpecialday.value,
          );

          if (tpmIndex !== -1) {
            if (this.tmpUnlockingTime[tpmIndex].allDay === 1 && this.allDay === false) {
              this.tmpUnlockingTime[tpmIndex].allDay = 0;

              this.tmpUnlockingTime[tpmIndex].listTime =
                this.hoursStartSpecialArr.map((item: any, index: any) => {
                  return {
                    startTime: this.hoursStartSpecialArr[index],
                    endTime: this.hoursEndSpecialArr[index],
                  };
                });
            } else {
              this.tmpUnlockingTime[tpmIndex].allDay = this.allDay === true ? 1 : 0;

              this.tmpUnlockingTime[tpmIndex].listTime = this.tmpUnlockingTime[tpmIndex].listTime.concat(
                this.hoursStartSpecialArr.map((item: any, index: any) => {
                  return {
                    startTime: this.allDay === true ? '00:00' : this.hoursStartSpecialArr[index],
                    endTime: this.allDay === true ? '00:00' : this.hoursEndSpecialArr[index],
                  };
                }),
              );
            }

            this.tmpUnlockingTime[tpmIndex].action = this.tmpUnlockingTime[tpmIndex].action !== 'add' ?
              'edit' :
              this.tmpUnlockingTime[tpmIndex].action;

            this.hoursStartSpecialArr = [];
            this.hoursEndSpecialArr = [];
            this.allDay = false;
            this.inputSpecialday = [];
            this.tmpArray = [0];
            this.hourStartArr = [];
            this.minuteStartArr = [];
            this.hourEndArr = [];
            this.minuteEndArr = [];

            return;
          }

          this.tmpUnlockingTime.unshift(
            {
              gateId: '',
              dayTypeId: this.inputSpecialday.value,
              dayTypeName: this.inputSpecialday.text,
              allDay: this.allDay === true ? 1 : 0,
              pos: 0,
              action: 'add',
              index: ii > 0 ? this.tmpUnlockingTime[ii].index + 1 : this.tmpUnlockingTime.length,
              listTime: this.hoursStartSpecialArr.map((item: any, index: any) => {
                return {
                  startTime: this.allDay === true ? '00:00' : this.hoursStartSpecialArr[index],
                  endTime: this.allDay === true ? '00:00' : this.hoursEndSpecialArr[index],
                };
              }),
            });
        }
        this.hoursStartSpecialArr = [];
        this.hoursEndSpecialArr = [];
        this.allDay = false;
        this.inputSpecialday = [];
        this.tmpArray = [0];
        this.hourStartArr = [];
        this.minuteStartArr = [];
        this.hourEndArr = [];
        this.minuteEndArr = [];
      }
    },
    deleteSpecialTime(index: any) {
      const i = this.tmpUnlockingTime.map((e: { index: any; }) => e.index).indexOf(index);
      if (this.tmpUnlockingTime[parseInt(i)].action === 'add') {
        this.tmpUnlockingTime.splice(parseInt(i), 1);
      } else {
        this.tmpUnlockingTime[parseInt(i)].action = 'del';
      }
    },
    addRow() {
      if (this.tmpArray.length < 5) {
        this.tmpArray.push(this.tmpArray.length);
      }
    },
    deleteItem(index: any) {
      this.tmpArray.splice(parseInt(index), 1);
      this.hoursStartSpecialArr.splice(parseInt(index), 1);
      this.hoursEndSpecialArr.splice(parseInt(index), 1);
      this.hourStartArr.splice(parseInt(index), 1);
      this.minuteStartArr.splice(parseInt(index), 1);
      this.hourEndArr.splice(parseInt(index), 1);
      this.minuteEndArr.splice(parseInt(index), 1);
    },
  },
});
