var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("group-add-template", {
    attrs: {
      gateItems: _vm.gateItems,
      userItems: _vm.userItems,
      loading: _vm.loading,
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      groupDetail: _vm.groupDetail,
      dataAllTimes: _vm.dataAllTimes,
      dataAllGates: _vm.dataAllGates,
      dataAllUses: _vm.dataAllUses,
      UserSubmittedFail: _vm.UserSubmittedFail,
      GateSubmittedFail: _vm.GateSubmittedFail,
      administratorsAddFail: _vm.administratorsAddFail,
      isEdit: _vm.isEdit,
      expand: _vm.expand,
      gateGroupOptions: _vm.gateGroupOptions,
      isDisabledGroupOptions: _vm.isDisabledGroupOptions
    },
    on: {
      clickBackGroup: function($event) {
        return _vm.clickBackGroup($event)
      },
      clickSaveGroup: function($event) {
        return _vm.clickSaveGroup($event)
      },
      clickUpdateGroup: function($event) {
        return _vm.clickUpdateGroup($event)
      },
      "change-expanded": function($event) {
        return _vm.changeExpanded($event)
      },
      "go-time-settings": function($event) {
        return _vm.$emit("go-time-settings", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }