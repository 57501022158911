var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _vm._l(_vm.successMessages, function(message, index) {
            return _c("alert", {
              key: index,
              attrs: { type: "success", text: message, dismissible: false },
              model: {
                value: _vm.showSuccessMessages[index],
                callback: function($$v) {
                  _vm.$set(_vm.showSuccessMessages, index, $$v)
                },
                expression: "showSuccessMessages[index]"
              }
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.errorMessages, function(message) {
            return _c("alert", {
              key: message,
              attrs: { value: message !== "", type: "error", text: message }
            })
          }),
          _vm._v(" "),
          _c("v-row", { staticClass: "ml-5", attrs: { justify: "start" } }, [
            _c(
              "div",
              { staticClass: "mt-5 mb-3 mr-9" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-secondary ",
                        attrs: { outlined: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("close-look-room")
                          }
                        }
                      },
                      [_vm._v("\n          戻る\n          ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "pt-0" },
        [
          _c("InRoomModalForm", {
            attrs: {
              "room-info": _vm.roomInfo,
              "all-gates": _vm.allGates,
              "room-info-loading": _vm.roomInfoLoading,
              "success-messages": _vm.successMessages,
              "error-messages": _vm.errorMessages
            },
            on: {
              "click-edit-room": function($event) {
                return _vm.$emit("click-edit-room", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }