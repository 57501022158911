var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("gate-lock-add-template", {
    attrs: {
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      successMessageModal: _vm.successMessageModal,
      errorMessageModal: _vm.errorMessageModal,
      gateDetail: _vm.gateDetail,
      listGateModel: _vm.listGateModel,
      isEdit: _vm.isEdit,
      isUserOfGate: _vm.isUserOfGate,
      isGroupOfGate: _vm.isGroupOfGate,
      gateListPages: _vm.gateListPages,
      relationsGateGroup: _vm.relationsGateGroup,
      page: _vm.currPage,
      pageGroup: _vm.currPageGroup,
      userOfGate: _vm.userOfGate,
      pageUser: _vm.currPageUser,
      totalGate: _vm.totalGate,
      listAntiPassGates: _vm.listAntiPassGates,
      loading: _vm.loading,
      showConfirm: _vm.showConfirm,
      groupId: _vm.groupId,
      dayTypes: _vm.dayTypes,
      groupTotalCount: _vm.groupTotalCount,
      userTotalCount: _vm.userTotalCount,
      dataUnlockingTime: _vm.dataUnlockingTime,
      groupsAddFail: _vm.groupsAddFail,
      isFinishDetailGate: _vm.isFinishDetailGate,
      isShowConfirmUser: _vm.isShowConfirmUser,
      showProgressUser: _vm.showProgressUser,
      userTotalCountDel: _vm.userTotalCountDel,
      totalDataRowProcessUser: _vm.totalDataRowProcessUser,
      progressUser: _vm.progressUser,
      successItemProcessUser: _vm.successItemProcessUser,
      errorMessageProcessUser: _vm.errorMessageProcessUser,
      isFinishProcessUser: _vm.isFinishProcessUser,
      isShowConfirmGroup: _vm.isShowConfirmGroup,
      showProgressGroup: _vm.showProgressGroup,
      groupTotalCountDel: _vm.groupTotalCountDel,
      totalDataRowProcessGroup: _vm.totalDataRowProcessGroup,
      progressGroup: _vm.progressGroup,
      successItemProcessGroup: _vm.successItemProcessGroup,
      errorMessageProcessGroup: _vm.errorMessageProcessGroup,
      isFinishProcessGroup: _vm.isFinishProcessGroup,
      isEditGate: _vm.isEditGate
    },
    on: {
      clickBackGate: function($event) {
        return _vm.clickBackGate($event)
      },
      clickRemoteUnlock: function($event) {
        return _vm.remoteUnlock($event)
      },
      clickConfirm: function($event) {
        return _vm.clickConfirm($event)
      },
      closeModalConfirm: function($event) {
        return _vm.closeModalConfirm($event)
      },
      changeInfoGate: function($event) {
        return _vm.changeInfoGate($event)
      },
      resetAntiPassGate: function($event) {
        return _vm.resetAntiPassGate($event)
      },
      disableAntiModes: function($event) {
        return _vm.disableAntiModes($event)
      },
      "get-gates": function($event) {
        return _vm.getGates($event)
      },
      "change-page": function($event) {
        return _vm.changePage($event)
      },
      "change-page-user": function($event) {
        return _vm.changePageUser($event)
      },
      "change-page-group": function($event) {
        return _vm.changePageGroup($event)
      },
      "search-page": function($event) {
        return _vm.searchPage($event)
      },
      setGate: function($event) {
        return _vm.setGate($event)
      },
      "register-gates": function($event) {
        return _vm.registerGates($event)
      },
      "edit-gates": function($event) {
        return _vm.editGates($event)
      },
      clickRemoveUser: function($event) {
        return _vm.clickRemoveUser($event)
      },
      clickRemoveGroup: function($event) {
        return _vm.clickRemoveGroup($event)
      },
      closeModalProgressUser: function($event) {
        return _vm.closeModalProgressUser($event)
      },
      closeModalProgressGroup: function($event) {
        return _vm.closeModalProgressGroup($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }