var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-add-form" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-form",
            { ref: "formGroup", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-6 pr-10 mb-5" },
                [
                  _c("input-form", {
                    attrs: {
                      rules: [_vm.rulesValidate.validateGroupName],
                      title:
                        "通行権限グループ名 <span class='required'>※必須</span>",
                      inputType: "text"
                    },
                    model: {
                      value: _vm.groupNameValue,
                      callback: function($$v) {
                        _vm.groupNameValue = $$v
                      },
                      expression: "groupNameValue"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { staticClass: "col-2 mb-0 pb-0" }),
                      _vm._v(" "),
                      _c("v-col", { staticClass: "col-2 mb-0 pb-0" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "v-subheader theme--light ml-0 pa-0 mt-0 mb-0"
                          },
                          [_vm._v(" 通行権限グループ管理者")]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.listGroup, function(item, i) {
                    return _c(
                      "div",
                      { key: i },
                      [
                        _vm.checkShow(item)
                          ? _c(
                              "v-row",
                              { staticClass: "mt-0" },
                              [
                                _c("v-col", { staticClass: "col-2" }, [
                                  _c("div", {
                                    staticClass: "v-subheader theme--light",
                                    attrs: { "data-v-f4f20dd2": "" }
                                  })
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { md: "2", sm: "12", cols: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "2" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { icon: "", al: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteItem(i)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "gray--text",
                                                    attrs: {
                                                      "align-self": "ceb="
                                                    }
                                                  },
                                                  [_vm._v("mdi-minus-circle")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "10" } },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                items: _vm.dataAllow,
                                                "item-title": "text",
                                                "item-value": "value",
                                                "single-line": "",
                                                color: "green",
                                                "hide-details": "auto",
                                                outlined: "",
                                                dense: "",
                                                "return-object": "",
                                                "no-data-text": "",
                                                disabled:
                                                  item.disabled === true,
                                                "menu-props": {
                                                  contentClass: "v-select-item"
                                                }
                                              },
                                              on: { change: _vm.changeData },
                                              model: {
                                                value: item.Ids,
                                                callback: function($$v) {
                                                  _vm.$set(item, "Ids", $$v)
                                                },
                                                expression: "item.Ids"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { md: "8", sm: "12", cols: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "item-check-box" },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _vm.isEdit === true
                                              ? _c("v-checkbox", {
                                                  attrs: {
                                                    disabled: "",
                                                    color: "grey",
                                                    dense: "",
                                                    "hide-details": "",
                                                    label: "ゲート閲覧権限"
                                                  },
                                                  model: {
                                                    value: item.isAdminToView,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "isAdminToView",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.isAdminToView"
                                                  }
                                                })
                                              : _c("v-checkbox", {
                                                  attrs: {
                                                    color: "grey",
                                                    dense: "",
                                                    "hide-details": "",
                                                    label: "ゲート閲覧権限"
                                                  },
                                                  model: {
                                                    value: item.isAdminToView,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "isAdminToView",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.isAdminToView"
                                                  }
                                                })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c("v-checkbox", {
                                              attrs: {
                                                color: "green",
                                                dense: "",
                                                "hide-details": "",
                                                label:
                                                  "通行可能所属/ユーザーの設定権限"
                                              },
                                              model: {
                                                value:
                                                  item.isAdminOfGroupPassages,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "isAdminOfGroupPassages",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.isAdminOfGroupPassages"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c("v-checkbox", {
                                              attrs: {
                                                color: "green",
                                                dense: "",
                                                "hide-details": "",
                                                label:
                                                  "一時通行可能ユーザーの設定権限"
                                              },
                                              model: {
                                                value:
                                                  item.isAdminOfGatePassages,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "isAdminOfGatePassages",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.isAdminOfGatePassages"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c("v-checkbox", {
                                              attrs: {
                                                color: "green",
                                                dense: "",
                                                "hide-details": "",
                                                label: "遠隔解錠権限"
                                              },
                                              model: {
                                                value:
                                                  item.isAdminOfRemoteUnlock,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "isAdminOfRemoteUnlock",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.isAdminOfRemoteUnlock"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mt-0 pt-0" },
                    [
                      _c("v-col", { staticClass: "col-2 pt-0" }),
                      _vm._v(" "),
                      _c("v-col", { staticClass: "col-2 pt-0" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "v-subheader theme--light ml-0 pa-0 mt-0 add-button",
                            on: { click: _vm.addGroup }
                          },
                          [_vm._v(" + 追加する ")]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "input-form mt-3" },
                    [
                      _c(
                        "v-row",
                        [
                          _vm.$vuetify.breakpoint.mdAndUp
                            ? _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-subheader", [
                                    _vm._v(
                                      "\n                ゲート\n              "
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                staticClass: "allig-multi-select select-all",
                                attrs: {
                                  items: _vm.dataAllGates,
                                  "return-object": "",
                                  multiple: "",
                                  "item-color": "green darken-3",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: "",
                                  "no-data-text": "",
                                  chips: "",
                                  "deletable-chips": ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-item",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: { ripple: "" },
                                            on: { click: _vm.toggle }
                                          },
                                          [
                                            _c(
                                              "v-list-item-action",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color:
                                                        _vm.gateValue.length > 0
                                                          ? "indigo darken-4"
                                                          : ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(_vm.icon) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    "\n                      全選択／解除\n                  "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("v-divider", { staticClass: "mt-2" })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.gateValue,
                                  callback: function($$v) {
                                    _vm.gateValue = $$v
                                  },
                                  expression: "gateValue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c("v-subheader", [
                                _vm._v(
                                  "\n              通行可能時間設定\n            "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.dataAllTimes,
                              color: "green",
                              "return-object": "",
                              "hide-details": "auto",
                              outlined: "",
                              dense: "",
                              "no-data-text": "",
                              clearable: "",
                              disabled: _vm.isGateLock === 2
                            },
                            model: {
                              value: _vm.inputTime,
                              callback: function($$v) {
                                _vm.inputTime = $$v
                              },
                              expression: "inputTime"
                            }
                          }),
                          _vm._v(" "),
                          _vm.isGateLock === 1
                            ? _c(
                                "p",
                                { staticClass: "red--text mgs-gate-lock" },
                                [
                                  _vm._v(
                                    "※通行可能時間設定は、ALLIGATE Lock Pro のゲートのみ設定が反映されます"
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c("v-col", { attrs: { cols: "2" } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c("text-link", {
                            staticClass: "item-user-name block",
                            attrs: {
                              text: "通行可能時間設定を新規作成/編集する"
                            },
                            on: { click: _vm.clickTimeSettings }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _vm.isEdit === true
                ? _c(
                    "v-row",
                    { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
                    [
                      _vm.successMessageLogs !== "" &&
                      _vm.GateSubmittedFail.length === 0 &&
                      _vm.UserSubmittedFail.length === 0 &&
                      _vm.administratorsAddFail.length === 0 &&
                      _vm.loading === false
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mb-4 font-weight-bold mt-4 bg-success"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.successMessageLogs) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.successMessageLogs !== "" &&
                      _vm.GateSubmittedFail.length === 0 &&
                      _vm.UserSubmittedFail.length === 0 &&
                      _vm.administratorsAddFail.length > 0 &&
                      _vm.loading === false
                        ? _c(
                            "v-col",
                            {
                              staticClass: "mb-4 font-weight-bold mt-4 bg-error"
                            },
                            [
                              _vm.isEdit
                                ? _c("span", [
                                    _vm._v("グループが編集されました。")
                                  ])
                                : _c("span", [
                                    _vm._v("グループが追加されました。")
                                  ]),
                              _vm._v(
                                "通行権限グループ管理者紐づけに失敗しています。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            通行権限グループ管理者: " +
                                  _vm._s(_vm.administratorsAddFail.toString()) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.successMessageLogs !== "" &&
                      _vm.GateSubmittedFail.length > 0 &&
                      _vm.UserSubmittedFail.length > 0 &&
                      _vm.loading === false
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                              attrs: { cols: "11", align: "right" }
                            },
                            [
                              _vm.isEdit
                                ? _c("span", [
                                    _vm._v("グループが編集されました。")
                                  ])
                                : _c("span", [
                                    _vm._v("グループが追加されました。")
                                  ]),
                              _vm._v(
                                "ゲートとユーザーの紐づけに失敗しています。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            グループ編集より、ゲートとユーザーの確認をしてください。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            別の通行可能時間帯が設定されているグループに紐づいている場合、追加することができません。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            登録失敗ゲート名: " +
                                  _vm._s(_vm.GateSubmittedFail.toString()) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            登録失敗ユーザー名: " +
                                  _vm._s(_vm.UserSubmittedFail.toString()) +
                                  "\n            "
                              ),
                              _vm.administratorsAddFail.length > 0
                                ? _c("span", [
                                    _c("br"),
                                    _vm._v(
                                      " 登録失敗ユーザー名: " +
                                        _vm._s(
                                          _vm.administratorsAddFail.toString()
                                        )
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.successMessageLogs !== "" &&
                      _vm.GateSubmittedFail.length > 0 &&
                      _vm.UserSubmittedFail.length === 0 &&
                      _vm.loading === false
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                              attrs: { cols: "11", align: "right" }
                            },
                            [
                              _vm.isEdit
                                ? _c("span", [
                                    _vm._v("グループが編集されました。")
                                  ])
                                : _c("span", [
                                    _vm._v("グループが追加されました。")
                                  ]),
                              _vm._v("ゲートの紐づけに失敗しています。"),
                              _c("br"),
                              _vm._v(
                                "\n            グループ編集より、ゲートの確認をしてください。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            別の通行可能時間帯が設定されているグループに紐づいている場合、追加することができません。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            登録失敗ゲート名: " +
                                  _vm._s(_vm.GateSubmittedFail.toString()) +
                                  "\n            "
                              ),
                              _vm.administratorsAddFail.length > 0
                                ? _c("span", [
                                    _c("br"),
                                    _vm._v(
                                      " 登録失敗ユーザー名: " +
                                        _vm._s(
                                          _vm.administratorsAddFail.toString()
                                        )
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.successMessageLogs !== "" &&
                      _vm.GateSubmittedFail.length === 0 &&
                      _vm.UserSubmittedFail.length > 0 &&
                      _vm.loading === false
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                              attrs: { cols: "11", align: "right" }
                            },
                            [
                              _vm.isEdit
                                ? _c("span", [
                                    _vm._v("グループが編集されました。")
                                  ])
                                : _c("span", [
                                    _vm._v("グループが追加されました。")
                                  ]),
                              _vm._v("ユーザーの紐づけに失敗しています。"),
                              _c("br"),
                              _vm._v(
                                "\n            グループ編集より、ユーザーの確認をしてください。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            別の通行可能時間帯が設定されているグループに紐づいている場合、追加することができません。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            登録失敗ユーザー名: " +
                                  _vm._s(_vm.UserSubmittedFail.toString()) +
                                  "\n            "
                              ),
                              _vm.administratorsAddFail.length > 0
                                ? _c("span", [
                                    _c("br"),
                                    _vm._v(
                                      " 登録失敗ユーザー名: " +
                                        _vm._s(
                                          _vm.administratorsAddFail.toString()
                                        )
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageLogs !== "" && _vm.loading === false
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                              attrs: { cols: "11", align: "right" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errorMessageLogs) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "messageRow",
                          attrs: { cols: "1", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: {
                                disabled:
                                  _vm.groupDetail.groupId === null ||
                                  _vm.groupDetail.groupId === undefined ||
                                  _vm.groupDetail.groupId === "",
                                outlined: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.clickUpdateGroup()
                                }
                              }
                            },
                            [_vm._v("\n            登録\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    { staticClass: "mb-2 mt-2", attrs: { justify: "end" } },
                    [
                      _vm.successMessageLogs !== "" &&
                      _vm.GateSubmittedFail.length === 0 &&
                      _vm.UserSubmittedFail.length === 0 &&
                      _vm.administratorsAddFail.length === 0 &&
                      _vm.loading === false
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mb-4 font-weight-bold mt-4 bg-success"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.successMessageLogs) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.successMessageLogs !== "" &&
                      _vm.GateSubmittedFail.length === 0 &&
                      _vm.UserSubmittedFail.length === 0 &&
                      _vm.administratorsAddFail.length > 0 &&
                      _vm.loading === false
                        ? _c(
                            "v-col",
                            {
                              staticClass: "mb-4 font-weight-bold mt-4 bg-error"
                            },
                            [
                              _vm.isEdit
                                ? _c("span", [
                                    _vm._v("グループが編集されました。")
                                  ])
                                : _c("span", [
                                    _vm._v("グループが追加されました。")
                                  ]),
                              _vm._v(
                                "通行権限グループ管理者紐づけに失敗しています。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            通行権限グループ管理者: " +
                                  _vm._s(_vm.administratorsAddFail.toString()) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.successMessageLogs !== "" &&
                      _vm.GateSubmittedFail.length > 0 &&
                      _vm.UserSubmittedFail.length > 0 &&
                      _vm.loading === false
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                              attrs: { cols: "11", align: "right" }
                            },
                            [
                              _vm.isEdit
                                ? _c("span", [
                                    _vm._v("グループが編集されました。")
                                  ])
                                : _c("span", [
                                    _vm._v("グループが追加されました。")
                                  ]),
                              _vm._v(
                                "ゲートとユーザーの紐づけに失敗しています。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            グループ編集より、ゲートとユーザーの確認をしてください。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            別の通行可能時間帯が設定されているグループに紐づいている場合、追加することができません。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            登録失敗ゲート名: " +
                                  _vm._s(_vm.GateSubmittedFail.toString()) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            登録失敗ユーザー名: " +
                                  _vm._s(_vm.UserSubmittedFail.toString()) +
                                  "\n            "
                              ),
                              _vm.administratorsAddFail.length > 0
                                ? _c("span", [
                                    _c("br"),
                                    _vm._v(
                                      " 登録失敗ユーザー名: " +
                                        _vm._s(
                                          _vm.administratorsAddFail.toString()
                                        )
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.successMessageLogs !== "" &&
                      _vm.GateSubmittedFail.length > 0 &&
                      _vm.UserSubmittedFail.length === 0 &&
                      _vm.loading === false
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                              attrs: { cols: "11", align: "right" }
                            },
                            [
                              _vm.isEdit
                                ? _c("span", [
                                    _vm._v("グループが編集されました。")
                                  ])
                                : _c("span", [
                                    _vm._v("グループが追加されました。")
                                  ]),
                              _vm._v("ゲートの紐づけに失敗しています。"),
                              _c("br"),
                              _vm._v(
                                "\n            グループ編集より、ゲートの確認をしてください。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            別の通行可能時間帯が設定されているグループに紐づいている場合、追加することができません。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            登録失敗ゲート名: " +
                                  _vm._s(_vm.GateSubmittedFail.toString()) +
                                  "\n            "
                              ),
                              _vm.administratorsAddFail.length > 0
                                ? _c("span", [
                                    _c("br"),
                                    _vm._v(
                                      " 登録失敗ユーザー名: " +
                                        _vm._s(
                                          _vm.administratorsAddFail.toString()
                                        )
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.successMessageLogs !== "" &&
                      _vm.GateSubmittedFail.length === 0 &&
                      _vm.UserSubmittedFail.length > 0 &&
                      _vm.loading === false
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                              attrs: { cols: "11", align: "right" }
                            },
                            [
                              _vm.isEdit
                                ? _c("span", [
                                    _vm._v("グループが編集されました。")
                                  ])
                                : _c("span", [
                                    _vm._v("グループが追加されました。")
                                  ]),
                              _vm._v("ユーザーの紐づけに失敗しています。"),
                              _c("br"),
                              _vm._v(
                                "\n            グループ編集より、ユーザーの確認をしてください。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            別の通行可能時間帯が設定されているグループに紐づいている場合、追加することができません。"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            登録失敗ユーザー名: " +
                                  _vm._s(_vm.UserSubmittedFail.toString()) +
                                  "\n            "
                              ),
                              _vm.administratorsAddFail.length > 0
                                ? _c("span", [
                                    _c("br"),
                                    _vm._v(
                                      " 登録失敗ユーザー名: " +
                                        _vm._s(
                                          _vm.administratorsAddFail.toString()
                                        )
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageLogs !== "" && _vm.loading === false
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mb-4 font-weight-bold mt-4 bg-error bg-no messageRow",
                              attrs: { cols: "11", align: "right" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errorMessageLogs) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "messageRow",
                          attrs: { cols: "1", align: "right" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.clickSaveGroup()
                                }
                              }
                            },
                            [_vm._v("\n            登録\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }