var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-list-template" },
    [
      _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c("v-container", [
        _c(
          "div",
          { staticClass: "container-user" },
          [
            _c(
              "div",
              { staticClass: "round-action" },
              [
                _c(
                  "v-row",
                  { staticClass: "btn-div", attrs: { justify: "end" } },
                  [
                    _c("v-col", { attrs: { xl: "3", sm: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "area-search-box" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between"
                              }
                            },
                            [
                              _c("v-col", { staticClass: "col-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "v-subheader theme--light" },
                                  [_vm._v(" 通行権限グループ ")]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "col-8 mt-0" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.groupList,
                                      "item-text": "text",
                                      "item-value": "value",
                                      "single-line": "",
                                      color: "green",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: "",
                                      "no-data-text": ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.changeGroupId($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.groupItem,
                                      callback: function($$v) {
                                        _vm.groupItem = $$v
                                      },
                                      expression: "groupItem"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "ml-5 ", attrs: { cols: "2" } },
                      [
                        _c("text-link", {
                          staticClass: "item-user-name",
                          attrs: { text: "全ての通行権限グループを見る" },
                          on: {
                            click: function($event) {
                              _vm.isShow = true
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      {},
                      [
                        _vm.authority.isAdmin === true
                          ? _c("text-link", {
                              staticClass: "item-user-name ml-5",
                              attrs: { text: "全ての制御盤/ゲートを見る" },
                              on: {
                                click: function($event) {
                                  _vm.isShowGate = true
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("v-container", [
              _c(
                "div",
                { staticClass: "round-tab" },
                [
                  [
                    _c(
                      "v-tabs",
                      {
                        attrs: { transition: false },
                        on: {
                          change: function($event) {
                            return _vm.$emit("changeTab", {
                              tab: _vm.tab,
                              groupId: _vm.groupItem
                            })
                          }
                        },
                        model: {
                          value: _vm.tab,
                          callback: function($$v) {
                            _vm.tab = $$v
                          },
                          expression: "tab"
                        }
                      },
                      _vm._l(_vm.actionItems, function(item) {
                        return _c(
                          "v-tab",
                          { key: item.tabname, staticClass: "log-tab" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.title) +
                                "\n          "
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  _vm._v(" "),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    _vm._l(_vm.actionItems, function(item) {
                      return _c(
                        "v-tab-item",
                        {
                          key: item.tabname,
                          attrs: {
                            transition: false,
                            "reverse-transition": false
                          }
                        },
                        [
                          item.tabname === "tab1"
                            ? _c(
                                "v-card",
                                [
                                  _vm.groupItem !== "" && _vm.tab === 0
                                    ? _c("gate-list-page", {
                                        attrs: {
                                          "tab:": _vm.tab,
                                          groupId: _vm.groupItem
                                        },
                                        on: {
                                          "close-modal-control": function(
                                            $event
                                          ) {
                                            return _vm.loadGroupId(
                                              _vm.groupItem
                                            )
                                          },
                                          loading: function($event) {
                                            return _vm.$emit(
                                              "loading-from-child",
                                              $event
                                            )
                                          },
                                          clickGroupDetail: function($event) {
                                            return _vm.clickGroupDetail($event)
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.tabname === "tab2"
                            ? _c(
                                "v-card",
                                [
                                  _c("AffiliationAllowedTable", {
                                    attrs: {
                                      authority: _vm.authority,
                                      optionDepartments: _vm.optionDepartments,
                                      dataList:
                                        _vm.dataGroup.department !== undefined
                                          ? _vm.dataGroup.department.data
                                          : [],
                                      loading: _vm.dataGroup.department.loading,
                                      loadingAff: _vm.loadingAff,
                                      departments: _vm.departments,
                                      dataRelationDepartmentGroup:
                                        _vm.dataRelationDepartmentGroup
                                    },
                                    on: {
                                      "load-list-aff": function($event) {
                                        return _vm.$emit("load-list-aff", 1)
                                      },
                                      "close-modal": function($event) {
                                        _vm.$emit("load-list-aff", 1),
                                          _vm.$emit(
                                            "getDepartmentGroup",
                                            _vm.groupItem
                                          )
                                      },
                                      "close-modal-add": function($event) {
                                        return _vm.$emit(
                                          "close-modal-add",
                                          _vm.groupItem
                                        )
                                      },
                                      "change-page-aff": function($event) {
                                        return _vm.$emit(
                                          "change-page-aff",
                                          $event
                                        )
                                      },
                                      "add-aff-to-group": function($event) {
                                        return _vm.$emit("add-aff-to-group", {
                                          groupId: _vm.groupItem,
                                          aff: $event
                                        })
                                      },
                                      "remove-aff-to-group": function($event) {
                                        return _vm.$emit(
                                          "remove-aff-to-group",
                                          {
                                            groupId: _vm.groupItem,
                                            aff: $event
                                          }
                                        )
                                      },
                                      "loading-from-child": function($event) {
                                        return _vm.$emit(
                                          "loading-from-child",
                                          $event
                                        )
                                      },
                                      "back-group-detail": function($event) {
                                        return _vm.clickGroupDetail($event)
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("UsersAllowedTable", {
                                    attrs: {
                                      groupId: _vm.groupItem,
                                      authority: _vm.authority,
                                      dataList:
                                        _vm.dataGroup.groupPassages !==
                                        undefined
                                          ? _vm.dataGroup.groupPassages.data
                                          : [],
                                      loading:
                                        _vm.dataGroup.groupPassages !==
                                        undefined
                                          ? _vm.dataGroup.groupPassages.loading
                                          : false,
                                      totalCount:
                                        _vm.dataGroup.groupPassages.total !==
                                        undefined
                                          ? _vm.dataGroup.groupPassages.total
                                          : 0,
                                      perPage:
                                        _vm.dataGroup.groupPassages.perPage !==
                                        undefined
                                          ? _vm.dataGroup.groupPassages.perPage
                                          : 25,
                                      page:
                                        _vm.dataGroup.groupPassages.page !==
                                        undefined
                                          ? _vm.dataGroup.groupPassages.page
                                          : 1,
                                      isPage: "group",
                                      dataRelationUser:
                                        _vm.dataRelationUserGroup
                                    },
                                    on: {
                                      "load-users": function($event) {
                                        return _vm.$emit(
                                          "load-users",
                                          _vm.groupItem
                                        )
                                      },
                                      "remove-user-to-group": function($event) {
                                        return _vm.$emit(
                                          "remove-user-to-group",
                                          {
                                            groupId: _vm.groupItem,
                                            users: $event
                                          }
                                        )
                                      },
                                      "close-modal": function($event) {
                                        return _vm.$emit(
                                          "load-users",
                                          _vm.groupItem
                                        )
                                      },
                                      "change-page": function($event) {
                                        return _vm.$emit("change-page-users", {
                                          groupId: _vm.groupItem,
                                          $event: $event
                                        })
                                      },
                                      "search-user": function($event) {
                                        return _vm.$emit(
                                          "search-user-group-passage",
                                          {
                                            groupId: _vm.groupItem,
                                            data: $event
                                          }
                                        )
                                      },
                                      "loading-from-child": function($event) {
                                        return _vm.$emit(
                                          "loading-from-child",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.tabname === "tab3"
                            ? _c(
                                "v-card",
                                [
                                  _c("TimePeriodAllowed", {
                                    attrs: {
                                      authority: _vm.authority,
                                      dataList:
                                        _vm.dataGroup.passableTimeSetting !==
                                        undefined
                                          ? _vm.dataGroup.passableTimeSetting
                                          : [],
                                      passableName:
                                        _vm.dataGroup.passableName !== undefined
                                          ? _vm.dataGroup.passableName
                                          : "",
                                      loading: _vm.dataGroup.loading,
                                      groupId: _vm.groupItem
                                    },
                                    on: {
                                      "click-EditGate": function($event) {
                                        return _vm.$emit(
                                          "click-EditGate",
                                          $event
                                        )
                                      },
                                      "close-modal": function($event) {
                                        return _vm.$emit(
                                          "load-passable-time-setting",
                                          _vm.groupItem
                                        )
                                      },
                                      "loading-from-child": function($event) {
                                        return _vm.$emit(
                                          "loading-from-child",
                                          $event
                                        )
                                      },
                                      "go-time-settings": _vm.goTimeSettings
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.tabname === "tab4"
                            ? _c(
                                "v-card",
                                [
                                  _c("TemporaryAccessAuthority", {
                                    attrs: {
                                      authority: _vm.authority,
                                      gateList: _vm.gateList,
                                      userList: _vm.userList,
                                      errorMessageLogs: _vm.errorMessageLogs,
                                      dataList:
                                        _vm.dataGroup.realtionsGateList !==
                                        undefined
                                          ? _vm.dataGroup.realtionsGateList.data
                                          : [],
                                      dataGatePass: _vm.dataGatePass,
                                      loading:
                                        _vm.dataGroup.realtionsGateList !==
                                        undefined
                                          ? _vm.dataGroup.realtionsGateList
                                              .loading
                                          : false
                                    },
                                    on: {
                                      "add-gate": function($event) {
                                        return _vm.$emit("add-gate", $event)
                                      },
                                      "load-gates": function($event) {
                                        return _vm.$emit("load-gates", 1)
                                      },
                                      "remove-gate-pass": function($event) {
                                        return _vm.$emit(
                                          "remove-gate-pass",
                                          $event
                                        )
                                      },
                                      "remove-gate-pass-item": function(
                                        $event
                                      ) {
                                        return _vm.$emit(
                                          "remove-gate-pass",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                2
              )
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "800", persistent: "" },
          model: {
            value: _vm.isShow,
            callback: function($$v) {
              _vm.isShow = $$v
            },
            expression: "isShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _vm.isShow
                ? _c("GroupListModalPage", {
                    on: {
                      "go-time-settings": _vm.goTimeSettings,
                      "click-group-detail": function($event) {
                        return _vm.clickGroupDetail($event)
                      },
                      "close-page-modal": function($event) {
                        _vm.isShow = false
                        _vm.loadGroupId(_vm.groupItem)
                      },
                      loading: function($event) {
                        return _vm.$emit("loading-from-child", $event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "800", persistent: "" },
          model: {
            value: _vm.isShowGate,
            callback: function($$v) {
              _vm.isShowGate = $$v
            },
            expression: "isShowGate"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v("全ての制御盤/ゲート一覧")
              ]),
              _vm._v(" "),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "ml-5", attrs: { justify: "start" } },
                    [
                      _c(
                        "div",
                        { staticClass: "mt-5 mb-3 mr-9" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary ",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.isShowGate = false
                                      _vm.loadGroupId(_vm.groupItem)
                                    }
                                  }
                                },
                                [_vm._v("\n              戻る\n              ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.isShowGate
                ? _c("gate-list-page", {
                    attrs: { inPage: "modal-group", groupId: "no-id" },
                    on: {
                      loading: function($event) {
                        return _vm.$emit("loading-from-child", $event)
                      },
                      clickGroupDetail: function($event) {
                        return _vm.clickGroupDetail($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }