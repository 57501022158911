





















import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import Searchbox from '@/components/molecules/Searchbox/Searchbox.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import { DataTableHeader } from 'vuetify';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import moment from 'moment';

export interface TypeItem {
  title: string;
}
export interface DataType {
  displayOptionItems: DisplayOptionItem[];
  selectedDisplayOption: string[];
  idSelected: [];
  showOption: boolean;
  isVaildOnly: boolean;
  isShow: boolean;
  isShowUserDetail: boolean;
  // checkedColumnNames: any;
}

export default Vue.extend({
  name: 'UsersListTable',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    Searchbox,
    ShowPerPage,
    Pagination,
    SelectPerPage,
    DisplayOption,
    TextLink,
  },
  watch: {
    dataGroupAdd(val: any) {
      this.idSelected = val;
    },
    idSelected(val: any) {
      this.$emit('select-Ids', val.map((item: any) => item));
    },
    dataGroupPass(data: any) {
      if (data.length > 0) {
        this.idSelected = [];
        this.dataModalGroup.data.forEach((element: any, key: any) => {
        const check = data.some((item: any) => item.groupId === element.groupId);
        if (check) {
          this.dataModalGroup.data[key].disabled = true;
        }
      });
      }
    },
  },
  props: {
    dataGroupAdd: {
      type: Array,
      default: () => [],
    },
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    dataModalGroup: {
        type: Object,
        default: {
          data: [],
          total: 0,
          page: 1,
          perPage: 25,
        },
      },
      dataGroupPass: {
        type: Array,
        default: {
          data: [],
        },
      },
  },
  mounted() {
    //
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: 'グループID',
            align: 'start',
            sortable: true,
            value: 'groupId',
          },
        ];
        if (this.selectedDisplayOption.includes('name')) {
          headers.push({
            text: 'グループ名',
            align: 'start',
            sortable: true,
            value: 'name',
          });
        }
        return headers;
      },
      set(): void {
        return;
      },
    },
    innerPage: {
      get(): number {
        return this.dataModalGroup.page;
      },
      set(val: number): void {
        this.$emit('change-page', val);
        return;
      },
    },
    innerPerPage: {
      get(): number {
        return this.dataModalGroup.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page', val);
        return;
      },
    },
  },
  data: (): DataType => ({
    isShow: false,
    isShowUserDetail: false,
    selectedDisplayOption: [
      'groupId',
      'name',
    ],
    displayOptionItems: [
      { label: 'グループID', value: 'groupId', isDisabled: true },
      { label: 'グループ名', value: 'name', isDisabled: false },
    ],
    idSelected: [],
    showOption: false,
    isVaildOnly: localStorage.isVaildDataCard === 'true' ? true : false,
  }),
  methods: {
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      items.sort((a: any, b: any) => {
        if (!isDescending[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
        }
      });
      return items;
    },
  },
});
