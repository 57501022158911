









































































































































































































































































































































































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import GroupGateTable from '@/components/organisms/GroupGateTable/GroupGateTable.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import GateDetailTemplate from '@/components/templates/GateDetailTemplate/GateDetailTemplate.vue';
import { User, Group, RelationGateGroup, UnlockGate } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
import ModalPage from '@/components/pages/common-modal/ModalPage.vue';
import DateTimePicker from '@/components/molecules/DateTimePicker/DateTimePicker.vue';
import ModalPageGroupGateInDetail from '@/components/pages/common-modal/ModalPage.vue';
import UserAddPage from '@/components/pages/user/UserAddPage.vue';
import moment from 'moment';

export interface GatePassages {
  gateId: string;
  gateName: string;
  notAfter: number;
  notBefore: number;
}
export interface DataType {
  searchTargetItems: SearchTargetItem[];
  selectedDisplayOption: string[];
  selectedItem: User[];
  searchItem: [];
  sortBy: any;
  selectedGroupIds: string[];
  isCheck: boolean;
  isShowGroup: boolean;
  selectedIds: string[];
  timeStartGate: string;
  timeEndGate: string;
  dateStartGate: string;
  dateEndGate: string;
  itemGate: any;
  errGate: any;
  dataGatePassages: any;
  isShow: boolean;
  showConfirm: boolean;
  passageId: any;
  showProgress: boolean;
}

export default Vue.extend({
  name: 'UserTable',
  components: {
    CancelButton,
    DisplayOption,
    Pagination,
    Searchbox,
    SelectPerPage,
    ShowPerPage,
    SuccessButton,
    TextLink,
    GroupGateTable,
    InfoItem,
    InputForm,
    GateDetailTemplate,
    ModalPage,
    DateTimePicker,
    ModalPageGroupGateInDetail,
    UserAddPage,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    listGatePassagesInUser: {
      type: Array as PropType<GatePassages[]>,
      default: () => [],
    },
    listphones: {
      type: Array,
      default: () => [],
    },
    tempGroupPassages: {
      default: () => [],
    },
    cardItems: {
      default: () => [],
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    authority: {
      type: Object,
      default: ({}),
    },
    user: {
      type: Object as PropType<User>,
      default: () => ({
      userId: '',
      name: '',
      furigana: '',
      email: '',
      phoneNumber: '',
      isAdmin: false,
      isPhoneAdmin: false,
      shareKeyBeValid: false,
      isMfaSms: false,
      passCode: '',
      isValid: false,
      option1: '',
      option2: '',
      isPasswordLoginValid: false,
      groupCount: 0,
      gateCount: 0,
      timelimitedGateCount: 0,
      department: '',
      groupAdministrators: [{
        name: '',
        isAdminToView: false,
        isAdminOfGroupPassages: false,
        isAdminOfGatePassages: false,
        isAdminOfRemoteUnlock: false,
      }],
      departmentAdministrators: [{
        name: '',
        isAdminToView: false,
        isAdminToAddUser: false,
        isAdminToEditUser: false,
        isAdminToSetPcodesToUser: false,
      }],
    }),
    },
    phoneItems: {
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    messageSetting: {
      default: () => ({}),
    },
    dataProgress: {
      default: () => ({}),
    },
    gateList: {
      type: Array,
      default: () => [],
    },
    dataAllow: {
      type: Array,
      default: () => [],
    },
  },
  data: (): DataType => ({
    dataGatePassages: [],
    errGate: {
      errAdd: '',
      errorGate: '',
      errorTime: '',
    },
    itemGate: {},
    timeStartGate: '',
    timeEndGate: '',
    dateStartGate: '',
    dateEndGate: '',
    isShowGroup: false,
    selectedIds: [],
    isCheck: true,
    searchTargetItems: [
      { label: 'ユーザー名', value: 'id' },
      { label: 'メールアドレス', value: 'time' },
    ],
    selectedDisplayOption: [
      'id',
      'time',
    ],
    selectedItem: [],
    searchItem: [],
    selectedGroupIds: [],
    sortBy: 'time',
    isShow: false,
    showConfirm: false,
    passageId: '',
    showProgress: false,
  }),
  computed: {
    headerGateTable: {
      get(): DataTableHeader[] {
        const ret: DataTableHeader[] = [
          {
            text: 'ゲート名',
            align: 'start',
            sortable: false,
            value: 'gateId',
          },
          {
            text: '時間',
            align: 'start',
            sortable: false,
            value: 'time',
          },
        ];
        return ret;
      },
    },
  },
  watch: {
    listGatePassagesInUser(data: any) {
      if (data.length > 0) {
        this.dataGatePassages = data;
      } else {
        this.dataGatePassages = [];
      }
    },
  },
  methods: {

    getNewTimeStart(time: string) {
      this.timeStartGate = time;
    },
    getNewTimeEnd(time: string) {
      this.timeEndGate = time;
    },
    getNewDateStart(date: string) {
      this.dateStartGate = date;
    },
    getNewDateEnd(date: string) {
      this.dateEndGate = date;
    },
    goLogPage() {
      this.$router.push({ path: Config.LOG, query: {tabId: 'user'}});
    },
    clickGroup(userId: any, ID: any) {
      this.$router.push({ path: Config.GROUP_DETAIL, query: {groupId: ID, goBack: 'user/detail?userId=' + userId}});
    },
    formatDateTime(date: Date): string {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const min = date.getMinutes().toString().padStart(2, '0');
      return year + '/' + month + '/' + day + ' ' + hour + ':' + min;
    },
    closeModalGroup() {
      this.isShowGroup = false;
      this.$emit('closeModalProgress');
    },
    formatTimestamp(t: string) {
      return t !== '' ? moment(t).unix() : '';
    },
    deleteGate(ID: any) {
      this.showConfirm = true;
      this.passageId = ID;
    },
    addGate() {
      let count = 0;
      this.errGate.errAdd = '';
      this.errGate.errorGate = '';
      this.errGate.errorTime = '';
      const pattern = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
      const hoursStartError =  pattern.test(this.timeStartGate);
      const hoursEndError =  pattern.test(this.timeEndGate);
      const timeStart = this.dateStartGate + ' ' + this.timeStartGate;
      const timeEnd = this.dateEndGate + ' ' + this.timeEndGate;
      for (const item of this.dataGatePassages) {
        const oldGate = item.gateId + '-' + item.notBefore + '-' + item.notAfter;
        const newGate = this.itemGate.value + '-' + this.formatTimestamp(this.dateStartGate + ' ' + this.timeStartGate)
        + '-' + this.formatTimestamp(this.dateEndGate + ' ' + this.timeEndGate);
        if (oldGate === newGate) {
          this.errGate.errAdd = 'すでに追加されている時間帯になります。追加する場合は、一度削除してから実施してください。';
          this.errGate.errorGate = '';
          this.errGate.errorTime = '';
          break ;
        } else {
          count++;
        }
      }
      if (count === this.dataGatePassages.length && this.dateStartGate !== ''
      && this.timeStartGate !== '' && this.dateEndGate !== ' ' && this.timeEndGate !== '' &&
      hoursStartError === true && hoursEndError === true && timeEnd > timeStart) {
        //
        this.$emit('add-gate', {
          userId: this.user.userId,
          gateId: this.itemGate.value,
          gateName: this.itemGate.text,
          notBefore: this.formatTimestamp(this.dateStartGate + ' ' + this.timeStartGate),
          notAfter: this.formatTimestamp(this.dateEndGate + ' ' + this.timeEndGate),
        });
      } else {
        if (this.itemGate.value === undefined || this.itemGate.value === '') {
          this.errGate.errorGate = 'ゲートを指定してください。';
          this.errGate.errAdd = '';
        }
        if (timeEnd <= timeStart && this.errGate.errAdd === '') {
          this.errGate.errorTime = Config.ERROR_END_TIME;
        }
        if ((hoursStartError === false || hoursEndError === false) && this.errGate.errAdd === '') {
          this.errGate.errorTime = '時間を指定してください。';
        }
      }
    },
    yesChoice() {
      this.showProgress = true;
      this.$emit('del-gate', this.passageId);
    },
    noChoice() {
      this.showConfirm = false;
      this.showProgress = false;
      this.passageId = '';
    },
    closeModalGate() {
      this.showConfirm = false;
      this.showProgress = false;
      this.passageId = '';
      this.$emit('close-modal-gate', this.user.userId);
    },
  },
});
