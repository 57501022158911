import { Room, User } from '@/types/alligate';
import Vue from 'vue';
import * as Config from '@/config';

/**
 * 全部屋を取得する
 * @param {Vue} vue Vueインスタンス
 * @returns {Promsie<Room[]>} 取得した全部屋
 */
export async function getAllRooms(vue: Vue): Promise<Room[]> {
  try {
    const rooms = await getAllRoomsIter(vue, 1);
    return rooms;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
          throw(new Error('部屋情報の取得に失敗しました。部屋情報を取得する権限がありません。'));
      case 404:
          // 取得時404エラーの場合は、データ無しなので正常終了。
          return [];
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 全部屋を取得するイテレータ
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @return {Promise<Room[]>} 取得した全部屋
 */
async function getAllRoomsIter(vue: Vue, page: number): Promise<Room[]> {
  const query = `?limit=100&page=${page}&sortKey=${Config.ASC}:name&embed=roomUserCount,relationsRoomGate`;
  try {
    const response = await vue.$axios.get(Config.ROOM_API + query);
    if (response.data.endRecord === response.data.maxRecords) {
      return response.data.rooms;
    } else {
      const nextRooms = await getAllRoomsIter(vue, page + 1);
      return response.data.rooms.concat(nextRooms);
    }
  } catch (err) {
    throw err;
  }
}

/**
 * Get rooms by keyword
 * @param {Vue} vue Vueインスタンス
 * @param {number} page ページ番号
 * @param {string} keyword キーワード
 * @return {Promise<any>} response data
 */
export async function getRoomsByKeyword(vue: Vue, page: number, keyword?: string): Promise<any> {
  const query = `?limit=25&page=${page}${keyword ? `&keyword=${keyword}` : ''}&sortKey=${Config.ASC}:name&embed=roomUserCount,relationsRoomGate`;
  try {
    const response = await vue.$axios.get(Config.ROOM_API + query);

    return response.data;
  } catch (err) {
    throw err;
  }
}

/**
 * Get room by roomId
 * @param {Vue} vue Vueインスタンス
 * @param {number} roomId room id
 * @return {Promise<Room>} response data
 */
export async function getRoomById(vue: Vue, roomId?: string): Promise<Room> {
  const query = `?roomId=${roomId}&embed=relationsRoomGate`;
  try {
    const response = await vue.$axios.get(Config.ROOM_API + query);

    return response.data.rooms[0];
  } catch (err) {
    switch (err.response.status) {
      case 400:
        throw(new Error(Config.ERROR_400));
      case 403:
        if (err.message === 'Unauthorized') {
          throw(new Error(Config.ERROR_403));
        } else {
          throw(new Error(Config.ERROR_403_DENIED));
        }
      case 404:
        throw(new Error(Config.ERROR_404));
      case 500:
        throw(new Error(Config.ERROR_500));
      case 503:
        throw(new Error(Config.ERROR_503));
      default:
        throw(new Error(Config.ERROR_DEFAULT));
    }
  }
}

/**
 * 在室中のユーザを取得する
 * @param {Vue} vue Vueインスタンス
 * @param {string} roomId 部屋ID
 * @param {Object} query クエリ (クエリ名がキー、クエリ値がバリューのオブジェクト)
 * @returns {{
 *  maxRecords: number,
 *  users: User[],
 * }} 結果の配列と必要情報
 */
export async function getInRoomUsers(vue: Vue, roomId: string, query: { [name: string]: string })
  : Promise<{ maxRecords: number, users: User[]}> {
  let q = '';
  Object.keys(query).forEach((queryName, index) => {
    const op = index === 0 ? '?' : '&';
    q += `${op}${queryName}=${query[queryName]}`;
  });
  try {
    const response = await vue.$axios.get(`${Config.ROOM_API}/${roomId}/user${q}`);
    return {
      maxRecords: response.data.maxRecords,
      users: response.data.users,
    };
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw new Error('在室中ユーザー情報を取得する権限がありません。');
      case 404:
        // 取得時404エラーの場合は、データ無しなので正常終了.
        return {
          maxRecords: 0,
          users: [],
        };
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_GET);
    }
  }
}

/**
 * 部屋情報をPOSTする
 * @param {Vue} vue Vueインスタンス
 * @param {Objecty} data データ
 * @return 作成した部屋のID
 */
export async function postRoom(vue: Vue, data: { [name: string]: any }): Promise<string> {
  try {
    const response = await vue.$axios.post(Config.ROOM_API, data);
    return response.data.roomId;
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw new Error('部屋情報を編集する権限がありません。');
      case 404:
        throw new Error('指定されたゲートが存在しません。');
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_POST);
    }
  }
}

/**
 * 部屋を削除する
 * @param {Vue} vue Vueインスタンス
 * @param {string} roomId 部屋ID
 */
export async function deleteRoom(vue: Vue, roomId: string): Promise<void> {
  try {
    await vue.$axios.delete(`${Config.ROOM_API}/${roomId}`);
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw new Error('部屋情報を削除する権限がありません。');
      case 404:
        throw new Error('指定された部屋が存在しません。');
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_POST);
    }
  }
}

/**
 * 部屋を更新する
 * @param {Vue} vue Vueインスタンス
 * @param {string} roomId 部屋ID
 * @param {Objecty} data データ
 */
export async function putRoom(vue: Vue, roomId: string, data: { [name: string]: any }): Promise<void> {
  try {
    await vue.$axios.put(`${Config.ROOM_API}/${roomId}`, data);
  } catch (err) {
    switch ((err as any).response.status) {
      case 403:
        throw new Error('部屋情報を更新する権限がありません。');
      case 404:
        throw new Error('指定された部屋あるいはゲートが存在しません。');
      case 503:
        throw new Error(Config.ERROR_MAINTENANCE);
      default:
        throw new Error(Config.ERROR_POST);
    }
  }
}
