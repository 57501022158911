











































































































































































































































































































































export type InputType = 'text'
  | 'number'
  | 'switch'
  | 'select'
  | 'multi-select'
  | 'another'
  | 'clearable'
  | 'checkbox'
  | 'file';
export type TextType = 'text' | 'password';
export interface SelectItem { text: string; value: any; }

import Vue, { PropType } from 'vue';
import HelpIcon from '@/components/molecules/HelpIcon/HelpIcon.vue';

export default Vue.extend({
  name: 'FormInput',
  components: {
    HelpIcon,
  },
  props: {
    value: {
      type: [String, Boolean, Array, Number, File] as PropType<string | boolean | string[] | number | File>,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    isShow: {
      type: String,
      default: '',
    },
    isCol: {
      type: String,
      default: '2',
    },
    label: {
      type: String,
      default: '',
    },
    inputType: {
      type: String as PropType<InputType>,
      default: 'text',
    },
    textType: {
      type: String as PropType<TextType>,
      default: 'text',
    },
    selectItems: {
      type: Array as PropType<SelectItem[]>,
      default: () => [],
    },
    helpText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    trueValue: {
      type: [String, Boolean, Number] as PropType<string | boolean | number>,
      default: true,
    },
    falseValue: {
      type: [String, Boolean, Number] as PropType<string | boolean | number>,
      default: false,
    },
    accept: {
      type: String,
      default: '*.csv',
    },
    appendIcon: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    showIconEye: false,
  }),
  computed: {
    innerValue: {
      get(): string | boolean | string[] | number | File {
        return this.value;
      },
      set(val: string | boolean | string[] | number | File): void {
        this.$emit('input', val);
        return;
      },
    },
  },
});
