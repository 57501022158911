var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-reflection-controller-table" },
    [
      _c(
        "v-row",
        { staticClass: "justify-end" },
        [
          _vm.authority.isAdmin
            ? _c(
                "v-btn",
                {
                  staticClass: "btn-secondary",
                  attrs: { outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("clickEditController")
                    }
                  }
                },
                [_vm._v("\n          制御盤情報を編集する\n      ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-subtitle-2 border-left-blue font-weight-bold"
                },
                [_vm._v("\n          制御盤情報\n        ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card", { staticClass: "pa-6 pr-10 mb-5" }, [
            _c(
              "div",
              { staticClass: "page-detail mb-10" },
              [
                _c("v-subheader", { staticClass: "ml-0 pl-0" }, [
                  _vm._v("制御盤名")
                ]),
                _vm._v(" "),
                _vm.controlPanelDetail.controller.name !== undefined
                  ? _c(
                      "div",
                      { staticClass: "text-body-2 col align-self-center" },
                      [_vm._v(_vm._s(_vm.controlPanelDetail.controller.name))]
                    )
                  : _c("div", {
                      staticClass: "text-body-2 col align-self-center"
                    }),
                _vm._v(" "),
                _c("div", { staticClass: "v-subheader ml-0 pl-0" }, [
                  _vm._v("制御盤モデル")
                ]),
                _vm._v(" "),
                _vm.controlPanelDetail.controller.model !== undefined
                  ? _c(
                      "div",
                      { staticClass: "text-body-2 col align-self-center" },
                      [_vm._v(_vm._s(_vm.controlPanelDetail.controller.model))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "v-subheader ml-0 pl-0" }, [
                  _vm._v("制御盤に設定されているゲート")
                ]),
                _vm._v(" "),
                _c(
                  "v-col",
                  [
                    _vm.gateList.length > 0
                      ? _c(
                          "v-row",
                          _vm._l(_vm.gateList, function(item) {
                            return _c(
                              "v-col",
                              {
                                key: item.value,
                                attrs: { md: "3", sm: "6", cols: "12" }
                              },
                              [
                                _c("text-link", {
                                  attrs: { text: item.text },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit(
                                        "clickGateDetail",
                                        item.value
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-subtitle-2 border-left-blue font-weight-bold"
                },
                [_vm._v("\n          アンチパス設定\n        ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card", { staticClass: "pa-6 pr-10 mb-5" }, [
            _c("div", { staticClass: "page-detail mb-10" }, [
              _c(
                "div",
                { staticClass: "controlPanelDetail-time-zone ml-10 mt-5" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-subheader", { staticClass: "ml-0 pl-0" }, [
                            _vm._v("アンチパス設定")
                          ]),
                          _vm._v(" "),
                          _c("v-subheader", { staticClass: "ml-0 pl-0 mt-0" }, [
                            _vm.controlPanelDetail.controller
                              .antipassbackSetting !== undefined &&
                            _vm.controlPanelDetail.controller
                              .antipassbackSetting === 2
                              ? _c("div", [_vm._v("ON")])
                              : _c("div", [_vm._v("OFF")])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c("v-subheader", { staticClass: "ml-0 pl-0" }, [
                            _vm._v("アンチパスクリア時間")
                          ]),
                          _vm._v(" "),
                          _c("v-subheader", { staticClass: "ml-0 pl-0 mt-0" }, [
                            _vm.controlPanelDetail.controller
                              .antipassbackClearTime !== undefined
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.controlPanelDetail.controller
                                        .antipassbackClearTime
                                    )
                                  )
                                ])
                              : _c("div")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-subtitle-2 border-left-blue font-weight-bold"
                },
                [_vm._v("\n          Attoューザー\n        ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card", { staticClass: "pa-6 pr-10 mb-5" }, [
            _c("div", { staticClass: "page-detail mb-10" }, [
              _c(
                "div",
                { staticClass: "controlPanelDetail-time-zone ml-10 mt-5" },
                [
                  _c("div", { staticClass: "info-item" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col col-3" }, [
                        _c("div", { staticClass: "v-subheader" }, [
                          _vm._v("ユーザーID")
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.controlPanelDetail.user !== undefined &&
                      _vm.controlPanelDetail.user.userId !== undefined
                        ? _c(
                            "div",
                            {
                              staticClass: "text-body-2 col align-self-center"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.controlPanelDetail.user.userId) +
                                  " "
                              )
                            ]
                          )
                        : _c("div", {
                            staticClass: "text-body-2 col align-self-center"
                          })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "info-item" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col col-3" }, [
                        _c("div", { staticClass: "v-subheader" }, [
                          _vm._v("ユーザー")
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.controlPanelDetail.user !== undefined &&
                      _vm.controlPanelDetail.user.name !== undefined
                        ? _c(
                            "div",
                            {
                              staticClass: "text-body-2 col align-self-center"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.controlPanelDetail.user.name) +
                                  " "
                              )
                            ]
                          )
                        : _c("div", {
                            staticClass: "text-body-2 col align-self-center"
                          })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "info-item" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col col-3" }, [
                        _c("div", { staticClass: "v-subheader" }, [
                          _vm._v("パスワード")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "text-body-2 col align-self-center" },
                        [_vm._v(" ******* ")]
                      )
                    ])
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mb-10", attrs: { justify: "end" } },
            [
              _vm.authority.isAdmin
                ? _c(
                    "v-btn",
                    {
                      staticClass: "btn-secondary",
                      attrs: {
                        outlined: "",
                        disabled: _vm.controlPanelDetail.controllerId === ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("clickReconfigure")
                        }
                      }
                    },
                    [_vm._v("\n          制御盤を再設定する\n      ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v(" 制御盤の再設定 ")
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-4 mb-10" },
                [
                  _vm.successMessageModal === "" && _vm.errorMessageModal === ""
                    ? _c("p", [
                        _vm._v("\n          制御盤の設定を一度クリアした後に "),
                        _c("br"),
                        _vm._v(
                          "\n          再度現在の設定を更新します。\n        "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _vm.successMessageModal !== ""
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mt-5 mb-4 font-weight-bold mt-1 bg-success no-bg"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.successMessageModal) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageModal !== ""
                        ? _c(
                            "v-col",
                            {
                              staticClass:
                                "mt-5 mb-4 font-weight-bold mt-1 bg-error no-bg"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.errorMessageModal) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _vm.errorMessageModal === "" && _vm.successMessageModal === ""
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("closeModal")
                                }
                              }
                            },
                            [_vm._v("\n          キャンセル\n          ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: {
                                outlined: "",
                                disabled:
                                  _vm.controlPanelDetail.controllerId === ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "clickPostReconfigure",
                                    _vm.controlPanelDetail.controllerId
                                  )
                                }
                              }
                            },
                            [_vm._v("\n          OK\n          ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("closeModal")
                                }
                              }
                            },
                            [_vm._v("\n          OK\n          ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showModalClear,
            callback: function($$v) {
              _vm.showModalClear = $$v
            },
            expression: "showModalClear"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v("アンチパスクリア")
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pa-4 mb-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _vm._v(
                          "\n            チェックしたユーザーをアンチパスバッククリアしますか？ "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n            ※ユーザーを選択していない場合、ゲートに紐づくユーザーのアンチパスがクリアされます。\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("closeModalClear")
                            }
                          }
                        },
                        [_c("span", [_vm._v("キャンセル")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("clickAntiPassBackClear", {
                                data: _vm.userIds,
                                controllerId:
                                  _vm.controlPanelDetail.controllerId
                              })
                            }
                          }
                        },
                        [_vm._v("OK")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.showProgress,
            callback: function($$v) {
              _vm.showProgress = $$v
            },
            expression: "showProgress"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "background-color-green" }, [
                _vm._v("ユーザーの関連付けをアンチパスバッククリア")
              ]),
              _vm._v(" "),
              _vm.totalDataRowProcess > 0
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-progress-linear",
                        {
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.progress,
                            callback: function($$v) {
                              _vm.progress = $$v
                            },
                            expression: "progress"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(Math.round(_vm.progress)) + "%")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.successItemProcess > 0
                        ? _c("span", { staticClass: "success-label" }, [
                            _vm._v(
                              _vm._s(_vm.totalDataRowProcess) +
                                "件中" +
                                _vm._s(_vm.successItemProcess) +
                                "件を追加しました。"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageProcess.length > 0
                        ? _c("div", [
                            _c("span", { staticClass: "error-box-title" }, [
                              _vm._v("追加失敗")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "error-box" },
                              [
                                _vm._l(_vm.errorMessageProcess, function(item) {
                                  return [
                                    _c("p", {
                                      key: item,
                                      domProps: { innerHTML: _vm._s(item) }
                                    })
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "v-card-text",
                    { staticClass: "pa-4" },
                    [
                      _c(
                        "v-progress-linear",
                        {
                          attrs: {
                            height: "25",
                            color: "green darken-1",
                            striped: ""
                          },
                          model: {
                            value: _vm.progress,
                            callback: function($$v) {
                              _vm.progress = $$v
                            },
                            expression: "progress"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(Math.round(_vm.progress)) + "%")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _vm.isFinishProcess
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("closeModalProgress")
                                }
                              }
                            },
                            [_vm._v("OK")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }