






































import Vue from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';

export default Vue.extend({
  name: 'Modal',
  components: {
    CancelButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
    smallScreen: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    innerValue: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        if (val === false) {
          this.$emit('close');
        }
        this.$emit('input', val);
        return;
      },
    },
  },
});
