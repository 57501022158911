
























































































































































































































import Vue, { PropType } from 'vue';
import GroupListModalTable from '@/components/organisms/ModalTable/GroupDropdownList.vue';
import GroupGateTable from '@/components/organisms/ModalTable/GroupGateTable.vue';
import UserListModalTable from '@/components/organisms/ModalTable/UserListTable.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Searchbox, { SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import * as Config from '@/config';

export interface DataType {
  isAuthority: string;
  showProgress: boolean;
  selectedGroupIds: any;
  isShowGroup: any;
  isConfirm: any;
  dataGroupAdd: any;
  type: any;
}
export default Vue.extend({
  name: 'GroupListModalTemplate',
  components: {
    Searchbox,
    Breadcrumbs,
    ActionButton,
    GroupListModalTable,
    GroupGateTable,
    UserListModalTable,
  },
  props: {
    isAction: {
      type: String,
      default: '',
    },
    isPage: {
      type: String,
      default: '',
    },
    dataModal: {
      type: Object,
      default: ({}),
    },
    dataProgress: {
      type: Object,
      default: ({}),
    },
    authority: {
      type: Object,
      default: ({}),
    },
    userId: {
      type: String,
      default: '',
    },
    departmentId: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      default: '',
    },
    groupPassages: {
      type: Array,
      default: [],
    },
  },
  data: (): DataType => ({
    isConfirm: false,
    isShowGroup: false,
    showProgress: false,
    isAuthority: localStorage.authority,
    selectedGroupIds: [],
    type: 'add',
    dataGroupAdd: [],
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: '全ての通行権限グループ一覧', disabled: true },
        ];
      },
    },
  },
  methods: {
    //
    selectIds(data: any) {
      this.selectedGroupIds = data;
      this.$emit('select-Ids', data);
    },
    closeModalGroup() {
      this.showProgress = false;
      this.$emit('close-modal-group');
    },
    addGroup(event: any) {
      this.type = 'add';
      this.showProgress = true;
      this.$emit('add-group-user', event);
    },
    addGroupDirect(event: any) {
      this.type = 'add';
      const data  = Array.of(...event);
      data.map((v: any) => Object.assign(v, {group:
        {
          passableTimeSetting: v.passableTimeSetting,
          name: v.name,
          groupId: v.groupId,
        },
      }));
      this.dataGroupAdd = data;
      console.log('this.dataGroupAdd', this.dataGroupAdd);
      this.$emit('select-data-add', this.dataGroupAdd);
      this.$emit('get-data-add', this.dataGroupAdd);
    },
    removeGroupDirect() {
      this.type = 'del';
      this.selectedGroupIds.forEach((item: any) => {
        const i = this.dataGroupAdd.map((e: any) => e.groupId).indexOf(item);
        if (i >= 0) {
          this.dataGroupAdd.splice(parseInt(i), 1);
        }
      });
      const Ids: any[] = [];
      this.dataGroupAdd.forEach((item: any) => {
        Ids.push(item.groupId);
      });
      this.isConfirm = false;
      this.$emit('select-Ids', Ids);
      this.$emit('select-data-add', this.dataGroupAdd);
      this.$emit('get-data-add', this.dataGroupAdd);
    },
    removeGroup() {
      this.type = 'del';
      this.isConfirm = false;
      this.showProgress = true;
      this.$emit('remove-group-user', this.selectedGroupIds);
    },
    showGroup() {
      this.isShowGroup = true;
      this.$emit('get-all-group');
    },
    addUser(event: any) {
      this.type = 'add';
      this.showProgress = true;
      if (this.isAction === 'add-user-to-aff') {
        this.$emit('add-user-to-aff', event);
      } else {
        this.$emit('add-user-to-group', event);
      }
    },
  },
});
