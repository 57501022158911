var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "room-form" },
    [
      _c("loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.roomInfoLoading,
            expression: "roomInfoLoading"
          }
        ]
      }),
      _vm._v(" "),
      _vm.authority.isAdmin === true || _vm.authority.isAdminToViewUser === true
        ? _c("v-row", { staticClass: "btn-div", attrs: { justify: "end" } }, [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "v-col",
                  { staticClass: "btn-add pt-0", attrs: { cols: "12" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn-primary",
                        attrs: { outlined: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("click-edit-room", _vm.roomInfo)
                          }
                        }
                      },
                      [_vm._v("\n          部屋情報を編集する\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3 room-info" },
        [
          _c(
            "v-col",
            [
              _c("v-row", { staticClass: "v-subheader" }, [
                _vm._v("\n        部屋名\n      ")
              ]),
              _vm._v(" "),
              _c("v-row", { staticClass: "text-body-2 font-weight-bold" }, [
                _vm._v("\n        " + _vm._s(_vm.roomInfo.name) + "\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "mt-5" },
            [
              _c("v-row", { staticClass: "v-subheader" }, [
                _vm._v("\n        ゲート\n      ")
              ]),
              _vm._v(" "),
              _vm._l(_vm.gateItems, function(gate, index) {
                return _c(
                  "v-row",
                  { key: index, staticClass: "text-body-2 font-weight-bold" },
                  [_vm._v("\n        " + _vm._s(gate.title) + "\n      ")]
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }