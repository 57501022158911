























































































































import Vue, { PropType } from 'vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import Modal from '@/components/molecules/Modal/Modal.vue';
import Alert from '@/components/molecules/Alert/Alert.vue';
import { DataTableHeader } from 'vuetify';
import { NoticeSetting, NoticeSettingType } from '@/types/alligate';

interface DataType {
  deleteTargetNoticeSettingId: string;
  isDisplaySuccessMessage: boolean;
  isDisplayErrorMessage: boolean;
  isDisplayConfirm: boolean;
  perPageItemLength: number;
  page: number;
  headers: DataTableHeader[];
}

export default Vue.extend({
  name: 'NoticeSettingList',
  components: {
    InputForm,
    SuccessButton,
    CancelButton,
    Modal,
    Alert,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: () => true,
    },
    loadBlockingTextNoticeSetting: {
      type: String,
      default: () => '',
    },
    isLoadBlockingNoticeSetting: {
      type: Boolean,
      default: () => false,
    },
    isLoadingNoticeSetting: {
      type: Boolean,
      default: () => false,
    },
    successMessageLogsModal: {
      type: String,
      default: '',
    },
    errorMessageLogsModal: {
      type: String,
      default: '',
    },
    noticeSettings: {
      type: Array as PropType<NoticeSetting[]>,
      default: () => [],
    },
    noticeSettingType: {
      type: Object as PropType<NoticeSettingType>,
      default: () => {
        return {
          noticeSettingTypeId: 'miss',
          description: '未設定',
          name: '未設定',
          targets: [],
        };
      },
    },
  },
  data: (): DataType => {
    return {
      deleteTargetNoticeSettingId: '',
      isDisplaySuccessMessage: false,
      isDisplayErrorMessage: false,
      isDisplayConfirm: false,
      /** 1page毎の表示件数 */
      perPageItemLength: 10,
      page: 1,
      headers: [{
        text: '通知設定名',
        align: 'start',
        sortable: true,
        value: 'name',
      }, {
        text: '備考欄',
        align: 'start',
        sortable: false,
        value: 'option',
      }, {
        text: '有効/無効',
        align: 'center',
        sortable: false,
        value: 'isValid',
      }, {
        text: '',
        align: 'end',
        sortable: false,
        value: 'icons',
      }],
    };
  },
  watch: {
    successMessageLogsModal(val: string) {
      if (val.length > 0) {
        this.isDisplaySuccessMessage = true;
      } else {
        this.isDisplaySuccessMessage = false;
      }
    },
    errorMessageLogsModal(val: string) {
      if (val.length > 0) {
        this.isDisplayErrorMessage = true;
      } else {
        this.isDisplayErrorMessage = false;
      }
    },
  },
  computed: {
    selectedTypeNoticeSettings: {
      get(): NoticeSetting[] {
        const copiedNoticeSettings: NoticeSetting[] = JSON.parse(JSON.stringify(this.noticeSettings));
        return copiedNoticeSettings
          .filter((n) => n.noticeSettingTypeId === this.noticeSettingType.noticeSettingTypeId);
      },
      set(): void {
        return;
      },
    },
  },
  methods: {
    clickConfirmDeleteNoticeSetting(ID: string): void {
      this.$emit('reset-msg');
      this.deleteTargetNoticeSettingId = ID;
      this.isDisplayConfirm = true;
    },
    deleteComfirmNoticeSetting(isConfirm: boolean): void {
      if (isConfirm === true) {
        this.$emit('click-delete-notice-setting', this.deleteTargetNoticeSettingId);
        this.$emit('reload-notice-settings');
      }
      this.deleteTargetNoticeSettingId = '';
      this.isDisplayConfirm = false;
    },
  },
});
