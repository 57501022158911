var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c(
        "v-card-title",
        { staticClass: "modal-title", attrs: { color: "green" } },
        [
          _vm._t("default", [
            _c(
              "v-row",
              { attrs: { "no-gutters": "", justify: "space-between" } },
              [
                _vm.isEdit
                  ? _c("span", [_vm._v("制御盤編集")])
                  : _c("span", [_vm._v("制御盤追加")]),
                _vm._v(" "),
                _c(
                  "v-icon",
                  {
                    attrs: { color: "white" },
                    on: {
                      click: function($event) {
                        return _vm.$emit(
                          "close-modal-control",
                          _vm.successMessageLogs !== "" ? true : false
                        )
                      }
                    }
                  },
                  [_vm._v("mdi-close")]
                )
              ],
              1
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _vm.successMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-2 bg-success" },
                [_vm._v("\n      " + _vm._s(_vm.successMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessageLogs !== "" && _vm.loading === false
            ? _c(
                "v-col",
                { staticClass: "mb-4 font-weight-bold mt-2 bg-error" },
                [_vm._v("\n      " + _vm._s(_vm.errorMessageLogs) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("control-panel-add-form", {
            attrs: {
              typeItems: _vm.typeItems,
              dataAllGates: _vm.dataAllGates,
              dataControlPanels: _vm.dataControlPanels,
              successMessageLogs: _vm.successMessageLogs,
              errorMessageLogs: _vm.errorMessageLogs,
              loading: _vm.loading,
              controllerModel: _vm.controllerModel,
              isEdit: _vm.isEdit,
              gateList: _vm.gateList
            },
            on: {
              clickSaveControlPanel: function($event) {
                return _vm.$emit("clickSaveControlPanel", $event)
              },
              "close-modal-control": function($event) {
                return _vm.$emit("close-modal-control", $event)
              },
              clickUpdateControlPanel: function($event) {
                return _vm.$emit("clickUpdateControlPanel", $event)
              },
              changeModel: function($event) {
                return _vm.$emit("changeModel", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }