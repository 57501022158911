var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-reflection-gate-table mt-5" },
    [
      _c(
        "v-container",
        { staticClass: "pb-0 pt-0 mb-5" },
        [
          _c("div", { staticClass: "title-block-gate mt-0 mb-0" }, [
            _vm._v("ゲート一覧")
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "mt-1 ",
              attrs: {
                "no-gutters": "",
                justify: "space-between",
                "align-content": "center"
              }
            },
            [
              _c("show-per-page", {
                staticClass: "ml-3 mb-1",
                attrs: {
                  "per-page": _vm.perPage,
                  page: _vm.page,
                  "items-length": _vm.gateTotalCount
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "round-data table-affiliation" },
            [
              _c("v-data-table", {
                staticClass: "border-table gate-of-group",
                attrs: {
                  "must-sort": "",
                  "custom-sort": _vm.customSort,
                  headers: _vm.headers,
                  loading: _vm.loading,
                  items: _vm.dataGates,
                  "items-per-page": 25,
                  "item-key": "gateId",
                  "no-data-text": "データーはありません",
                  "loading-text": "データを読み込中です",
                  "hide-default-footer": "",
                  expanded: _vm.expanded
                },
                on: {
                  "update:expanded": function($event) {
                    _vm.expanded = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.name",
                      fn: function(ref) {
                        var item = ref.item
                        return [_c("span", [_vm._v(_vm._s(item.gate.name))])]
                      }
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selectedItem,
                  callback: function($$v) {
                    _vm.selectedItem = $$v
                  },
                  expression: "selectedItem"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "space-between" } },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-col", { staticClass: "mr-5 " }),
              _vm._v(" "),
              _c("pagination", {
                attrs: {
                  "page-length": Math.ceil(_vm.gateTotalCount / _vm.perPage),
                  disabled: _vm.isGetDataList
                },
                model: {
                  value: _vm.innerPage,
                  callback: function($$v) {
                    _vm.innerPage = $$v
                  },
                  expression: "innerPage"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }