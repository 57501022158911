var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("gate-lock-detail-template", {
    attrs: {
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      successMessageModal: _vm.successMessageModal,
      errorMessageModal: _vm.errorMessageModal,
      gateDetail: _vm.gateDetail,
      groupList: _vm.groupList,
      loading: _vm.loading,
      showConfirm: _vm.showConfirm,
      groupId: _vm.groupId,
      dayTypes: _vm.dayTypes,
      dataUnlockingTime: _vm.dataUnlockingTime,
      groupsAddFail: _vm.groupsAddFail,
      isFinishDetailGate: _vm.isFinishDetailGate,
      isRemoteUnlockValid: _vm.isRemoteUnlockValid,
      btnUnlock: _vm.btnUnlock
    },
    on: {
      clickBackGate: function($event) {
        return _vm.$emit("clickBackGate", $event)
      },
      clickRemoteUnlock: function($event) {
        return _vm.$emit("clickRemoteUnlock", $event)
      },
      clickConfirm: function($event) {
        return _vm.clickConfirm($event)
      },
      closeModalConfirm: function($event) {
        return _vm.closeModalConfirm($event)
      },
      "loading-from-child": function($event) {
        return _vm.$emit("loading", $event)
      },
      "gate-edit": function($event) {
        return _vm.$emit("gate-edit", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }