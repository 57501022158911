









































import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import InAllRoomModalForm from '../../organisms/InALLRoomModalForm/InAllRoomModalForm.vue';
import { SearchValue } from '@/components/molecules/Search/Search.vue';

export default Vue.extend({
  name: 'InAllRoomModalTemplate',
  components: {
    Breadcrumbs,
    InAllRoomModalForm,
  },
  props: {
    inAllRoomLoading: {
      type: Boolean,
      default: false,
    },
    successMessages: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    inAllRoom: {
      type: Array,
      default: () => [],
    },
    inAllRoomSearchValue: {
      type: Object as PropType<SearchValue>,
      default: () => ({
        text: '',
        targets: ['name'],
      }),
    },
    roomTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: '部屋一覧', disabled: true },
        ];
      },
    },
    innerInAllRoomSearchValue: {
      get(): SearchValue {
        return this.inAllRoomSearchValue;
      },
      set(val: SearchValue): void {
        this.$emit('input-in-all-room-search-value', val);
        return;
      },
    },
  },
});
