

























































































































































































































































































































import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import UserTable from '@/components/organisms/UserTable/UserTable.vue';
import UserImport from '@/components/organisms/UserImport/UserImport.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import { User, Group, RelationGateGroup } from '@/types/alligate';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import AffiliationListModalPage from '@/components/pages/aff-modal/AffiliationListModalPage.vue';
import UserDetailPage from '@/components/pages/user/UserDetailPage.vue';

export interface DataType {
  actionItems: ActionItem[];
  selectedUserIds: string[];
  searchTargetItems: SearchTargetItem[];
  searchItem: [];
  groupIdSearch: string;
  isShow: boolean;
}
export default Vue.extend({
  name: 'UsersTemplate',
  components: {
    Breadcrumbs,
    SuccessButton,
    UserTable,
    ActionButton,
    UserImport,
    Searchbox,
    TextLink,
    AffiliationListModalPage,
    UserDetailPage,
  },
  props: {
    typeConfirm: {
      type: Number,
      default: 0,
    },
    totalDataRowCSV: {
      type: Number,
      default: 0,
    },
    pageGate: {
      type: Number,
      default: 0,
    },
    errorMessageProcess: {
      default: [],
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    typeAction: {
      type: Number,
      default: 0,
    },
    successItemCSV: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      default: [],
    },
    gateList: {
      default: [],
    },
    showModalProcess: {
      type: Boolean,
      default: false,
    },
    loadingGate: {
      type: Boolean,
      default: false,
    },
    showModalConfirmEmail: {
      type: Boolean,
      default: false,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    showConfirm: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    loadingGroup: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array as PropType<User[]>,
      default: () => [],
    },
    listGroupPassages: {
      type: Array,
      default: () => [],
    },
    listphones: {
      type: Array,
      default: () => [],
    },
    listGatePassages: {
      type: Array,
      default: () => [],
    },
    groupDetail: {
      default: () => [],
    },
    tempGroupPassages: {
      default: () => [],
    },
    ExpandedGroup: {
      default: () => [],
    },
    cardItems: {
      default: () => [],
    },
    phoneItems: {
      default: () => [],
    },
    maxRecord: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 0,
    },
    pageGroup: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
    action: {
      type: Number,
      default: 0,
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },

    // Excel
    showModalImport: {
      type: Boolean,
      default: false,
    },
    isExport: {
      type: Boolean,
      default: false,
    },
    showProcessImportExcel: {
      type: Boolean,
      default: false,
    },
    successItem: {
      type: Number,
      default: 0,
    },
    totalDataRow: {
      type: Number,
      default: 0,
    },
    errorMessageExcel: {
      default: [],
    },
    isFinishImportExcel: {
      type: Boolean,
      default: false,
    },
    sheetUserName: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      default: '',
    },
    isValid: {
      type: Boolean,
      default: '',
    },
    isType: {
      type: String,
      default: '',
    },
    exportType: {
      type: Number,
      default: 0, // 0: CSV (user), 1: CSV (gate), 2: Excel
    },
    groupToSearch: {
      default: [],
    },
    sheet1Name: {
      type: String,
      default: '',
    },
    successItemSheet1: {
      type: Number,
      default: 0,
    },
    progressSheet1: {
      type: Number,
      default: 0,
    },
    totalDataRowSheet1: {
      type: Number,
      default: 0,
    },
    sheet2Name: {
      type: String,
      default: '',
    },
    successItemSheet2: {
      type: Number,
      default: 0,
    },
    progressSheet2: {
      type: Number,
      default: 0,
    },
    totalDataRowSheet2: {
      type: Number,
      default: 0,
    },
    isHasLowerCaseCardNumberExcel: {
      type: Boolean,
      default: false,
    },
    ExpandedData: {
      default: () => [],
    },
    authority: {
      type: Object,
      default: ({}),
    },
    departments: {
      type: Array,
      default: () => [],
    },
    dataAllow: {
      type: Array,
      default: () => [],
    },
  },
  data: (): DataType => ({
    isShow: false,
    groupIdSearch: '',
    searchTargetItems: [
      { label: 'ユーザーID', value: 'userId' },
      { label: 'ユーザー名', value: 'name' },
      { label: 'ユーザー名ふりがな', value: 'furigana' },
      { label: 'メールアドレス', value: 'email' },
      { label: '備考1', value: 'option1' },
      { label: '備考2', value: 'option2' },
    ],
    searchItem: [],
    actionItems: [
      { title: '有効化する', emitName: 'click-enable' },
      { title: '無効化する', emitName: 'click-disable' },
    ],
    selectedUserIds: [],
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ユーザー', disabled: true },
        ];
      },
    },
  },
});
