var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-gate-passage-table" },
    [
      _c(
        "v-card-title",
        { staticClass: "background-color-green" },
        [
          _vm._t("default", [
            _c(
              "v-row",
              { attrs: { "no-gutters": "", justify: "space-between" } },
              [
                _vm._v("\n        部屋情報\n        "),
                _c(
                  "v-icon",
                  {
                    attrs: { color: "white" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("close-look-room")
                      }
                    }
                  },
                  [_vm._v("mdi-close")]
                )
              ],
              1
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _vm._l(_vm.successMessages, function(message, index) {
            return _c("alert", {
              key: index,
              attrs: { type: "success", text: message, dismissible: false },
              model: {
                value: _vm.showSuccessMessages[index],
                callback: function($$v) {
                  _vm.$set(_vm.showSuccessMessages, index, $$v)
                },
                expression: "showSuccessMessages[index]"
              }
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.errorMessages, function(message) {
            return _c("alert", {
              key: message,
              attrs: { value: message !== "", type: "error", text: message }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "pt-0" },
        [
          _c("InRoomModalForm", {
            attrs: {
              "room-info": _vm.roomInfo,
              "all-gates": _vm.allGates,
              "room-info-loading": _vm.roomInfoLoading,
              "success-messages": _vm.successMessages,
              "error-messages": _vm.errorMessages,
              authority: _vm.authority
            },
            on: {
              "click-edit-room": function($event) {
                return _vm.$emit("click-edit-room", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }