import { render, staticRenderFns } from "./GroupDetailTemplate.vue?vue&type=template&id=fb62dae2&"
import script from "./GroupDetailTemplate.vue?vue&type=script&lang=ts&"
export * from "./GroupDetailTemplate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\003742\\Documents\\Alligate_join\\03_code\\Update\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fb62dae2')) {
      api.createRecord('fb62dae2', component.options)
    } else {
      api.reload('fb62dae2', component.options)
    }
    module.hot.accept("./GroupDetailTemplate.vue?vue&type=template&id=fb62dae2&", function () {
      api.rerender('fb62dae2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/templates/GroupDetailTemplate/GroupDetailTemplate.vue"
export default component.exports