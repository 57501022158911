






















import {Vue } from 'vue-property-decorator';
import GateEditTemplate from '@/components/templates/GateEditTemplate/GateEditTemplate.vue';
import * as Config from '@/config';
const arrayDay = ['月曜', '火曜', '水曜', '木曜', '金曜', '土曜', '日曜'];
export interface TypeDessert {
  name: string;
  isVaild: boolean;
}
export interface DataType {
  loading: boolean;
  successMessageLogs: string;
  errorMessageLogs: string;
  gateDetail: any;
  groupList: any;
  dataUnlockingTime: any;
  dataUnlockingTimeOri: any;
  dataUnTimeOri: any;
  groupId: any;
  dayTypes: any;
  groupItemsOrigin: any;
  dataPost: any;
  dataUnlockingTimeConvert: any;
  groupsAddFail: any;
  isFinishEditGate: boolean;
  goBack: string;
  successMessageModal: string;
  errorMessageTime: string;
  errorMessageModal: string;
  showModal: boolean;
  listGateModel: any;
}
export default Vue.extend({
  name: 'GateEditPage',
  components: {
    GateEditTemplate,
  },
  data: (): DataType => ({
    loading: false,
    successMessageLogs: '',
    errorMessageTime: '',
    errorMessageLogs: '',
    gateDetail: {
      gateId: '',
      gateName: '',
      option1: '',
      option2: '',
      gateModelCode: '',
      groups: [],
      gateModel: {},
    },
    groupList: [],
    dataUnTimeOri: [],
    groupsAddFail: [],
    dataUnlockingTime: [],
    dataUnlockingTimeOri: [],
    groupItemsOrigin: [],
    dayTypes: [],
    dataUnlockingTimeConvert: [],
    dataPost: [],
    isFinishEditGate: false,
    groupId: '',
    goBack: 'gates',
    showModal: false,
    successMessageModal: '',
    errorMessageModal: '',
    listGateModel: [],
  }),
  mounted() {
    // 一旦べた書き
    const gateId = localStorage.getItem('gateId');
    if (gateId != null) {
      this.getGateData(gateId).then(() => {
        this.gateDetail.groups = [];
        // なんか配列持たせてみる？ゲート製品型番ID
        this.gateDetail.gateModelId = [];

        this.getGroups(1);
        this.getGroupByGate(1, gateId);
        // Phase 2
        this.getUnlockingTime(gateId);
        this.getDayTypes(1);
      });
    }
  },
  created() {
    this.getListGateModel();
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  methods: {
    clickBackGate() {
      // this.$router.push({ path: '/' + this.goBack});
      this.$emit('deleteBridgeProperty');
      if (this.isFinishEditGate) {
        this.$emit('load-gates', 1);
      }
    },
    async clickUpdateGate(eventArgs: any) {
      this.dataPost = [];
      let dataTmp = [];
      if (this.dataUnlockingTimeConvert.length === 0) {
        dataTmp  = eventArgs.tmpUnlockingTime;
      } else {
        dataTmp  = this.dataUnlockingTimeConvert;
      }
      const dataAventArgs = dataTmp.slice(0);
      dataAventArgs.sort((a: { index: number; }, b: { index: number; }) => {
        return a.index - b.index;
      });
      for (const item of dataAventArgs) {
        const check = this.dataPost.filter((o1: { dayTypeId: any; }) => o1.dayTypeId === item.dayTypeId);
        if (check.length === 0) {
          if (item.action === 'del') {
            this.dataPost.push(
              {
                dayTypeId: item.dayTypeId,
                allDay: item.allDay,
                del: 1,
                add: 0,
                edit: 0,
                countItem: 1,
              },
            );
          } else {
            const data: any = {
              dayTypeId: item.dayTypeId,
              allDay: item.allDay,
              startTime: item.startTime,
              endTime: item.endTime,
              del: 0,
              add: item.action === 'add' ? 1 : 0,
              edit: item.action === 'edit' ? 1 : 0,
              countItem: 1,
            };

            for (const index in item.listTime) {
              if (item.listTime.hasOwnProperty(index)) {
                switch (Number(index)) {
                  case 0:
                    data.startTime = item.listTime[index].startTime;
                    data.endTime = item.listTime[index].endTime;
                    break;
                  case 1:
                    data.startTime2 = item.listTime[index].startTime;
                    data.endTime2 = item.listTime[index].endTime;
                    break;
                  case 2:
                    data.startTime3 = item.listTime[index].startTime;
                    data.endTime3 = item.listTime[index].endTime;
                    break;
                  case 3:
                    data.startTime4 = item.listTime[index].startTime;
                    data.endTime4 = item.listTime[index].endTime;
                    break;
                  case 4:
                    data.startTime5 = item.listTime[index].startTime;
                    data.endTime5 = item.listTime[index].endTime;
                    break;
                }
              }
            }

            this.dataPost.push(data);
          }
        }
      }
      const dataQuery = this.$route.query;
      const groupId = eventArgs.groupId;
      const dataTimeOri = this.dataUnlockingTimeOri;
      const dataUnlockingTime = eventArgs.dataUnlockingTime;
      this.isFinishEditGate = false;
      this.loading = true;

      // ここの処理は誰かに相談しないといけないかも
       // 一旦外だししておく
      const gateId = localStorage.getItem('gateId');
      if (gateId !== null) {
        const gateDetail = eventArgs.gateDetail;
        const dataGate = {
          gateName: gateDetail.gateName,
          option1: gateDetail.option1,
          option2: gateDetail.option2,
          gateModelCode: gateDetail.gateDetail,
        };
        const result = await this.updateData(gateId, dataGate);
        if (result) {
          // GROUP
          const groupListForm = eventArgs.gateDetail.groups;
          const groupList = this.groupItemsOrigin;
          if (groupListForm !== undefined && groupListForm.length > 0) {
            // group add
            const deGroupData = groupList.filter((o1: { value: any; }) =>
            !groupListForm.some((o2: { value: any; }) => o1.value === o2.value));
            // group delete
            const postGroupData = groupListForm.filter((o1: { value: any; }) =>
            !groupList.some((o2: { value: any; }) => o1.value === o2.value));
            this.groupItemsOrigin = [];
            this.gateDetail.groups = [];
            if (deGroupData.length > 0) {
              for (const item of deGroupData) {
                await this.delGroupItem(item);
              }
            }
            if (postGroupData.length > 0) {
              for (const item of postGroupData) {
                await this.postGroupItem(item, gateId);
              }
            }
            this.getGroupByGate(1, gateId.toString());
          } else if ((groupListForm !== undefined || groupListForm.length === 0) && groupList.length > 0) {
            if (groupList.length > 0) {
              for (const item of groupList) {
                await this.delGroupItem(item);
              }
            }
            this.getGroupByGate(1, gateId.toString());
          }
          // time delete
          this.errorMessageTime = '';
          if (this.groupsAddFail.length === 0) {
            const timeArray = [];
            for (const item of this.dataPost) {
              if (item.del === item.countItem) {
                await this.delUnlockingTime(item.dayTypeId, gateId);
              } else if (item.add === item.countItem) {
                //
                delete item.add;
                delete item.del;
                delete item.edit;
                delete item.countItem;
                timeArray.push(item);
              } else if ( item.edit === 1 || (item.del > 0 && item.del < item.countItem)
              || (item.add > 0 && item.add < item.countItem) ) {
                const ID = item.dayTypeId;
                delete item.add;
                delete item.del;
                delete item.edit;
                delete item.countItem;
                delete item.dayTypeId;

                await this.putUnlockingTime(ID, gateId, item);
              }
            }
            if (timeArray.length > 0) {
              const dataPost = {
                gateId,
                timeSettings: timeArray,
              };
              await this.postUnlockingTime(dataPost);
            }
            this.getUnlockingTime(gateId.toString());
          }
          this.isFinishEditGate = true;
          this.loading = false;
          //
        }
      }
    },
    getGateData(gateId: string) {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.errorMessageLogs = '';
        this.$http.get(Config.GATES_LIST_API + '?gateId=' + encodeURIComponent(gateId))
          .then((response: any) => {
            this.loading = false;
            if (response.body.maxRecords > 0 && response.body.gates.length > 0) {
              this.gateDetail = response.body.gates[0];
              this.gateDetail.gateModel = {
                gateModelCode: response.body.gates[0].gateModelCode,
                gateModelId: response.body.gates[0].gateModelId,
                gateModelName: response.body.gates[0].gateModelName,
              };
              resolve(null);
            }
          })
          .then( null, (err: any) => {
          console.log('err: ', err);
          this.loading = false;
          resolve(null);
          switch (err.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (err.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
        }); // catch
      });
    },

    getGroups(page: any) {
      this.$http.get(Config.GROUP_LIST_API + '?limit=1000&page=' + page + '&sortKey=desc:registered&isValid=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.groups.forEach((element: any) => {
              this.groupList.push({text: element.name, value: element.groupId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getGroups(page + 1);
            }
          } else {
            this.groupList = [];
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
        }); // catch
    },
    getGroupByGate(page: any, gateId: string) {
      this.$http.get(Config.RELATION_GATE_GROUP_API + '?gateId='
      + encodeURIComponent(gateId) + '&isExceptOptionalGate=true&embed=group')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.relationsGateGroup.forEach((element: any) => {
              this.gateDetail.groups.push({text: element.group.name, value: element.groupId});
              this.groupItemsOrigin.push({text: element.group.name, value: element.groupId,
              relationId: element.relationId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getGroupByGate(page + 1, gateId);
            }
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
        }); // catch
    },
    postGroupItem(item: any, dataGateId: any) {
      return new Promise((resolve, reject) => {
        const data = {
          gateId: dataGateId,
          groupId: item.value,
        };
        this.$http.post(Config.RELATION_GATE_GROUP_API, data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              // this.loading = false;
              resolve(true);
            } else {
              this.groupsAddFail.push(item.text);
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            this.groupsAddFail.push(item.text);
            resolve(false);
            // this.loading = false;
          }); // catch
      });
    },
    delGroupItem(item: any) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.RELATION_GATE_GROUP_API + '/' + encodeURIComponent(item.relationId))
          .then((response: any) => {
            if (response.body.message === 'Success') {
              // this.loading = false;
              resolve(true);
            } else {
              this.groupsAddFail.push(item.text);
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            console.log('err: ', err);
            this.groupsAddFail.push(item.text);
            resolve(false);
            // this.loading = false;
          }); // catch
      });
    },
    delUnlockingTime(dayTypeId: any, gateId: any) {
      return new Promise((resolve, reject) => {
        this.$http.delete(Config.UNLOCKING_TIME_SETTING + '/' +
        encodeURIComponent(gateId) + '/' + encodeURIComponent(dayTypeId))
          .then((response: any) => {
            this.errorMessageTime = '';
            resolve(true);
          })
          .then( null, (err: any) => {
            switch (err.status) {
              case 400:
                this.errorMessageTime = Config.ERROR_400_UNLOCKTIME;
                break;
              case 403:
                this.errorMessageTime = Config.ERROR_403;
                break;
              case 500:
                this.errorMessageTime = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageTime = Config.ERROR_503;
                break;
              default:
                this.errorMessageTime = Config.ERROR_DEFAULT;
                break;
            }
            resolve(false);
            // this.loading = false;
          }); // catch
      });
    },
    putUnlockingTime(dayTypeId: any, gateId: any, data: any) {
      console.log('data', data);
      return new Promise((resolve, reject) => {
      this.$http.put(Config.UNLOCKING_TIME_SETTING + '/' +
        encodeURIComponent(gateId) + '/' + encodeURIComponent(dayTypeId), data)
        .then((response: any) => {
          this.errorMessageTime = '';
          resolve(true);
        })
        .then( null, (err: any) => {
          switch (err.status) {
            case 400:
              this.errorMessageTime = Config.ERROR_400_UNLOCKTIME;
              break;
            case 409:
              this.errorMessageTime = Config.ERROR_409_UNLOCKTIME;
              break;
            case 403:
              this.errorMessageTime = Config.ERROR_403;
              break;
            case 500:
              this.errorMessageTime = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageTime = Config.ERROR_503;
              break;
            default:
              this.errorMessageTime = Config.ERROR_DEFAULT;
              break;
          }
          resolve(false);
          console.log('err: ', err);
          // this.loading = false;
        }); // catch
      });
    },
    postUnlockingTime(data: any) {
      console.log('data', data);
      return new Promise((resolve, reject) => {
      this.$http.post(Config.UNLOCKING_TIME_SETTING, data)
        .then((response: any) => {
          this.errorMessageTime = '';
          resolve(true);
        })
        .then( null, (err: any) => {
          switch (err.status) {
            case 400:
              this.errorMessageTime = Config.ERROR_400_UNLOCKTIME;
              break;
            case 409:
              this.errorMessageTime = Config.ERROR_409_UNLOCKTIME;
              break;
            case 403:
              this.errorMessageTime = Config.ERROR_403;
              break;
            case 500:
              this.errorMessageTime = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageTime = Config.ERROR_503;
              break;
            default:
              this.errorMessageTime = Config.ERROR_DEFAULT;
              break;
          }
          resolve(false);
          console.log('err: ', err);
          // this.loading = false;
        }); // catch
      });
    },
    getUnlockingTime(gateId: string) {
      this.dataUnlockingTimeOri = [];
      this.dataUnlockingTimeConvert = [];
      this.$http.get(Config.UNLOCKING_TIME_SETTING + '?gateId=' + encodeURIComponent(gateId) + '&sortKey=asc:dayTypeId')
        .then((response: any) => {
          if (response.body.maxRecords > 0 && response.body.unlockingTimeSettings.length > 0) {
            const unlockingTimeSettings = response.body.unlockingTimeSettings;
            this.dataUnlockingTime = unlockingTimeSettings;
            unlockingTimeSettings.forEach((item: any, i: any) => {
              const check = this.dataUnlockingTimeConvert.filter((o1: { dayTypeId: any; }) => o1.dayTypeId
              === item.dayTypeId);
              if (check.length === 0) {
                const listTime = [];
                if (item.startTime && item.startTime !== '88:88') {
                  listTime.push(
                    {
                      startTime: item.startTime,
                      endTime: item.endTime,
                    },
                  );
                }
                if (item.startTime2 && item.startTime2 !== '88:88') {
                  listTime.push(
                    {
                      startTime: item.startTime2,
                      endTime: item.endTime2,
                    },
                  );
                }
                if (item.startTime3 && item.startTime3 !== '88:88') {
                  listTime.push(
                    {
                      startTime: item.startTime3,
                      endTime: item.endTime3,
                    },
                  );
                }
                if (item.startTime4 && item.startTime4 !== '88:88') {
                  listTime.push(
                    {
                      startTime: item.startTime4,
                      endTime: item.endTime4,
                    },
                  );
                }
                if (item.startTime5 && item.startTime5 !== '88:88') {
                  listTime.push(
                    {
                      startTime: item.startTime5,
                      endTime: item.endTime5,
                    },
                  );
                }
                this.dataUnlockingTimeConvert.push(
                    {
                      gateId: item.gateId,
                      dayTypeId: item.dayTypeId.toString(),
                      dayTypeName: item.dayTypeName,
                      allDay: item.allDay,
                      startTime: item.startTime,
                      endTime: item.endTime,
                      listTime,
                      pos: 1,
                      action: '',
                      index: 5 * i + 0,
                    },
                  );
              } else {
                console.log(check);
              }
            });
          } else {
            this.dataUnlockingTime = [];
            this.dataUnlockingTimeOri = [];
            this.dataUnlockingTimeConvert = [];
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          this.dataUnlockingTime = [];
          this.dataUnlockingTimeOri = [];
          this.dataUnlockingTimeConvert = [];
        }); // catch
    },
    getDayTypes(page: any) {
      this.$http.get(Config.DATE_TYPE + '?limit=1000&page=' + page + '&sortKey=asc:dayTypeId')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.dayTypes.forEach((element: any) => {
              this.dayTypes.push({text: element.name, value: element.dayTypeId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getDayTypes(page + 1);
            }
          } else {
            this.dayTypes = [];
          }
        })
        .then( null, (err: any) => {
          this.dayTypes = [];
          console.log('err: ', err);
        }); // catch
    },
    updateData(gateId: any, data: any) {
      return new Promise((resolve, reject) => {
        this.resetData();
        this.$http.put(Config.GATES_LIST_API + '/' + encodeURIComponent(encodeURIComponent(gateId)), data)
          .then((response: any) => {
            if (response.body.message === 'Success') {
              this.successMessageLogs = Config.EDIT_GATE_API_SUCCESS;
              resolve(true);
            } else {
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              resolve(false);
            }
          })
          .then( null, (err: any) => {
            resolve(false);
            switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                this.errorMessageLogs = Config.ERROR_403;
                break;
              case 409:
                this.errorMessageLogs = Config.ERROR_409;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
          }); // catch
      });
    },
    validateBeforeSubmit(data: any) {
      this.errorMessageLogs = '';
    },
    resetData() {
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
    },
    isEmpty(obj: { hasOwnProperty: (arg0: string) => any; }) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    },
    getListGateModel() {
      this.$http.get(Config.GATE_MODEL_LIST_API)
        .then((response: any) => {
          this.listGateModel = response.body.gateModels;
          this.gateDetail.gateModels = response.body.gateModels;
        })
        .then(null, (err: any) => {
          console.log('err: ', err);
        });
    },
  },
});
