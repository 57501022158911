











































































































































import Vue, { PropType } from 'vue';
import GroupListModalTable from '@/components/organisms/GroupListModalTable/GroupListModalTable.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import Searchbox, { SearchTargetItem} from '@/components/molecules/Searchbox/Searchbox.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import GroupAddPage from '@/components/pages/group/GroupAddPage.vue';
import ModalTitle from '@/components/molecules/ModalTitle/ModalTitle.vue';
import * as Config from '@/config';

export interface DataType {
  searchTargetItems: SearchTargetItem[];
  selectedGroupIds: string[];
  actionItems: ActionItem[];
  showConfirm: boolean;
  showProgress: boolean;
  typeAction: number;
  isAuthority: string;
}
interface Type {
  groupId: string;
  groupName: string;
  userId: string;
  isValid: boolean;
  detail: string;
  gateCount: number;
  userCount: number;
}
interface ArrayTime {
  passableTimeSettingId: string;
  name: string;
  dayTypeName: string;
  startTime: string;
  endTime: string;
}
export default Vue.extend({
  name: 'GroupListModalTemplate',
  components: {
    Searchbox,
    GroupListModalTable,
    Breadcrumbs,
    ActionButton,
    GroupAddPage,
    ModalTitle,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    dataGroupsModal: {
      type: Object,
      default: {
        data: [],
        total: 0,
      },
    },
    errorMessageProcess: {
      default: [],
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
      successItemProcess: {
      type: Number,
      default: 0,
    },
    isGroupEditModalShow: {
      type: Boolean,
      default: false,
    },
    authority: {
      type: Object,
      default: ({}),
    },
  },
  data: (): DataType => ({
    isAuthority: '',
    typeAction: 0,
    showConfirm: false,
    showProgress: false,
    searchTargetItems: [
      { label: 'グループ名', value: 'name', isDisabled: true },
    ],
    selectedGroupIds: [],
    actionItems: [
      { title: '有効化する', emitName: 'click-enable' },
      { title: '無効化する', emitName: 'click-disable' },
    ],
  }),
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: '全ての通行権限グループ一覧', disabled: true },
        ];
      },
    },
  },
  methods: {
    showDialogConfirm(type: any) {
      this.showConfirm = true;
      this.typeAction = type;
    },
    closeDialogConfirm() {
      this.showConfirm = false;
    },
    clickGroupsAction() {
      this.showProgress = true;
      this.showConfirm = false;
      this.$emit('click-groups-action', {data: this.selectedGroupIds, typeAction: this.typeAction});
    },
    closeModalProgress() {
      this.showProgress = false;
      this.showConfirm = false;
      this.$emit('get-list-group-modal');
    },
    deleteBridgeProperty() {
      this.$emit('ChangeProperty');
    },
    clickEditGroup(id: any) {
      localStorage.setItem('groupId', id);
      this.$emit('clickEditGroup');
    },
  },
});
