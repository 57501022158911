

















































































































































































































































































































































































































































































































































































































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import UserOfGateLockTable from '@/components/organisms/UserOfGateLockTable/UserOfGateLockTable.vue';
import GroupOfGateLockTable from '@/components/organisms/GroupOfGateLockTable/GroupOfGateLockTable.vue';
import { Group } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import moment from 'moment';
import * as Config from '@/config';
const min: string[] = [];
for (let i = 0; i <= 59 ; i++) {
  let value = '';
  if (i < 10) {
    value = '0' + i;
  } else {
    value = i.toString();
  }
  min.push(value);
}
export interface TypeItem {
  title: string;
}
export interface TypeDessert {
  name: string;
  isVaild: boolean;
}
export interface TimeSettings {
  dayTypeId: number;
  dayTypeName: string;
  allDay: number;
  startTime: string;
  endTime: string;
  pos?: number;
  action: string;
}
export interface DataType {
  groupValue: string;
  inputSpecialday: any;
  hours: any[];
  minutes: any[];
  timeS: string;
  hourStart: string;
  minuteStart: string;
  hourEnd: string;
  minuteEnd: string;
  hoursStartSpecial: string;
  hoursEndSpecial: string;
  errorAddListTime: string;
  errorGate: string;
  errorTime: string;
  searchText: string;
  allDay: boolean;
  arrayAddSpecialTime: any;
  arrayDelSpecialTime: any;
  rulesValidate: any;
  tmpUnlockingTime: any;
  listIconType: any;
  listIconTypeForVirtualGate: any;
  listControlTime: any;
  listIsVisitor: any;
  listBuzzerPattern: any;
  listPassLenght: any;
  listBleIntensity: any;
  listBleIntensityForWafer: any;
  listAntiMode: any;
  filterSectionHeight: any;
  search: any;
  selected: any;
  inputIndex: any;
  selectUser: any;
  selectGroup: any;
  isShow: boolean;
  isShowConfirmUser: boolean;
  isShowConfirmGroup: boolean;

}
export default Vue.extend({
  name: 'GateLockEditForm',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    Pagination,
    ShowPerPage,
    UserOfGateLockTable,
    GroupOfGateLockTable,
  },
  props: {
    typeItems: {
      type: Array as PropType<TypeItem[]>,
      default: () => [],
    },
    unlockTimes: {
      type: Array as PropType<TypeDessert[]>,
      default: () => [],
    },
    groups: {
      type: Array as PropType<Group[]>,
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
    gateDetail: {
      type: Object,
      default: () => ({}),
    },
    listGateModel: {
      type: Array,
      default: () => [],
    },
    relationsGateGroup: {
      type: Array,
      default: () => [],
    },
    userOfGate: {
      type: Array,
      default: () => [],
    },
    dayTypes: {
      type: Array,
      default: [],
    },
    dataUnlockingTime: {
      type: Array as PropType<TimeSettings[]>,
      default: [],
    },
    isFinishEditGate: {
      default: false,
    },
    isUserOfGate: {
      default: false,
    },
    isGroupOfGate: {
      default: false,
    },
    loading: {
      default: false,
    },
    gateListPages: {
      type: Number,
      default: 0,
    },
    groupTotalCount: {
      type: Number,
      default: 0,
    },
    userTotalCount: {
      type: Number,
      default: 0,
    },
    totalGate: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 0,
    },
    pageGroup: {
      type: Number,
      default: 1,
    },
    pageUser: {
      type: Number,
      default: 1,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    listAntiPassGates: {
      type: Array,
      default: () => [],
    },
    showProgressUser: {
      type: Boolean,
      default: false,
    },
    progressUser: {
      type: Number,
      default: 0,
    },
    isFinishProcessUser: {
      type: Boolean,
      default: false,
    },
    userTotalCountDel: {
      type: Number,
      default: 0,
    },
    totalDataRowProcessUser: {
      type: Number,
      default: 0,
    },
    successItemProcessUser: {
      type: Number,
      default: 0,
    },
    errorMessageProcessUser: {
      type: Array,
      default: () => [],
    },
    showProgressGroup: {
      type: Boolean,
      default: false,
    },
    progressGroup: {
      type: Number,
      default: 0,
    },
    isFinishProcessGroup: {
      type: Boolean,
      default: false,
    },
    groupTotalCountDel: {
      type: Number,
      default: 0,
    },
    totalDataRowProcessGroup: {
      type: Number,
      default: 0,
    },
    successItemProcessGroup: {
      type: Number,
      default: 0,
    },
    errorMessageProcessGroup: {
      type: Array,
      default: () => [],
    },
  },
  data: (): DataType => ({
    isShowConfirmUser: false,
    isShowConfirmGroup: false,
    groupValue: '',
    selectUser: [],
    selectGroup: [],
    inputSpecialday: [],
    hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
    minutes: min,
    timeS: '00:00',
    hourStart: '',
    minuteStart: '',
    hourEnd: '',
    minuteEnd: '',
    hoursStartSpecial: '',
    hoursEndSpecial: '',
    errorAddListTime: '',
    errorGate: '',
    searchText: '',
    errorTime: '',
    search: '',
    isShow: false,
    allDay: false,
    arrayAddSpecialTime: [],
    arrayDelSpecialTime: [],
    tmpUnlockingTime: [],
    selected: [],
    filterSectionHeight: 0,
    listIconType: [
      { text: 'その他', value: 0},
      { text: 'ドア', value: 1},
      { text: 'ロッカー', value: 2},
    ],
    listIconTypeForVirtualGate: [
      { text: 'その他', value: 0},
    ],
    listControlTime: [
      { text: '0000－9999 S', value: 0},
      { text: '000.0-999.9 S', value: 1},
      { text: '00.00-99.99 S', value: 2},
      { text: '0.000-9.999 S', value: 3},
    ],
    listIsVisitor: [
      { text: 'ビジター', value: true},
      { text: 'メンバー', value: false},
    ],
    listBuzzerPattern: [
      { text: 'OFF', value: 0},
      { text: '鳴動パターン１', value: 1},
      { text: '鳴動パターン２', value: 2},
    ],
    listPassLenght: [
      { text: '5桁', value: 5},
      { text: '6桁', value: 6},
      { text: '7桁', value: 7},
      { text: '8桁', value: 8},
    ],
    listBleIntensity: [
      { text: '非常に強い', value: 0},
      { text: 'とても強い', value: 1},
      { text: '強い', value: 2},
      { text: 'やや強い', value: 3},
      { text: '普通', value: 4},
      { text: 'やや弱い', value: 5},
      { text: '弱い', value: 6},
      { text: 'とても弱い', value: 7},
    ],
    listBleIntensityForWafer: [
      { text: 'とても強い', value: 1},
      { text: '強い', value: 2},
      { text: 'やや強い', value: 3},
      { text: '普通', value: 4},
      { text: 'やや弱い', value: 5},
      { text: '弱い', value: 6},
    ],
    listAntiMode: [
      { text: 'ログモード', value: 0},
      { text: '通行禁止モード', value: 1},
    ],
    inputIndex: {
      gateName: 1,
      isVisitor: 2,
      isNFCValid: 3,
      isTenKeyValid: 4,
      isGatePasswordValid: 5,
      gatePasswordLength: 6,
      gatePasswordSeed: 7,
      isTwoStepAuthValid: 8,
      bleIntensity: 9,
      buzzerPattern: 10,
      option1: 11,
      option2: 12,
      gateSNMain: 13,
      gateSNBLE: 14,
      gateFWMain: 15,
      gateFWBLE: 16,
      registered: 17,
      updated: 18,
      reported: 19,
      revisionDesired: 20,
      revisionReported: 21,
      voltage: 22,
      scanDate: 23,
      isValid: 24,
      controlTime: 25,
      needLocation: 26,
    },
    rulesValidate: {
      requiredGateModelId: (value: string) => !!value || '製品名は必須です。',
      requiredName: (value: string) => !!value || 'ゲート名は必須です。',
      validateName: (name: string) => {
        return name.length <= 64 || 'ゲート名は64文字以内で入力してください。';
      },
      validateIsGatePasswordValid: (isGatePasswordValid: any , gatePasswordSeed: any) => {
        if (isGatePasswordValid === true && gatePasswordSeed.length > 0) {
          const isOk = /^[0-9a-fA-F]+$/.test(gatePasswordSeed);
          if (!isOk || gatePasswordSeed.length !== 18) {
            return '半角英数（0～9、A〜F）で18文字入力してください。';
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
      validateOption1: (option: string) => {
      if (option !== null && option !== undefined && option !== '') {
          return option.length <= 64 || '備考1は64文字以内で入力してください。';
        } else {
          return true;
        }
      },
      validateOption2: (option: string) => {
      if (option !== null && option !== undefined && option !== '') {
          return option.length <= 64 || '備考2は64文字以内で入力してください。';
        } else {
          return true;
        }
      },
      validateAntiPassback: (isAntiPassback: any, gateId: any) => {
      if (isAntiPassback === true) {
        if (gateId.trim() === '') {
          return '対向ゲートを選択してください';
        } else {
          return true;
        }
      } else {
        return true;
      }
      },
    },
  }),
  watch: {
    isFinishEditGate(value) {
      if (value) {
        this.arrayAddSpecialTime = [];
        this.arrayDelSpecialTime = [];
      }
    },
    loading(val) {
      if (val === false && this.isEdit === true) {
        this.matchHeight();
      }
    },
    gateDetail(item) {
      if (this.isEdit) {
        this.selected = item.antiPassback.gateId;
      }
    },
  },
  mounted() {
    //
    this.matchHeight();
  },
  computed: {
    innerPageGroup: {
      get(): number {
        return this.pageGroup;
      },
      set(val: number): void {
        this.$emit('change-page-gate', {page: val});
        return;
      },
    },
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
          {
            text: '曜日',
            align: 'start',
            sortable: false,
            value: 'date',
          },
          {
            text: '期間指定',
            align: 'start',
            sortable: false,
            value: 'time',
          },
          {
            text: '',
            align: 'center',
            sortable: false,
            value: 'delete',
          },
        ];
        return headers;
      },
      set(): void {
        return;
      },
    },
    headersGate: {
      get(): DataTableHeader[] {
        const headersGate: DataTableHeader[] = [
          {
            text: 'ゲートID',
            align: 'start',
            sortable: false,
            value: 'gateId',
          },
          {
            text: 'ゲート名',
            align: 'start',
            sortable: false,
            value: 'gateName',
          },
        ];
        return headersGate;
      },
      set(): void {
        return;
      },
    },
    headersGroup: {
      get(): DataTableHeader[] {
        const headersGroup: DataTableHeader[] = [
          {
            text: 'ゲートグループID	',
            align: 'start',
            sortable: false,
            value: 'group.groupId',
          },
          {
            text: 'ゲートグループ名	',
            align: 'start',
            sortable: false,
            value: 'group.groupName',
          },
          {
            text: 'ステータス',
            align: 'start',
            sortable: false,
            value: 'isValid',
          },
        ];
        return headersGroup;
      },
      set(): void {
        return;
      },
    },
    filteredItems: {
      get(): any {
        return this.tmpUnlockingTime.filter((i: any) => {
          return (i.action !== 'del');
        });
      },
    },
    innerPage: {
      get(): number {
        return this.page;
      },
      set(val: number): void {
        this.$emit('change-page', {page: val});
        return;
      },
    },
  },
  methods: {
    clickChangeInfoGate(gateModel: string) {
      this.$emit('changeInfoGate', gateModel);
      this.matchHeight();
    },
    clickRemoveUser(val: any) {
      this.isShowConfirmUser = false;
      this.$emit('clickRemoveUser', val);
    },
    clickRemoveGroup(val: any) {
      this.isShowConfirmGroup = false;
      this.$emit('clickRemoveGroup', val);
    },
    getSelectUser(val: any) {
      this.selectUser = val;
    },
    getSelectGroup(val: any) {
      this.selectGroup = val;
    },
    setGate(val: any) {
      this.$emit('setGate', val);
      this.isShow = false;
    },
    getGate() {
      this.$emit('get-gates');
      this.isShow = true;
    },
    addGate() {
      //
      const result = (this.$refs.GateAddForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        //
        this.$emit('register-gates');
      }
    },
    editGate() {
      //
      const result = (this.$refs.GateAddForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        //
        this.$emit('edit-gates');
      }
    },
    disableTwoStepAuth() {
      // this method uses to inactive and disable two step field when anti-pass is actived
      if (this.gateDetail.isAntiPassback) {
        // if anti-pass is actived then inactive and disable two-step-auth field
        this.gateDetail.isTwoStepAuthValid = false;
      }
      return this.gateDetail.isAntiPassback;
    },
    disableAntiPass() {
      // this method uses to inactive and disable anti-pass when two-step-auth field is actived
      if (this.gateDetail.isTwoStepAuthValid) {
        this.gateDetail.isAntiPassback = false;
        this.gateDetail.antiPassback.gateId = '';
        this.gateDetail.antiPassback.gateName = '';
        this.gateDetail.antiPassback.mode = 0;
      }
      return this.gateDetail.isTwoStepAuthValid;
    },
    clickSearch() {
      this.search = this.searchText;
    },
    matchHeight() {
      this.filterSectionHeight = 734;
      const colLeft = document.getElementById('col-left');
      const colRight = document.getElementById('col-right');
      setTimeout(() => {
        if (colRight && colLeft) {
          const heightRight = colRight.offsetHeight;
          const heightLeft = colLeft.offsetHeight;
          this.filterSectionHeight = heightRight > heightLeft ? heightRight : heightLeft;
        }
      }, 100);
    },
    clickUpdateGate() {
      const result = (this.$refs.GateAddForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        this.$emit('clickUpdateGate', {
          dataUnlockingTime: this.dataUnlockingTime,
          tmpUnlockingTime: this.tmpUnlockingTime,
          gateDetail: this.gateDetail,
          groupId: this.groupValue,
        });
      }
    },
    formatTime(hours: string, minute: number | string) {
      return `${('00' + hours).slice(-2)}:${('00' + minute).slice(-2)}`;
    },
    converDate(t: any) {
      return (t !== '' && t !== 0) ? moment(new Date(t * 1000)).format('YYYY/MM/DD HH:mm:ss') : 'ー';
    },
    deleteSpecialTime(index: any) {
      console.log(index);
      // this.dataUnlockingTime.splice(parseInt(index), 1);
      const i = this.tmpUnlockingTime.map((e: { index: any; }) => e.index).indexOf(index);
      if (this.tmpUnlockingTime[parseInt(i)].action === 'add') {
        this.tmpUnlockingTime.splice(parseInt(i), 1);
      } else {
        this.tmpUnlockingTime[parseInt(i)].action = 'del';
      }
    },
    checkShowAnti() {
      // only show Anti-pass when makercode is ALLIGATE (=1) and is not visitor
      if (!this.gateDetail.isVisitor) {
        if (this.gateDetail.makerCode === 1 && (this.gateDetail.target === 1 || this.gateDetail.target === 5)) {
          // markercode is ALLIGATE and target is ALLIGATE I/O (not Logger)
          return true;
        }
        // markercode is not ALLIGATE, it is able to be WaferLock => Anti-pass is resetted (inactive)
        this.resetAntiPassGate();
      }
      return false;
    },
    getEditStatus() {
      if (!this.gateDetail.isGatePasswordValid || this.gateDetail.isVisitor) {
        return true;
      } else {
        return false;
      }
    },
    resetAntiPassGate() {
      this.$emit('resetAntiPassGate');
    },
    disableAntiModes() {
      this.$emit('disableAntiModes');
      return !this.gateDetail.isAntiPassback;
    },
    checkShowHide(makerCode: any, inputIndex: any) {
      inputIndex = inputIndex || -1;
      switch (inputIndex) {
        case this.inputIndex.isTwoStepAuthValid:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.bleIntensity:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.buzzerPattern:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.gateSNMain:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.gateSNBLE:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.gateFWMain:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.gateFWBLE:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.registered:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.updated:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.reported:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.revisionDesired:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.revisionReported:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.voltage:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.scanDate:
          if (makerCode === 91) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.isVisitor:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.isNFCValid:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.isTenKeyValid:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.isGatePasswordValid:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.gatePasswordLength:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.gatePasswordSeed:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.controlTime:
          if (makerCode === 91 || makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
        case this.inputIndex.needLocation:
          if (makerCode === 91) {
            return true;
          } else {
            return false;
          }
        default:
          if (makerCode === 2 || makerCode === 4) {
            return false;
          } else {
            return true;
          }
      }
    },
  },
});
