var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPage !== "detail"
    ? _c(
        "div",
        { staticClass: "allig-group-list-template" },
        [
          _c("breadcrumbs", { attrs: { items: _vm.breadcrumbs } }),
          _vm._v(" "),
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "ml-5", attrs: { justify: "start" } },
                [
                  _c(
                    "div",
                    { staticClass: "mt-4 mb-3 mr-9" },
                    [
                      _vm.isPage === "user"
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary ",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("close-page-modal-user")
                                    }
                                  }
                                },
                                [_vm._v("\n          戻る\n          ")]
                              )
                            ],
                            1
                          )
                        : _c(
                            "v-row",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary ",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("close-page-modal")
                                    }
                                  }
                                },
                                [_vm._v("\n          戻る\n          ")]
                              )
                            ],
                            1
                          )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-container", { staticClass: "mt-0 pt-0" }, [
            _c(
              "div",
              { staticClass: "container-user mt-0 pt-0" },
              [
                _c(
                  "div",
                  { staticClass: "round-action" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "btn-div", attrs: { justify: "end" } },
                      [
                        _c("v-col", { attrs: { xl: "7", sm: "7" } }, [
                          _c(
                            "div",
                            { staticClass: "area-search-box" },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    "no-gutters": "",
                                    justify: "space-between"
                                  }
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("searchbox", {
                                        staticClass:
                                          "input-search btn-user-search",
                                        attrs: {
                                          searchTargetItems:
                                            _vm.searchTargetItems,
                                          title: "所属を検索"
                                        },
                                        on: {
                                          search: function($event) {
                                            return _vm.$emit(
                                              "search-group",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "btn-div",
                                attrs: { justify: "end" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "text-center mr-1" },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "btn-add pt-0" },
                                      [
                                        _vm.authority.isAdmin === true
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "btn-primary",
                                                attrs: { outlined: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addAff()
                                                  }
                                                }
                                              },
                                              [_vm._v("新しい所属を作成する")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("AffiliationTable", {
                  attrs: {
                    authority: _vm.authority,
                    dataGroupsModal: _vm.dataGroupsModal,
                    page: _vm.page,
                    perPage: _vm.perPage,
                    loading: _vm.loading,
                    expandData: _vm.expandData,
                    dataRelationUser: _vm.dataRelationUser,
                    dataRelationGroup: _vm.dataRelationGroup,
                    userItems: _vm.userItems,
                    successMessageLogs: _vm.successMessageLogs,
                    errorMessageLogs: _vm.errorMessageLogs,
                    isPage: _vm.isPage,
                    departmentItem: _vm.department,
                    isGetUser: _vm.isGetUser
                  },
                  on: {
                    "change-per-page": function($event) {
                      return _vm.$emit("changePerPage", $event)
                    },
                    "change-page": function($event) {
                      return _vm.$emit("changePage", $event)
                    },
                    changeTab: function($event) {
                      return _vm.$emit("changeTab", $event)
                    },
                    "select-groups": function(groupIds) {
                      _vm.selectedGroupIds = groupIds
                    },
                    "expand-gate-group": function($event) {
                      return _vm.$emit("expand-gate-group", $event)
                    },
                    "click-delete-item": function($event) {
                      return _vm.clickDeleteItem($event)
                    },
                    "change-page-user": function($event) {
                      return _vm.$emit("change-page-user", $event)
                    },
                    "change-per-page-user": function($event) {
                      return _vm.$emit("change-per-page-user", $event)
                    },
                    "search-user": function($event) {
                      return _vm.$emit("search-user", $event)
                    },
                    "load-list-users": function($event) {
                      return _vm.$emit("load-list-users", $event)
                    },
                    "add-user": function($event) {
                      return _vm.$emit("add-user", $event)
                    },
                    "change-page-user-all": function($event) {
                      return _vm.$emit("change-page-user-all", $event)
                    },
                    "change-per-page-user-all": function($event) {
                      return _vm.$emit("change-per-page-user-all", $event)
                    },
                    "remove-user": function($event) {
                      return _vm.$emit("remove-user", $event)
                    },
                    "load-users": function($event) {
                      return _vm.$emit("load-users", $event)
                    },
                    "change-page-group": function($event) {
                      return _vm.$emit("change-page-group", $event)
                    },
                    "change-page-gate-group": function($event) {
                      return _vm.$emit("change-page-gate-group", $event)
                    },
                    "remove-group": function($event) {
                      return _vm.$emit("remove-group", $event)
                    },
                    "load-list-groups": function($event) {
                      return _vm.$emit("load-list-groups", $event)
                    },
                    "add-group": function($event) {
                      return _vm.$emit("add-group", $event)
                    },
                    "change-page-group-all": function($event) {
                      return _vm.$emit("change-page-group-all", $event)
                    },
                    "change-per-page-group-all": function($event) {
                      return _vm.$emit("change-per-page-group-all", $event)
                    },
                    "load-aff-detail": function($event) {
                      return _vm.$emit("load-aff-detail", $event)
                    },
                    "remove-detail-aff": function($event) {
                      return _vm.$emit("remove-detail-aff", $event)
                    },
                    "load-detail-aff-again": function($event) {
                      return _vm.$emit("load-detail-aff-again", $event)
                    },
                    "update-aff": function($event) {
                      return _vm.$emit("update-aff", $event)
                    },
                    "loading-from-child": function($event) {
                      return _vm.$emit("loading-from-child", $event)
                    },
                    "close-page-modal": function($event) {
                      return _vm.$emit("close-page-modal")
                    },
                    "search-group-department": function($event) {
                      return _vm.$emit("search-group-department", $event)
                    },
                    "get-list-group-modal": function($event) {
                      return _vm.$emit("get-list-group-modal")
                    },
                    "get-list-user": function($event) {
                      return _vm.$emit("get-list-user")
                    },
                    "reload-data-group": function($event) {
                      return _vm.$emit("reload-data-group", $event)
                    },
                    getDepartmentUserValid: function($event) {
                      return _vm.$emit("getDepartmentUserValid", $event)
                    },
                    "change-expanded": function($event) {
                      return _vm.$emit("change-expanded", $event)
                    }
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { width: "600", persistent: "" },
              model: {
                value: _vm.showConfirm,
                callback: function($$v) {
                  _vm.showConfirm = $$v
                },
                expression: "showConfirm"
              }
            },
            [
              _c(
                "v-card",
                [
                  _vm.typeAction === 0
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("グループを有効化")]
                      )
                    : _vm.typeAction === 1
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("グループを無効化")]
                      )
                    : _vm.typeAction === 3
                    ? _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("所属一覧")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c("v-row", {
                    staticStyle: { height: "5px" },
                    attrs: { "no-gutters": "" }
                  }),
                  _vm._v(" "),
                  _vm.typeAction === 0
                    ? _c("v-card-text", { staticClass: "pa-4" }, [
                        _c("p", [
                          _vm._v("チェックしたグループを有効化します。")
                        ])
                      ])
                    : _vm.typeAction === 1
                    ? _c("v-card-text", { staticClass: "pa-4" }, [
                        _c("p", [
                          _vm._v("チェックしたグループを無効化します。")
                        ])
                      ])
                    : _vm.typeAction === 3
                    ? _c("v-card-text", { staticClass: "pa-4" }, [
                        _c("p", [_vm._v("関連付けを解除しますか？")])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  _vm.showConfirm = false
                                }
                              }
                            },
                            [_vm._v("\n            キャンセル\n          ")]
                          ),
                          _vm._v(" "),
                          _vm.typeAction === 3
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.clickDeleteDepartment()
                                    }
                                  }
                                },
                                [_vm._v("OK")]
                              )
                            : _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.clickGroupsAction()
                                    }
                                  }
                                },
                                [_vm._v("OK")]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { width: "800", persistent: "" },
              model: {
                value: _vm.showProgress,
                callback: function($$v) {
                  _vm.showProgress = $$v
                },
                expression: "showProgress"
              }
            },
            [
              _vm.typeAction === 3
                ? _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "background-color-green" },
                        [_vm._v("所属一覧")]
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c("v-card-text", { staticClass: "pa-4" }, [
                        _vm.errorMessageLogs === ""
                          ? _c("p", [_vm._v("件を削除しました。")])
                          : _c("p", [_vm._v(_vm._s(_vm.errorMessageLogs))])
                      ]),
                      _vm._v(" "),
                      _vm.loading === false
                        ? _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-2",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-secondary",
                                      attrs: { outlined: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.closeModalProgressDelete()
                                        }
                                      }
                                    },
                                    [_vm._v("OK")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    [
                      _vm.typeAction === 0
                        ? _c(
                            "v-card-title",
                            { staticClass: "background-color-green" },
                            [_vm._v("グループを有効化")]
                          )
                        : _vm.typeAction === 1
                        ? _c(
                            "v-card-title",
                            { staticClass: "background-color-green" },
                            [_vm._v("グループを無効化")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c("v-row", {
                        staticStyle: { height: "5px" },
                        attrs: { "no-gutters": "" }
                      }),
                      _vm._v(" "),
                      _vm.totalDataRowProcess > 0
                        ? _c(
                            "v-card-text",
                            { staticClass: "pa-4" },
                            [
                              _c(
                                "v-progress-linear",
                                {
                                  staticStyle: { "pointer-events": "none" },
                                  attrs: {
                                    height: "25",
                                    color: "green darken-1",
                                    striped: ""
                                  },
                                  model: {
                                    value: _vm.progress,
                                    callback: function($$v) {
                                      _vm.progress = $$v
                                    },
                                    expression: "progress"
                                  }
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(Math.round(_vm.progress)) + "%"
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _vm.successItemProcess > 0 && _vm.typeAction === 0
                                ? _c("span", { staticClass: "success-label" }, [
                                    _vm._v(
                                      _vm._s(_vm.totalDataRowProcess) +
                                        "件中" +
                                        _vm._s(_vm.successItemProcess) +
                                        "件を有効化しました。"
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.successItemProcess > 0 && _vm.typeAction === 1
                                ? _c("span", { staticClass: "success-label" }, [
                                    _vm._v(
                                      _vm._s(_vm.totalDataRowProcess) +
                                        "件中" +
                                        _vm._s(_vm.successItemProcess) +
                                        "件を無効化しました。"
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.errorMessageProcess.length > 0
                                ? _c("div", [
                                    _vm.typeAction === 0
                                      ? _c(
                                          "span",
                                          { staticClass: "error-box-title" },
                                          [_vm._v("有効化失敗")]
                                        )
                                      : _vm.typeAction === 1
                                      ? _c(
                                          "span",
                                          { staticClass: "error-box-title" },
                                          [_vm._v("無効化失敗")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "error-box" },
                                      _vm._l(_vm.errorMessageProcess, function(
                                        item
                                      ) {
                                        return _c("p", {
                                          key: item,
                                          domProps: { innerHTML: _vm._s(item) }
                                        })
                                      }),
                                      0
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isFinishProcess
                        ? _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-2",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "btn-secondary",
                                      attrs: { outlined: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.closeModalProgress()
                                        }
                                      }
                                    },
                                    [_vm._v("OK")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isShow
            ? _c(
                "v-dialog",
                {
                  attrs: { width: "1200", persistent: "" },
                  model: {
                    value: _vm.isShow,
                    callback: function($$v) {
                      _vm.isShow = $$v
                    },
                    expression: "isShow"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "modal-title",
                          attrs: { color: "green" }
                        },
                        [_vm._v("所属追加")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-container",
                        { staticStyle: { "padding-bottom": "0" } },
                        [
                          _vm.successMessageLogs !== "" &&
                          _vm.loading === false &&
                          _vm.expandData.errorData.groups.length === 0 &&
                          _vm.expandData.errorData.users.length === 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-bold bg-success bg-no align-self-center mr-2"
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.successMessageLogs) +
                                      "\n        "
                                  )
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-bold bg-error bg-no align-self-center mr-2"
                                },
                                [
                                  _vm.successMessageLogs !== "" &&
                                  _vm.loading === false
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.successMessageLogs))
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.errorMessageLogs !== "" &&
                                  _vm.loading === false
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.errorMessageLogs))
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.expandData.errorData.users.length > 0 &&
                                  _vm.loading === false
                                    ? _c("p", [
                                        _vm._v(
                                          "登録失敗所属ユーザー: " +
                                            _vm._s(
                                              _vm.expandData.errorData.users.toString()
                                            )
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.expandData.errorData.groups.length > 0 &&
                                  _vm.loading === false
                                    ? _c("p", [
                                        _vm._v(
                                          "設定失敗通行権限グループ: " +
                                            _vm._s(
                                              _vm.expandData.errorData.groups.toString()
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-secondary ",
                              attrs: { outlined: "" },
                              on: { click: _vm.clickBack }
                            },
                            [_vm._v("\n        戻る\n        ")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-container",
                        [
                          _c("div", { staticClass: "title-block-card mt-0" }, [
                            _vm._v("所属情報")
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-card",
                            { staticClass: "affiliation-information" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { staticClass: "col-12" }, [
                                    _c("div", [_vm._v("所属名 ")])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { staticClass: "col-12" },
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          ref: "AffAddForm",
                                          attrs: { "lazy-validation": "" }
                                        },
                                        [
                                          _c("input-form", {
                                            attrs: {
                                              rules: [
                                                _vm.rulesValidate.requiredName
                                              ]
                                            },
                                            model: {
                                              value: _vm.departmentInfo.name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.departmentInfo,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "departmentInfo.name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { staticClass: "col-12" }, [
                                    _c("div", [_vm._v("所属管理者 ")])
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.listAff, function(item, i) {
                                return _c(
                                  "v-row",
                                  { key: i, staticClass: "mt-2 ml-5" },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "col-4" },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.userItems,
                                            "item-text": "text",
                                            "item-value": "value",
                                            "single-line": "",
                                            color: "green",
                                            "hide-details": "auto",
                                            outlined: "",
                                            dense: "",
                                            "no-data-text": ""
                                          },
                                          model: {
                                            value: item.userId,
                                            callback: function($$v) {
                                              _vm.$set(item, "userId", $$v)
                                            },
                                            expression: "item.userId"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { staticClass: "col-6" },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "item-check-box-detail"
                                          },
                                          [
                                            _c("v-checkbox", {
                                              attrs: {
                                                color: "green",
                                                dense: "",
                                                "hide-details": "",
                                                label: "ユーザー追加権限"
                                              },
                                              model: {
                                                value: item.checkOne,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "checkOne",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.checkOne"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("v-checkbox", {
                                              attrs: {
                                                color: "green",
                                                dense: "",
                                                "hide-details": "",
                                                label: "ユーザー編集権限"
                                              },
                                              model: {
                                                value: item.checkTwo,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "checkTwo",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.checkTwo"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c("v-col", { staticClass: "col-2 pt-0" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "v-subheader theme--light ml-0 pa-0 mt-0 add-button",
                                        on: { click: _vm.addAffiliation }
                                      },
                                      [_vm._v(" + 追加する ")]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "title-block-card" }, [
                            _vm._v("所属ユーザー")
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-card",
                            [
                              _c("UsersAllowedTable", {
                                attrs: {
                                  authority: _vm.authority,
                                  dataList: _vm.expandData.userAll.data,
                                  isPage: "affiliation-add",
                                  loading: _vm.expandData.userAll.loading,
                                  totalCount: _vm.expandData.userAll.total,
                                  perPage: _vm.expandData.userAll.perPage,
                                  page: _vm.expandData.userAll.page,
                                  users: _vm.expandData.userAll,
                                  dataRelationUser: _vm.dataRelationUser
                                },
                                on: {
                                  "change-page": function($event) {
                                    return _vm.$emit(
                                      "change-page-user-all",
                                      $event.page
                                    )
                                  },
                                  "change-per-page": function($event) {
                                    return _vm.$emit(
                                      "change-per-page-user-all",
                                      $event.perPage
                                    )
                                  },
                                  "select-Ids": function(Ids) {
                                    _vm.selectedUserIds = Ids
                                  },
                                  "search-user": function($event) {
                                    return _vm.$emit("search-user-add", $event)
                                  },
                                  "loading-from-child": function($event) {
                                    return _vm.$emit(
                                      "loading-from-child",
                                      $event
                                    )
                                  },
                                  getDepartmentUserValid: function($event) {
                                    return _vm.$emit(
                                      "getDepartmentUserValid",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "title-block-card" }, [
                            _vm._v("設定されている通行権限グループ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-card",
                            [
                              _c("AccessPermissionGroupTable", {
                                attrs: {
                                  authority: _vm.authority,
                                  dataGroupsModal: _vm.expandData.groupAll,
                                  gateGroup: _vm.expandData.gateGroup,
                                  isPage: "affiliation-add",
                                  loading: _vm.expandData.groupAll.loading,
                                  page: _vm.expandData.groupAll.page,
                                  dataRelation: _vm.dataRelationGroup,
                                  groups: _vm.expandData.groupAll
                                },
                                on: {
                                  "expand-gate-group": function($event) {
                                    return _vm.$emit(
                                      "expand-gate-group",
                                      $event
                                    )
                                  },
                                  "change-page": function($event) {
                                    return _vm.$emit(
                                      "change-page-group-all",
                                      $event
                                    )
                                  },
                                  "change-page-group-all": function($event) {
                                    return _vm.$emit(
                                      "change-page-group-all",
                                      $event
                                    )
                                  },
                                  "change-page-gate-group": function($event) {
                                    return _vm.$emit(
                                      "change-page-gate-group",
                                      $event
                                    )
                                  },
                                  "change-per-page-group-all": function(
                                    $event
                                  ) {
                                    return _vm.$emit(
                                      "change-per-page-group-all",
                                      $event
                                    )
                                  },
                                  "select-Ids": function(Ids) {
                                    _vm.selectedGIds = Ids
                                  },
                                  "search-group": function($event) {
                                    return _vm.$emit("search-group-add", $event)
                                  },
                                  changeTab: function($event) {
                                    return _vm.$emit("change-tab", { tab: 1 })
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-row",
                            { staticClass: "ma-2", attrs: { justify: "end" } },
                            [
                              _vm.successMessageLogs !== "" &&
                              _vm.loading === false &&
                              _vm.expandData.errorData.groups.length === 0 &&
                              _vm.expandData.errorData.users.length === 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-weight-bold bg-success bg-no align-self-center mr-2"
                                    },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(_vm.successMessageLogs) +
                                          "\n        "
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-weight-bold bg-error bg-no align-self-center mr-2"
                                    },
                                    [
                                      _vm.successMessageLogs !== "" &&
                                      _vm.loading === false
                                        ? _c("p", [
                                            _vm._v(
                                              _vm._s(_vm.successMessageLogs)
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.errorMessageLogs !== "" &&
                                      _vm.loading === false
                                        ? _c("p", [
                                            _vm._v(_vm._s(_vm.errorMessageLogs))
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.expandData.errorData.users.length >
                                        0 && _vm.loading === false
                                        ? _c("p", [
                                            _vm._v(
                                              "登録失敗所属ユーザー: " +
                                                _vm._s(
                                                  _vm.expandData.errorData.users.toString()
                                                )
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.expandData.errorData.groups.length >
                                        0 && _vm.loading === false
                                        ? _c("p", [
                                            _vm._v(
                                              "設定失敗通行権限グループ: " +
                                                _vm._s(
                                                  _vm.expandData.errorData.groups.toString()
                                                )
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-primary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.registerAff()
                                    }
                                  }
                                },
                                [_vm._v("\n          作成する\n        ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _c(
        "div",
        [
          _c("AffiliationTable", {
            attrs: {
              authority: _vm.authority,
              dataGroupsModal: _vm.dataGroupsModal,
              page: _vm.page,
              perPage: _vm.perPage,
              loading: _vm.loading,
              expandData: _vm.expandData,
              dataRelationUser: _vm.dataRelationUser,
              dataRelationGroup: _vm.dataRelationGroup,
              userItems: _vm.userItems,
              successMessageLogs: _vm.successMessageLogs,
              errorMessageLogs: _vm.errorMessageLogs,
              isPage: _vm.isPage,
              departmentItem: _vm.department,
              isGetUser: _vm.isGetUser
            },
            on: {
              "change-per-page": function($event) {
                return _vm.$emit("changePerPage", $event)
              },
              "change-page": function($event) {
                return _vm.$emit("changePage", $event)
              },
              changeTab: function($event) {
                return _vm.$emit("changeTab", $event)
              },
              "select-groups": function(groupIds) {
                _vm.selectedGroupIds = groupIds
              },
              "expand-gate-group": function($event) {
                return _vm.$emit("expand-gate-group", $event)
              },
              "click-delete-item": function($event) {
                return _vm.clickDeleteItem($event)
              },
              "change-page-user": function($event) {
                return _vm.$emit("change-page-user", $event)
              },
              "change-per-page-user": function($event) {
                return _vm.$emit("change-per-page-user", $event)
              },
              "search-user": function($event) {
                return _vm.$emit("search-user", $event)
              },
              "load-list-users": function($event) {
                return _vm.$emit("load-list-users", $event)
              },
              "add-user": function($event) {
                return _vm.$emit("add-user", $event)
              },
              "change-page-user-all": function($event) {
                return _vm.$emit("change-page-user-all", $event)
              },
              "change-per-page-user-all": function($event) {
                return _vm.$emit("change-per-page-user-all", $event)
              },
              "remove-user": function($event) {
                return _vm.$emit("remove-user", $event)
              },
              "load-users": function($event) {
                return _vm.$emit("load-users", $event)
              },
              "change-page-group": function($event) {
                return _vm.$emit("change-page-group", $event)
              },
              "change-page-gate-group": function($event) {
                return _vm.$emit("change-page-gate-group", $event)
              },
              "remove-group": function($event) {
                return _vm.$emit("remove-group", $event)
              },
              "load-list-groups": function($event) {
                return _vm.$emit("load-list-groups", $event)
              },
              "add-group": function($event) {
                return _vm.$emit("add-group", $event)
              },
              "change-page-group-all": function($event) {
                return _vm.$emit("change-page-group-all", $event)
              },
              "change-per-page-group-all": function($event) {
                return _vm.$emit("change-per-page-group-all", $event)
              },
              "load-aff-detail": function($event) {
                return _vm.$emit("load-aff-detail", $event)
              },
              "remove-detail-aff": function($event) {
                return _vm.$emit("remove-detail-aff", $event)
              },
              "load-detail-aff-again": function($event) {
                return _vm.$emit("load-detail-aff-again", $event)
              },
              "update-aff": function($event) {
                return _vm.$emit("update-aff", $event)
              },
              "loading-from-child": function($event) {
                return _vm.$emit("loading-from-child", $event)
              },
              "close-page-modal": function($event) {
                return _vm.$emit("close-page-modal")
              },
              "search-group-department": function($event) {
                return _vm.$emit("search-group-department", $event)
              },
              "get-list-group-modal": function($event) {
                return _vm.$emit("get-list-group-modal")
              },
              "get-list-user": function($event) {
                return _vm.$emit("get-list-user")
              },
              "back-group-detail": function($event) {
                return _vm.$emit("back-group-detail", $event)
              },
              getDepartmentUserValid: function($event) {
                return _vm.$emit("getDepartmentUserValid", $event)
              },
              "change-expanded": function($event) {
                return _vm.$emit("change-expanded", $event)
              }
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }