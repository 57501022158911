var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("affiliation-list-modal-template", {
    attrs: {
      page: _vm.currPage,
      perPage: _vm.perPage,
      loading: _vm.loading,
      successMessageLogs: _vm.successMessageLogs,
      errorMessageLogs: _vm.errorMessageLogs,
      dataGroupsModal: _vm.dataGroupsModal,
      rowinErrDialog: _vm.rowinErrDialog,
      errorMessageProcess: _vm.errorMessageProcess,
      successItemProcess: _vm.successItemProcess,
      progress: _vm.progress,
      isFinishProcess: _vm.isFinishProcess,
      totalDataRowProcess: _vm.totalDataRowProcess,
      expandData: _vm.expandData,
      dataRelationUser: _vm.dataRelationUser,
      userItems: _vm.userItems,
      authority: _vm.authority,
      dataRelationGroup: _vm.dataRelationGroup,
      isPage: _vm.isPage,
      department: _vm.departmentItem,
      isGetUser: _vm.isGetUser
    },
    on: {
      changePage: function($event) {
        return _vm.changePage($event)
      },
      changePerPage: function($event) {
        return _vm.changePerPage($event)
      },
      "search-group": function($event) {
        return _vm.searchGroup($event)
      },
      "click-groups-action": function($event) {
        return _vm.clickGroupsAction($event)
      },
      "get-list-group-modal": function($event) {
        return _vm.getListGroupsModal()
      },
      changeTab: function($event) {
        return _vm.changeTab($event)
      },
      "expand-gate-group": function($event) {
        return _vm.expandGateGroup($event)
      },
      "delete-department": function($event) {
        return _vm.deleteDepartment($event)
      },
      "change-page-user": function($event) {
        return _vm.changePageAndPerPageUser($event)
      },
      "change-per-page-user": function($event) {
        return _vm.changePageAndPerPageUser($event)
      },
      "search-user": function($event) {
        return _vm.searchUser($event)
      },
      "load-list-users": function($event) {
        return _vm.loadListUsers($event)
      },
      "add-user": function($event) {
        return _vm.addUsers($event)
      },
      "change-page-user-all": function($event) {
        return _vm.changePageUserAll($event)
      },
      "change-per-page-user-all": function($event) {
        return _vm.changePerPageUserAll($event)
      },
      "remove-user": function($event) {
        return _vm.removeUsers($event)
      },
      "change-page-group": function($event) {
        return _vm.changePageGroup($event)
      },
      "load-list-groups": function($event) {
        return _vm.loadListGroups($event)
      },
      "change-page-group-all": function($event) {
        return _vm.changePageGroupAll($event)
      },
      "change-per-page-group-all": function($event) {
        return _vm.changePerPageGroupAll($event)
      },
      "add-group": function($event) {
        return _vm.addGroups($event)
      },
      "load-aff-detail": function($event) {
        return _vm.loadAffDetail($event)
      },
      "remove-detail-aff": function($event) {
        return _vm.removeAffDetail($event)
      },
      "load-detail-aff-again": function($event) {
        return _vm.getListGroupsModal()
      },
      "update-aff": function($event) {
        return _vm.updateAff($event)
      },
      "load-group-and-user-for-add": function($event) {
        return _vm.loadAllUserForAdd($event)
      },
      "register-aff": function($event) {
        return _vm.registerAff($event)
      },
      "loading-from-child": function($event) {
        return _vm.$emit("loading", $event)
      },
      "close-page-modal": function($event) {
        return _vm.$emit("close-page-modal")
      },
      "close-page-modal-user": function($event) {
        return _vm.$emit("close-page-modal-user")
      },
      "search-group-department": function($event) {
        return _vm.searchGroupDe($event)
      },
      "search-group-add": function($event) {
        return _vm.searchGroupAddPage($event)
      },
      "search-user-add": function($event) {
        return _vm.searchUserAddPage($event)
      },
      "get-list-user": function($event) {
        return _vm.$emit("get-list-user")
      },
      "remove-group": function($event) {
        return _vm.removeGroups($event)
      },
      "reload-data-group": function($event) {
        return _vm.reloadDataGroup($event)
      },
      "back-group-detail": function($event) {
        return _vm.$emit("back-group-detail", $event)
      },
      "change-page-gate-group": function($event) {
        return _vm.changePageGateGroup($event)
      },
      getDepartmentUserValid: function($event) {
        return _vm.getDepartmentUserValid($event)
      },
      "change-expanded": function($event) {
        return _vm.changeExpanded($event)
      },
      "load-list-users-again": function($event) {
        return _vm.getUsersAgain(1)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }