var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-group-table aff-table" },
    [
      _vm.isPage !== "detail"
        ? _c(
            "div",
            [
              _c(
                "v-row",
                {
                  staticClass: "mt-2 border-bottom-bold",
                  attrs: {
                    "no-gutters": "",
                    justify: "space-between",
                    "align-content": "center"
                  }
                },
                [
                  _c("show-per-page", {
                    staticClass: "ml-4",
                    attrs: {
                      "per-page": _vm.perPage,
                      page: _vm.page,
                      "items-length": _vm.dataGroupsModal.total
                    }
                  }),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-caption display-option included-option text-sm-caption text-md-caption text-lg-caption text-xl-caption",
                      on: {
                        click: function() {
                          _vm.showOption = !_vm.showOption
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "display-option-title" }, [
                        _vm._v("\n        表示オプション\n      ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _c("v-icon", { attrs: { dense: "" } }, [
                            _vm._v("mdi-chevron-down")
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "pt-0 pb-0 mt-0" },
                [
                  _c(
                    "v-container",
                    { staticClass: "pt-0" },
                    [
                      _c("display-option", {
                        attrs: {
                          isShowOption: _vm.showOption,
                          "display-option-items": _vm.displayOptionItems,
                          disabled: _vm.loading,
                          isVaildOnly: _vm.isVaildOnly,
                          isVaildOnlyShow: false
                        },
                        on: {
                          "show-only-isVaild": function($event) {
                            return _vm.$emit("show-only-isVaild", $event)
                          }
                        },
                        model: {
                          value: _vm.selectedDisplayOption,
                          callback: function($$v) {
                            _vm.selectedDisplayOption = $$v
                          },
                          expression: "selectedDisplayOption"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-data-table", {
                staticClass:
                  "border-table scroll-table modal-table aff-modal-table",
                attrs: {
                  "must-sort": "",
                  headers: _vm.headers,
                  items: _vm.dataGroupsModal.data,
                  "items-per-page": _vm.perPage,
                  "item-key": "departmentId",
                  "no-data-text": "データーはありません",
                  loading: _vm.loading,
                  "loading-text": "データを読み込中です",
                  "hide-default-footer": "",
                  "custom-sort": _vm.customSort,
                  "show-expand": "",
                  "single-expand": ""
                },
                on: {
                  "item-expanded": function($event) {
                    return _vm.$emit("change-expanded", {
                      tab: _vm.tab,
                      change: $event
                    })
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.name",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("text-link", {
                            staticClass: "item-user-name",
                            attrs: { text: item.name },
                            on: {
                              click: function($event) {
                                return _vm.loadAffDetails(item)
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "item.data-table-expand",
                      fn: function(ref) {
                        var expand = ref.expand
                        var item = ref.item
                        var isExpanded = ref.isExpanded
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "white", elevation: "0" },
                              on: {
                                click: function($event) {
                                  return expand(!isExpanded)
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "item-count" },
                                [
                                  _c("v-icon", [_vm._v("mdi-account")]),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.userCount) +
                                      "\n                "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "mr-5 item-count" },
                                [
                                  _c("v-icon", [_vm._v("mdi-domain")]),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.groupCount) +
                                      "\n                "
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "transition",
                            { attrs: { name: "expand-icon" } },
                            [
                              isExpanded
                                ? _c("v-icon", [_vm._v("mdi-chevron-down")])
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.icons",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _vm.authority.isAdmin === true
                                          ? _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: { icon: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.loadEdit(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "mdi-square-edit-outline"
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_vm._v(" "), _c("span", [_vm._v("所属編集")])]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _vm.authority.isAdmin === true
                                          ? _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: { icon: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.$emit(
                                                          "click-delete-item",
                                                          item.departmentId
                                                        )
                                                      }
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "mdi-trash-can-outline"
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_vm._v(" "), _c("span", [_vm._v("削除")])]
                          )
                        ]
                      }
                    },
                    {
                      key: "expanded-item",
                      fn: function(ref) {
                        var headers = ref.headers
                        var item = ref.item
                        return [
                          _c(
                            "td",
                            {
                              staticClass: "pt-5 pb-5 expand-tab group-tab",
                              attrs: { colspan: headers.length }
                            },
                            [
                              [
                                _c(
                                  "v-tabs",
                                  {
                                    attrs: { transition: false },
                                    on: {
                                      change: function($event) {
                                        return _vm.$emit("changeTab", {
                                          tab: _vm.tab,
                                          departmentId: item.departmentId
                                        })
                                      }
                                    },
                                    model: {
                                      value: _vm.tab,
                                      callback: function($$v) {
                                        _vm.tab = $$v
                                      },
                                      expression: "tab"
                                    }
                                  },
                                  _vm._l(_vm.actionItems, function(item) {
                                    return _c(
                                      "v-tab",
                                      {
                                        key: item.tabname,
                                        staticClass: "log-tab"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.title) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                )
                              ],
                              _vm._v(" "),
                              _c(
                                "v-tabs-items",
                                {
                                  model: {
                                    value: _vm.tab,
                                    callback: function($$v) {
                                      _vm.tab = $$v
                                    },
                                    expression: "tab"
                                  }
                                },
                                _vm._l(_vm.actionItems, function(el) {
                                  return _c(
                                    "v-tab-item",
                                    {
                                      key: el.tabname,
                                      attrs: {
                                        transition: false,
                                        "reverse-transition": false
                                      }
                                    },
                                    [
                                      el.tabname === "tab1"
                                        ? _c(
                                            "v-card",
                                            [
                                              _c("UsersAllowedTable", {
                                                attrs: {
                                                  authority: _vm.authority,
                                                  departmentId:
                                                    item.departmentId,
                                                  dataList:
                                                    _vm.expandData.user.data,
                                                  isPage: "affiliation",
                                                  loading:
                                                    _vm.expandData.user.loading,
                                                  totalCount:
                                                    _vm.expandData.user.total,
                                                  perPage:
                                                    _vm.expandData.user.perPage,
                                                  page:
                                                    _vm.expandData.user.page,
                                                  users: _vm.expandData.userAll,
                                                  dataRelationUser:
                                                    _vm.dataRelationUser
                                                },
                                                on: {
                                                  "change-page": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "change-page-user",
                                                      {
                                                        agr: $event,
                                                        departmentId:
                                                          item.departmentId
                                                      }
                                                    )
                                                  },
                                                  "change-per-page": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "change-per-page-user",
                                                      {
                                                        agr: $event,
                                                        departmentId:
                                                          item.departmentId
                                                      }
                                                    )
                                                  },
                                                  "search-user": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "search-user",
                                                      {
                                                        agr: $event,
                                                        departmentId:
                                                          item.departmentId
                                                      }
                                                    )
                                                  },
                                                  "load-list-users": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "load-list-users",
                                                      { page: 1, perPage: 25 }
                                                    )
                                                  },
                                                  "add-user-to-group": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "add-user",
                                                      {
                                                        users: $event,
                                                        departmentId:
                                                          item.departmentId
                                                      }
                                                    )
                                                  },
                                                  "remove-user-to-group": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "remove-user",
                                                      {
                                                        users: $event,
                                                        departmentId:
                                                          item.departmentId
                                                      }
                                                    )
                                                  },
                                                  "load-users": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "changeTab",
                                                      {
                                                        tab: 0,
                                                        departmentId:
                                                          item.departmentId
                                                      }
                                                    )
                                                  },
                                                  "loading-from-child": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "loading-from-child",
                                                      $event
                                                    )
                                                  },
                                                  getDepartmentUserValid: function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "getDepartmentUserValid",
                                                      $event
                                                    )
                                                  },
                                                  "close-modal": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "changeTab",
                                                      {
                                                        tab: 0,
                                                        departmentId:
                                                          item.departmentId
                                                      }
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      el.tabname === "tab2"
                                        ? _c(
                                            "v-card",
                                            [
                                              _c("AccessPermissionGroupTable", {
                                                attrs: {
                                                  authority: _vm.authority,
                                                  dataGroupsModal:
                                                    _vm.expandData.group,
                                                  gateGroup:
                                                    _vm.expandData.gateGroup,
                                                  isPage: "affiliation",
                                                  loading:
                                                    _vm.expandData.group
                                                      .loading,
                                                  page:
                                                    _vm.expandData.group.page,
                                                  dataRelation:
                                                    _vm.dataRelationGroup,
                                                  groups:
                                                    _vm.expandData.groupAll
                                                },
                                                on: {
                                                  "expand-gate-group": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "expand-gate-group",
                                                      $event
                                                    )
                                                  },
                                                  "change-page": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "change-page-group",
                                                      {
                                                        page: $event,
                                                        departmentId:
                                                          item.departmentId
                                                      }
                                                    )
                                                  },
                                                  "change-page-gate-group": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "change-page-gate-group",
                                                      $event
                                                    )
                                                  },
                                                  "remove-group": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "remove-group",
                                                      {
                                                        groups: $event,
                                                        departmentId:
                                                          item.departmentId
                                                      }
                                                    )
                                                  },
                                                  "load-list-groups": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "load-list-groups",
                                                      { page: 1, perPage: 25 }
                                                    )
                                                  },
                                                  "add-group": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "add-group",
                                                      {
                                                        groups: $event,
                                                        departmentId:
                                                          item.departmentId
                                                      }
                                                    )
                                                  },
                                                  "change-page-group-all": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "change-page-group-all",
                                                      $event
                                                    )
                                                  },
                                                  "change-per-page-group-all": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "change-per-page-group-all",
                                                      $event
                                                    )
                                                  },
                                                  "search-group": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "search-group-department",
                                                      {
                                                        agr: $event,
                                                        departmentId:
                                                          item.departmentId
                                                      }
                                                    )
                                                  },
                                                  "close-modal": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "changeTab",
                                                      {
                                                        tab: 1,
                                                        departmentId:
                                                          item.departmentId
                                                      }
                                                    )
                                                  },
                                                  "reload-data-group": function(
                                                    $event
                                                  ) {
                                                    return _vm.$emit(
                                                      "changeTab",
                                                      {
                                                        tab: 1,
                                                        departmentId:
                                                          item.departmentId
                                                      }
                                                    )
                                                  },
                                                  "load-groups": function(
                                                    $event
                                                  ) {
                                                    _vm.$emit("changeTab", {
                                                      tab: 1,
                                                      departmentId:
                                                        item.departmentId
                                                    })
                                                    _vm.$emit(
                                                      "load-list-groups",
                                                      { page: 1, perPage: 25 }
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            2
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.groupSelected,
                  callback: function($$v) {
                    _vm.groupSelected = $$v
                  },
                  expression: "groupSelected"
                }
              }),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "mt-3",
                  attrs: { "no-gutters": "", justify: "space-between" }
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mr-5 mb-2" },
                    [
                      _c("select-per-page", {
                        attrs: {
                          page: _vm.page,
                          "per-page-items": [25, 50, 100, 1000],
                          "items-length": _vm.dataGroupsModal.total,
                          disabled: _vm.loading
                        },
                        model: {
                          value: _vm.innerPerPage,
                          callback: function($$v) {
                            _vm.innerPerPage = $$v
                          },
                          expression: "innerPerPage"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    staticClass: "mb-1",
                    attrs: {
                      "page-length": Math.ceil(
                        _vm.dataGroupsModal.total / _vm.perPage
                      ),
                      disabled: _vm.loading
                    },
                    model: {
                      value: _vm.innerPage,
                      callback: function($$v) {
                        _vm.innerPage = $$v
                      },
                      expression: "innerPage"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "1200", persistent: "" },
          model: {
            value: _vm.isShow,
            callback: function($$v) {
              _vm.isShow = $$v
            },
            expression: "isShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "modal-title", attrs: { color: "green" } },
                [
                  _vm._t("default", [
                    _vm.isAction === "detail"
                      ? _c(
                          "v-row",
                          {
                            attrs: {
                              "no-gutters": "",
                              justify: "space-between"
                            }
                          },
                          [
                            _vm._v("所属詳細\n              "),
                            _c(
                              "v-icon",
                              {
                                attrs: { color: "white" },
                                on: { click: _vm.clickBack }
                              },
                              [_vm._v("mdi-close")]
                            )
                          ],
                          1
                        )
                      : _c(
                          "v-row",
                          {
                            attrs: {
                              "no-gutters": "",
                              justify: "space-between"
                            }
                          },
                          [
                            _vm._v("所属編集\n              "),
                            _c(
                              "v-icon",
                              {
                                attrs: { color: "white" },
                                on: { click: _vm.clickBack }
                              },
                              [_vm._v("mdi-close")]
                            )
                          ],
                          1
                        )
                  ])
                ],
                2
              ),
              _vm._v(" "),
              _c("v-container", { staticStyle: { "padding-bottom": "0" } }, [
                _vm.successMessageLogs !== "" &&
                _vm.loading === false &&
                _vm.expandData.errorData.groups.length === 0 &&
                _vm.expandData.errorData.users.length === 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "font-weight-bold bg-success bg-no align-self-center mr-2"
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.successMessageLogs) +
                            "\n          "
                        )
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "font-weight-bold bg-error bg-no align-self-center mr-2"
                      },
                      [
                        _vm.successMessageLogs !== "" && _vm.loading === false
                          ? _c("p", [_vm._v(_vm._s(_vm.successMessageLogs))])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.errorMessageLogs !== "" && _vm.loading === false
                          ? _c("p", [_vm._v(_vm._s(_vm.errorMessageLogs))])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.expandData.errorData.users.length > 0 &&
                        _vm.loading === false
                          ? _c("p", [
                              _vm._v(
                                "登録失敗所属ユーザー: " +
                                  _vm._s(
                                    _vm.expandData.errorData.users.toString()
                                  )
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.expandData.errorData.groups.length > 0 &&
                        _vm.loading === false
                          ? _c("p", [
                              _vm._v(
                                "設定失敗通行権限グループ: " +
                                  _vm._s(
                                    _vm.expandData.errorData.groups.toString()
                                  )
                              )
                            ])
                          : _vm._e()
                      ]
                    )
              ]),
              _vm._v(" "),
              _c(
                "v-container",
                [
                  _vm.isAction === "detail"
                    ? _c(
                        "v-row",
                        { staticClass: "btn-div", attrs: { justify: "end" } },
                        [
                          _c(
                            "div",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "btn-add pt-0",
                                  attrs: { cols: "6" }
                                },
                                [
                                  _vm.authority.isAdmin
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn-red",
                                          attrs: { outlined: "" },
                                          on: {
                                            click: function($event) {
                                              _vm.isConfirm = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  所属を削除する\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "btn-add pt-0",
                                  attrs: { cols: "6" }
                                },
                                [
                                  _vm.authority.isAdmin
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn-primary",
                                          attrs: { outlined: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.editAff()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  所属情報を編集する\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAction === "detail"
                    ? _c("div", { staticClass: "title-block-card" }, [
                        _vm._v("所属情報")
                      ])
                    : _c("div", { staticClass: "title-block-card" }, [
                        _vm._v("所属情報")
                      ]),
                  _vm._v(" "),
                  _vm.isAction === "detail"
                    ? _c(
                        "v-card",
                        { staticClass: "affiliation-information" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { staticClass: "col-12" }, [
                                _c("div", [_vm._v("所属名 ")])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { staticClass: "col-12" }, [
                                _c("div", { staticClass: "weight-bold" }, [
                                  _vm._v(" " + _vm._s(_vm.departments.name))
                                ])
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { staticClass: "col-12" }, [
                                _c("div", [_vm._v("所属管理者 ")])
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.expandData.departmentAd.data, function(
                            el
                          ) {
                            return _c(
                              "v-row",
                              { key: el.relationId, staticClass: "ml-5" },
                              [
                                _c("v-col", { staticClass: "col-2" }, [
                                  _c("div", { staticClass: "weight-bold" }, [
                                    _vm._v(_vm._s(el.userName) + " ")
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { staticClass: "col-8" },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "item-check-box-detail" },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            color: "green",
                                            dense: "",
                                            "hide-details": "",
                                            label: "ユーザー追加権限",
                                            disabled: ""
                                          },
                                          model: {
                                            value: el.isAdminToAddUser,
                                            callback: function($$v) {
                                              _vm.$set(
                                                el,
                                                "isAdminToAddUser",
                                                $$v
                                              )
                                            },
                                            expression: "el.isAdminToAddUser"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("v-checkbox", {
                                          attrs: {
                                            color: "green",
                                            dense: "",
                                            "hide-details": "",
                                            label: "ユーザー編集権限",
                                            disabled: ""
                                          },
                                          model: {
                                            value: el.isAdminToEditUser,
                                            callback: function($$v) {
                                              _vm.$set(
                                                el,
                                                "isAdminToEditUser",
                                                $$v
                                              )
                                            },
                                            expression: "el.isAdminToEditUser"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAction === "edit"
                    ? _c(
                        "v-card",
                        { staticClass: "affiliation-information" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { staticClass: "col-12" }, [
                                _c("div", [_vm._v("所属名 ")])
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "col-12" },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "AffAddForm",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    [
                                      _c("input-form", {
                                        attrs: {
                                          rules: [
                                            _vm.rulesValidate.requiredName
                                          ]
                                        },
                                        model: {
                                          value: _vm.departmentInfo.name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.departmentInfo,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "departmentInfo.name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { staticClass: "col-12" }, [
                                _c("div", [_vm._v("所属管理者 ")])
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.listAff, function(item, i) {
                            return _c(
                              "v-row",
                              { key: i, staticClass: "mt-2" },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "col-4" },
                                  [
                                    _c("v-combobox", {
                                      staticClass: "v-select-group",
                                      attrs: {
                                        items: _vm.dataAllow,
                                        autocomplete: "off",
                                        color: "green",
                                        "hide-details": "auto",
                                        "no-data-text": "",
                                        outlined: "",
                                        dense: "",
                                        disabled: item.disabled,
                                        "menu-props": {
                                          contentClass: "v-select-item"
                                        }
                                      },
                                      on: { change: _vm.changeAllow },
                                      model: {
                                        value: item.userId,
                                        callback: function($$v) {
                                          _vm.$set(item, "userId", $$v)
                                        },
                                        expression: "item.userId"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { staticClass: "col-6" },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "item-check-box-detail" },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            color: "green",
                                            dense: "",
                                            "hide-details": "",
                                            label: "ユーザー追加権限"
                                          },
                                          model: {
                                            value: item.checkOne,
                                            callback: function($$v) {
                                              _vm.$set(item, "checkOne", $$v)
                                            },
                                            expression: "item.checkOne"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("v-checkbox", {
                                          attrs: {
                                            color: "green",
                                            dense: "",
                                            "hide-details": "",
                                            label: "ユーザー編集権限"
                                          },
                                          model: {
                                            value: item.checkTwo,
                                            callback: function($$v) {
                                              _vm.$set(item, "checkTwo", $$v)
                                            },
                                            expression: "item.checkTwo"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mt-0 pt-0" },
                            [
                              _c("v-col", { staticClass: "col-2 pt-0" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "v-subheader theme--light ml-0 pa-0 mt-0 add-button",
                                    on: { click: _vm.addAffiliation }
                                  },
                                  [_vm._v(" + 追加する ")]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "title-block-card" }, [
                    _vm._v("所属ユーザー")
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c("UsersAllowedTable", {
                        attrs: {
                          authority: _vm.authority,
                          departmentId: _vm.departments.departmentId,
                          dataList: _vm.expandData.user.data,
                          isPage: "affiliation",
                          loading: _vm.expandData.user.loading,
                          totalCount: _vm.expandData.user.total,
                          perPage: _vm.expandData.user.perPage,
                          page: _vm.expandData.user.page,
                          users: _vm.expandData.userAll,
                          dataRelationUser: _vm.dataRelationUser
                        },
                        on: {
                          "change-page": function($event) {
                            return _vm.$emit("change-page-user", {
                              agr: $event,
                              departmentId: _vm.departments.departmentId
                            })
                          },
                          "change-per-page": function($event) {
                            return _vm.$emit("change-per-page-user", {
                              agr: $event,
                              departmentId: _vm.departments.departmentId
                            })
                          },
                          "search-user": function($event) {
                            return _vm.$emit("search-user", {
                              agr: $event,
                              departmentId: _vm.departments.departmentId
                            })
                          },
                          "load-list-users": function($event) {
                            return _vm.$emit("load-list-users", {
                              page: 1,
                              perPage: 25
                            })
                          },
                          "add-user-to-group": function($event) {
                            return _vm.$emit("add-user", {
                              users: $event,
                              departmentId: _vm.departments.departmentId
                            })
                          },
                          "change-page-user-all": function($event) {
                            return _vm.$emit("change-page-user-all", $event)
                          },
                          "change-per-page-user-all": function($event) {
                            return _vm.$emit("change-per-page-user-all", $event)
                          },
                          "remove-user-to-group": function($event) {
                            return _vm.$emit("remove-user", {
                              users: $event,
                              departmentId: _vm.departments.departmentId
                            })
                          },
                          "load-users": function($event) {
                            return _vm.$emit("changeTab", {
                              tab: 0,
                              departmentId: _vm.departments.departmentId
                            })
                          },
                          "close-modal": function($event) {
                            _vm.$emit("changeTab", {
                              tab: 0,
                              departmentId: _vm.departments.departmentId
                            })
                            _vm.$emit("load-list-users-again")
                          },
                          "loading-from-child": function($event) {
                            return _vm.$emit("loading-from-child", $event)
                          },
                          getDepartmentUserValid: function($event) {
                            return _vm.$emit("getDepartmentUserValid", $event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title-block-card" }, [
                    _vm._v("設定されている通行権限グループ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c("AccessPermissionGroupTable", {
                        attrs: {
                          authority: _vm.authority,
                          dataGroupsModal: _vm.expandData.group,
                          gateGroup: _vm.expandData.gateGroup,
                          isPage: "affiliation",
                          loading: _vm.expandData.group.loading,
                          page: _vm.expandData.group.page,
                          dataRelation: _vm.dataRelationGroup,
                          groups: _vm.expandData.groupAll
                        },
                        on: {
                          "expand-gate-group": function($event) {
                            return _vm.$emit("expand-gate-group", $event)
                          },
                          "change-page": function($event) {
                            return _vm.$emit("change-page-group", {
                              page: $event,
                              departmentId: _vm.departments.departmentId
                            })
                          },
                          "change-page-gate-group": function($event) {
                            return _vm.$emit("change-page-gate-group", $event)
                          },
                          "remove-group": function($event) {
                            return _vm.$emit("remove-group", {
                              groups: $event,
                              departmentId: _vm.departments.departmentId
                            })
                          },
                          "load-list-groups": function($event) {
                            return _vm.$emit("load-list-groups", {
                              page: 1,
                              perPage: 25
                            })
                          },
                          "add-group": function($event) {
                            return _vm.$emit("add-group", {
                              groups: $event,
                              departmentId: _vm.departments.departmentId
                            })
                          },
                          "change-page-group-all": function($event) {
                            return _vm.$emit("change-page-group-all", $event)
                          },
                          "change-per-page-group-all": function($event) {
                            return _vm.$emit(
                              "change-per-page-group-all",
                              $event
                            )
                          },
                          "reload-data-group": function($event) {
                            return _vm.$emit("reload-data-group", {
                              departmentId: _vm.departments.departmentId
                            })
                          },
                          "search-group": function($event) {
                            return _vm.$emit("search-group-department", {
                              agr: $event,
                              departmentId: _vm.departments.departmentId
                            })
                          },
                          "back-group-detail": function($event) {
                            return _vm.$emit("back-group-detail", $event)
                          },
                          "close-modal": function($event) {
                            return _vm.$emit("changeTab", {
                              tab: 1,
                              departmentId: _vm.departments.departmentId
                            })
                          },
                          "load-groups": function($event) {
                            _vm.$emit("changeTab", {
                              tab: 1,
                              departmentId: _vm.departments.departmentId
                            })
                            _vm.$emit("load-list-groups", {
                              page: 1,
                              perPage: 25
                            })
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _vm.successMessageLogs !== "" && _vm.loading === false
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "font-weight-bold bg-success bg-no align-self-center mr-2"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.successMessageLogs) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorMessageLogs !== "" && _vm.loading === false
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "font-weight-bold bg-error bg-no align-self-center mr-2"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errorMessageLogs) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isAction === "edit"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.updateAff()
                                }
                              }
                            },
                            [_vm._v("\n            保存する\n          ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "800", persistent: "" },
          model: {
            value: _vm.isConfirm,
            callback: function($$v) {
              _vm.isConfirm = $$v
            },
            expression: "isConfirm"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("ModalTitle", {
                attrs: { title: "所属を削除" },
                on: {
                  click: function($event) {
                    _vm.isConfirm = false
                  }
                }
              }),
              _vm._v(" "),
              _vm.loading === false &&
              (_vm.expandData.departmentAd.sucessMessage !== "" ||
                _vm.expandData.departmentAd.errorMessage !== "")
                ? _c("v-card-text", { staticClass: "mt-5" }, [
                    _vm.expandData.departmentAd.sucessMessage !== "" &&
                    _vm.loading === false
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "mb-4 font-weight-bold mt-2 bg-success bg-no"
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.expandData.departmentAd.sucessMessage
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.expandData.departmentAd.errorMessage !== "" &&
                    _vm.loading === false
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "mb-4 font-weight-bold mt-2 bg-error bg-no"
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.expandData.departmentAd.errorMessage
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                : _c("v-card-text", { staticClass: "mt-5" }, [
                    _vm.departments.userCount > 0
                      ? _c("p", [
                          _vm._v(
                            " 削除しようとしている所属に、ユーザが所属しております。所属を削除した場合、ユーザの所属先が解除され、無所属状態となります。削除してもよろしいでしょうか？"
                          )
                        ])
                      : _c("p", [
                          _vm._v("この所属との関連付けを解除しますか？")
                        ])
                  ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _vm.loading === false &&
                  (_vm.expandData.departmentAd.sucessMessage !== "" ||
                    _vm.expandData.departmentAd.errorMessage !== "")
                    ? _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _vm.expandData.departmentAd.sucessMessage !== ""
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.loadAffDetailsAgain(),
                                        _vm.$emit("get-list-user")
                                    }
                                  }
                                },
                                [_vm._v("\n            OK\n          ")]
                              )
                            : _vm.expandData.departmentAd.errorMessage !== ""
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "btn-secondary",
                                  attrs: { outlined: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.isConfirm = false
                                    }
                                  }
                                },
                                [_vm._v("\n            OK\n          ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        { staticClass: "ma-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: { outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.removeAff()
                                }
                              }
                            },
                            [_vm._v("\n            OK\n          ")]
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }