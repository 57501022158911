










































































































































































































































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import CardListTable from '@/components/organisms/CardListTable/CardListTable.vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import CardImport from '@/components/organisms/CardImport/CardImport.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import { CardDetail } from '@/types/alligate';

export interface TypeItem {
  title: string;
}
export interface DataType {
  searchTargetItems: SearchTargetItem[];
  actionItems: ActionItem[];
  selectedpCodeIds: string[];
}

export default Vue.extend({
  name: 'CardListTemplate',
  components: {
    CancelButton,
    Searchbox,
    CardListTable,
    Breadcrumbs,
    CardImport,
    ActionButton,
  },
  props: {
    cardTotalCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataCards: {
      type: Array as PropType<CardDetail[]>,
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },

    // CSV
    showProgressExportCSV: {
      type: Boolean,
      default: false,
    },

    // Excel
    progressCsvXlsx: {
      type: Number,
      default: 0,
    },
    showModalImport: {
      type: Boolean,
      default: false,
    },
    showProgressImport: {
      type: Boolean,
      default: false,
    },
    successItemImport: {
      type: Number,
      default: 0,
    },
    totalDataRowImport: {
      type: Number,
      default: 0,
    },
    errorMessageImport: {
      default: [],
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    sheetUserName: {
      type: String,
      default: '',
    },
    sheet2Name: {
      type: String,
      default: '',
    },
    isShowViewCSV: {
      type: Boolean,
      default: false,
    },
    errorMessageCsvXlsx: {
      type: String,
      default: '',
    },

    // Disable, enable Pcodes
    showProgress: {
      type: Boolean,
      default: false,
    },
    errorMessageProcess: {
      default: [],
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    isFinishProcessEnable: {
      type: Boolean,
      default: false,
    },
    typeAction: {
      type: Number,
      default: 0,
    },
    showConfirm: {
      type: Boolean,
      default: false,
    },
    isGetDataList: {
      type: Boolean,
      default: false,
    },
    isHasLowerCaseCardNumberCSV: {
      type: Boolean,
      default: false,
    },
    isHasLowerCaseCardNumberExcel: {
      type: Boolean,
      default: false,
    },
    successItemSheet2: {
      type: Number,
      default: 0,
    },
    progressSheet2: {
      type: Number,
      default: 0,
    },
    totalDataRowSheet2: {
      type: Number,
      default: 0,
    },
    typeConfirm: {
      type: Number,
      default: 0,
    },
    showModalConfirmEmail: {
      type: Boolean,
      default: false,
    },
    showModalProcess: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'カード', disabled: true },
        ];
      },
    },
  },
  data: (): DataType => ({
    actionItems: [
      { title: '有効化する', emitName: 'click-enable' },
      { title: '無効化する', emitName: 'click-disable' },
    ],
    searchTargetItems: [
      { label: 'カード番号', value: 'pCodeId'},
      { label: '名称', value: 'name'},
      { label: 'ユーザーID', value: 'userId'},
    ],
    selectedpCodeIds: [],
  }),
});
