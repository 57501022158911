














































































import Vue from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import ControlPanelDetail from '@/components/organisms/ControlPanelDetail/ControlPanelDetail.vue';
import ControlPanelAddPage from '@/components/pages/controlPanel/ControlPanelAddPage.vue';
import GateDetailPage from '../../pages/gate/GateDetailPage.vue';
import * as Config from '@/config';
export default Vue.extend({
  name: 'ControlPanelDetailTemplate',
  components: {
    Breadcrumbs,
    ControlPanelDetail,
    ControlPanelAddPage,
    GateDetailPage,
  },
  props: {
    errorMessageLogs: {
      type: String,
      default: '',
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    showModalClear: {
      type: Boolean,
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageModal: {
      type: String,
      default: '',
    },
    successMessageModal: {
      type: String,
      default: '',
    },
    controlPanelDetail: {
      default: {},
    },
    gateList: {
      default: [],
    },
    dataAllUses: {
      default: [],
    },
    errorMessageProcess: {
      default: [],
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    isFinishProcess: {
      type: Boolean,
      default: false,
    },
    successItemProcess: {
      type: Number,
      default: 0,
    },
    totalDataRowProcess: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    countUsers: {
      type: Number,
      default: 0,
    },
    isControlPanelEditModalShow: {
      type: Boolean,
      default: 0,
    },
    isGateDetailModalShow: {
      type: Boolean,
      default: 0,
    },
    authority: {
      type: Object,
      default: ({}),
    },
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: '制御盤', disabled: true },
          { text: '制御盤詳細', disabled: true },
        ];
      },
    },
  },
  methods: {
    deleteBridgeProperty() {
      this.$emit('ChangeProperty');
    },
  },
});
